import React from 'react';
import {
  Stack, mergeStyleSets, ImageFit, Image, DefaultPalette, MessageBarType,
} from '@fluentui/react';
import { isMobile } from 'react-device-detect';
import ChartDefaults from '../../shared/ChartDefaults';
import MultiLineUserText from '../../shared/MultiLineUserText';
import OutputCardSelector from './OutputCardSelector';
import tokens from '../../shared/StackTokens';
import CardChart, { CardInfo as CardChartInfo } from './CardChart';
import CardSummaryStatistics, { CardInfo as CardSummaryStatisticsInfo } from './CardSummaryStatistics';
import CardChartData, { CardInfo as CardChartDataInfo } from './CardChartData';
import CardRawData, { CardInfo as CardRawDataInfo } from './CardRawData';
import OutputCardActionSelector from './OutputCardActionSelector';
import Store from '../../shared/state/Store';
import DataReducer from '../../shared/state/DataReducer';
import ConfigReducer from '../../shared/state/ConfigReducer';
import OutputSettingsButton from './OutputSettingsButton';
import Utils from '../../shared/Utils';
import Theme from '../../shared/Theme';
import MessageBar from '../../shared/MessageBar';

const oStyleValues = {
  commonLabel: {
    overflow: 'hidden',
    'white-space': 'nowrap',
  },
  item: {
    background: ChartDefaults.OutputItemBackgroundColor,
    'padding-bottom': '5px',
  },
  itemDesktopDefault: {
    width: '48.2vw',
  },
  itemDesktop: {
    margin: '2px',
  },
  itemName: {
    color: DefaultPalette.themeDark,
  },
  itemRow: {
    // background: 'red',
    padding: '2px 0',
    // background: DefaultPalette.themeLighterAlt,
    background: 'white',
  },
  itemRowLabel: {
    // background: 'cyan',
    width: '25%',
    padding: '5px 5px 5px 0',
    textAlign: 'right',
    'font-weight': 'bold',
  },
  itemRowValueText: {
    width: '20%',
    padding: '5px 0 5px 5px',
    textAlign: 'left',
    'font-weight': 'bold',
  },
  itemRowValueGraph: {
    // background: 'lime',
    selectors: {
      span: {
        display: 'inline-block',
        padding: '5px 15px',
        background: DefaultPalette.themePrimary,
        color: 'white',
      },
    },
  },

  legendIcon: {
    display: 'inline-block',
    width: '16px',
    height: '16px',
    'vertical-align': 'middle',
    'margin-right': '5px',
  },

  legendName: {
    'line-height': '16px',
    'vertical-align': 'middle',
    'margin-right': '15px',
  },

  iconContainer: {
    width: '40px',
    height: '40px',
    paddingRight: '5px',
    paddingLeft: '5px',
  },

  itemNameWIcon: {
    lineHeight: '40px',
  },

  graphDescription: {
    fontSize: 'smaller',
    padding: '0',
    margin: '0',
  },

  multiLineText: {
    whiteSpace: 'pre-line',
  },

  actions: {
    margin: '5px 0 0 0',

    selectors: {
      button: {
        minWidth: 'auto',
        padding: '0',
        border: 'none',
        background: 'none !important',
      },

      'button > span': {
        display: 'initial',
      },
      'button i': {
        fontWeight: 'bold',
        fontSize: '15px',
        lineHeight: '15px',
        color: 'rgb(50, 49, 48)',
      },
      'button:hover:enabled i': {
        color: 'rgb(0, 120, 212)',
      },
    },
  },

};
const oStyle = mergeStyleSets(oStyleValues);

export default ({
  config,
}) => {
  const [globalState, dispatch] = Store.useStore();

  let width = '100%';
  if (!isMobile) {
    width = config.width ? config.width.trim() : oStyleValues.itemDesktopDefault.width;
    if (width.match(/^[0-9]+$/)) {
      width += 'px';
    }
  }

  const headerClass = oStyle.itemName + ((config.icon && config.icon !== '') ? ` ${oStyle.itemNameWIcon}` : '');

  // config.curveFunction = fname;

  let itemStyle = {
    width,
    padding: '5px',
  };

  const itemClass = `${config.selectedView} output-item ${oStyle.item} ${isMobile ? '' : oStyle.itemDesktop}`;

  if (config.backgroundColor) {
    itemStyle.backgroundColor = config.backgroundColor;
  }

  const borderStyles = config.border ? { ...config.border } : { ...ChartDefaults.OutputItemBorder };
  itemStyle = { ...itemStyle, ...borderStyles };

  const key = Utils.createNetworkNodeKey(config.network, config.node);
  const resultCache = DataReducer.getCachedResultsForDataSetAndKey(globalState);

  let noData = true;
  Object.keys(resultCache).forEach((caseId) => {
    if (Object.keys(resultCache[caseId]).length > 0) {
      noData = false;
    }
  });

  const activeCard = config.selectedView || CardChartInfo.key;
  const setActiveCard = (card) => {
    const updatedItem = { ...config };
    updatedItem.selectedView = card;
    dispatch(ConfigReducer.updateOutputItem(updatedItem));
  };

  const [activeCardActions, setActiveCardActions] = React.useState([]);
  const [actionRequested, requestAction] = React.useState();
  React.useEffect(() => {
    let actions = [];
    switch (activeCard) {
      case CardChartInfo.key:
        actions = CardChartInfo.actions;
        break;
      case CardSummaryStatisticsInfo.key:
        actions = CardSummaryStatisticsInfo.actions;
        break;
      case CardChartDataInfo.key:
        actions = CardChartDataInfo.actions;
        break;
      case CardRawDataInfo.key:
        actions = CardRawDataInfo.actions;
        break;
      default:
    }
    setActiveCardActions(actions);
  }, [activeCard]);

  return (
    <Stack
      vertical
      key={key}
      className={itemClass}
      style={itemStyle}
    >
      <Stack
        horizontal
        tokens={tokens.spacing}
      >
        {
          config.icon && config.icon !== ''
          && (
          <div
            className={oStyle.iconContainer}
          >
            <Image
              src={config.icon}
              imageFit={ImageFit.contain}
              maximizeFrame
            />
          </div>
          )
        }

        <Stack.Item grow={10}>
          <h5 className={headerClass}>
            {config.label}
          </h5>
        </Stack.Item>

        <Stack
          vertical
          className={[oStyle.actions, 'output-item-actions'].join(' ')}
        >
          <div className={Theme.styles.noPrint}>
            <Stack
              horizontal
              tokens={{ childrenGap: '0 5' }}
            >

              <OutputSettingsButton
                config={config}
              />

              <OutputCardActionSelector
                actions={activeCardActions}
                requestAction={requestAction}
              />

              <OutputCardSelector
                config={config}
                activeCard={activeCard}
                setActiveCard={setActiveCard}
                requestAction={requestAction}
              />

            </Stack>

          </div>

        </Stack>

      </Stack>

      {config.description && <MultiLineUserText className={oStyle.graphDescription}>{config.description}</MultiLineUserText>}

      {noData ? (
        <MessageBar
          messageBarType={MessageBarType.severeWarning}
          text="No data"
        />
      ) : (
        <>
          {
        activeCard === CardChartInfo.key
        && (
        <CardChart
          config={config}
          actionRequested={actionRequested}
        />
        )
      }

          {
        activeCard === CardSummaryStatisticsInfo.key
        && (
          <CardSummaryStatistics
            resultCache={resultCache}
            config={config}
            actionRequested={actionRequested}
          />
        )
      }

          {
        activeCard === CardChartDataInfo.key
        && (
          <CardChartData
            resultCache={resultCache}
            config={config}
            actionRequested={actionRequested}
          />
        )
      }

          {
        activeCard === CardRawDataInfo.key
        && (
          <CardRawData
            resultCache={resultCache}
            config={config}
            actionRequested={actionRequested}
          />
        )
      }
        </>
      )}
    </Stack>
  );
};
