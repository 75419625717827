import React from 'react';
import {
  ActionButton,
  DefaultButton, Image, ImageFit,
} from '@fluentui/react';

import Store from '../../shared/state/Store';
import UiReducer from '../../shared/state/UiReducer';

export default ({
  imageUrl, setImageUrl, fileSizeLimit, buttonText, width, height, className,
}) => {
  const [, dispatch] = Store.useStore();
  const browseButtonRef = React.useRef(null);

  const clickSelectFile = () => {
    if (browseButtonRef.current) {
      browseButtonRef.current.click();
    }
  };

  const resetFileBrowseButtonSelection = () => {
    if (browseButtonRef.current) {
      browseButtonRef.current.value = '';
    }
  };

  const loadFile = (event) => {
    if (event.target.files?.length > 0) {
      const file = event.target.files[0];
      const { size } = file;

      if (fileSizeLimit && size > fileSizeLimit) {
        dispatch(UiReducer.uiSetErrors([`File size too big, max ${fileSizeLimit / 1024} kib allowed`]));
        resetFileBrowseButtonSelection();
        return;
      }

      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        setImageUrl(e.target.result);
      };
      fileReader.readAsDataURL(file);
    }
  };

  return (
    <div className={className}>
      <div>
        <span>
          <DefaultButton
            text={buttonText || 'Select Image'}
            onClick={clickSelectFile}
          />

          <input ref={browseButtonRef} type="file" accept="image/*" onChange={loadFile} style={{ display: 'none' }} />

          <ActionButton
            disabled={!imageUrl || imageUrl === ''}
            title="Clear"
            onClick={() => {
              setImageUrl('');
            }}
            iconProps={{
              iconName: 'Clear',
            }}
            style={{
              height: '32px',
              lineHeight: '32px',
              verticalAlign: 'bottom',
            }}
          />
        </span>
      </div>

      {imageUrl && imageUrl !== ''
        && (
        <div
          style={{
            marginTop: '10px',
            width: width || 'auto',
            height: height || 'auto',
          }}
        >
          <Image
            src={imageUrl}
            imageFit={(width || height) ? ImageFit.contain : ImageFit.none}
            maximizeFrame
            style={{
              maxWidth: '98vw',
            }}
          />
        </div>
        )}
    </div>
  );
};
