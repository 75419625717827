import React from 'react';
import Store from './state/Store';
import ModalGeneric from './ModalGeneric';
import UiReducer from './state/UiReducer';

export default () => {
  const [globalState, dispatch] = Store.useStore();
  const simpleMessage = UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.GeneralSimpleMessageModal);
  return (
    <ModalGeneric
      initialIsOpen={!!simpleMessage}
      title={simpleMessage.title}
      styles={{
        modal: {
          main: {
            width: 'min-content',
          },
        },
      }}
      onClose={() => {
        dispatch(UiReducer.setGenericUiAttribute(UiReducer.Keys.GeneralSimpleMessageModal));
      }}
      key={!!simpleMessage}
      message={simpleMessage.message}
      messages={simpleMessage.messages}
      content={simpleMessage.content}
    />
  );
};
