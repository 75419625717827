import React from 'react';
import InputConfirmationDialog from '../../shared/InputConfirmationDialog';
import Store from '../../shared/state/Store';
import UiReducer from '../../shared/state/UiReducer';
import Utils from '../../shared/Utils';
import GraphUtils from '../GraphUtils';
import ModellerDefaults from '../ModellerDefaults';
import ModellerReducer from '../ModellerReducer';
import CytoReducer from '../ModelPreview/cytoscape/CytoReducer';

export default () => {
  const [globalState, dispatch] = Store.useStore();
  const data = UiReducer.getGenericUiAttribute(globalState, ModellerDefaults.ComponentKeys.ModellerDataEntryPopupKey) || {};

  if (data?.type !== 'numeric') {
    return <></>;
  }

  return (
    <>
      <InputConfirmationDialog
        uiElementId={ModellerDefaults.ComponentKeys.ModellerDataEntryPopupKey}
        title={`Enter data for case: ${data?.datasetId || ''}`}
        text={`Enter data for node "${Utils.createFriendlyReference(data.nodeId, data.nodeName)}" in network "${Utils.createFriendlyReference(data.networkId, data.networkName)}"`}
        inputPlaceholder="Value"
        primaryButtonText="Submit"
        hideOnAction
        validateValue={(val) => {
          const value = val.trim();
          if (value === '') {
            return value;
          }

          let validatedValue;
          try {
            validatedValue = GraphUtils.validateInputValue({
              value,
              validationRules: { type: (data.nodeData.type === 'IntegerInterval') ? 'int' : 'float' },
            });
          } catch (error) {
            throw new Error(error.message);
          }
          return validatedValue;
        }}
        primaryCallback={async (value) => {
          dispatch(ModellerReducer.setObservation(data.datasetId, data.networkId, data.nodeId, value));
          dispatch(CytoReducer.setRepaintRequested());
        }}
      />

    </>
  );
};
