import React from 'react';
import { MessageBarType, Stack } from '@fluentui/react';
import {
  useParams, Routes, Route,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import Store from '../../shared/state/Store';
import Routing from '../../shared/Routing';
import AccountsState from './AccountsState';
import AccountSubscription from './AccountSubscription';
import AccountKeys from './AccountKeys';
import PlaceholderError from '../../shared/PlaceholderError';
import User from '../../shared/User';
import AdminApi from '../../shared/util/AdminApi';
import MessageBar from '../../shared/MessageBar';

export default () => {
  const [globalState, dispatch] = Store.useStore();
  const navigate = useNavigate();
  const location = useLocation();
  const routingTree = Routing.getTree(globalState).home.routes.administration.routes.accounts.routes.account;
  const DefaultNavOption = routingTree.routes.subscription;

  const uuid = useParams().id;
  const [errors, setErrors] = React.useState();

  const [relatedAccounts, setRelatedAccounts] = React.useState();

  React.useEffect(() => () => {
    // Unset selected account when leaving this component
    AccountsState.setRelatedAccounts(dispatch, undefined);
  }, []);

  React.useEffect(() => {
    (async () => {
      if (!User.getCurrentUser()/* || relatedAccounts */) {
        return;
      }
      // Even if related accounts were loaded, load them again to refresh

      // // Related accounts might have been loaded already
      // const sRelatedAccounts = AccountsState.getRelatedAccounts(globalState);
      // if (sRelatedAccounts?.primaryAccount?.uuid === uuid) {
      //   setRelatedAccounts(sRelatedAccounts);
      //   return;
      // }

      const fRelatedAccounts = await AdminApi.getRelatedAccounts({ uuid });
      if (fRelatedAccounts.responseStatus === 200) {
        // AccountsState.setSelectedAccount(dispatch, fRelatedAccounts.primaryAccount);
        AccountsState.setRelatedAccounts(dispatch, fRelatedAccounts);
      } else {
        setErrors(fRelatedAccounts.messages);
      }
    })();
  }, [location.pathname]);

  React.useEffect(() => {
    const fRelatedAccounts = AccountsState.getRelatedAccounts(globalState);
    if (fRelatedAccounts?.primaryAccount?.uuid === uuid) {
      setRelatedAccounts(fRelatedAccounts);
    }
  },
  [AccountsState.getRelatedAccounts(globalState)]);

  React.useEffect(() => {
    if (relatedAccounts && location.pathname === routingTree.path.replace(':id', uuid)) {
      // If no subcomponent selected, navigate to default subcomponent
      navigate(DefaultNavOption.path.replace(':id', uuid), { replace: true });
    }
  },
  [relatedAccounts]);

  if (!relatedAccounts && !errors) {
    return (
      <MessageBar
        messageBarType={MessageBarType.info}
        text="Loading"
      />
    );
  }

  if (!relatedAccounts && errors) {
    return <PlaceholderError messages={errors} />;
  }

  return (
    <Stack vertical>
      <header>
        Account:&nbsp;
        {relatedAccounts.primaryAccount.email}
      </header>
      <Routes>
        <Route element={<AccountSubscription relatedAccounts={relatedAccounts} />} path={routingTree.routes.subscription.getRelativePath(routingTree.path)} />
        <Route element={<AccountKeys relatedAccounts={relatedAccounts} />} path={routingTree.routes.licenseKeys.getRelativePath(routingTree.path)} />
      </Routes>
    </Stack>

  );
};
