import { DefaultButton } from '@fluentui/react';
import React from 'react';
import Store from '../../shared/state/Store';
import UiReducer from '../../shared/state/UiReducer';
import Theme from '../../shared/Theme';
import ModellerUtils from '../ModellerUtils';
import ModellerDefaults from '../ModellerDefaults';
import ConfigReducer from '../../shared/state/ConfigReducer';
import AppReducer from '../../shared/state/AppReducer';

export default ({ mode }) => {
  const [globalState, dispatch] = Store.useStore();
  // const [button, setButton] = React.useState();

  // const items = [
  //   {
  //     key: 'runCalculation',
  //     text: 'Run calculation',
  //     onClick: () => {
  //       ModellerUtils.calculate(globalState, dispatch);
  //     },
  //   },
  // ];

  const [cancelObject] = React.useState({ isCancelled: false });
  const wappdMode = globalState.config?.generalConfig?.appViewEnabled && mode === ModellerDefaults.OperationMode.ModellerWebApp;
  const { generalConfig } = ConfigReducer.getConfig(globalState);
  const modelPath = (wappdMode && generalConfig?.serverModelPathEnabled) ? generalConfig?.serverModelPath : undefined;
  const appData = AppReducer.getAppData(globalState);
  const appId = wappdMode ? appData?.uuid : undefined;

  return (
    <>
      {/* <DefaultButton
        text="Calculate"
        className={Styles.menuButton}
        menuProps={{
          className: Styles.menuItemList,
          calloutProps: {
            minPagePadding: 0,
          },
          items,
        }}
        onMenuClick={(event) => {
          setButton(event.target.closest('button'));
        }}
        onAfterMenuDismiss={() => {
          button.blur();
        }}
      /> */}
      <DefaultButton
        text="Calculate"
        iconProps={{ iconName: 'MSNVideos' }}// CaretHollow //Play
        onClick={() => {
          ModellerUtils.calculate(globalState,
            dispatch,
            () => cancelObject.isCancelled, () => { cancelObject.isCancelled = false; },
            appId,
            modelPath);

          dispatch(UiReducer.uiSetLoading({
            cancelAction: () => { cancelObject.isCancelled = true; },
          }));
        }}
        className={Theme.styles.outlineControlButton}
      />
    </>
  );
};
