/* eslint-disable max-len */
import {
  DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton, Text, TextField,
} from '@fluentui/react';
import React from 'react';
import Utils from '../../shared/Utils';
import Store from '../../shared/state/Store';
import UiReducer from '../../shared/state/UiReducer';
import InputConfirmationDialog from '../../shared/InputConfirmationDialog';
import User from '../../shared/User';
import AppsApi from '../../shared/util/AppsApi';

export default () => {
  const [globalState, dispatch] = Store.useStore();

  const licenseKey = UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.AccountKeyAssignmentDialogOpen);
  const textFieldRef = React.useRef();
  const applyButtonRef = React.useRef();

  const hide = () => {
    dispatch(UiReducer.setGenericUiAttribute(UiReducer.Keys.AccountKeyAssignmentDialogOpen, undefined));
  };

  if (!licenseKey) {
    return <></>;
  }

  return (
    <div style={{
      position: 'fixed',
      left: '-9999px',
      width: '0px',
      height: '0px',
    }}
    >
      <InputConfirmationDialog
        uiElementId={UiReducer.Keys.AccountKeyAssignmentDialogOpen}
        title="Assign License Key"
        styles={{ main: { maxWidth: '600px !important' } }}
        text={(
          <>
            <Text block>Enter emails one per line</Text>
            <Text block>{`You can assign this key to ${licenseKey.size} accounts`}</Text>
          </>
        )}
        inputPlaceholder="Enter emails"
        initialValue={licenseKey.assignments.join('\n')}
        primaryButtonText="Apply"
        multiline
        rows={10}
        inputExtRef={textFieldRef}
        validateValue={(val) => {
          const value = `${val}`.trim().toLowerCase();
          if (value === '') {
            // If value is empty, we are un-assigning users from this key
            return '';
          }

          const emails = value.split('\n').filter((row) => row.trim() !== '').map((emailRaw) => {
            const email = Utils.emailPreformat(emailRaw);
            if (!email.match(/\S+@\S+\.\S+/) || (email.match(/@/g) || []).length !== 1) {
              throw new Error(`Invalid email format: ${email}`);
            }
            return email;
          });
          if (emails.length > licenseKey.size) {
            throw new Error(`Maximum ${licenseKey.size} users for this key`);
          }
          return emails.join('\n');
        }}
        primaryButtonRef={applyButtonRef}
        primaryCallback={async (emailsRaw) => {
          const accountInfo = UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.AccountInfo);
          // const { keys } = accountInfo.primaryAccount;
          // const affectedKeys = [];

          let emails = [];
          if (emailsRaw.trim() !== '') {
            emails = emailsRaw.split('\n');
          }

          const updatedKey = { key: licenseKey.key, assignments: emails };

          if (emails.length === 0 && licenseKey.assignments.length === 0) {
            // No change
            hide();
            return;
          }

          const newEmailsSet = new Set(emails);
          if (newEmailsSet.size === licenseKey.assignments.length && licenseKey.assignments.every((item) => newEmailsSet.has(item))) {
            // Same set of emails, do nothing
            hide();
            return;
          }

          // // Clearing assignments from key
          // keys.forEach((keyData) => {
          //   const key = { ...keyData };
          //   if (key.key === licenseKey.key) {
          //     // Assigning to this key
          //     if (emails.length === 0 && key.assignments.length === 0) {
          //       // Key had no assignments, and not assigning anything
          //       // No need to do anything
          //       return;
          //     }

          //     // Set assignments equal to passed in list, check if it changed
          //     const newEmailsSet = new Set(emails);
          //     if (newEmailsSet.size === key.assignments.length && key.assignments.every((item) => newEmailsSet.has(item))) {
          //       // Same set of emails, do nothing
          //       return;
          //     }

          //     key.assignments = [...newEmailsSet];

          //     affectedKeys.push({ key: key.key, assignments: key.assignments });
          //   } else {
          //     // Different key; if this has any of the emails, delete them and mark as changed
          //     let affected = false;
          //     emails.forEach((email) => {
          //       const index = key.assignments.indexOf(email);
          //       if (index >= 0) {
          //         affected = true;
          //         key.assignments.splice(index, 1);
          //       }
          //     });
          //     if (affected) {
          //       affectedKeys.push({ key: key.key, assignments: key.assignments });
          //     }
          //   }
          // });

          // if (affectedKeys.length === 0) {
          //   return;
          // }

          const responseJson = await AppsApi.assignKeys(updatedKey);
          if (responseJson?.code >= 300 || responseJson?.error?.code >= 300) {
            dispatch(UiReducer.uiSetErrors(responseJson.messages));
          } else {
            hide();
            dispatch(UiReducer.setGenericUiAttribute(UiReducer.Keys.AccountInfo, responseJson));
            dispatch(UiReducer.setGenericUiAttribute(UiReducer.Keys.AccountInfoServerError, undefined));
          }
        }}
        secondaryCallback={() => { hide(); }}
        extraButtons={[
          <DefaultButton
            key="ClearButton"
            text="Clear"
            onClick={() => {
              if (textFieldRef.current) {
                textFieldRef.current.props.onChange(null, '');
                setTimeout(() => {
                  if (applyButtonRef.current) {
                    applyButtonRef.current.props.onClick();
                  }
                }, 10);
              }
            }}
          />,

          <DefaultButton
            key="AtMButton"
            text="Assign to Me"
            onClick={() => {
              if (textFieldRef.current) {
                const updatedValue = `${textFieldRef.current.value}\n${User.getCurrentUser()}`.trim();
                textFieldRef.current.props.onChange(null, updatedValue);
                setTimeout(() => {
                  if (applyButtonRef.current) {
                    applyButtonRef.current.props.onClick();
                  }
                }, 10);
              }
            }}
          />,
        ]}
      />
    </div>
  );
};
