import { Breadcrumb } from '@fluentui/react';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Store from '../shared/state/Store';
import Routing from '../shared/Routing';

export default () => {
  const [globalState] = Store.useStore();
  const location = useLocation();
  const navigate = useNavigate();

  const items = [];
  const routingTree = Routing.getTree(globalState);

  const pathLevels = (location.pathname === '/') ? [''] : location.pathname.split('/');
  let currentPathLevel = '';
  let currentRoutingItem = routingTree.home;

  for (let i = 0; i < pathLevels.length; i += 1) {
    const p = pathLevels[i];
    currentPathLevel += `${(i === 1) ? '' : '/'}${p}`;
    if (i > 0) {
      const routes = Object.values(currentRoutingItem.routes || []);
      if (routes.length === 1) {
        // eslint-disable-next-line prefer-destructuring
        currentRoutingItem = routes[0];
      } else {
        // eslint-disable-next-line no-loop-func
        currentRoutingItem = routes.find((obj) => obj.path.substring(obj.path.lastIndexOf('/')) === currentPathLevel.substring(currentPathLevel.lastIndexOf('/')));
      }
    }
    const item = { ...currentRoutingItem };
    item.disabled = item.bcDisabled || (!!item.disabled && item.disabled(globalState));
    item.onClick = () => {
      if (item.dynpath) {
        navigate(item.dynpath);
      } else {
        navigate(item.path);
      }
    };

    delete item.routes;
    delete item.skip;
    delete item.getRelativePath;
    delete item.bcDisabled;
    delete item.requireApp;

    items.push(item);
  }

  if (items.length === 1) {
    document.title = 'Portal Home: agena.ai';
  } else {
    document.title = items.slice(1).map((el) => el.text).join(': ');
  }

  return (
    <Breadcrumb
      styles={{
        root: {
          margin: '0',
        },
        itemLink: {
          fontSize: '14px',
          fontWeight: 'normal',
          outline: 'none !important',
        },
        item: {
          fontSize: '14px',
          fontWeight: 'normal',
        },
      }}
      items={items}
      maxDisplayedItems={10}
      overflowAriaLabel="More"
    />
  );
};
