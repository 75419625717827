import React from 'react';
import { Checkbox, mergeStyleSets, Stack } from '@fluentui/react';
import { deepEqual } from 'fast-equals';
import Utils from '../../../shared/Utils';
import ChartDefaults from '../../../shared/ChartDefaults';
import GraphDisplayLayer from './GraphDisplayLayer';
import ModellerDefaults from '../../ModellerDefaults';
import GraphUtils from '../../GraphUtils';
import CytoReducer from './CytoReducer';
import Store from '../../../shared/state/Store';
import ModellerReducer from '../../ModellerReducer';

const componentStyles = mergeStyleSets({
  container: {
    width: '100%',
  },
});

const getColor = (group) => ChartDefaults.Colors[group] || 'gray';

export default ({ graphKey }) => {
  const [globalState, dispatch] = Store.useStore();
  const model = ModellerReducer.getModel(globalState);
  const [chartData, setChartData] = React.useState();

  const singleNetwork = graphKey !== ModellerDefaults.FlatModelGraphKey;

  const prepareData = (json) => {
    const fChartData = {
      elements: {
        nodes: [],
        edges: [],
      },
      style: [],
    };

    if (graphKey === ModellerDefaults.FlatModelGraphKey && json.graphics?.cyto) {
      // console.log('Merging model graphics:', json.graphics.cyto);
      Object.assign(fChartData, json.graphics.cyto);
    }

    const linkedNodes = {};
    const skipNodes = {};
    const skipLinks = [];

    if (json && json.networks) {
      for (let netIndex = 0; netIndex < json.networks.length; netIndex += 1) {
        const network = json.networks[netIndex];
        linkedNodes[network.id] = {};
        skipNodes[network.id] = {};

        const networkGraphKey = GraphUtils.escapeSelector(network.id);

        if (singleNetwork && networkGraphKey !== graphKey) {
          continue;
        }

        if (graphKey === networkGraphKey && network.graphics?.cyto) {
          // console.log('Merging network graphics:', network.graphics.cyto);
          Object.assign(fChartData, network.graphics.cyto);
        }

        if (network.nodes) {
          for (let nodeIndex = 0; nodeIndex < json.networks[netIndex].nodes.length; nodeIndex += 1) {
            const node = json.networks[netIndex].nodes[nodeIndex];
            const netNodeKey = Utils.createNetworkNodeKey(network.id, node.id);
            const nodeGraphics = node.graphics?.graph || ModellerDefaults.DefaultGraphics.graph;
            const graphicsClasses = (nodeGraphics.classes || []);
            const graphicsExtraDisplayModeClass = graphicsClasses.includes(`mode_${ModellerDefaults.NodeModes.CHART}`) ? '' : `mode_${ModellerDefaults.NodeModes.PLAIN}`;

            linkedNodes[network.id][node.id] = {
              id: node.id,
              linksOut: [],
            };
            if (node.graphics?.visible === false) {
              skipNodes[network.id][node.id] = true;
              continue;
            }

            const continuous = ['ContinuousInterval', 'IntegerInterval'].includes(node.configuration.type);
            const elementData = {
              data: {
                selector: GraphUtils.escapeSelector(netNodeKey),
                id: netNodeKey,
                nodeId: node.id,
                name: node.name,
                networkId: network.id,
                continuous,
                discrete: !continuous,
                parent: `${netNodeKey}wrapper`,
              },
              classes: [...graphicsClasses, graphicsExtraDisplayModeClass, 'primary'],
              selected: nodeGraphics.selected || false,
              position: nodeGraphics.position?.[graphKey],
            };
            // if (node.graphics?.cyto?.mode === ModellerDefaults.NodeModes.CHART) {
            //   elementData.classes.push('mode_chart');
            //   // chartData.style.push({
            //   //   selector: `[name=${netNodeKey}]`,
            //   //   style: {
            //   //     shape: 'rectangle',
            //   //     'background-image': `url('${example.chartUrlSvg}')`,
            //   //     'background-repeat': 'no-repeat',
            //   //     'background-width-relative-to': 'inner',
            //   //     'background-height-relative-to': 'inner',
            //   //     // 'background-size': '100%',
            //   //     'background-width': (elNode) => `${elNode.width()}px`,
            //   //     'background-height': (elNode) => `${elNode.height()}px`,
            //   //     // 'background-clip': 'node',
            //   //   },
            //   // });
            // }

            fChartData.elements.nodes.push(elementData);

            // Add a wrapper compound node that will hold the actual node and observation labels
            fChartData.elements.nodes.push({
              selectable: false,
              grabbable: false,
              pannable: true,
              data: {
                id: `${netNodeKey}wrapper`,
                name: '',
              },
              classes: ['wrapper'],
            });

            model.dataSets.forEach((ds, dsIndex) => {
              const mappedObservations = globalState.modeller?.mappedObservations?.[ds.id]?.[network.id]?.[node.id] || {};
              Object.keys(mappedObservations).forEach((obsKey) => {
                const obs = mappedObservations[obsKey];
                const labelValue = (obs.entries.length === 1) ? obs.entries[0].value : 'Soft Evidence';
                const objId = GraphUtils.escapeSelector(ds.id + network.id + node.id + obsKey);
                fChartData.elements.nodes.push({
                  selectable: false,
                  grabbable: false,
                  pannable: true,
                  data: {
                    parent: `${netNodeKey}wrapper`,
                    id: objId,
                    name: `${ds.id}: ${labelValue}${(obsKey && obsKey !== 'undefined') ? ` (${obsKey})` : ''}`,
                    dsIndex,
                    color: getColor(dsIndex),
                  },
                  classes: ['observation', 'hidden'],
                });
              });
            });

            const nodeStyle = {
              selector: `[selector="${GraphUtils.escapeSelector(netNodeKey)}"]`,
              style: {},
            };

            if (Object.keys(nodeGraphics.style || {}).length > 0) {
              nodeStyle.style = {
                ...nodeStyle.style,
                ...nodeGraphics.style,
                custom: undefined,
              };

              if (nodeGraphics.style.custom) {
                Object.keys(nodeGraphics.style.custom).forEach((customClass) => {
                  fChartData.style.push({
                    selector: `[selector="${GraphUtils.escapeSelector(netNodeKey)}"].${customClass}`,
                    style: nodeGraphics.style.custom[customClass],
                  });
                });
              }
            }

            if (graphKey === ModellerDefaults.FlatModelGraphKey && ModellerReducer.getSetting(globalState, ModellerReducer.SettingKeys.colorByNetwork)) {
              nodeStyle.style['background-color'] = getColor(netIndex);
            }

            fChartData.style.push(nodeStyle);
          }
        }

        if (network.links) {
          network.links.forEach((link) => {
            linkedNodes[network.id][link.parent].linksOut.push({
              network: network.id,
              node: link.child,
            });

            if (skipNodes[network.id][link.parent]) {
              // Source is hidden, discard this link
              return;
            }
            if (skipNodes[network.id][link.child]) {
              // The target is hidden, make this a skip link
              skipLinks[network.id] = skipLinks.push({
                sourceNetwork: network.id,
                sourceNode: link.parent,
                targetNetwork: network.id,
                targetNode: link.child,
                value: 2,
              });
              return;
            }
            const source = `${network.id}.${link.parent}`;
            const target = `${network.id}.${link.child}`;
            fChartData.elements.edges.push({
              data: {
                id: `${source}-${target}`,
                source,
                target,
                value: 2,
              },
            });
          });
        }
      }
    }

    if (!singleNetwork && json && json.links) {
      json.links.forEach((link) => {
        // Build a linked graph of nodes so we can replace links to hidden nodes with links to their non-hidden descendants
        linkedNodes[link.sourceNetwork][link.sourceNode].linksOut.push({
          network: link.targetNetwork,
          node: link.targetNode,
        });

        if (skipNodes[link.sourceNetwork][link.sourceNode]) {
          // Source is hidden, discard this link
          return;
        }
        if (skipNodes[link.targetNetwork][link.targetNode]) {
          // The target is hidden, make this a skip link
          skipLinks.push({
            sourceNetwork: link.sourceNetwork,
            sourceNode: link.sourceNode,
            targetNetwork: link.targetNetwork,
            targetNode: link.targetNode,
            value: 1,
          });
          return;
        }
        const source = `${link.sourceNetwork}.${link.sourceNode}`;
        const target = `${link.targetNetwork}.${link.targetNode}`;
        fChartData.elements.edges.push({
          data: {
            id: `${source}-${target}`,
            source,
            target,
            value: 1,
          },
        });
      });
    }

    while (skipLinks.length > 0) {
      const linkData = skipLinks[0];
      // const targetNetNodeKey = Utils.createNetworkNodeKey(linkData.targetNetwork, linkData.targetNode);
      if (!skipNodes[linkData.targetNetwork]?.[linkData.targetNode]) {
        // Target node not hidden, we can move this link to actual links
        const source = `${linkData.sourceNetwork}.${linkData.sourceNode}`;
        const target = `${linkData.targetNetwork}.${linkData.targetNode}`;
        fChartData.elements.edges.push({
          data: {
            id: `${source}-${target}`,
            source,
            target,
            value: 1,
          },
          classes: ['dashed'],
        });
      } else {
        // Target node is hidden, need to replace this link with a skip link per each of its outgoing links
        const targetNode = linkedNodes[linkData.targetNetwork][linkData.targetNode];
        (targetNode.linksOut || []).forEach((linkOut) => {
          skipLinks.push({
            sourceNetwork: linkData.sourceNetwork,
            sourceNode: linkData.sourceNode,
            targetNetwork: linkOut.network,
            targetNode: linkOut.node,
            value: linkData.value,
          });
        });
      }
      skipLinks.shift();
    }

    return fChartData;
  };

  // const updateNode = ({ updateBatch, updatePath, mergeUpdate }) => {
  //   const modelCopy = Utils.clone(model);
  //   updateBatch.forEach((row) => {
  //     const { networkId, nodeId, updateBody } = row;

  //     // eslint-disable-next-line no-restricted-syntax, no-labels
  //     loop:
  //     for (let netIndex = 0; netIndex < modelCopy.networks.length; netIndex += 1) {
  //       const net = modelCopy.networks[netIndex];
  //       if (net.id === networkId) {
  //         for (let nodeIndex = 0; nodeIndex < modelCopy.networks[netIndex].nodes.length; nodeIndex += 1) {
  //           const node = modelCopy.networks[netIndex].nodes[nodeIndex];
  //           if (node.id === nodeId) {
  //             const oldNode = modelCopy.networks[netIndex].nodes[nodeIndex];
  //             if (mergeUpdate) {
  //               const oldBody = Utils.resolveObjectPath(oldNode, updatePath, {});
  //               const newBody = { ...oldBody, ...updateBody };
  //               Utils.setObjectPath(oldNode, updatePath, newBody);
  //               console.log(oldBody, updateBody, newBody, oldNode);
  //               modelCopy.networks[netIndex].nodes[nodeIndex] = oldNode;
  //             } else {
  //               Utils.setObjectPath(oldNode, updatePath, updateBody);
  //               modelCopy.networks[netIndex].nodes[nodeIndex] = oldNode;
  //             }
  //             // eslint-disable-next-line no-restricted-syntax, no-labels
  //             break loop;
  //           }
  //         }
  //       }
  //     }
  //   });

  //   updateModel(modelCopy);
  // };

  // const updateNodeGraphics = (updateBatch) => {
  //   const preparedBatch = [];
  //   updateBatch.forEach((row) => {
  //     preparedBatch.push(row);
  //   });
  //   updateNode({ updateBatch: preparedBatch, mergeUpdate: true, updatePath: 'graphics.cyto' });
  // };

  // const updateModelGraphics = (updateBody, mergeUpdate = false) => {
  //   const modelCopy = Utils.clone(model);
  //   modelCopy.graphics = modelCopy.graphics || {};
  //   modelCopy.graphics.cyto = modelCopy.graphics.cyto || {};
  //   if (mergeUpdate) {
  //     modelCopy.graphics.cyto = {
  //       ...modelCopy.graphics.cyto,
  //       ...updateBody,
  //     };
  //   }
  //   updateModel(modelCopy);
  // };

  React.useEffect(() => {
    // if (chartData && !CytoReducer.isRepaintRequested(globalState) && ModellerReducer.getMappedGraph(globalState, graphKey)) {
    //   return;
    // }
    const preChartData = prepareData(model);
    if (!deepEqual(chartData, preChartData)) {
      setChartData(preChartData);
    }
  },
  [model, CytoReducer.isRepaintRequested(globalState)]);

  if (!model || !model.networks) {
    return <div><p>No valid model</p></div>;
  }

  if (!chartData || Object.values(chartData).length === 0) {
    return <div><p>No valid chart data</p></div>;
  }

  // // ////////////////
  // chartData.style.push(
  //   {
  //     selector: `[selector="${GraphUtils.escapeSelector('River Flooding T2_2.Flood')}"]`,
  //     style: {
  //       'background-color': 'lime',
  //     },
  //   },
  // );
  // // ////////////////

  // console.log('cyto preview', cyto);

  return (
    <Stack vertical className={componentStyles.container}>
      {/* {
        cyto && (
          <p>
            {
              cyto.$('node:selected').map((node) => (
                <span key={node.id()}>
                  {node.data().name}
                  ,
                  {' '}
                </span>
              ))
              // cyto.nodes().map((node) => <span>{node.data().name}</span>)
            }
          </p>
        )
      } */}

      {
        (!singleNetwork && model.networks?.length > 1) && (
          <Checkbox
            label="Color by network"
            checked={ModellerReducer.getSetting(globalState, ModellerReducer.SettingKeys.colorByNetwork)}
            onChange={async () => {
              dispatch(ModellerReducer.setSetting(ModellerReducer.SettingKeys.colorByNetwork, !ModellerReducer.getSetting(globalState, ModellerReducer.SettingKeys.colorByNetwork)));
              dispatch(CytoReducer.setRepaintRequested());
            }}
          />
        )
      }

      <GraphDisplayLayer
        data={chartData}
        graphKey={graphKey}
      />

    </Stack>
  );
};
