/* eslint-disable max-len */
import React from 'react';
import Store from '../../shared/state/Store';
import UiReducer from '../../shared/state/UiReducer';
import InputConfirmationDialog from '../../shared/InputConfirmationDialog';
import User from '../../shared/User';
import Utils from '../../shared/Utils';

export default ({ app, primaryCallback }) => {
  const [globalState, dispatch] = Store.useStore();
  const uiKeyRoleDialogOpen = UiReducer.Keys.CloudAppManagerAddRoleAssignmentDialogOpen;
  const roleType = UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.CloudAppManagerAddRoleAssignmentDialogOpenForRole);

  const hide = () => {
    dispatch(UiReducer.setGenericUiAttribute(uiKeyRoleDialogOpen, false));
  };

  if (!app || !UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.CloudAppManagerAddRoleAssignmentDialogOpen)) {
    return <></>;
  }

  let initialItems = [];

  let maxLimit = 0;
  let maxLimitText = '';
  if (roleType === 'users') {
    maxLimit = User.getEffectiveSubscriptionAccount(globalState)?.subscription.maxUsersPerApp || 0;
    maxLimitText = `up to [${maxLimit}]`;
    initialItems = [...app.users];
  } else {
    initialItems = [...app.contributors];
  }

  return (
    <div style={{
      position: 'fixed',
      left: '-9999px',
      width: '0px',
      height: '0px',
    }}
    >
      <InputConfirmationDialog
        uiElementId={uiKeyRoleDialogOpen}
        title={`Assign ${roleType}`}
        text={`Enter ${maxLimitText} emails one per line; or empty the box to clear assignments completely`}
        inputPlaceholder="Enter emails"
        initialValue={initialItems.join('\n')}
        primaryButtonText="Assign"
        multiline
        rows={10}

        validateValue={(val) => {
          const value = `${val}`.trim().toLowerCase();
          if (value === '') {
            // If value is empty, we are un-assigning all emails
            return '';
          }

          const emails = value.split('\n').filter((row) => row.trim() !== '').map((emailRaw) => {
            const email = Utils.emailPreformat(emailRaw);
            if (!email.match(/\S+@\S+\.\S+/) || (email.match(/@/g) || []).length !== 1) {
              throw new Error(`Invalid email format: ${email}`);
            }
            return email;
          });

          const emailsUnique = [...new Set(emails)];

          if (maxLimit > 0 && emailsUnique.length > maxLimit) {
            throw new Error(`Maximum [${maxLimit}] users per app allowed`);
          }
          return emailsUnique.join('\n');
        }}

        primaryCallback={primaryCallback}
        secondaryCallback={() => { hide(); }}
      />
    </div>
  );
};
