import {
  DefaultButton, Dialog, DialogFooter, DialogType, IconButton, mergeStyleSets, Stack, TextField,
} from '@fluentui/react';
import React from 'react';
import Theme from '../Theme';

const componentStyles = mergeStyleSets({
  header: {
    width: '100% !important',
    minWidt: '100% !important',
    maxWidth: '100% !important',
  },
});

export default ({ json, setJson }) => (
  <div style={{
    position: 'fixed',
    left: '-9999px',
    width: '0px',
    height: '0px',
  }}
  >
    <Dialog
      hidden={!json}
      onDismiss={() => { setJson(); }}
      dialogContentProps={{
        type: DialogType.normal,
        title: (
          <Stack horizontal className={componentStyles.header}>

            <Stack horizontal horizontalAlign="start">
              <header>{`Full Record Entry: ${json?.uuid}`}</header>
            </Stack>

            <Stack grow={100} />

            <Stack horizontal horizontalAlign="end">
              <IconButton
                className={Theme.styles.outlineControlButton}
                iconProps={{ iconName: 'Cancel' }}
                aria-label="Close"
                onClick={() => { setJson(); }}
              />
            </Stack>
          </Stack>
        ),
        closeButtonAriaLabel: 'Close',
        styles: {
          title: {
            paddingRight: '14px',
          },
          subText: {
            marginBottom: '5px',
          },
        },
      }}
      modalProps={{
        isBlocking: false,
        styles: { main: { maxWidth: '80vw !important', width: '80vw !important' } },
      }}
    >

      <Stack horizontal>
        <Stack.Item grow>
          <TextField
            readOnly
            multiline
            rows={40}
            value={JSON.stringify(json || {}, null, 4)}
          />
        </Stack.Item>
      </Stack>

      <DialogFooter>

        <DefaultButton
          onClick={() => {
            setJson();
          }}
          text="Close"
        />
      </DialogFooter>
    </Dialog>
  </div>
);
