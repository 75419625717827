import {
  DefaultButton, mergeStyleSets, PrimaryButton, Stack, Text, TextField,
} from '@fluentui/react';
import React from 'react';
import StackTokens from '../../shared/StackTokens';
import TextFieldBuffered from '../../shared/TextFieldBuffered';
import GraphUtils from '../../modeller/GraphUtils';
import Popup from '../../shared/Popup';
import Theme from '../../shared/Theme';

const componentStyles = mergeStyleSets({
  contentRoot: {
    width: '100%',
    maxHeight: '40vh',
    overflow: 'auto',
  },
  table: {
    width: '100%',
  },
});

export default ({
  isOpen,
  setIsOpen,
  defaultVariables,
  inputConfig,
  setVariableData,
  getVariableData,
}) => {
  const [userValues, setUserValues] = React.useState({});

  React.useEffect(() => {
    if (isOpen) {
      setUserValues(getVariableData());
    }
  }, [isOpen]);


  return (
    <Popup
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title={`Enter variable data for ${inputConfig.label}`}
      showFooter={false}
      showXButton
    >
      <Stack vertical tokens={StackTokens.spacing}>
        <Stack vertical tokens={StackTokens.spacing} style={{ maxWidth: '460px' }}>
          {(inputConfig.nodeVariableOverrideInstructions || 'Enter custom variable values or leave default values.').split('\n').map((line, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Text key={index} block>{line}</Text>
          ))}
        </Stack>
        <div className={componentStyles.contentRoot}>
          <table className={componentStyles.table}>
            <thead>
              <tr>
                <th>Variable</th>
                <th>Default</th>
                <th>Override</th>
              </tr>
            </thead>
            <tbody>
              {
                (Object.keys(defaultVariables) || []).map((varName) => (
                  <tr key={varName}>
                    <th>{varName}</th>
                    <td>
                      <TextField
                        defaultValue={defaultVariables[varName]}
                        disabled
                      />
                    </td>
                    <td>
                      <TextFieldBuffered
                        validateValue={(value) => {
                          let validatedValue;
                          try {
                            validatedValue = GraphUtils.validateInputValue({
                              value,
                              validationRules: { type: 'float', allowEmpty: true },
                            });
                          } catch (error) {
                            throw new Error(error.message);
                          }
                          return validatedValue;
                        }}
                        applyValue={(value) => {
                          const updatedFormData = { ...userValues };
                          updatedFormData[varName] = value;
                          setUserValues(updatedFormData);
                        }}
                        actualValue={userValues[varName] || ''}
                      />
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>

        <Stack horizontal tokens={StackTokens.spacing} horizontalAlign="end">
          <PrimaryButton
            className={Theme.styles.outlineControl}
            onClick={() => {
              setVariableData(userValues);
              setIsOpen(false);
            }}
            text="Submit"
          />
          <DefaultButton
            className={Theme.styles.outlineControl}
            onClick={() => {
              setIsOpen(false);
            }}
            text="Cancel"
          />
        </Stack>

      </Stack>
    </Popup>
  );
};
