import React from 'react';
import { Separator, Stack } from '@fluentui/react';
import CollapsiblePanel from '../../shared/CollapsiblePanel';
import AccountsHelper from '../AccountsHelper';
import SubscriptionItem from './SubscriptionItem';
import Theme from '../../shared/Theme';

export default ({ relatedAccounts }) => {
  const effectiveSubscriptionAccount = AccountsHelper.getEffectiveSubscriptionAccount(relatedAccounts);

  const dependentSubscription = effectiveSubscriptionAccount.email !== relatedAccounts.primaryAccount.email;
  let ownSubscriptionSection;
  if (dependentSubscription) {
    // Effective subscription different from own subscription
    ownSubscriptionSection = (
      <>
        <Separator />
        <CollapsiblePanel
          collapsible
          collapsed
          title="Own Subscription"
          styles={{ header: { fontSize: 'initial' }, icon: { fontSize: 'smaller', marginTop: '3px' } }}
        >
          <SubscriptionItem subscriptionAccount={relatedAccounts.primaryAccount} relatedAccounts={relatedAccounts} />
        </CollapsiblePanel>
      </>
    );
  }

  return (
    <Stack vertical>
      <header className={Theme.styles.sectionHeader}>
        {dependentSubscription ? 'Effective' : ''}
        {' '}
        Subscription Summary
      </header>
      <SubscriptionItem subscriptionAccount={effectiveSubscriptionAccount} relatedAccounts={relatedAccounts} includeUsage />
      {ownSubscriptionSection}
    </Stack>
  );
};
