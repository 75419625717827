import React from 'react';
import Store from '../state/Store';
import IconButton from '../IconButton';
import HelpSystem from './HelpSystem';

const localStyles = {
  root: {
    verticalAlign: 'middle',
    height: '20px',
  },
};

export default ({
  children, title, tooltip = 'Help', styles = {},
}) => {
  const [, dispatch] = Store.useStore();

  return (
    <span style={{ ...localStyles.root, ...styles?.root }}>
      <IconButton
        iconName="UnknownSolid"
        tooltip={tooltip}
        onClick={() => {
          HelpSystem.overrideContent(dispatch, {
            title,
            children,
          });
        }}
        styles={{
          icon: {
            fontSize: '20px !important',
            height: '20px !important',
            lineHeight: '20px !important',
          },
        }}
      />
    </span>
  );
};
