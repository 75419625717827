import {
  Dialog, DialogType, Stack, DialogFooter, DefaultButton, IconButton, mergeStyleSets,
} from '@fluentui/react';
import React from 'react';
import MultiLineUserText from './MultiLineUserText';
import StackTokens from './StackTokens';
import Theme from './Theme';
import HeaderWithTrailingContent from './HeaderWithTrailingContent';

const componentStyles = mergeStyleSets({
  content: {
    width: '95%',
  },
});

export default ({
  title, message, messages, initialIsOpen, onClose, content, children, styles,
}) => {
  const [isOpen, setIsOpen] = React.useState(initialIsOpen);

  const onDismiss = () => {
    setIsOpen(false);
    if (typeof onClose === 'function') {
      onClose();
    }
  };

  return (
    <div style={{
      position: 'fixed',
      left: '-9999px',
      width: '0px',
      height: '0px',
    }}
    >
      <Dialog
        hidden={!isOpen}
        onDismiss={() => { onDismiss(); }}
        dialogContentProps={{
          type: DialogType.normal,
          title: (
            <HeaderWithTrailingContent
              text={title}
              trailing={(
                <IconButton
                  className={Theme.styles.outlineControlButton}
                  iconProps={{ iconName: 'Cancel' }}
                  aria-label="Close"
                  onClick={() => { onDismiss(); }}
                />
            )}
            />
          ),
          closeButtonAriaLabel: 'Close',
          styles: {
            title: {
              paddingRight: '10px',
              paddingLeft: '10px',
            },
            subText: {
              marginBottom: '5px',
            },
            inner: {
              padding: '0 5px 10px 10px !important',
            },
            innerContent: {
              minHeight: 'fit-content !important',
              maxHeight: '80vh !important',
              overflow: 'auto',
              paddingBottom: '5px',
            },
          },
        }}
        modalProps={{
          isBlocking: false,
          styles: {
            main: {
              width: `${styles?.modal?.main?.width} !important` || 'fit-content !important',
              maxWidth: '80vw !important',
              minWidth: '30vw !important',
            },
          },
        }}
      >
        <Stack vertical tokens={StackTokens.spacing} className={componentStyles.content}>
          {children}
          {content}
          {
        [message, ...(messages || [])].filter((el) => el !== undefined).map((msg, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <MultiLineUserText key={i}>{msg}</MultiLineUserText>
        ))
      }
        </Stack>

        <DialogFooter>

          <DefaultButton
            onClick={() => { onDismiss(); }}
            text="Close"
            className={Theme.styles.outlineControlButton}
          />
        </DialogFooter>
      </Dialog>
    </div>
  );
};
