/* eslint-disable max-len */
import {
  DefaultButton,
  Dialog, DialogFooter, DialogType, mergeStyleSets, PrimaryButton, Stack,
  Text,
  TextField,
} from '@fluentui/react';
import React from 'react';
import StackTokens from '../../shared/StackTokens';
import Store from '../../shared/state/Store';
import UiReducer from '../../shared/state/UiReducer';
import TextFieldBuffered from '../../shared/TextFieldBuffered';
import Theme from '../../shared/Theme';
import GraphUtils from '../GraphUtils';
import ModellerDefaults from '../ModellerDefaults';
import ModellerReducer from '../ModellerReducer';
import Utils from '../../shared/Utils';
import CytoReducer from '../ModelPreview/cytoscape/CytoReducer';

const componentStyles = mergeStyleSets({
  tableContainer: {
    maxHeight: '40vh',
    overflowY: 'auto',
  },
});

export default () => {
  const [globalState, dispatch] = Store.useStore();
  const uiKey = ModellerDefaults.ComponentKeys.ModellerDataEntryPopupKey;
  const [dialogData, setDialogData] = React.useState(undefined);
  const [defaultFormData, setDefaultFormData] = React.useState({});
  const [userFormData, setUserFormData] = React.useState({});

  React.useEffect(() => {
    const data = UiReducer.getGenericUiAttribute(globalState, uiKey) || {};
    setDialogData(data);
  }, [UiReducer.getGenericUiAttribute(globalState, uiKey)]);

  React.useEffect(() => {
    const formDataD = {};
    const formDataU = {};
    (dialogData?.nodeData?.variables || []).forEach((varData) => {
      formDataD[varData.name] = varData.value;

      const observation = ModellerReducer.getMappedObservation(globalState, dialogData.datasetId, dialogData.networkId, dialogData.nodeId, varData.name);
      if (observation) {
        formDataU[varData.name] = observation.entries[0].value;
      }
    });

    setDefaultFormData(formDataD);
    setUserFormData(formDataU);
  }, [dialogData]);

  if (dialogData?.type !== 'variables') {
    return <></>;
  }

  const hide = () => {
    dispatch(UiReducer.setGenericUiAttribute(uiKey, false));
  };

  return (
    <div style={{
      position: 'fixed',
      left: '-9999px',
      width: '0px',
      height: '0px',
    }}
    >
      <Dialog
        hidden={!UiReducer.getGenericUiAttribute(globalState, uiKey)}
        onDismiss={hide}
        dialogContentProps={{
          type: DialogType.normal,
          title: `Enter data for case: ${dialogData?.datasetId || ''}`,
          styles: {
            subText: {
              marginBottom: '5px',
            },
          },
        }}
        modalProps={{
          titleAriaId: uiKey,
          isBlocking: false,
          styles: {
            main: {
              maxWidth: 450,
            },
          },
        }}
      >

        <Stack vertical tokens={StackTokens.spacing}>
          <Text block>
            {`Enter variable data for node "${Utils.createFriendlyReference(dialogData.nodeId, dialogData.nodeName)}" in network "${Utils.createFriendlyReference(dialogData.networkId, dialogData.networkName)}"`}
          </Text>

          <div className={componentStyles.tableContainer}>
            <table>
              <thead>
                <tr>
                  <th>Variable</th>
                  <th>Default</th>
                  <th>Override</th>
                </tr>
              </thead>
              <tbody>
                {
                (dialogData?.nodeData?.variables || []).map((varData) => (
                  <tr key={varData.name}>
                    <th style={{ paddingRight: '5px' }}>{varData.name}</th>
                    <td>
                      <TextField
                        defaultValue={defaultFormData[varData.name]}
                        disabled
                      />
                    </td>
                    <td>
                      <TextFieldBuffered
                        validateValue={(value) => {
                          let validatedValue;
                          try {
                            validatedValue = GraphUtils.validateInputValue({
                              value,
                              validationRules: { type: 'float', allowEmpty: true },
                            });
                          } catch (error) {
                            throw new Error(error.message);
                          }
                          return validatedValue;
                        }}
                        applyValue={(value) => {
                          const updatedFormData = { ...userFormData };
                          updatedFormData[varData.name] = value;
                          setUserFormData(updatedFormData);
                        }}
                        actualValue={userFormData[varData.name] || ''}
                      />
                    </td>
                  </tr>
                ))
              }
              </tbody>
            </table>
          </div>
        </Stack>

        <DialogFooter>
          <PrimaryButton
            onClick={() => {
              Object.keys(userFormData).forEach((variable) => {
                dispatch(ModellerReducer.setObservation(dialogData.datasetId, dialogData.networkId, dialogData.nodeId, userFormData[variable], variable));
              });
              dispatch(CytoReducer.setRepaintRequested());
              hide();
            }}
            text="Submit"
          />
          <DefaultButton
            className={Theme.styles.outlineControl}
            onClick={() => {
              hide();
            }}
            text="Cancel"
          />
        </DialogFooter>
      </Dialog>
    </div>
  );
};
