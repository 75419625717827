import { Stack } from '@fluentui/react';
import React from 'react';
import Footer from './Footer';
import MenuBar from './MenuBar';

export default ({ children }) => (
  <>
    <Stack vertical>
      <MenuBar />
      {children}
    </Stack>
    <Footer />
  </>
);
