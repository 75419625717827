import { IconButton, mergeStyleSets } from '@fluentui/react';
import React from 'react';
import Theme from './Theme';

const localStyles = mergeStyleSets({
  container: {
    lineHeight: '0',
    height: 'fit-content',
    display: 'inline-block',
  },
});

export default ({
  tooltip = '',
  iconName = 'Unknown',
  onClick = () => {},
  styles,
  className,
  id,
}) => {
  const effectiveStyles = {
    ...{
      label: {
        display: 'none',
      },
    },
    ...styles,
  };

  return (
    <span className={localStyles.container}>
      <IconButton
        id={id}
        iconProps={{ iconName }}
        title={tooltip}
        className={[Theme.styles.iconButton, className].join(' ')}
        styles={effectiveStyles}
        onClick={(event) => {
          const button = event.target.closest('button');
          onClick();
          if (button?.blur) {
            button.blur();
          }
        }}
      />
    </span>
  );
};
