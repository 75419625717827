import React from 'react';
import {
  DefaultButton,
} from '@fluentui/react';
import Store from '../../shared/state/Store';
import UiReducer from '../../shared/state/UiReducer';
import ModellerReducer from '../ModellerReducer';
import Styles from '../Styles';
import ModellerDefaults from '../ModellerDefaults';

export default ({ mode }) => {
  const [globalState, dispatch] = Store.useStore();
  const [button, setButton] = React.useState();
  const openModelCmpxRef = React.useRef();

  const items = [
    {
      key: 'openModelCmpx',
      text: 'Open Model (CMPX)',
      disabled: mode === ModellerDefaults.OperationMode.ModellerWebApp,
      onClick: () => {
        if (openModelCmpxRef.current) {
          openModelCmpxRef.current.click();
        }
      },
    },
    {
      key: 'saveModelAs',
      text: 'Save model as',
      disabled: mode === ModellerDefaults.OperationMode.ModellerWebApp,
      onClick: () => {
        const model = ModellerReducer.getModel(globalState);
        const content = JSON.stringify({ model });
        const a = document.createElement('a');
        const file = new Blob([content], { type: 'file' });
        a.href = URL.createObjectURL(file);
        a.download = ModellerReducer.getSetting(globalState, ModellerReducer.SettingKeys.filename) || 'Saved Model.cmpx';
        a.click();
        URL.revokeObjectURL(a.href);
        a.remove();
      },
    },
    {
      key: 'modelProperties',
      text: 'Model Properties',
      disabled: !ModellerReducer.getModel(globalState),
      onClick: () => {
        dispatch(UiReducer.setGenericUiAttribute(ModellerDefaults.ComponentKeys.ModellerModelPropertiesPopupKey, true));
      },
    },
  ];

  return (
    <>
      <DefaultButton
        text="File"
        className={Styles.menuButton}
        menuProps={{
          className: Styles.menuItemList,
          calloutProps: {
            minPagePadding: 0,
          },
          items,
        }}
        onMenuClick={(event) => {
          setButton(event.target.closest('button'));
        }}
        onAfterMenuDismiss={() => {
          button.blur();
        }}
      />

      <input
        ref={openModelCmpxRef}
        type="file"
        style={{ display: 'none' }}
        onChange={(event) => {
          dispatch(UiReducer.uiSetErrors());

          if (event.target.files?.length > 0) {
            // Check size
            // Set file selection
            const file = event.target.files[0];
            const { size, name } = file;
            dispatch(ModellerReducer.setSetting(ModellerReducer.SettingKeys.filename, name));

            if (size > 26214400) {
              dispatch(UiReducer.uiSetErrors(['File size too big, max 25 Mb allowed']));
              if (openModelCmpxRef.current) {
                openModelCmpxRef.current.value = '';
              }
              return;
            }

            const fileReaderText = new FileReader();
            fileReaderText.onload = (e) => {
              let errors;
              let newModel;
              try {
                newModel = JSON.parse(e.target.result).model;
              } catch (error) {
                console.log('Load error', error);
                const errorText = new Option(`${error}`).innerHTML;
                if (errorText.indexOf('not valid JSON') >= 0) {
                  errors = [
                    'Invalid file format.',
                    'Only CMPX is supported.',
                  ];
                } else {
                  errors = [
                    'Invalid format',
                    errorText,
                  ];
                }
              }

              if (!errors && !newModel?.networks) {
                errors = [
                  'Invalid file format.',
                  'Only CMPX is supported.',
                ];
              }

              if (errors) {
                dispatch(UiReducer.uiSetErrors(errors));
                return;
              }

              dispatch(ModellerReducer.loadModel(newModel));
            };
            fileReaderText.readAsText(file);
          } else {
            // No file selected, do nothing
          }
          if (openModelCmpxRef.current) {
            openModelCmpxRef.current.value = '';
          }
        }}
      />
    </>
  );
};
