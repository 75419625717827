import { mergeStyleSets } from '@fluentui/react';
import Theme from './Theme';

export default mergeStyleSets({
  tableView: {
    padding: '5px',
    background: 'white',
    overflow: 'auto',

    selectors: {
      th: {
        textAlign: 'center',
      },
    },
  },

  prettyTable: {
    whiteSpace: 'nowrap',
    selectors: {
      th: {
        textAlign: 'left',
      },

      'thead > tr:first-of-type > th': {
        borderBottom: `1px solid ${Theme.Colors.Gray}`,
        paddingBottom: '2px',
        textAlign: 'center',
      },

      'tbody th': {
        textAlign: 'left',
      },

      'tbody > tr:first-of-type > td': {
        paddingTop: '2px',
      },
    },
  },

  dataTable: {
    overflow: 'auto',
  },
});
