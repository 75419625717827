import React from 'react';
import {
  Stack, ActionButton, mergeStyleSets,
} from '@fluentui/react';
import { Collapse } from 'reactstrap';
import MultiLineUserText from './MultiLineUserText';

const componentStyles = mergeStyleSets({
  panel: {
    // background: 'red',
    display: 'flex',
  },

  panelDesktopWidth: {
    // 'min-width': '90vw',
    // 'max-width': '99vw',
    width: '95vw',
  },

  transition: {
    transition: 'transform .2s',
  },

  icon: {
    fontSize: 16,
    height: 16,
    width: 16,
    margin: '0 5px 0 0',
  },
  iconRotated: {
    transform: 'rotate(90deg)',
  },

  header: {
    // cursor: 'pointer',
    paddingLeft: '0',
    marginLeft: '-5px',
    fontSize: 'large',
    color: '#333',
    outline: 'none !important',
  },

  description: {
    fontSize: 'smaller',
    paddingBottom: '0',
    marginBottom: '5px',
    whiteSpace: 'pre-line',
  },

});

export default (props) => {
  const {
    title, description, collapsible, collapsed, children, keyOverride, styles, classNames,
    callback, renderContentsWhenCollapsed = true,
  } = props;

  const [isCollapsed, setIsCollapsed] = React.useState(collapsed);

  const contents = (!isCollapsed || renderContentsWhenCollapsed) ? children : <></>;

  return (
    <Stack vertical className={componentStyles.panel} style={styles?.root} key={keyOverride || title}>
      <ActionButton
        onClick={(event) => {
          if (collapsible) {
            const newIsCollapsed = !isCollapsed;
            setIsCollapsed(newIsCollapsed);
            if (typeof callback === 'function') {
              callback(newIsCollapsed);
            }
          }
          const button = event.target.closest('button');
          if (button) {
            button.blur();
          }
        }}
        text={title}
        className={[componentStyles.header, classNames?.header].join(' ')}
        disabled={!collapsible}
        iconProps={{
          iconName: (collapsible ? 'ChevronRight' : ''),
          className: [componentStyles.icon, `${componentStyles.transition} ${isCollapsed ? '' : componentStyles.iconRotated}`].join(' '),
          styles: { root: styles?.icon },
        }}
        style={styles?.header}
      />
      {description
      && <MultiLineUserText className={componentStyles.description}>{description}</MultiLineUserText>}
      <Collapse isOpen={!isCollapsed}>
        {contents}
      </Collapse>
    </Stack>
  );
};
