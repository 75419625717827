import { Stack, mergeStyleSets } from '@fluentui/react';
import React from 'react';

const componentStyles = mergeStyleSets({
  root: {
    width: '100% !important',
    minWidth: '100% !important',
    maxWidth: '100% !important',
  },
});

export default ({ text, trailing = [], styles }) => {
  let dynStyles;
  if (styles?.root) {
    dynStyles = mergeStyleSets(styles);
  }

  return (
    <Stack horizontal className={dynStyles ? dynStyles.root : componentStyles.root}>

      <Stack horizontal horizontalAlign="start">
        <header style={styles?.header}>{text}</header>
      </Stack>

      <Stack grow={100} />

      <Stack horizontal horizontalAlign="end">
        {trailing}
      </Stack>
    </Stack>
  );
};
