import React from 'react';
import {
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  AreaSeries,
  FlexibleXYPlot,
  MarkSeries,
  LineSeries,
  LineMarkSeries,
  VerticalRectSeries,
} from 'react-vis';
import { Stack, Text, Tooltip } from '@fluentui/react';
import ChartDefaults from '../../../shared/ChartDefaults';
import Store from '../../../shared/state/Store';
import GraphUtils from '../../GraphUtils';
import Theme from '../../../shared/Theme';
import Utils from '../../../shared/Utils';
import StackTokens from '../../../shared/StackTokens';
import CytoReducer from '../../ModelPreview/cytoscape/CytoReducer';
import ModellerDefaults from '../../ModellerDefaults';

const xAxisStyle = {
  text: {
    fill: ChartDefaults.ThemeColors.AxisTicks,
    fontSize: '10px',
  },
  line: { stroke: ChartDefaults.ThemeColors.AxisLine },
};

const yAxisStyle = {
  text: {
    fill: ChartDefaults.ThemeColors.AxisTicks,
    fontSize: '10px',
  },
  line: { stroke: ChartDefaults.ThemeColors.AxisLine },
};

export default ({
  data, nodeConfig, chartRef, className, networkId,
}) => {
  const [/* globalState */, dispatch] = Store.useStore();
  const chartType = GraphUtils.getChartType(nodeConfig);
  const nodeType = nodeConfig.configuration.type;
  const nodeGraphics = nodeConfig.graphics || ModellerDefaults.DefaultGraphics;

  let curveFunctionName = nodeGraphics.chart?.curveFunctionName;
  if (!curveFunctionName) {
    curveFunctionName = (nodeType === 'IntegerInterval') ? ChartDefaults.CurveInteger : ChartDefaults.CurveContinuous;
  }

  const integerOffset = (nodeType === 'IntegerInterval') ? 1 : 0;
  let maxY = 0;

  const formatData = (dataSet) => dataSet.map((result, index) => {
    const isInterval = result.label.indexOf(' - ') >= 0;
    const rangeSplit = result.label.split(' - ');
    let lower = parseFloat(rangeSplit[0]);
    let upper = parseFloat(rangeSplit[1]);

    if (!isInterval) {
      upper = lower;
    }

    if (!Number.isFinite(lower)) {
      lower = Number.MAX_VALUE * (lower < 0 ? -1 : 1);
    }

    if (!Number.isFinite(upper)) {
      upper = Number.MAX_VALUE * (upper < 0 ? -1 : 1);
    }

    let midPoint;
    if (isInterval) {
      midPoint = (lower + upper) / 2;
    } else {
      midPoint = lower;
    }

    const range = (upper - lower) + integerOffset;

    const x = midPoint;

    let { value } = result;
    if (!Number.isFinite(value)) {
      value = Number.MAX_VALUE * (value < 0 ? -1 : 1);
    }

    const y = (range > 0) ? value / range : value;

    if (y > maxY) {
      maxY = y;
    }

    if (chartType === ChartDefaults.ChartTypes.Histogram) {
      const dataPoint = {
        x0: lower,
        x: upper + integerOffset,
        y0: 0,
        y,
        index,
      };
      return dataPoint;
    }
    const dataPoint = {
      x,
      y,
    };

    return dataPoint;
  }).flat(1);

  const compileSubChartsData = (nodeResultData) => {
    const subChartsData = [];

    if (!nodeResultData || nodeResultData.length === 0) {
      return subChartsData;
    }

    for (let datasetIndex = 0; datasetIndex < nodeResultData.length; datasetIndex += 1) {
      const dsData = data[datasetIndex];
      const dsId = dsData.dataset;
      const dsKey = GraphUtils.convertDatasetIdToKey(dsId);
      const dsChartData = {
        key: dsKey,
        curve: ChartDefaults.CurveFunctions[curveFunctionName],
        data: formatData(dsData.resultValues),
        color: ChartDefaults.Colors[datasetIndex],
        opacity: 1 - (datasetIndex / nodeResultData.length),
      };

      if (chartType === ChartDefaults.ChartTypes.ScatterLine) {
        dsChartData.style = { fill: 'none' };
        dsChartData.strokeWidth = '2px';
        dsChartData.size = 3;
      }
      if (chartType === ChartDefaults.ChartTypes.Scatter) {
        dsChartData.size = 3;
      }
      if (chartType === ChartDefaults.ChartTypes.Line) {
        dsChartData.style = { fill: 'none' };
        dsChartData.strokeWidth = '2px';
        dsChartData.opacity = 1;
      }
      if (chartType === ChartDefaults.ChartTypes.Histogram) {
        dsChartData.stroke = Theme.Colors.Blackish;
      }

      subChartsData.push(dsChartData);
    }

    return subChartsData;
  };

  const subChartsData = compileSubChartsData(data);

  let chartComponent;
  switch (chartType) {
    case ChartDefaults.ChartTypes.Area: chartComponent = <AreaSeries />; break;
    case ChartDefaults.ChartTypes.Histogram: chartComponent = <VerticalRectSeries />; break;
    case ChartDefaults.ChartTypes.Scatter: chartComponent = <MarkSeries />; break;
    case ChartDefaults.ChartTypes.Line: chartComponent = <LineSeries />; break;
    case ChartDefaults.ChartTypes.ScatterLine: chartComponent = <LineMarkSeries />; break;
    default: chartComponent = <></>;
  }

  const [tooltipData, setTooltipData] = React.useState(null);
  const [mousePoint, setMousePoint] = React.useState({ x: 0, y: 0 });
  const [mousePointHit/* , setMousePointHit */] = React.useState({ x: 0, y: 0 });
  React.useEffect(() => {
    if (tooltipData === null) {
      return;
    }
    if (Math.abs(mousePointHit.x - mousePoint.x) > 50 || Math.abs(mousePointHit.y - mousePoint.y) > 100) {
      setTooltipData(null);
    }
  }, [mousePoint]);

  // const linesToPlot = [];
  // const plotExtraItemsRequired = nodeChartGraphics.plotPercentiles || nodeChartGraphics.plotMean || nodeChartGraphics.plotMedian;

  // if (plotExtraItemsRequired) {
  //   [
  //     DataReducer.getCachedResultsForDataSet(globalState, DataReducer.DefaultCaseNames.BASELINE),
  //     DataReducer.getCachedResultsForDataSet(globalState, DataReducer.DefaultCaseNames.USER),
  //   ].forEach((dsCalculationData, dsIndex) => {
  //     if (!dsCalculationData) {
  //       return;
  //     }

  //     const key = Utils.createNetworkNodeKey(config.network, config.node);

  //     const resultData = dsCalculationData[key];

  //     if (!resultData) {
  //       return;
  //     }

  //     let dsKey;
  //     let dsColor;
  //     if (dsIndex === 0) {
  //       dsKey = 'baseline';
  //       dsColor = generalConfig.colorBaseline || ChartDefaults.ColorBaseline;
  //     } else {
  //       dsKey = 'user';
  //       dsColor = generalConfig.colorUser || ChartDefaults.ColorUser;
  //     }

  //     const generatePlotData = (uKey, dValue, prefixText) => ({
  //       key: uKey,
  //       color: dsColor,
  //       strokeWidth: '1px',
  //       style: {
  //         filter: 'drop-shadow(#e4e4e4 1px 0px 1px )',
  //       },
  //       data: [
  //         { x: dValue, y: 0 },
  //         { x: dValue, y: maxY },
  //       ],
  //       onSeriesMouseOver: () => {
  //         setTooltipData(null);
  //         setTimeout(() => {
  //           setMousePointHit(mousePoint);
  //           setTooltipData({
  //             color: dsColor,
  //             dsKey: (dsIndex === 0) ? DataReducer.DefaultCaseNames.BASELINE : DataReducer.DefaultCaseNames.USER,
  //             text: prefixText,
  //             value: Utils.roundFormat(dValue, config.decimalPlaces, ChartDefaults.decimalPlaces),
  //           });
  //         }, 1);
  //       },
  //     });

  //     if (config.plotPercentiles) {
  //       if (config.customPercentiles && config.customPercentiles.length > 0) {
  //         if (resultData.percentiles) {
  //           Object.values(config.customPercentiles).forEach((pName) => {
  //             if (!resultData.percentiles[pName]) {
  //               return;
  //             }
  //             linesToPlot.push(generatePlotData(dsKey + pName, resultData.percentiles[pName], `${pName}th percentile`));
  //           });
  //         }
  //       } else if (resultData.summaryStatistics) {
  //         linesToPlot.push(generatePlotData(`${dsKey}lowerPercentile`, resultData.summaryStatistics.lowerPercentile, 'Lower percentile'));
  //         linesToPlot.push(generatePlotData(`${dsKey}upperPercentile`, resultData.summaryStatistics.upperPercentile, 'Upper percentile'));
  //       }
  //     }

  //     if (config.plotMean && resultData.summaryStatistics.mean) {
  //       linesToPlot.push(generatePlotData(`${dsKey}mean`, resultData.summaryStatistics.mean, 'Mean'));
  //     }

  //     if (config.plotMedian && resultData.summaryStatistics.median) {
  //       linesToPlot.push(generatePlotData(`${dsKey}median`, resultData.summaryStatistics.median, 'Median'));
  //     }
  //   });
  // }

  const margins = {};
  ['Left', 'Right', 'Top', 'Bottom'].forEach((p) => {
    const paddingKey = `padding${p}`;
    if (nodeGraphics.chart?.[paddingKey]) {
      margins[p.toLowerCase()] = parseInt(nodeGraphics.chart?.[paddingKey], 10);
    }
    // const marginKey2 = `margin${p}`;
    // if (config[marginKey2]) {
    //   margins[p.toLowerCase()] = parseInt(config[marginKey2], 10);
    // }
  });

  const [paddingBottomAuto, setPaddingBottomAuto] = React.useState(0);
  const [paddingLeftAuto, setPaddingLeftAuto] = React.useState(0);

  const adjustMargins = (svg) => {
    if (!svg) {
      return;
    }
    const yAxis = svg.getElementsByClassName('rv-xy-plot__axis--vertical')[0];
    setPaddingLeftAuto(yAxis.getBoundingClientRect().width);

    const xAxis = svg.getElementsByClassName('rv-xy-plot__axis--horizontal')[0];
    setPaddingBottomAuto(xAxis.getBoundingClientRect().height);

    dispatch(CytoReducer.updateNodeChart(chartRef, networkId, nodeConfig.id));
  };

  React.useEffect(() => {
    setTimeout(() => {
      if (data && data.length > 0) { adjustMargins(Utils.getSvg(chartRef)); }
    }, 10);
  }, [data[0]?.refreshedAt, nodeConfig.graphics?.chart]);

  margins.bottom = margins.bottom !== undefined ? margins.bottom : paddingBottomAuto;
  margins.left = margins.left !== undefined ? margins.left : paddingLeftAuto;

  const [forceRender, setForceRender] = React.useState(false);
  React.useEffect(() => {
    if (!nodeGraphics.graph?.height) {
      setForceRender(true);
      setTimeout(() => {
        setForceRender(false);
      }, 10);
    } else {
      setForceRender(false);
    }
  }, [nodeGraphics.graph?.height]);

  return (
    <div
      style={{
        width: '100%',
        height: forceRender ? `${ModellerDefaults.DefaultGraphics.graph.height}px` : (nodeGraphics.graph?.height || '100%'),
        minHeight: (nodeGraphics.graph?.height === undefined) ? `${ModellerDefaults.DefaultGraphics.graph.height}px` : 'auto',
      }}
      ref={chartRef}
      className={className}
    >
      <FlexibleXYPlot
        margin={margins}
        style={{
          overflow: 'hidden',
          backgroundColor: 'white',
          fontFamily: '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
        }}
        onMouseMove={(event) => {
          setMousePoint({ x: event.clientX, y: event.clientY });
        }}
      >
        <VerticalGridLines />
        <HorizontalGridLines />
        <XAxis
          style={xAxisStyle}
          tickLabelAngle={-45}
          tickSizeOuter={5}
          tickSizeInner={0}
        />
        <YAxis
          style={yAxisStyle}
          tickSizeOuter={5}
          tickSizeInner={0}
        />

        {
          subChartsData.map((subChartProps) => (
            React.cloneElement(chartComponent, { ...subChartProps })
          ))
        }

        {/* {linesToPlot.map((pData) => (
          <LineSeries
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...pData}
          />
        ))} */}
      </FlexibleXYPlot>

      {tooltipData !== null && (
        <Tooltip
          x={mousePoint.x}
          y={mousePoint.y}
        >
          <Stack horizontal tokens={StackTokens.spacing}>
            <div
              style={{
                width: '20px',
                height: '20px',
                background: tooltipData.color,
              }}
            />
            <Text>{tooltipData.dsKey}</Text>
            <Text>{`${tooltipData.text}: ${tooltipData.value}`}</Text>
          </Stack>
        </Tooltip>
      )}

    </div>
  );
};
