import GeneralApi from './GeneralApi';

const { makeRequest } = GeneralApi;

const saveApp = async (data, uuid = '') => makeRequest({ path: `/public/v1/app${uuid ? `/${uuid}` : ''}`, method: 'POST', data });

const getApps = async (params) => makeRequest({ path: '/public/v1/apps', method: 'GET', params });

const getPublicApps = async (params) => makeRequest({ path: '/public/v1/apps-public', method: 'GET', params });

const getApp = async (uuid) => makeRequest({ path: `/public/v1/app/${uuid}`, method: 'GET' });

const deleteApp = async (uuid) => makeRequest({ path: `/public/v1/app/${uuid}`, method: 'DELETE' });

const getSettings = async () => makeRequest({ path: '/private/v1/settings', method: 'GET' });

const saveSettings = async (data) => makeRequest({ path: '/private/v1/settings', method: 'POST', data });

const getAccountInfo = async (params) => makeRequest({ path: '/public/v1/accounts', method: 'GET', params });

const assignKeys = async (data) => makeRequest({ path: '/public/v1/account/assign-keys', method: 'POST', data });

const getAppConfig = async (uuid) => makeRequest({ path: `/public/v1/app-config/${uuid}`, method: 'GET' });

export default {
  saveApp,
  getApps,
  getPublicApps,
  getApp,
  deleteApp,
  getSettings,
  saveSettings,
  getAccountInfo,
  assignKeys,
  getAppConfig,
};
