import { Stack, TextField } from '@fluentui/react';
import React from 'react';
import PercentileListItem from './PercentileListItem';
import ConfigReducer from '../../../../shared/state/ConfigReducer';

export default ({ config, dispatch }) => {
  const percentiles = config.customPercentiles || [];
  const textFieldRef = React.useRef();
  const [errorMessage, setErrorMessage] = React.useState('');
  const [editableText, setEditableText] = React.useState('');

  return (
    <Stack vertical>
      <TextField
        label="Custom Percentiles"
        ref={textFieldRef}
        placeholder="Enter percentile"
        value={editableText}
        onChange={(event, value) => {
          setErrorMessage('');
          setEditableText(value);
        }}
        onKeyDown={(event) => {
          if (event.keyCode === 13) {
            event.target.blur();
          } else if (event.keyCode === 27) {
            setErrorMessage('');
            setEditableText('');
            event.target.blur();
          }
        }}
        onBlur={() => {
          if (!textFieldRef.current) {
            setErrorMessage('Error');
            return;
          }

          const valueText = `${textFieldRef.current.value}`.trim();

          if (valueText === '') {
            return;
          }

          const value = Number.parseFloat(valueText);

          if (Number.isNaN(value) || !Number.isFinite(value) || value <= 0 || value >= 100) {
            setErrorMessage('Percentile must be a number between 0 and 100');
            textFieldRef.current.focus();
            textFieldRef.current.select();
            return;
          }

          setEditableText('');

          const updatedItem = { ...config };
          const updatedSet = new Set(percentiles);
          updatedSet.add(value);
          const updatedList = Array.from(updatedSet);
          updatedList.sort((a, b) => a - b);

          updatedItem.customPercentiles = updatedList;

          dispatch(ConfigReducer.updateOutputItem(updatedItem));

          textFieldRef.current.focus();
          textFieldRef.current.select();
        }}
        errorMessage={errorMessage}
      />

      <Stack horizontal wrap>
        {
            percentiles.map((percentile) => (
              <PercentileListItem
                text={percentile}
                key={percentile}
                onClick={() => {
                  const updatedItem = { ...config };
                  const updatedList = [...percentiles];
                  updatedList.splice(updatedList.indexOf(percentile), 1);
                  updatedItem.customPercentiles = updatedList;
                  dispatch(ConfigReducer.updateOutputItem(updatedItem));
                }}
              />
            ))
        }
      </Stack>
    </Stack>
  );
};
