import { mergeStyleSets } from '@fluentui/react';
import React from 'react';

const styles = mergeStyleSets({
  multiLineText: {
    whiteSpace: 'pre-line',
  },

  wrapperPara: {
    margin: '0',
    padding: '0',
  },
});

const parseLinks = (textToCheck) => {
  // https://medium.com/better-programming/detecting-external-links-in-a-paragraph-of-text-with-javascript-automatically-3c15537f4997
//   const tlds = [
//     'com', 'de', 'org', 'net', 'us', 'co', 'edu', 'gov', 'biz', 'za', 'info', 'cc', 'ca', 'cn',
//     'fr', 'ch', 'au', 'in', 'jp', 'be', 'it', 'nl', 'uk', 'mx', 'no', 'ru', 'br', 'se', 'es',
//     'at', 'dk', 'eu', 'il', 'me',
//   ];
  const expression = /(https?:\/\/[\w\-~.#/?]+)/gi;
  const regex = new RegExp(expression);
  let match = '';
  const splitText = [];
  let startIndex = 0;
  // eslint-disable-next-line no-cond-assign
  while ((match = regex.exec(textToCheck)) !== null) {
    splitText.push({ text: textToCheck.substr(startIndex, (match.index - startIndex)), type: 'text' });

    let cleanedLink = textToCheck.substr(match.index, (match[0].length));

    let adjustStartIndex = 0;
    if (cleanedLink.match(/[?#]$/)) {
      cleanedLink = cleanedLink.substring(0, cleanedLink.length - 1);
      adjustStartIndex = -1;
    }

    // const tld = cleanedLink.substr(cleanedLink.lastIndexOf('.') + 1, 10);
    // if (tld && tld.match(/[a-zA-Z]+/) && !tlds.includes(tld.toLowerCase())) {
    //   // If domain is unexpected, assume this is text
    //   splitText.push({ text: cleanedLink, type: 'text' });
    // } else {
    let protocol;
    if (cleanedLink.match(/^https/i)) {
      protocol = 'https';
    } else if (cleanedLink.match(/^http/i)) {
      protocol = 'http';
    } else {
      protocol = 'https';
    }
    cleanedLink = cleanedLink.replace(/^https?:\/\//, '');
    splitText.push({ text: cleanedLink, type: 'link', protocol });
    // }

    startIndex = match.index + (match[0].length) + adjustStartIndex;
  }
  if (startIndex < textToCheck.length) {
    splitText.push({ text: textToCheck.substr(startIndex), type: 'text' });
  }
  return splitText;
};

export default ({ style, children, className }) => {
  let transformedText;
  if (typeof children === 'string') {
    const parsedText = parseLinks(children);
    transformedText = parsedText.map((item, index) => {
      let content = item.text;
      if (item.type === 'link') {
        content = <a href={`${item.protocol}://${item.text}`} target="_blank" rel="noopener noreferrer">{item.text}</a>;
      }
      return (
        // eslint-disable-next-line react/no-array-index-key
        <span key={index}>{content}</span>
      );
    });
  }

  return (
    <p className={`${styles.wrapperPara} ${styles.multiLineText} ${className || ''}`} style={style}>
      {transformedText || children}
    </p>
  );
};
