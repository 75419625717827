import { mergeStyleSets } from '@fluentui/react';
import React from 'react';

const style = mergeStyleSets({

  legendItem: {
    display: 'inline-block',
  },

  legendIcon: {
    display: 'inline-block',
    width: '16px',
    height: '16px',
    verticalAlign: 'middle',
    marginRight: '5px',
  },

  legendName: {
    lineHeight: '16px',
    verticalAlign: 'text-top',
    marginRight: '15px',
  },
});

export default ({ color, name, styles }) => (
  <div style={styles?.root}>

    <table className={style.legendItem}>
      <tbody>
        <tr>
          <td className={style.legendIcon} style={{ background: color }}>&nbsp;</td>
        </tr>
      </tbody>
    </table>

    {
      name && <span className={style.legendName}>{name}</span>
    }
  </div>
);
