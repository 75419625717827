import {
  Icon, mergeStyleSets, Stack, Text,
} from '@fluentui/react';
import React from 'react';
import {
  Link,
} from 'react-router-dom';
import Routing from '../shared/Routing';
import Store from '../shared/state/Store';
import AppReducer from '../shared/state/AppReducer';

const styles = mergeStyleSets({
  stepsList: {
    fontSize: '14px',
    selectors: {
      li: {
        padding: '2px 0',
      },
    },
  },
});

export default () => {
  const [globalState] = Store.useStore();
  const routingTree = Routing.getTree(globalState).home;
  const appData = AppReducer.getAppData(globalState);
  const appId = appData?.uuid;
  return (
    <Stack vertical>
      <Text block>
        Welcome to the agena.ai web app designer, which enables you to be able to quickly design and publish simple questionnaire-based web applications based on existing Bayesian network models.
      </Text>
      <Text block>
        If you are new to the agena.ai web app Designer then follow these steps:
      </Text>
      <ol className={styles.stepsList}>
        <li>
          Go to
          {' '}
          <Link to={routingTree.routes.webAppDesigner.routes.file.routes.new.path}>File → New</Link>
          {' '}
          and press “Select Model File”
        </li>
        <li>
          After file is validated, confirm your selection
        </li>
        <li>
          Configure your app
          <ol type="a">
            <li>
              Once the model file is confirmed, you will be redirected to
              {' '}
              <Link to={routingTree.routes.webAppDesigner.routes.design.routes.selectInputsOutputs.path}>Design → Select Inputs &amp; Outputs</Link>
              , so you can choose your input and output nodes
            </li>
            <li>
              You can group and rearrange your inputs and outputs in
              {' '}
              <Link to={routingTree.routes.webAppDesigner.routes.design.routes.configureInputs.routes.inputGroups.path}>Design → Configure Inputs → Input Groups</Link>
              {' '}
              and
              {' '}
              <Link to={routingTree.routes.webAppDesigner.routes.design.routes.configureOutputs.routes.outputGroups.path}>Design → Configure Outputs → Output Groups</Link>
            </li>
            <li>
              You can optionally customize your inputs and outputs further in
              {' '}
              <Link to={routingTree.routes.webAppDesigner.routes.design.routes.configureInputs.routes.inputItems.path}>Design → Configure Inputs → Input Items</Link>
              {' '}
              and
              {' '}
              <Link to={routingTree.routes.webAppDesigner.routes.design.routes.configureOutputs.routes.outputItems.path}>Design → Configure Outputs → Output Items</Link>
            </li>
            <li>
              You can customize the name and description of your app, set an app image and adjust names of input and output sections in
              {' '}
              <Link to={routingTree.routes.webAppDesigner.routes.design.routes.applicationProperties.path}>Design → Application Properties</Link>
            </li>
          </ol>
        </li>
        <li>
          Preview and fine tune
          <ul>
            <li>
              You can switch to the
              {' '}
              <Link to={routingTree.routes.webAppDesigner.routes.preview.path}>Preview</Link>
              {' '}
              to see how your app looks and works with the current settings
            </li>
            <li>By default, none of the outputs are shown until you click the Calculate button</li>
            <li>
              Enable Baseline case in
              {' '}
              <Link to={routingTree.routes.webAppDesigner.routes.design.routes.applicationProperties.routes.outputs.path}>Design → Application Properties → Outputs</Link>
              {' '}
              to auto-calculate the app when it loads (including in
              {' '}
              <Link to={routingTree.routes.webAppDesigner.routes.preview.path}>Preview</Link>
              ), and let the user compare their use case with the baseline values (these are the values when there are no questions answered)
            </li>
            <li>
              Note that you have a limited number of re-calculations per day in your preview so use sparingly (you can check your limits and usage in
              {' '}
              <Link to={routingTree.routes.account.routes.subscription.path}>My Portal Account → Subscription</Link>
              )
            </li>
            <li>
              You can also configure individual output items right from the
              {' '}
              <Link to={routingTree.routes.webAppDesigner.routes.preview.path}>Preview</Link>
              {' '}
              interface by clicking the
              {' '}
              <Icon iconName="Settings" style={{ position: 'relative', top: '2px' }} />
              {' '}
              button at the top of the output item
            </li>
          </ul>
        </li>
        <li>
          You can save your app either to your Cloud Account or to disk in
          {' '}
          <Link to={routingTree.routes.webAppDesigner.routes.file.routes.save.path}>File → Save</Link>
        </li>
        <li>
          If saved to the Cloud, collaborate on and publish your app
          <ol type="a">
            <li>
              Go to
              {' '}
              <Link to={routingTree.routes.cloudAppManager.routes.app.routes.accessControl.path.replace(':id', appId || 'my-app')}>Cloud App Manager → Access Control</Link>
              {' '}
              to assign Contributors to your app so they can also edit and save it
            </li>
            <li>
              Assign end-users to your app in
              {' '}
              <Link to={routingTree.routes.cloudAppManager.routes.app.routes.accessControl.path.replace(':id', appId || 'my-app')}>Cloud App Manager → Access Control</Link>
              {' '}
              so they can access the published version of the app
            </li>
            <li>
              Publish your app at
              {' '}
              <Link to={routingTree.routes.cloudAppManager.routes.app.routes.publishing.path.replace(':id', appId || 'my-app')}>Cloud App Manager → Publishing</Link>
              {' '}
              by choosing an available subdomain and enabling publishing
            </li>
            <li>
              Note that you can check how many contributors and end-users you can assign, and how many apps you can publish in
              {' '}
              <Link to={routingTree.routes.account.routes.subscription.path}>My Portal Account → Subscription</Link>
            </li>
          </ol>
        </li>


      </ol>
    </Stack>
  );
};
