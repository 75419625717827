import {
  DefaultButton, mergeStyleSets, Stack,
} from '@fluentui/react';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import keycloak from '../shared/Keycloak';
import logo from '../resources/logo.svg';
import UiReducer from '../shared/state/UiReducer';
import Store from '../shared/state/Store';
import Menu from './Menu';
import changeLogEntries from '../resources/change-log.json';
import ChangeLog from './changelog/ChangeLog';
import HelpButton from './HelpButton';
import Routing from '../shared/Routing';
import Env from '../shared/Env';

const styles = mergeStyleSets({

  root: {
    maxWidth: '100vw',
  },

  leftPart: {
    selectors: {
      p: {
        paddingLeft: '5px',
      },
    },
  },

  menuButton: {
    padding: '20px 0 20px 0',
    border: 'none',
    // fontWeight: 'normal',
    selectors: {
      i: {
        fontSize: '30px',
      },
    },
  },

  logo: {
    height: '20px',
    margin: '10px 0 0 5px !important',
  },

  headerStaging: {
    lineHeight: '11.5px',
    fontSize: '19px',
    padding: '0px',
    margin: '13px 0px 0px',
    fontWeight: '600',
  },
});

export default () => {
  const [globalState, dispatch] = Store.useStore();
  const navigate = useNavigate();
  const routingTree = Routing.getTree(globalState).home;

  React.useEffect(() => {
    dispatch(UiReducer.changeLogUpdate(changeLogEntries));
  }, []);

  // console.log(keycloak);
  const [button, setButton] = React.useState();

  let portalSubHeader = '';
  if (Env.MODE_DEV) {
    portalSubHeader = 'dev';
  }
  if (Env.MODE_STAGING) {
    portalSubHeader = 'staging';
  }

  if (!keycloak.authenticated) {
    return <></>;
  }

  return (
    <Stack horizontal className={styles.root}>

      <Stack horizontal horizontalAlign="start" className={styles.leftPart}>
        <Stack horizontal tokens={{ childrenGap: 5 }}>
          <Menu />
          <a href="/">
            <img
              src={logo}
              alt="agena.ai cloud service"
              className={styles.logo}
            />
          </a>

          <header className={styles.headerStaging}>{portalSubHeader}</header>

        </Stack>

        <HelpButton />
      </Stack>

      <Stack grow={100} />

      <Stack horizontal horizontalAlign="end">

        <ChangeLog />

        <DefaultButton
          text={isMobile ? '' : keycloak.idTokenParsed?.email}
          menuIconProps={{
            iconName: 'Contact',
          }}
          styles={{
            root: {
              minWidth: 'auto',
            },
            label: {
              fontWeight: 'normal',
              display: isMobile ? 'none' : 'block',
            },
          }}
          title="User Options"
          className={styles.menuButton}
          menuProps={{
            // calloutProps: {},
            // directionalHint: DirectionalHint.topRightEdge,
            className: styles.menuItem,
            items: [
              // Mobile compatibility
              ...(isMobile ? [{
                key: 'email',
                text: keycloak.idTokenParsed?.email,
                iconProps: { iconName: 'Contact' },
              }] : []),
              {
                key: 'account',
                text: 'My Portal Account',
                iconProps: { iconName: 'AccountManagement' },
                onClick: () => navigate(routingTree.routes.account.path),
              },
              {
                key: 'logOut',
                text: 'Log Out',
                iconProps: { iconName: 'SignOut' },
                onClick: () => keycloak.logout(),
              },
            ],
          }}
          onMenuClick={(event) => {
            setButton(event.target.closest('button'));
          }}
          onAfterMenuDismiss={() => {
            button.blur();
          }}
        />
      </Stack>
    </Stack>

  );
};
