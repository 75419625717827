// import ModellerReducer from '../../../shared/state/ModellerReducer';
import Utils from '../../../shared/Utils';

const updateNetwork = ({ updateBatch, updatePath, mergeUpdate }) => ({
  reduce: ((prevState) => {
    const updatedState = { ...prevState };
    const modelCopy = updatedState.modeller?.model;
    if (!modelCopy) {
      console.log('No model loaded');
      return prevState;
    }
    updateBatch.forEach((row) => {
      const { networkId, updateBody } = row;

      for (let netIndex = 0; netIndex < modelCopy.networks.length; netIndex += 1) {
        const network = modelCopy.networks[netIndex];
        if (network.id === networkId) {
          if (mergeUpdate) {
            const oldBody = Utils.resolveObjectPath(network, updatePath, {});
            const newBody = { ...oldBody, ...updateBody };
            Utils.setObjectPath(network, updatePath, newBody);
            modelCopy.networks[netIndex] = network;
          } else {
            Utils.setObjectPath(network, updatePath, updateBody);
            modelCopy.networks[netIndex] = network;
          }
          break;
        }
      }
    });

    return updatedState;
  }),
});

const updateNode = ({ updateBatch, updatePath, mergeUpdate }) => ({
  reduce: ((prevState) => {
    // const modelCopy = Utils.clone(model);
    const updatedState = { ...prevState };
    const modelCopy = updatedState.modeller?.model;
    if (!modelCopy) {
      console.log('No model loaded');
      return prevState;
    }
    updateBatch.forEach((row) => {
      const { networkId, nodeId, updateBody } = row;

      // eslint-disable-next-line no-restricted-syntax, no-labels
      loop:
      for (let netIndex = 0; netIndex < modelCopy.networks.length; netIndex += 1) {
        const net = modelCopy.networks[netIndex];
        if (net.id === networkId) {
          for (let nodeIndex = 0; nodeIndex < modelCopy.networks[netIndex].nodes.length; nodeIndex += 1) {
            const node = modelCopy.networks[netIndex].nodes[nodeIndex];
            if (node.id === nodeId) {
              const oldNode = modelCopy.networks[netIndex].nodes[nodeIndex];
              if (mergeUpdate) {
                const oldBody = Utils.resolveObjectPath(oldNode, updatePath, {});
                const newBody = { ...oldBody, ...updateBody };
                Utils.setObjectPath(oldNode, updatePath, newBody);
                // console.log(oldBody, updateBody, newBody, oldNode);
                modelCopy.networks[netIndex].nodes[nodeIndex] = oldNode;
              } else {
                Utils.setObjectPath(oldNode, updatePath, updateBody);
                modelCopy.networks[netIndex].nodes[nodeIndex] = oldNode;
              }
              // eslint-disable-next-line no-restricted-syntax, no-labels
              break loop;
            }
          }
        }
      }
    });

    return updatedState;
  }),
});

const updateNodeGraphics = ({ updateBatch, updatePath = 'graphics.graph' }) => ({
  reduce: ((prevState) => updateNode({ updateBatch, mergeUpdate: true, updatePath }).reduce(prevState)),
});

const updateNetworkGraphics = ({ updateBatch, updatePath = 'graphics.cyto' }) => ({
  reduce: ((prevState) => updateNetwork({ updateBatch, mergeUpdate: true, updatePath }).reduce(prevState)),
});

// const updateNodeCytoStyle = (updateBatch) => ({
//   reduce: ((prevState) => {
//     const preparedBatch = [];
//     updateBatch.forEach((row) => {
//       preparedBatch.push(row);
//     });
//     return updateNode({ updateBatch: preparedBatch, mergeUpdate: true, updatePath: 'graphics.cyto.style' }).reduce(prevState);
//   }),
// });

const updateModelGraphics = (updateBody, mergeUpdate = false) => ({
  reduce: ((prevState) => {
    // const modelCopy = Utils.clone(model);
    const updatedState = { ...prevState };
    const modelCopy = updatedState.modeller?.model;
    if (!modelCopy) {
      console.log('No model loaded');
      return prevState;
    }
    modelCopy.graphics = modelCopy.graphics || {};
    modelCopy.graphics.cyto = modelCopy.graphics.cyto || {};
    if (mergeUpdate) {
      modelCopy.graphics.cyto = {
        ...modelCopy.graphics.cyto,
        ...updateBody,
      };
    }
    return updatedState;
  }),
});

const setRepaintRequested = () => ({
  reduce: ((prevState) => {
    // console.log('Repaint requested');
    const updatedState = { ...prevState };
    updatedState.modeller = updatedState.modeller || {};
    updatedState.modeller.repaintRequested = Date.now();
    return updatedState;
  }),
});

const isRepaintRequested = (globalState) => globalState.modeller?.repaintRequested;

const updateNodeChart = (chartRef, networkId, nodeId) => ({
  reduce: ((prevState) => {
    // let updatedState = prevState;
    const updatedState = { ...prevState };
    const chartImage = Utils.svgToDataUri(Utils.getSvg(chartRef), networkId, nodeId);
    // updatedState = ModellerReducer.cacheNodeChart(networkId, nodeId, chartImage).reduce(updatedState);

    return updateNodeGraphics({
      updateBatch: [{
        networkId,
        nodeId,
        updateBody: {
          'background-image': `url('${chartImage}')`,
        },
      }],
      updatePath: 'graphics.graph.style.custom.mode_chart',
    }).reduce(updatedState);
  }),
});

export default {
  updateNode,
  updateNodeGraphics,
  updateModelGraphics,
  //   updateNodeCytoStyle,
  setRepaintRequested,
  isRepaintRequested,
  updateNodeChart,
  updateNetwork,
  updateNetworkGraphics,
};
