/* eslint-disable max-len */
import {
  mergeStyleSets, Modal, Stack,
} from '@fluentui/react';
import React from 'react';
import Store from '../state/Store';
import Article from './articles/Article';
import PortalManual from './articles/PortalManual';
import WebAppDesignerManual from './articles/WebAppDesignerManual';
import HelpSystem from './HelpSystem';

const localStyles = mergeStyleSets({
  container: {
    position: 'fixed',
    left: '-9999px',
    width: '0px',
    height: '0px',
  },
  footer: {
    paddingTop: '10px',
  },
});

export default () => {
  const [globalState, dispatch] = Store.useStore();

  const hidden = !HelpSystem.isShowModal(globalState);

  if (hidden) {
    return <></>;
  }

  const context = HelpSystem.getContext(globalState);

  let content;

  if (HelpSystem.isContentOverridden(globalState)) {
    const overriddenContent = HelpSystem.getOverrideContent(globalState);
    if (overriddenContent) {
      content = <Article title={overriddenContent.title}>{overriddenContent.children}</Article>;
    }
  }

  if (!content) {
    switch (context) {
      case HelpSystem.Catalog.WebAppDesigner: content = <WebAppDesignerManual />; break;
      default: content = <PortalManual />;
    }
  }

  return (
    <div className={localStyles.container}>
      <Modal
        titleAriaId="Help Modal"
        isOpen
        onDismiss={() => HelpSystem.hideModal(dispatch)}
        isBlocking={false}
        styles={{
          main: {
            minHeight: 'auto',
            height: 'auto',
            padding: '10px',
          },
        }}
      >
        <Stack vertical>

          {content}

        </Stack>
      </Modal>
    </div>
  );
};
