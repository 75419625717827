import React from 'react';
import { DefaultButton, mergeStyleSets, Stack } from '@fluentui/react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import Store from '../shared/state/Store';
import Routing from '../shared/Routing';
import UiReducer from '../shared/state/UiReducer';

export const DefaultNavOption = Routing.getTree({}).home.routes.cloudAppManager.routes.app.routes.overview.path;

const styles = mergeStyleSets({
  menuItem: {
    textAlign: 'left',
    border: 'none !important',
    outline: 'none !important',
    selectors: {
      ':hover': {
        backgroundColor: '#e4e4e4',
      },

      span: {
        fontWeight: 'normal !important',
      },
    },
  },

  menuItemSelected: {
    backgroundColor: '#e4e4e4',
  },
});

export default ({ disabled = false }) => {
  const [globalState] = Store.useStore();
  const navigate = useNavigate();
  const location = useLocation();
  const appId = useParams().id;
  const routingTree = Routing.getTree(globalState).home.routes.cloudAppManager.routes.app.routes;

  const cloudApps = UiReducer.getGenericUiAttribute(globalState, UiReducer.ListOptions.cloudManagerAppsList) || [];
  const app = cloudApps.filter((a) => a.uuid === appId)[0];

  const menuItems = [
    {
      type: 'button',
      text: routingTree.overview.text,
      key: routingTree.overview.path.replace(':id', appId),
      action: () => navigate(routingTree.overview.path.replace(':id', appId)),
    },
    {
      type: 'button',
      text: routingTree.accessControl.text,
      key: routingTree.accessControl.path.replace(':id', appId),
      action: () => navigate(routingTree.accessControl.path.replace(':id', appId)),
    },
    {
      type: 'button',
      text: routingTree.publishing.text,
      key: routingTree.publishing.path.replace(':id', appId),
      action: () => navigate(routingTree.publishing.path.replace(':id', appId)),
    },
    {
      type: 'button',
      text: routingTree.dataProcessing.text,
      key: routingTree.dataProcessing.path.replace(':id', appId),
      action: () => navigate(routingTree.dataProcessing.path.replace(':id', appId)),
    },
  ];

  if (app?.archivingEnabled) {
    menuItems.push({
      type: 'button',
      text: routingTree.archive.text,
      key: routingTree.archive.path.replace(':id', appId),
      action: () => navigate(routingTree.archive.path.replace(':id', appId)),
    });
  }


  const selectedMenuItem = location.pathname || DefaultNavOption;

  const renderedItems = menuItems.map(((menuItemData) => {
    let className = styles.menuItem;
    if (menuItemData.key === selectedMenuItem) {
      className += ` ${styles.menuItemSelected}`;
    }
    return (
      <DefaultButton
        key={menuItemData.key}
        text={menuItemData.text}
        className={className}
        onClick={(event) => {
          if (disabled) {
            return;
          }
          menuItemData.action();
          event.target.closest('button').blur();
        }}
      />
    );
  }));

  return (
    <Stack vertical>
      {renderedItems}
    </Stack>
  );
};
