import { mergeStyleSets, Stack } from '@fluentui/react';
import React from 'react';

export default ({ config }) => {
  const styles = mergeStyleSets({

    textArea: {
      height: '200px',
      width: '600px',
    },

  });

  return (

    <Stack vertical>

      <p>
        Model JSON
        <br />
        <textarea
          className={styles.textArea}
          readOnly
          value={JSON.stringify(config.model, null, 2)}
        />
      </p>

      <p>
        Input Config
        <br />
        <textarea
          className={styles.textArea}
          readOnly
          value={JSON.stringify(config.inputConfig, null, 2)}
        />
      </p>

      <p>
        Output Config
        <br />
        <textarea
          className={styles.textArea}
          readOnly
          value={JSON.stringify(config.outputConfig, null, 2)}
        />
      </p>

      <p>
        General Config
        <br />
        <textarea
          className={styles.textArea}
          readOnly
          value={JSON.stringify(config.generalConfig, null, 2)}
        />
      </p>

    </Stack>
  );
};
