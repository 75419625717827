import structuredClone from 'core-js/stable/structured-clone';

const build = (network, node) => {
  /* eslint-disable no-param-reassign */
  // Build a map of node.id to node
  const nodeMap = network.nodes.reduce((fmap, fnode) => {
    fmap[fnode.id] = fnode;
    return fmap;
  }, {});
  /* eslint-disable no-param-reassign */

  const nodeName = node.name || node.id;

  const columnHeaders = [];
  const parents = [];
  let columns = 1;
  // First, find all parents by inspecting network links, and for each parent add corresponding headers row with name and states
  network.links.forEach((link) => {
    if (link.child === node.id) {
      const parent = nodeMap[link.parent];
      parents.push(parent);
      columns *= parent.configuration.states.length;
      const parentHeaders = [{ label: parent.name || parent.id }];
      parent.configuration.states.forEach((state, i) => {
        parentHeaders.push({ label: state, index: i });
      });
      columnHeaders.push(parentHeaders);
    }
  });

  // Now in reverse order building from down up calculate colSpan for each parent
  // Parent's colSpan is colSpan_previous + parent_previous.states.length
  let colSpan = 0;
  for (let rowIndex = columnHeaders.length - 1; rowIndex >= 0; rowIndex -= 1) {
    const parent = parents[rowIndex];
    if (colSpan > 1) {
      for (let colIndex = 0; colIndex < columnHeaders[rowIndex].length; colIndex += 1) {
        if (colIndex > 0) {
          columnHeaders[rowIndex][colIndex].colSpan = colSpan;
        }
      }
    }

    // Make copies of parent columns for the full width of the table
    // eslint-disable-next-line no-undef
    const parentColumns = structuredClone(columnHeaders[rowIndex].slice(1)); // Clone this or column copies will have same reference
    const parentColumnsWidth = parentColumns.length * (colSpan || 1); // We initially set colSpan to 0 but it is actually 1
    for (let pCol = parentColumnsWidth; pCol < columns; pCol += parentColumnsWidth) {
      columnHeaders[rowIndex].push(...parentColumns);
    }

    colSpan += parent.configuration.states.length;
  }

  // columnHeaders.push([{ label: nodeName }, { label: ' ', ...(columns > 1 && { colSpan: columns }) }]); // Do not include colSpan if it is 1
  columnHeaders.unshift([{ label: nodeName, colSpan: (columns + 1) }]); // Do not include colSpan if it is 1

  return {
    network: network.id,
    node: node.id,
    columnHeaders,
    rowHeaders: node.configuration.states,
    rows: node.configuration.table.probabilities,
  };
};

const parseTabulatedData = (tabulatedData, separator = '\t') => {
  const lines = `${tabulatedData}`.trim().split(/\r?\n/);
  return lines.map((line) => line.split(separator).map((el) => el.trim()));
};

const toTabulatedData = (array2d, newlineCharacter = '\n', separator = '\t') => array2d.map((row) => row.join(separator)).join(newlineCharacter);

/**
 * Will non-destructively apply data to the provided table overwriting table cells with cells from data unless they are NaN
 * The returned table is the same size as provided table, any extra values from data will be ignored
 *
 * @param {*} table
 * @param {*} data
 * @param {*} startRow
 * @param {*} startCol
 */
const apply = (table, data, startRow = 0, startCol = 0) => {
  const newTable = structuredClone(table);
  for (let rowIndex = 0; rowIndex < data.length; rowIndex += 1) {
    for (let colIndex = 0; colIndex < data[rowIndex].length; colIndex += 1) {
      const tableRow = startRow + rowIndex;
      const tableCol = startCol + colIndex;
      if (tableRow > table.length - 1 || tableCol > table[tableRow].length - 1) {
        // Out of table bounds, ignore
        continue;
      }
      const dataValue = Number.parseFloat(data?.[rowIndex]?.[colIndex]);
      if (!Number.isNaN(dataValue) && Number.isFinite(dataValue)) {
        // Overwrite table number if data number is a finite number
        newTable[tableRow][tableCol] = dataValue;
      }
    }
  }
  return newTable;
};

const normalize = (column) => {
  const sum = column.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  if (sum === 0) {
    return column;
  }
  const normalized = column.map((value) => value / sum);
  // console.log('normalize', column, sum, normalized);
  return normalized;
};

const DEFAULT_MIN_VALUE = 1e-30;
const DEFAULT_MAX_VALUE = 1e30;

/**
 * Checks whether any cells are overridden
 * @param {*} tableData table data retrieved with UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.webAppInputsOverriddenNptMap)?.[fieldKey]
 * @param {*} defaultTableData default table data contained in inputConfig.editableNPT
 * @returns true if any cells do not match, false otherwise
 */
const isTableEdited = (tableData, defaultTableData) => {
  if (!tableData || !defaultTableData) {
    return false;
  }

  for (let rowIndex = 0; rowIndex < defaultTableData.rows.length; rowIndex += 1) {
    for (let colIndex = 0; colIndex < defaultTableData.rows[rowIndex]?.length; colIndex += 1) {
      if (defaultTableData.rows?.[rowIndex]?.[colIndex] !== tableData?.rows?.[rowIndex]?.[colIndex]) {
        return true;
      }
    }
  }
  return false;
};

export default {
  build,
  parseTabulatedData,
  toTabulatedData,
  apply,
  normalize,
  DEFAULT_MIN_VALUE,
  DEFAULT_MAX_VALUE,
  isTableEdited,
};
