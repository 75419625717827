import React from 'react';
import {
  ActionButton, Icon, mergeStyleSets, Panel, PanelType, Stack,
} from '@fluentui/react';
import { isMobile } from 'react-device-detect';
import Store from '../../shared/state/Store';
import UiReducer from '../../shared/state/UiReducer';
import packageJson from '../../../package.json';
import tokens from '../../shared/StackTokens';
import VersionItem from './VersionItem';
import User from '../../shared/User';

const styles = mergeStyleSets({
  linkButton: {
    'margin-left': '0',
    'padding-left': '0',
    color: 'rgb(0, 120, 212)',
    fontSize: 'smaller',
  },

  overlay: {},

  panel: {
    selectors: {
      h1: {
        fontSize: '25px',
        marginTop: '5px',
        marginBottom: '0px',
      },
      h2: {
        fontSize: '20px',
      },
      h3: {
        fontSize: 'initial',
      },
      'h2, h3': {
        padding: '5px 0 0 0',
        margin: '5px 0 0 0',
      },
      '.version-item': {
        borderBottom: '1px solid gray',
        paddingBottom: '10px',
      },
      '.version-item:last-of-type': {
        borderBottom: 'none',
      },
    },
  },

  versionItem: {

  },

  sectionItem: {

  },

  sectionEntry: {

  },

  buttonContainer: {
    whiteSpace: 'nowrap',
  },
});

const panelStyles = {
  // header: { fontSize: '25px !important' },
  // contentInner: { background: 'lime' },
//   content: { padding: '0px 10px' },
  // footer: { background: 'yellow' },
//   main: { left: 0 },
  // scrollableContent: { background: 'blue' },
  subComponentStyles: {
    closeButton: {
      outline: 'none !important',
    },
  },
  closeButton: {
    outline: 'none !important',
  },
};

export default () => {
  const [globalState, dispatch] = Store.useStore();

  const BreakException = {};
  const checkUnread = () => {
    globalState.changeLog.versionList.forEach((versionItem) => {
      versionItem.sections
        .filter((section) => !section.visibility || (section.visibility && User.getRealmGroups().filter((el) => section.visibility.includes(el)).length > 0))
        .forEach((sectionItem) => {
          sectionItem.entries.forEach((sectionEntry) => {
            const key = versionItem.version + sectionItem.name + sectionEntry.summary + sectionEntry.version;
            // eslint-disable-next-line eqeqeq
            const seen = localStorage.getItem(`ar-wappd-change-log-${key}`) != undefined;
            if (!seen) {
              dispatch(UiReducer.changeLogSetUnread(true));
              throw BreakException;
            }
          });
        });
    });
  };

  React.useEffect(() => {
    if (globalState.changeLog?.versionList) {
      try {
        checkUnread();
      } catch (e) {
        if (e !== BreakException) {
          throw e;
        }
      }
    }
  }, [globalState.changeLog?.versionList]);

  const panelOpen = globalState.changeLog?.isOpen;

  const iconUnread = (
    <Icon
      iconName="CircleFill"
      style={{
        fontSize: '10px',
        color: 'red',
      }}
    />
  );

  let content = [];
  if (panelOpen && globalState.changeLog.versionList) {
    content = globalState.changeLog.versionList.map((versionItem) => (
      <VersionItem
        key={versionItem.version}
        versionItem={versionItem}
        className={styles.versionItem}
        iconUnread={iconUnread}
      />
    ));
  }

  return (
    <>
      <div className={styles.buttonContainer}>
        <ActionButton
          onClick={(event) => {
            dispatch(UiReducer.changeLogSetOpen(true));
            dispatch(UiReducer.changeLogSetUnread(false));
            if (event?.target) {
              event.target.closest('button').blur();
            }
          }}
          text={(
            <span>
              {isMobile ? 'v' : 'Version '}
              {packageJson.version}
              {' '}
              {globalState.changeLog?.isUnread && iconUnread}
            </span>
          )}
          className={styles.linkButton}
        />

      </div>

      { panelOpen && (
        <Panel
          headerText={<h1 style={{ fontSize: '16px' }}>Change log</h1>}
          isOpen={panelOpen}
          onDismiss={() => {
            dispatch(UiReducer.changeLogSetOpen(false));
          }}
          closeButtonAriaLabel="Close"
          type={PanelType.medium}
          overlayProps={{
            allowTouchBodyScroll: true,
            onClick: () => {
              dispatch(UiReducer.changeLogSetOpen(false));
            },
            className: styles.overlay,
          }}
          styles={panelStyles}
        >
          <Stack vertical tokens={tokens.verticalSpacing} className={styles.panel}>
            {content}
          </Stack>
        </Panel>
      )}
    </>
  );
};
