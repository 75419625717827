import React from 'react';
import {
  // DefaultButton, TextField, PrimaryButton,
  Stack, Text, Icon,
} from '@fluentui/react';
// import { useKeycloak } from '@react-keycloak/web';
// import Store from '../shared/state/Store';
import StackTokens from '../shared/StackTokens';
import Theme from '../shared/Theme';


export default () => (
  <Stack vertical tokens={StackTokens.spacing} className={Theme.styles.majorSection}>
    <Stack vertical tokens={StackTokens.spacing}>
      <Text block>Your agena.ai cloud portal account enables you to use agena.ai cloud API.</Text>

      <Text block>
        <span>agena.ai cloud API provides endpoints for the following requests:</span>
        <ul className={Theme.styles.list}>
          <li>Calculate model with case data (observations)</li>
          <li>Run Sensitivity Analysis</li>
          <li>Convert AST, CMP, CMPX models to JSON</li>
          <li>Calculate percentiles on a result data in agena.ai format</li>
        </ul>
      </Text>

      <Text block>
        See our
        <a href="https://agenarisk.atlassian.net/wiki/external/MmI0OTI5MTZmMzZkNDgxMjhmYjJlZTQzY2RjZWZjZTM" target="_blank" rel="noopener noreferrer">
          &nbsp;
          <span>agena.ai cloud API manual</span>
          <span>&nbsp;</span>
          <sub>
            <Icon iconName="OpenInNewTab" />
          </sub>
          &nbsp;
        </a>
        for more details, instructions and data format.
      </Text>

      <Text block>
        <span>Use cases supported by agena.ai cloud API include:</span>
        <ul className={Theme.styles.list}>
          <li>Server-to-server, automated pipelines</li>
          <li>Tailored user applications</li>
        </ul>
      </Text>

      <Text block>
        <span>In addition to the cloud API we provide helper libraries to help working with models, authenticating and calculating:</span>
        <ul className={Theme.styles.list}>
          <li><a href="https://www.npmjs.com/package/agena-api-js" target="_blank" rel="noopener noreferrer">JavaScript</a></li>
          <li><a href="https://cran.r-project.org/web/packages/agena.ai/index.html" target="_blank" rel="noopener noreferrer">R</a></li>
          <li><a href="https://pypi.org/project/pyagena" target="_blank" rel="noopener noreferrer">Python</a></li>
        </ul>
      </Text>

      <Text block>
        <span>
          There is also a
          {' '}
          <a href="https://github.com/AgenaRisk/api-example-app" target="_blank" rel="noopener noreferrer">Java API</a>
          {' '}
          available if you are looking to integrate agena.ai core calculator into your Java application
        </span>
      </Text>

    </Stack>

  </Stack>
);
