// import { base32crockford } from '@scure/base';
import ChartDefaults from '../shared/ChartDefaults';
import GraphDefaults from './ModellerDefaults';

const validateInputValue = ({ value, validationRules = true }) => {
  let valueTrimmed = `${value}`.trim();

  if (!validationRules) {
    return valueTrimmed;
  }

  if (!validationRules.allowEmpty && valueTrimmed === '') {
    throw new Error('Empty value not allowed');
  }

  let valid = true;

  const {
    type, min, max, regex,
  } = validationRules;

  if (regex && !valueTrimmed.match(regex)) {
    valid = false;
  }

  if (valueTrimmed !== '' && type === 'int') {
    valid = /^(-)?\d+?$/.test(valueTrimmed);
    const valueInt = parseInt(valueTrimmed, 10);
    if (max !== undefined && valueInt > parseInt(max, 10)) {
      valid = false;
    }
    if (min !== undefined && valueInt < parseInt(min, 10)) {
      valid = false;
    }
    valueTrimmed = valueInt;
  }

  if (valueTrimmed !== '' && type === 'float') {
    valid = /^(-)?(\d+|(\d*(\.\d*)))([eE]+[-+]?\d+)?$/.test(valueTrimmed);
    const valueFloat = parseFloat(valueTrimmed, 10);
    if (valid && max !== undefined && valueFloat > parseFloat(max, 10)) {
      valid = false;
    }
    if (valid && min !== undefined && valueFloat < parseFloat(min, 10)) {
      valid = false;
    }
    valueTrimmed = valueFloat;
  }

  if (!valid) {
    throw new Error(`Invalid value: ${value}`);
  }

  return valueTrimmed;
};

// const escapeSelector = (itemKey) => base32crockford.encode(new TextEncoder().encode(itemKey));
const escapeSelector = (itemKey) => `${itemKey}`.trim().toLowerCase().replaceAll(/[^a-z0-9]/g, '___');

const convertDatasetIdToKey = (datasetId) => `${datasetId}`.trim().toLowerCase().replaceAll(/[^a-z0-9]/g, '__');

const getInputMode = (node) => {
  const graphicsConfig = node.graphics || GraphDefaults.DefaultGraphics;
  if (graphicsConfig.input?.entryMode) {
    return graphicsConfig.input?.entryMode;
  }

  if (['ContinuousInterval', 'IntegerInterval'].includes(node.configuration?.type)) {
    return GraphDefaults.InputModes.NUMERIC;
  }
  return GraphDefaults.InputModes.DROPDOWN;
};

const getChartType = (node) => {
  const graphicsConfig = node.graphics || GraphDefaults.DefaultGraphics;
  if (graphicsConfig.output?.entryMode) {
    return graphicsConfig.output?.entryMode;
  }

  const { ChartTypes } = ChartDefaults;

  if (['ContinuousInterval', 'IntegerInterval'].includes(node.configuration?.type)) {
    return ChartTypes.Area;
  }
  return ChartTypes.BarChart;
};

export default {
  validateInputValue,
  escapeSelector,
  convertDatasetIdToKey,
  getInputMode,
  getChartType,
};
