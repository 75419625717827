/* eslint-disable no-console */
import keycloak from '../Keycloak';
import Config from '../Config';

const buildHeaders = () => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'text/plain; charset=utf-8',
    ...(Config.isModePublicWebApp() && { 'Authentication-Mode': 'Anonymous' }),
  };
  if (keycloak.authenticated && keycloak.token) {
    headers.Authorization = `Bearer ${keycloak.token}`;
  }
  return headers;
};

function submitJob({ request, url, headers = {} }) {
  console.log(`Submitting job to: ${url}`);

  const compiledHeaders = {
    ...buildHeaders(),
    ...headers,
  };

  return fetch(url, {
    method: 'POST',
    headers: compiledHeaders,
    body: JSON.stringify(request),
  })
    .then((response) => response.json())
    .catch((error) => {
      console.log(error);
      return {
        status: 'failure',
        messages: [error.message],
      };
    });
}

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

function pollResult({ url, delay = 1000, headers = {} }) {
  console.log(`Checking job status at: ${url}`);
  const compiledHeaders = {
    ...buildHeaders(),
    ...headers,
  };
  return sleep(delay).then(() => fetch(url, {
    method: 'GET',
    headers: compiledHeaders,
  })
    .then((response) => response.json())
    .catch((error) => {
      console.log(error);
    }));
}

export default async ({
  requestJson, path, headers, server = window.env.REACT_APP_API_ROOT, skipPolling,
}) => {
  if (!server || server === '') {
    return {
      status: 'failure',
      messages: ['API server URL not specified'],
    };
  }

  const initialResponse = await submitJob({ request: requestJson, url: server + path, headers });

  if (['success', 'failure', 'rejected'].includes(initialResponse.status)) {
    // Check if the job was resolved on submission
    return initialResponse;
  }

  if (!initialResponse.pollingUrl) {
    // Rejected, no polling URL
    return initialResponse;
  }

  if (skipPolling) {
    return initialResponse;
  }

  const maxRetry = 200;
  const maxDelay = 5000;
  let delay = 1000;

  for (let i = 0; i < maxRetry; i += 1) {
    if (delay < maxDelay) {
      delay += i * 100;
    }
    // eslint-disable-next-line no-await-in-loop
    const pollResponse = await pollResult({ url: initialResponse.pollingUrl, delay, headers });
    if (!pollResponse) {
      // eslint-disable-next-line no-continue
      continue;
    }
    if (['success', 'failure', 'rejected'].includes(pollResponse.status)) {
      return pollResponse;
    }
  }

  return {
    status: 'failure',
    messages: [`No result data after ${maxRetry} attempts, check ${initialResponse.pollingUrl}`],
  };
};
