import { mergeStyleSets, Stack, Text } from '@fluentui/react';
import React from 'react';
import IconButton from '../../../../shared/IconButton';

const localStyles = mergeStyleSets({
  containerPanel: {
    height: 'fit-content',
    selectors: {
      ':hover': {
        background: '#edebe9',
      },
    },
  },

  text: {
    height: 'fit-content',
  },

  iconButton: {
    marginTop: '3px',
    height: 'fit-content',
  },
});

export default ({ text, onClick }) => (
  <Stack horizontal className={localStyles.containerPanel}>
    <Text className={localStyles.text}>{text}</Text>
    <IconButton
      iconName="StatusCircleErrorX"
      tooltip={`Delete ${text}`}
      className={localStyles.iconButton}
      onClick={onClick}
    />
  </Stack>
);
