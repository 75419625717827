import React from 'react';
import Store from '../shared/state/Store';
import ConfigReducer from '../shared/state/ConfigReducer';
import ModellerReducer from '../modeller/ModellerReducer';

export default ({ children }) => {
  const [globalState, dispatch] = Store.useStore();
  React.useEffect(() => {
    dispatch(ConfigReducer.mapOutputConfig());
  }, [globalState.config?.outputConfig]);

  React.useEffect(() => {
    if (globalState.config?.model) {
      dispatch(ModellerReducer.loadModel(globalState.config?.model));
    }
  }, [globalState.config?.model]);

  return (
    <>
      {children}
    </>
  );
};
