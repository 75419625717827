/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { DefaultButton, PrimaryButton } from '@fluentui/react';

const defaultStyles = {
  root: {
    // top: '3px',
    // outline: 'none !important',
  },
  button: {
    // outline: 'none !important',
  },
};

export default (props) => {
  const buttonRef = React.useRef();
  const [showReaction, setShowReaction] = React.useState(false);
  const [success, setSuccess] = React.useState(true);

  const {
    onClick,
    styles,
    iconDefault,
    iconSuccess,
    iconFailure,
    primaryButton,
  } = props;

  const appliedStyles = { ...defaultStyles, ...styles };

  let iconReact;
  if (success && iconSuccess) {
    iconReact = iconSuccess;
  }
  if (!success && iconFailure) {
    iconReact = iconFailure;
  }

  const appliedProps = {
    ...props,
    componentRef: buttonRef,
    iconProps: {
      iconName: showReaction ? iconReact : iconDefault,
    },
    onClick: async () => {
      if (typeof onClick !== 'function') {
        return;
      }
      const result = await onClick();
      setSuccess(result);

      if (buttonRef.current) {
        buttonRef.current.focus();
      }
      if ((result && iconSuccess) || (!result && iconFailure)) {
        // React if success and success icon was passed
        // or if failure and failure icon was passed
        setShowReaction(true);
      }
    },
    onBlur: () => {
      setShowReaction(false);
    },
    styles: appliedStyles,
  };

  if (primaryButton) {
    return (
      <PrimaryButton {...appliedProps} />
    );
  }

  return (
    <DefaultButton {...appliedProps} />
  );
};
