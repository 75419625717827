import { DefaultButton } from '@fluentui/react';
import React from 'react';
import Store from '../../shared/state/Store';
import UiReducer from '../../shared/state/UiReducer';


export default ({ licenseKey }) => {
  const [, dispatch] = Store.useStore();

  return (
    <DefaultButton
      text="Assign"
      onClick={() => {
        dispatch(UiReducer.setGenericUiAttribute(UiReducer.Keys.AccountKeyAssignmentDialogOpen, licenseKey));
      }}
      styles={{
        root: {
          height: '24px',
          outline: 'none !important',
        },
      }}
    />
  );
};
