import { mergeStyleSets } from '@fluentui/react';
import React from 'react';
import CollapsiblePanel from '../../shared/CollapsiblePanel';
import Theme from '../../shared/Theme';
import TextFieldBuffered from '../../shared/TextFieldBuffered';
import GraphUtils from '../../modeller/GraphUtils';

const componentStyles = mergeStyleSets({
  secondaryTh: {
    selectors: {
      p: {
        padding: '5px 0 0 0',
        margin: '0',
      },
    },
  },

  manualInput: {
    width: '100px',
  },
});

export default ({
  featureUsage,
  styles,
  setSubscriptionUpdate,
  editable,
  subscriptionUpdate,
}) => {
  const getFeatureTitle = (featureName) => {
    switch (featureName) {
      case 'calculator': return 'Preview calculation';
      case 'userCalculator': return 'End-user calculation';
      case 'apiCalculator': return 'API calculation';
      default: return featureName;
    }
  };

  const formatValue = (value) => Number(value).toLocaleString();

  const updatedFeatureUsage = subscriptionUpdate.featuresUsage[featureUsage.feature];

  return (
    <CollapsiblePanel
      collapsible
      collapsed={false}
      title={getFeatureTitle(featureUsage.feature)}
      keyOverride={featureUsage.feature}
      styles={{
        header: { fontSize: 'initial' },
        icon: { fontSize: 'smaller', marginTop: '3px' },
        ...styles,
      }}
    >
      <table className={[Theme.styles.table, Theme.styles.tableNoPadding, Theme.styles.tableText, Theme.styles.tableRowHighlight].join(' ')}>
        <thead>
          <tr>
            <th>Category</th>
            <th>Usage</th>
            <th>Cap</th>
          </tr>
        </thead>
        <tbody>
          <tr><th colSpan={3} className={componentStyles.secondaryTh}><p>Calculation Count</p></th></tr>
          <tr>
            <td>Total</td>
            <td>{formatValue(featureUsage.meter.totalCount)}</td>
            <td>
              {editable ? (
                <TextFieldBuffered
                  validateValue={(value) => {
                    let validatedValue;
                    try {
                      validatedValue = GraphUtils.validateInputValue({
                        value,
                        validationRules: { type: 'int', allowEmpty: false, min: 0 },
                      });
                    } catch (error) {
                      throw new Error(error.message);
                    }
                    return validatedValue;
                  }}
                  applyValue={(value) => {
                    const update = { ...subscriptionUpdate };
                    update.featuresUsage[featureUsage.feature].limit.totalCount = value;
                    setSubscriptionUpdate(update);
                  }}
                  actualValue={updatedFeatureUsage.limit.totalCount}
                  className={componentStyles.manualInput}
                />
              ) : (
                <span>{formatValue(featureUsage.limit.totalCount)}</span>
              )}
            </td>
          </tr>
          <tr>
            <td>Hourly</td>
            <td>{formatValue(featureUsage.meter.hourlyCount)}</td>
            <td>
              {editable ? (
                <TextFieldBuffered
                  validateValue={(value) => {
                    let validatedValue;
                    try {
                      validatedValue = GraphUtils.validateInputValue({
                        value,
                        validationRules: { type: 'int', allowEmpty: false, min: 0 },
                      });
                    } catch (error) {
                      throw new Error(error.message);
                    }
                    return validatedValue;
                  }}
                  applyValue={(value) => {
                    const update = { ...subscriptionUpdate };
                    update.featuresUsage[featureUsage.feature].limit.hourlyCount = value;
                    setSubscriptionUpdate(update);
                  }}
                  actualValue={updatedFeatureUsage.limit.hourlyCount}
                  className={componentStyles.manualInput}
                />
              ) : (
                <span>{formatValue(featureUsage.limit.hourlyCount)}</span>
              )}
            </td>
          </tr>
          <tr>
            <td>Daily</td>
            <td>{formatValue(featureUsage.meter.dailyCount)}</td>
            <td>
              {editable ? (
                <TextFieldBuffered
                  validateValue={(value) => {
                    let validatedValue;
                    try {
                      validatedValue = GraphUtils.validateInputValue({
                        value,
                        validationRules: { type: 'int', allowEmpty: false, min: 0 },
                      });
                    } catch (error) {
                      throw new Error(error.message);
                    }
                    return validatedValue;
                  }}
                  applyValue={(value) => {
                    const update = { ...subscriptionUpdate };
                    update.featuresUsage[featureUsage.feature].limit.dailyCount = value;
                    setSubscriptionUpdate(update);
                  }}
                  actualValue={updatedFeatureUsage.limit.dailyCount}
                  className={componentStyles.manualInput}
                />
              ) : (
                <span>{formatValue(featureUsage.limit.dailyCount)}</span>
              )}
            </td>
          </tr>
          <tr>
            <td>Weekly</td>
            <td>{formatValue(featureUsage.meter.weeklyCount)}</td>
            <td>
              {editable ? (
                <TextFieldBuffered
                  validateValue={(value) => {
                    let validatedValue;
                    try {
                      validatedValue = GraphUtils.validateInputValue({
                        value,
                        validationRules: { type: 'int', allowEmpty: false, min: 0 },
                      });
                    } catch (error) {
                      throw new Error(error.message);
                    }
                    return validatedValue;
                  }}
                  applyValue={(value) => {
                    const update = { ...subscriptionUpdate };
                    update.featuresUsage[featureUsage.feature].limit.weeklyCount = value;
                    setSubscriptionUpdate(update);
                  }}
                  actualValue={updatedFeatureUsage.limit.weeklyCount}
                  className={componentStyles.manualInput}
                />
              ) : (
                <span>{formatValue(featureUsage.limit.weeklyCount)}</span>
              )}
            </td>
          </tr>
          <tr>
            <td>Monthly</td>
            <td>{formatValue(featureUsage.meter.monthlyCount)}</td>
            <td>
              {editable ? (
                <TextFieldBuffered
                  validateValue={(value) => {
                    let validatedValue;
                    try {
                      validatedValue = GraphUtils.validateInputValue({
                        value,
                        validationRules: { type: 'int', allowEmpty: false, min: 0 },
                      });
                    } catch (error) {
                      throw new Error(error.message);
                    }
                    return validatedValue;
                  }}
                  applyValue={(value) => {
                    const update = { ...subscriptionUpdate };
                    update.featuresUsage[featureUsage.feature].limit.monthlyCount = value;
                    setSubscriptionUpdate(update);
                  }}
                  actualValue={updatedFeatureUsage.limit.monthlyCount}
                  className={componentStyles.manualInput}
                />
              ) : (
                <span>{formatValue(featureUsage.limit.monthlyCount)}</span>
              )}
            </td>
          </tr>

          <tr><th colSpan={3} className={componentStyles.secondaryTh}><p>CPU Time (ms)</p></th></tr>
          <tr>
            <td>Total</td>
            <td>{formatValue(featureUsage.meter.totalCpuTime)}</td>
            <td>
              {editable ? (
                <TextFieldBuffered
                  validateValue={(value) => {
                    let validatedValue;
                    try {
                      validatedValue = GraphUtils.validateInputValue({
                        value,
                        validationRules: { type: 'int', allowEmpty: false, min: 0 },
                      });
                    } catch (error) {
                      throw new Error(error.message);
                    }
                    return validatedValue;
                  }}
                  applyValue={(value) => {
                    const update = { ...subscriptionUpdate };
                    update.featuresUsage[featureUsage.feature].limit.totalCpuTime = value;
                    setSubscriptionUpdate(update);
                  }}
                  actualValue={updatedFeatureUsage.limit.totalCpuTime}
                  className={componentStyles.manualInput}
                />
              ) : (
                <span>{formatValue(featureUsage.limit.totalCpuTime)}</span>
              )}
            </td>
          </tr>
          <tr>
            <td>Hourly</td>
            <td>{formatValue(featureUsage.meter.hourlyCpuTime)}</td>
            <td>
              {editable ? (
                <TextFieldBuffered
                  validateValue={(value) => {
                    let validatedValue;
                    try {
                      validatedValue = GraphUtils.validateInputValue({
                        value,
                        validationRules: { type: 'int', allowEmpty: false, min: 0 },
                      });
                    } catch (error) {
                      throw new Error(error.message);
                    }
                    return validatedValue;
                  }}
                  applyValue={(value) => {
                    const update = { ...subscriptionUpdate };
                    update.featuresUsage[featureUsage.feature].limit.hourlyCpuTime = value;
                    setSubscriptionUpdate(update);
                  }}
                  actualValue={updatedFeatureUsage.limit.hourlyCpuTime}
                  className={componentStyles.manualInput}
                />
              ) : (
                <span>{formatValue(featureUsage.limit.hourlyCpuTime)}</span>
              )}
            </td>
          </tr>
          <tr>
            <td>Daily</td>
            <td>{formatValue(featureUsage.meter.dailyCpuTime)}</td>
            <td>
              {editable ? (
                <TextFieldBuffered
                  validateValue={(value) => {
                    let validatedValue;
                    try {
                      validatedValue = GraphUtils.validateInputValue({
                        value,
                        validationRules: { type: 'int', allowEmpty: false, min: 0 },
                      });
                    } catch (error) {
                      throw new Error(error.message);
                    }
                    return validatedValue;
                  }}
                  applyValue={(value) => {
                    const update = { ...subscriptionUpdate };
                    update.featuresUsage[featureUsage.feature].limit.dailyCpuTime = value;
                    setSubscriptionUpdate(update);
                  }}
                  actualValue={updatedFeatureUsage.limit.dailyCpuTime}
                  className={componentStyles.manualInput}
                />
              ) : (
                <span>{formatValue(featureUsage.limit.dailyCpuTime)}</span>
              )}
            </td>
          </tr>
          <tr>
            <td>Weekly</td>
            <td>{formatValue(featureUsage.meter.weeklyCpuTime)}</td>
            <td>
              {editable ? (
                <TextFieldBuffered
                  validateValue={(value) => {
                    let validatedValue;
                    try {
                      validatedValue = GraphUtils.validateInputValue({
                        value,
                        validationRules: { type: 'int', allowEmpty: false, min: 0 },
                      });
                    } catch (error) {
                      throw new Error(error.message);
                    }
                    return validatedValue;
                  }}
                  applyValue={(value) => {
                    const update = { ...subscriptionUpdate };
                    update.featuresUsage[featureUsage.feature].limit.weeklyCpuTime = value;
                    setSubscriptionUpdate(update);
                  }}
                  actualValue={updatedFeatureUsage.limit.weeklyCpuTime}
                  className={componentStyles.manualInput}
                />
              ) : (
                <span>{formatValue(featureUsage.limit.weeklyCpuTime)}</span>
              )}
            </td>
          </tr>
          <tr>
            <td>Monthly</td>
            <td>{formatValue(featureUsage.meter.monthlyCpuTime)}</td>
            <td>
              {editable ? (
                <TextFieldBuffered
                  validateValue={(value) => {
                    let validatedValue;
                    try {
                      validatedValue = GraphUtils.validateInputValue({
                        value,
                        validationRules: { type: 'int', allowEmpty: false, min: 0 },
                      });
                    } catch (error) {
                      throw new Error(error.message);
                    }
                    return validatedValue;
                  }}
                  applyValue={(value) => {
                    const update = { ...subscriptionUpdate };
                    update.featuresUsage[featureUsage.feature].limit.monthlyCpuTime = value;
                    setSubscriptionUpdate(update);
                  }}
                  actualValue={updatedFeatureUsage.limit.monthlyCpuTime}
                  className={componentStyles.manualInput}
                />
              ) : (
                <span>{formatValue(featureUsage.limit.monthlyCpuTime)}</span>
              )}
            </td>
          </tr>

          <tr><th colSpan={3} className={componentStyles.secondaryTh}><p>Memory (MiB)</p></th></tr>
          <tr>
            <td>Total</td>
            <td>{formatValue(featureUsage.meter.totalMemory)}</td>
            <td>
              {editable ? (
                <TextFieldBuffered
                  validateValue={(value) => {
                    let validatedValue;
                    try {
                      validatedValue = GraphUtils.validateInputValue({
                        value,
                        validationRules: { type: 'int', allowEmpty: false, min: 0 },
                      });
                    } catch (error) {
                      throw new Error(error.message);
                    }
                    return validatedValue;
                  }}
                  applyValue={(value) => {
                    const update = { ...subscriptionUpdate };
                    update.featuresUsage[featureUsage.feature].limit.totalMemory = value;
                    setSubscriptionUpdate(update);
                  }}
                  actualValue={updatedFeatureUsage.limit.totalMemory}
                  className={componentStyles.manualInput}
                />
              ) : (
                <span>{formatValue(featureUsage.limit.totalMemory)}</span>
              )}
            </td>
          </tr>
          <tr>
            <td>Hourly</td>
            <td>{formatValue(featureUsage.meter.hourlyMemory)}</td>
            <td>
              {editable ? (
                <TextFieldBuffered
                  validateValue={(value) => {
                    let validatedValue;
                    try {
                      validatedValue = GraphUtils.validateInputValue({
                        value,
                        validationRules: { type: 'int', allowEmpty: false, min: 0 },
                      });
                    } catch (error) {
                      throw new Error(error.message);
                    }
                    return validatedValue;
                  }}
                  applyValue={(value) => {
                    const update = { ...subscriptionUpdate };
                    update.featuresUsage[featureUsage.feature].limit.hourlyMemory = value;
                    setSubscriptionUpdate(update);
                  }}
                  actualValue={updatedFeatureUsage.limit.hourlyMemory}
                  className={componentStyles.manualInput}
                />
              ) : (
                <span>{formatValue(featureUsage.limit.hourlyMemory)}</span>
              )}
            </td>
          </tr>
          <tr>
            <td>Daily</td>
            <td>{formatValue(featureUsage.meter.dailyMemory)}</td>
            <td>
              {editable ? (
                <TextFieldBuffered
                  validateValue={(value) => {
                    let validatedValue;
                    try {
                      validatedValue = GraphUtils.validateInputValue({
                        value,
                        validationRules: { type: 'int', allowEmpty: false, min: 0 },
                      });
                    } catch (error) {
                      throw new Error(error.message);
                    }
                    return validatedValue;
                  }}
                  applyValue={(value) => {
                    const update = { ...subscriptionUpdate };
                    update.featuresUsage[featureUsage.feature].limit.dailyMemory = value;
                    setSubscriptionUpdate(update);
                  }}
                  actualValue={updatedFeatureUsage.limit.dailyMemory}
                  className={componentStyles.manualInput}
                />
              ) : (
                <span>{formatValue(featureUsage.limit.dailyMemory)}</span>
              )}
            </td>
          </tr>
          <tr>
            <td>Weekly</td>
            <td>{formatValue(featureUsage.meter.weeklyMemory)}</td>
            <td>
              {editable ? (
                <TextFieldBuffered
                  validateValue={(value) => {
                    let validatedValue;
                    try {
                      validatedValue = GraphUtils.validateInputValue({
                        value,
                        validationRules: { type: 'int', allowEmpty: false, min: 0 },
                      });
                    } catch (error) {
                      throw new Error(error.message);
                    }
                    return validatedValue;
                  }}
                  applyValue={(value) => {
                    const update = { ...subscriptionUpdate };
                    update.featuresUsage[featureUsage.feature].limit.weeklyMemory = value;
                    setSubscriptionUpdate(update);
                  }}
                  actualValue={updatedFeatureUsage.limit.weeklyMemory}
                  className={componentStyles.manualInput}
                />
              ) : (
                <span>{formatValue(featureUsage.limit.weeklyMemory)}</span>
              )}
            </td>
          </tr>
          <tr>
            <td>Monthly</td>
            <td>{formatValue(featureUsage.meter.monthlyMemory)}</td>
            <td>
              {editable ? (
                <TextFieldBuffered
                  validateValue={(value) => {
                    let validatedValue;
                    try {
                      validatedValue = GraphUtils.validateInputValue({
                        value,
                        validationRules: { type: 'int', allowEmpty: false, min: 0 },
                      });
                    } catch (error) {
                      throw new Error(error.message);
                    }
                    return validatedValue;
                  }}
                  applyValue={(value) => {
                    const update = { ...subscriptionUpdate };
                    update.featuresUsage[featureUsage.feature].limit.monthlyMemory = value;
                    setSubscriptionUpdate(update);
                  }}
                  actualValue={updatedFeatureUsage.limit.monthlyMemory}
                  className={componentStyles.manualInput}
                />
              ) : (
                <span>{formatValue(featureUsage.limit.monthlyMemory)}</span>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </CollapsiblePanel>
  );
};
