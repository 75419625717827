import React from 'react';
import {
  Callout,
  DefaultButton, mergeStyleSets, PrimaryButton, Stack, Text,
} from '@fluentui/react';
import User from '../../shared/User';
import Store from '../../shared/state/Store';
import IconButton from '../../shared/IconButton';
import AppsApi from '../../shared/util/AppsApi';
import UiReducer from '../../shared/state/UiReducer';
import StackTokens from '../../shared/StackTokens';

const styles = mergeStyleSets({
  container: {
    position: 'absolute',
    right: '12px',
    top: '20px',

    selectors: {
      ':hover p': {
        backgroundColor: 'yellow',
        boxShadow: '0 0 10px 5px yellow',
      },
    },
  },
  button: {
    color: '#FFD700',
    selectors: {
      i: {
        fontSize: '30px',
      },
      ':hover i': {
        color: '#FFD700',
      },
      ':active': {
        background: 'none !important',
      },
    },
  },
  light: {
    margin: '0px',
    padding: '0px',
    width: '10px',
    height: '12px',
    position: 'absolute',
    top: '2px',
    right: '11px',
    borderRadius: '50%',
  },
  callout: {
    width: 320,
    maxWidth: '90%',
    padding: '20px 24px',
  },
});

export default () => {
  const [globalState, dispatch] = Store.useStore();
  const [showHint, setShowHint] = React.useState(false);

  const buttonId = 'creatorKeyAvailableHint';

  const isActiveCreator = User.isActiveCreatorAccount(globalState);

  if (isActiveCreator) {
    return null;
  }

  const unusedCreatorKey = User.getAvailableKeys(globalState).find((key) => !key.disabled && key.allowsCreators && key.assignments.length < key.size);

  if (!unusedCreatorKey) {
    return null;
  }

  return (
    <>
      <div className={styles.container}>
        <p className={styles.light} />
        <IconButton
          iconName="CRMCustomerInsightsApp"
          className={styles.button}
          id={buttonId}
          onClick={() => {
            setShowHint(true);
          }}
        />
      </div>
      {showHint && (
      <Callout
        role="dialog"
        gapSpace={0}
        target={`#${buttonId}`}
        className={styles.callout}
        onDismiss={() => setShowHint(false)}
      >
        <Stack vertical tokens={StackTokens.spacing}>
          <Stack vertical>
            <Text block>You have an unused Creator key.</Text>
            <Text block>Would you like to use it to access Creator features?</Text>
          </Stack>
          <Stack horizontal horizontalAlign="end" tokens={StackTokens.spacing}>
            <DefaultButton
              text="Dismiss"
              onClick={() => {
                setShowHint(false);
              }}
            />
            <PrimaryButton
              text="Yes"
              onClick={async () => {
                const updatedAssignments = [...unusedCreatorKey.assignments, User.getCurrentUser()];
                const updatedKey = { key: unusedCreatorKey.key, assignments: updatedAssignments };
                const responseJson = await AppsApi.assignKeys(updatedKey);
                setShowHint(false);
                if (responseJson?.code >= 300 || responseJson?.error?.code >= 300) {
                  dispatch(UiReducer.uiSetErrors(responseJson.messages));
                } else {
                  dispatch(UiReducer.setGenericUiAttribute(UiReducer.Keys.AccountInfo, responseJson));
                  dispatch(UiReducer.uiSetErrors([]));
                }
              }}
            />
          </Stack>
        </Stack>
      </Callout>
      )}
    </>

  );
};
