/**
 * Reducer, use in dispatch
 */
const setFormData = (formData) => ({
  reduce: ((prevState) => {
    const updatedState = { ...prevState };
    updatedState.form = updatedState.form || {};
    updatedState.form.data = formData;
    return updatedState;
  }),
});

const getFormData = (globalState) => globalState.form?.data || {};

/**
 * Reducer, use in dispatch
 */
const setFormItem = (key, value) => ({
  reduce: ((prevState) => {
    const updatedState = { ...prevState };
    updatedState.form = updatedState.form || {};
    updatedState.form.data = updatedState.form.data || {};
    updatedState.form.data[key] = value;
    return updatedState;
  }),
});

const getFormItemValue = (globalState, key) => {
  if (!globalState.form || !globalState.form?.data) {
    return undefined;
  }
  return globalState.form.data[key];
};

const setSoftEvidenceItem = (key, distribution) => ({
  reduce: ((prevState) => {
    const updatedState = { ...prevState };
    updatedState.form = updatedState.form || {};
    updatedState.form.softEvidence = updatedState.form.softEvidence || {};
    updatedState.form.softEvidence[key] = distribution;
    return updatedState;
  }),
});

const getSoftEvidenceItem = (globalState, key) => {
  if (!globalState.form || !globalState.form?.softEvidence) {
    return undefined;
  }
  return globalState.form.softEvidence[key];
};

const getSoftEvidence = (globalState) => globalState?.form?.softEvidence || {};

const setSoftEvidence = (softEvidence) => ({
  reduce: ((prevState) => {
    const updatedState = { ...prevState };
    updatedState.form = updatedState.form || {};
    updatedState.form.softEvidence = softEvidence || {};
    return updatedState;
  }),
});

const setNodeVariableOverrideItem = (networkId, nodeId, variableName, value) => ({
  reduce: ((prevState) => {
    // const updatedState = { ...prevState };
    const updatedState = prevState;
    updatedState.form = updatedState.form || {};
    updatedState.form.variableOverrides = updatedState.form.variableOverrides || {};
    updatedState.form.variableOverrides[networkId] = updatedState.form.variableOverrides[networkId] || {};
    updatedState.form.variableOverrides[networkId][nodeId] = { ...updatedState.form.variableOverrides[networkId][nodeId] };
    if (!value && value !== 0) {
      delete updatedState.form.variableOverrides[networkId][nodeId][variableName];
    } else {
      updatedState.form.variableOverrides[networkId][nodeId][variableName] = value;
    }
    return updatedState;
  }),
});

const getNodeVariableOverridesForNode = (globalState, networkId, nodeId) => globalState.form?.variableOverrides?.[networkId]?.[nodeId] || {};

const getNodeVariableOverrideItem = (globalState, networkId, nodeId, variableName) => {
  if (!globalState.form || !globalState.form?.variableOverrides) {
    return undefined;
  }
  return globalState.form.variableOverrides[networkId]?.[nodeId]?.[variableName];
};

const getNodeVariableOverrides = (globalState) => globalState?.form?.variableOverrides || {};

const setNodeVariableOverrides = (variableOverrides) => ({
  reduce: ((prevState) => {
    const updatedState = { ...prevState };
    updatedState.form = updatedState.form || {};
    updatedState.form.variableOverrides = variableOverrides || {};
    return updatedState;
  }),
});

const isFormGroupCollapsed = (globalState, groupName) => {
  if (!globalState.form?.groupsCollapsed) {
    return undefined;
  }
  return globalState.form.groupsCollapsed[groupName];
};

/**
 * Reducer, use in dispatch
 */
const setFormGroupCollapsed = (groupName, collapsed) => ({
  reduce: ((prevState) => {
    const updatedState = { ...prevState };
    updatedState.form = updatedState.form || {};
    updatedState.form.groupsCollapsed = updatedState.form.data || {};
    updatedState.form.groupsCollapsed[groupName] = collapsed;
    return updatedState;
  }),
});

export default {
  setFormData,
  getFormData,
  setFormItem,
  getFormItemValue,
  isFormGroupCollapsed,
  setFormGroupCollapsed,
  setSoftEvidenceItem,
  getSoftEvidenceItem,
  getSoftEvidence,
  setSoftEvidence,
  setNodeVariableOverrideItem,
  getNodeVariableOverrideItem,
  setNodeVariableOverrides,
  getNodeVariableOverrides,
  getNodeVariableOverridesForNode,
};
