import React from 'react';
import PlaceholderError from '../../shared/PlaceholderError';
import AccountsHelper from '../AccountsHelper';
import Theme from '../../shared/Theme';
import KeyRow from './KeyRow';

export default ({ relatedAccounts }) => {
  const availableKeys = AccountsHelper.getAvailableKeys(relatedAccounts);

  if (availableKeys.length === 0) {
    return (<PlaceholderError message="No agena.ai licenses registered or assigned to this account." />);
  }

  return (
    <div>
      <table className={[Theme.styles.table, Theme.styles.tableNoPadding, Theme.styles.tableText, Theme.styles.tableRowHighlight].join(' ')}>
        <thead>
          <tr>
            <th>License Key</th>
            <th>&nbsp;</th>
            <th>Expires</th>
            <th colSpan={2}>Assigned</th>
            <th>Product</th>
            <th>Condition</th>
            <th>Type</th>
            <th>&nbsp;</th>
            <th>Owner</th>
          </tr>
        </thead>
        <tbody>
          {
            availableKeys.map((keyData) => <KeyRow keyData={keyData} key={keyData.key} />)
          }
        </tbody>
      </table>
    </div>
  );
};
