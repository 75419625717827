import createClone from 'rfdc';
import smoothscroll from 'smoothscroll-polyfill';
import fromEntries from 'fromentries';

smoothscroll.polyfill();

const createNetworkNodeKey = (networkId, nodeId, separator = '.') => `${networkId}${separator}${nodeId}`;

const capitalize = (text) => {
  const re = /(\b[a-z](?!\s))/g;
  return text.replace(re, (x) => x.toUpperCase());
};

const scrollToRef = (ref) => {
  if (ref?.current?.offsetTop) {
    window.scrollTo({ top: ref.current.offsetTop, left: 0, behavior: 'smooth' });
  }
};

const mapNetworksNodes = (model) => {
  const map = {};
  if (model.networks) {
    model.networks.forEach((network) => {
      if (network.nodes) {
        network.nodes.forEach((node) => {
          const key = createNetworkNodeKey(network.id, node.id);
          map[key] = node;
        });
      }
    });
  }
  return map;
};

const mapOutputConfig = (outputConfig) => {
  const map = {};
  outputConfig.forEach((group, groupIndex) => {
    group.items.forEach((item, itemIndex) => {
      const key = createNetworkNodeKey(item.network, item.node);
      map[key] = {
        groupIndex,
        itemIndex,
        item,
        groupName: group.groupName,
        customPercentiles: item.customPercentiles || [],
      };
    });
  });
  return map;
};

const mapInputConfig = (inputConfig) => {
  const map = {};
  inputConfig.forEach((group, groupIndex) => {
    group.items.forEach((item, itemIndex) => {
      const key = createNetworkNodeKey(item.network, item.node);
      map[key] = {
        groupIndex,
        itemIndex,
        item,
      };
    });
  });
  return map;
};

const formatResultData = (outputConfigMap, resultData) => fromEntries(
  resultData.results
    .filter((resultItem) => outputConfigMap[createNetworkNodeKey(resultItem.network, resultItem.node)])
    .map((resultItem) => [createNetworkNodeKey(resultItem.network, resultItem.node), resultItem]),
);

const readFileContents = (file) => {
  const fileReader = new FileReader();

  return new Promise((resolve, reject) => {
    fileReader.onerror = () => {
      fileReader.abort();
      reject(new DOMException('Problem parsing input file.'));
    };

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.readAsText(file);
  });
};

const importAll = (context) => {
  const files = {};
  context.keys().forEach((item) => {
    files[item.replace('./', '')] = context(item);
  });
  return files;
};

const saveToFile = (filename, content) => {
  const a = document.createElement('a');
  const file = new Blob([content], { type: 'file' });
  a.href = URL.createObjectURL(file);
  a.download = filename.replace(/[/\\?%*:|"<>]/g, '-');
  a.click();
  URL.revokeObjectURL(a.href);
  a.remove();
};

const triggerDownload = (filename, url) => {
  const evt = new MouseEvent('click', {
    view: window,
    bubbles: false,
    cancelable: true,
  });

  const a = document.createElement('a');
  a.setAttribute('download', filename);
  a.setAttribute('href', url);
  a.setAttribute('target', '_blank');

  a.dispatchEvent(evt);
  a.remove();
};

// https://stackoverflow.com/a/33928558/1246369
// eslint-disable-next-line consistent-return
const copyToClipboard = (text) => {
  if (window.clipboardData && window.clipboardData.setData) {
    // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
    return window.clipboardData.setData('Text', text);
  }
  if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
    const textarea = document.createElement('textarea');
    textarea.textContent = text;
    textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page in Microsoft Edge.
    document.body.appendChild(textarea);
    textarea.select();
    try {
      return document.execCommand('copy'); // Security exception may be thrown by some browsers.
    } catch (ex) {
      console.warn('Copy to clipboard failed.', ex);
      return false;
    } finally {
      document.body.removeChild(textarea);
    }
  }
};

// https://stackoverflow.com/a/38821410/1246369
const copyContentsToClipboard = (el, excludedElements = []) => {
  if (!el) {
    return;
  }
  const { body } = document;

  const exDisplayMap = {};
  excludedElements.forEach((exEl) => {
    exDisplayMap[exEl] = exEl.style.display;
    // eslint-disable-next-line no-param-reassign
    exEl.style.display = 'none';
  });

  let range;
  let sel;
  if (document.createRange && window.getSelection) {
    range = document.createRange();
    sel = window.getSelection();
    sel.removeAllRanges();
    try {
      range.selectNodeContents(el);
      sel.addRange(range);
    } catch (e) {
      range.selectNode(el);
      sel.addRange(range);
    }
  } else if (body.createTextRange) {
    range = body.createTextRange();
    range.moveToElementText(el);
    range.select();
  }
  document.execCommand('Copy');
  if (window.getSelection) {
    window.getSelection().removeAllRanges();
  } else if (document.selection) {
    document.selection.empty();
  }

  excludedElements.forEach((exEl) => {
    // eslint-disable-next-line no-param-reassign
    exEl.style.display = exDisplayMap[exEl];
  });
};

const svgToBlob = (svgElement) => {
  const svgURL = new XMLSerializer().serializeToString(svgElement);
  const svgBlob = new Blob([svgURL], { type: 'image/svg+xml' }); // image/svg+xml;charset=utf-8
  return svgBlob;
};

const saveSvg = (filename = 'image.svg', svgElement) => {
  const svgURL = new XMLSerializer().serializeToString(svgElement);
  saveToFile(filename, svgURL);
};

const svgToPngOperation = (svgElement, callback) => {
  const svgBlob = svgToBlob(svgElement);

  const DOMURL = window.URL || window.webkitURL || window;
  const canvas = document.createElement('canvas');
  const pixelRatio = window.devicePixelRatio || 1;

  const widthOriginal = svgElement.getAttribute('width');
  const heightOriginal = svgElement.getAttribute('height');
  canvas.style.width = `${widthOriginal}px`;
  canvas.style.height = `${heightOriginal}px`;
  canvas.width = widthOriginal * pixelRatio;
  canvas.height = heightOriginal * pixelRatio;

  canvas.imageSmoothingEnabled = false;
  const ctx = canvas.getContext('2d');
  ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);

  ctx.fillStyle = 'white';
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  const img = new Image(canvas.width, canvas.height);
  const url = DOMURL.createObjectURL(svgBlob);

  img.onload = () => {
    ctx.drawImage(img, 0, 0, widthOriginal, heightOriginal);
    DOMURL.revokeObjectURL(url);

    document.body.prepend(canvas);

    callback(canvas);
    canvas.remove();
  };

  img.src = url;
};

const svgToDataUri = (svgElement) => {
  if (!svgElement) {
    return '';
  }
  const tempId = 'agena-temp-background';
  const background = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
  background.setAttribute('width', '100%');
  background.setAttribute('height', '100%');
  background.setAttribute('fill', 'white');
  background.setAttribute('id', tempId);
  svgElement.prepend(background);

  const origWidth = svgElement.getAttribute('width');
  const origHeight = svgElement.getAttribute('height');
  svgElement.setAttribute('width', '100%');
  svgElement.setAttribute('height', '100%');

  let data = new XMLSerializer().serializeToString(svgElement);
  data = data.replaceAll(/viewBox="0 0 [0-9]+ [0-9]+" /g, '');

  const dataBase64 = btoa(data);
  const dataUri = `data:image/svg+xml;base64,${dataBase64}`;

  svgElement.setAttribute('width', origWidth);
  svgElement.setAttribute('height', origHeight);
  svgElement.querySelector(`rect#${tempId}`).remove();
  return dataUri;
};

const saveSvgAsPng = (filename = 'image.png', svgElement) => {
  svgToPngOperation(svgElement, async (canvas) => {
    const imgUri = canvas
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream');
    triggerDownload(filename, imgUri);
  });
};

const copyImageToClipboard = async (svgElement) => {
  // console.log(svgToDataUri(svgElement));
  try {
    svgToPngOperation(svgElement, async (canvas) => {
      canvas.toBlob(async (blob) => {
        await navigator.clipboard.write([
          new window.ClipboardItem({
            [blob.type]: blob,
          }),
        ]);
      }, 'image/png', 1);
    });
  } catch (error) {
    console.log(error);
  }
};

const toCsv = (array2d, separator = ',') => array2d.map((row) => `"${row.map((value) => `${value}`.replace('"', '""')).join(`"${separator}"`)}"`).join('\n');

const clone = createClone();

const roundFormat = (value, decimalPlaces, defaultDecimalPlaces) => {
  const dps = (decimalPlaces !== undefined) ? decimalPlaces : defaultDecimalPlaces;

  let val = value;
  if (typeof val === 'string') {
    val = Number.parseFloat(value);
  }

  if (val < 0) {
    return val.toPrecision(dps + 1);
  }

  const valString = `${val}`;
  const isScientificNotation = valString.match(/[eE]/);

  if (isScientificNotation) {
    return val.toPrecision(dps + 1);
  }

  return parseFloat(val.toFixed(dps));
};

const getSvg = (containerRef) => {
  if (!containerRef?.current) {
    console.log('Empty SVG container ref');
    return null;
  }

  const svgSel = containerRef.current.querySelectorAll('svg');
  if (svgSel.length === 0) {
    console.log('No SVG elements in ref');
    return null;
  }
  const svg = svgSel[0];

  const fontFamily = window.getComputedStyle(svg.parentElement, null).getPropertyValue('font-family');
  svg.style['font-family'] = fontFamily;

  if (!svg.getAttribute('viewbox')) {
    // We can probably add a viewbox
  }

  return svg;
};

const emailPreformat = (email) => email.replace(/[ <>.^$%*+?()[\]{}\\|'"-]+$/u, '').replace(/^[ <>.^$%*+?()[\]{}\\|'"-]+/u, '');

const roundRelativeLinear = (numbers) => {
  let fixed = 0;

  for (let i = 0; i < numbers.length - 1; i += 1) {
    const localFixed = Math.ceil(-Math.log10(Math.abs(numbers[i] - numbers[i + 1])));
    if (!Number.isFinite(localFixed)) {
      continue;
    }
    fixed = Math.max(fixed, localFixed);
  }

  return numbers.map((val) => Number.parseFloat(val.toFixed(fixed)));
};

const formatLabelsRelative = ({ labels, mergeRanges = false, returnAsOptions = false }) => {
  const formattedLabels = [];

  for (let i = 0; i < labels.length; i += 1) {
    const elLeft = labels[i - 1];
    const el = labels[i];
    const elRight = labels[i + 1];

    const numsLeft = elLeft ? elLeft.split(' - ').map((s) => Number.parseFloat(s)) : undefined;
    const nums = el.split(' - ').map((s) => Number.parseFloat(s));
    const numsRight = elRight ? elRight.split(' - ').map((s) => Number.parseFloat(s)) : undefined;

    const isInterval = nums.length === 2;

    // Format nums[0] relative to numsLeft[0], numsLeft[1], nums[1] and numsRight[0]
    // Format nums[1] relative to numsLeft[1], nums[0], numsRight[0] and numsRight[1]
    // If either side does not exist, use nums instead to keep the number being forrmatted in the same index 2

    let formattedLabel;

    const toRoundLeft = [];
    if (numsLeft) {
      toRoundLeft.push(...numsLeft);
    } else {
      toRoundLeft.push(...nums);
    }
    toRoundLeft.push(...nums);
    if (numsRight) {
      toRoundLeft.push(numsRight[0]);
    } else if (isInterval) {
      toRoundLeft.push(nums[1]);
    }

    const roundedLeft = roundRelativeLinear(toRoundLeft);

    if (isInterval) {
      const toRoundRight = [];
      if (numsLeft) {
        toRoundRight.push(numsLeft[1]);
      } else {
        toRoundRight.push(nums[0]);
      }
      toRoundRight.push(...nums);
      if (numsRight) {
        toRoundRight.push(...numsRight);
      } else {
        toRoundRight.push(...nums);
      }

      const roundedRight = roundRelativeLinear(toRoundRight);

      if (mergeRanges && roundedLeft[2] === roundedRight[2]) {
        formattedLabel = `${roundedLeft[2]}`;
      } else {
        formattedLabel = `${roundedLeft[2]} - ${roundedRight[2]}`;
      }
    } else {
      formattedLabel = `${roundedLeft[2]}`;
    }

    if (returnAsOptions) {
      formattedLabel = {
        key: isInterval ? ((nums[0] + nums[1]) / 2) : nums[0],
        text: formattedLabel,
      };
    }

    formattedLabels.push(formattedLabel);
  }
  return formattedLabels;
};

const math = (expression) => Number.parseFloat((expression).toFixed(12));

const resolveObjectPath = (object, path, defaultValue) => path
  .split('.')
  .reduce((o, p) => (o ? o[p] : defaultValue), object);

const setObjectPath = (object, path, value) => path
  .split('.')
  // eslint-disable-next-line no-return-assign, no-plusplus, no-param-reassign
  .reduce((o, p, i) => o[p] = path.split('.').length === ++i ? value : o[p] || {}, object);

const createFriendlyReference = (id, name) => {
  let reference = id;
  if (name) {
    reference = `${name} (${id})`;
  }
  return reference;
};

export default {
  capitalize,
  scrollToRef,
  mapNetworksNodes,
  mapOutputConfig,
  mapInputConfig,
  formatResultData,
  readFileContents,
  importAll,
  saveToFile,
  triggerDownload,
  copyToClipboard,
  copyContentsToClipboard,
  svgToPngOperation,
  svgToBlob,
  svgToDataUri,
  saveSvg,
  saveSvgAsPng,
  copyImageToClipboard,
  toCsv,
  clone,
  roundFormat,
  getSvg,
  emailPreformat,
  roundRelativeLinear,
  formatLabelsRelative,
  createNetworkNodeKey,
  math,
  resolveObjectPath,
  setObjectPath,
  createFriendlyReference,
};
