import {
  Checkbox, Label, Separator, Stack, TextField,
} from '@fluentui/react';
import React from 'react';
import tokens from '../../../../shared/StackTokens';
import ConfigReducer from '../../../../shared/state/ConfigReducer';
import Store from '../../../../shared/state/Store';

export default () => {
  const [globalState, dispatch] = Store.useStore();
  const generalConfig = ConfigReducer.getGeneralConfig(globalState);
  return (

    <Stack grow vertical tokens={tokens.verticalSpacing}>
      <TextField
        label="Input section header"
        value={generalConfig.inputSectionHeader}
        onChange={(event, value) => {
          dispatch(ConfigReducer.setGeneralConfig({ ...generalConfig, inputSectionHeader: value }));
        }}
      />

      <TextField
        label="Input section description"
        value={generalConfig.inputSectionDescription}
        multiline
        rows={3}
        onChange={(event, value) => {
          dispatch(ConfigReducer.setGeneralConfig({ ...generalConfig, inputSectionDescription: value }));
        }}
      />

      <Separator />

      <Label>Calculation Button Settings</Label>

      <Checkbox
        label="Prevent Calculation button stretching"
        checked={!!generalConfig.inputSectionCalculatePreventStretch}
        onChange={(ev, checked) => {
          dispatch(ConfigReducer.setGeneralConfig({ ...generalConfig, inputSectionCalculatePreventStretch: checked }));
        }}
      />

      <TextField
        label="Calculation button description text"
        value={generalConfig.inputSectionCalculateDescription}
        multiline
        rows={3}
        onChange={(event, value) => {
          dispatch(ConfigReducer.setGeneralConfig({ ...generalConfig, inputSectionCalculateDescription: value }));
        }}
      />

      <TextField
        label="Calculate button text"
        value={generalConfig.inputSectionCalculateButton}
        onChange={(event, value) => {
          dispatch(ConfigReducer.setGeneralConfig({ ...generalConfig, inputSectionCalculateButton: value }));
        }}
      />
    </Stack>
  );
};
