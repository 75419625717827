import { DefaultButton, mergeStyleSets } from '@fluentui/react';
import React from 'react';
import Theme from '../Theme';

const componentStyles = mergeStyleSets({
  button: {
    selectors: {
      i: {
        fontWeight: 'bold',
        fontSize: '15px',
      },
    },
  },
});

export default ({ record }) => (
  <>
    <DefaultButton
      text=""
      title="Actions"
      menuIconProps={{ iconName: 'More' }}
      className={[Theme.styles.iconButtonClearMinimalistic, componentStyles.button].join(' ')}
      menuProps={{
        items: [
          {
            key: 'viewCase',
            text: 'View case details',
            iconProps: { iconName: 'OpenInNewTab' },
            onClick: () => {
              window.open(`/archive?uuid=${record.uuid}`, '_blank');
            },
          },
        ],
      }}
    />
  </>
);
