import {
  DefaultButton, mergeStyleSets, Stack,
} from '@fluentui/react';
import React from 'react';
import StackTokens from '../StackTokens';
import TextFieldBuffered from '../TextFieldBuffered';
import Theme from '../Theme';

const componentStylesRaw = {
  textFieldRoot: {
    wrapper: {
      marginTop: 'none !important',
    },
  },
  checkbox: {
    label: {
      margin: '0',
    },
  },
};

const componentStyles = mergeStyleSets({

  callbackHeaders: {
    marginTop: '0 !important',
    selectors: {
      label: {
        padding: 0,
      },
    },
  },

  button: {
    width: '34px',
    minWidth: 'auto',
    height: '32px',
    padding: '0',
    paddingTop: '4px',
    paddingBottom: '4px',
    paddingLeft: '8px',
    // height: '30px',
    // paddingLeft: '0px',
    // selectors: {
    //   i: {
    //     marginLeft: '0px',
    //   },
    // },
  },


});

export default ({
  headerName, headerValue, apply, remove,
}) => (
  <Stack horizontal tokens={StackTokens.spacing} verticalAlign="end">
    <TextFieldBuffered
      label=""
      actualValue={headerName}
      validateValue={(value) => {
        const validatedValue = value.trim();
        return validatedValue;
      }}
      applyValue={async (value) => {
        apply(value, headerValue);
      }}
      styles={componentStylesRaw.textFieldRoot}
    />

    <TextFieldBuffered
      label=""
      actualValue={headerValue}
      validateValue={(value) => {
        const validatedValue = value.trim();
        return validatedValue;
      }}
      applyValue={async (value) => {
        apply(headerName, value);
      }}
      styles={componentStylesRaw.textFieldRoot}
    />

    <DefaultButton
      text=""
      iconProps={{
        iconName: 'Delete',
      }}
      className={[Theme.styles.outlineControlButton, componentStyles.button].join(' ')}
      onClick={async () => {
        remove(headerName);
      }}
      title="Delete header"
    />
  </Stack>
);
