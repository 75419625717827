import React from 'react';
import { ContextualMenuItemType, DefaultButton } from '@fluentui/react';
import Store from '../../shared/state/Store';
import Styles from '../Styles';
import ModellerReducer from '../ModellerReducer';
import ModelUtils from '../ModelUtils';
import Utils from '../../shared/Utils';
import CaseManager from '../../shared/state/CaseManager';
import UiReducer from '../../shared/state/UiReducer';
import ModellerDefaults from '../ModellerDefaults';
import InputConfirmationDialog from '../../shared/InputConfirmationDialog';
import DataUtils from '../../shared/DataUtils';

export default ({ mode }) => {
  const [globalState, dispatch] = Store.useStore();
  const [button, setButton] = React.useState();
  const [casesItems, setCasesItems] = React.useState([]);
  const [appViewCopyItems, setAppViewCopyItems] = React.useState([]);

  React.useEffect(() => {
    const fItems = [
      {
        key: 'add-case',
        text: 'Add new case',
        onClick: () => {
          dispatch(UiReducer.setGenericUiAttribute(ModellerDefaults.ComponentKeys.ModellerAddCaseDialog, {
            title: 'Add new case',
            primaryButtonText: 'Create',
            primaryCallback: (value) => {
              const updatedModel = { ...ModellerReducer.getModel(globalState) };
              updatedModel.dataSets = updatedModel.dataSets || [];
              updatedModel.dataSets.push({
                id: value,
                observations: [],
                displayable: true,
                active: true,
                logPe: {},
                results: [],
              });
              dispatch(ModellerReducer.updateModel(updatedModel));
            },
          }));
        },
      },
      { key: 'divider_1', itemType: ContextualMenuItemType.Divider },
    ];
    (ModellerReducer.getModel(globalState)?.dataSets || []).forEach((dataset) => {
      fItems.push({
        key: dataset.id,
        text: dataset.id,
        subMenuProps: {
          items: [
            {
              key: `${dataset.id}-clear-data`,
              text: 'Clear entered data',
              onClick: () => {
                const updatedModel = { ...ModellerReducer.getModel(globalState) };
                updatedModel.dataSets = (updatedModel.dataSets || []).map((ds) => {
                  if (ds.id === dataset.id) {
                    return {
                      ...ds,
                      observations: [],
                    };
                  }
                  return ds;
                });
                dispatch(ModellerReducer.updateModel(updatedModel));
                dispatch(ModellerReducer.mapObservationsFromCase({ id: dataset.id, observations: [] }));
              },
            },
            {
              key: `${dataset.id}-rename`,
              text: 'Rename',
              onClick: () => {
                dispatch(UiReducer.setGenericUiAttribute(ModellerDefaults.ComponentKeys.ModellerAddCaseDialog, {
                  title: 'Rename case',
                  primaryButtonText: 'Rename',
                  initialValue: dataset.id,
                  primaryCallback: (value) => {
                    const updatedModel = { ...ModellerReducer.getModel(globalState) };
                    updatedModel.dataSets = (updatedModel.dataSets || []).map((ds) => {
                      if (ds.id === dataset.id) {
                        return {
                          ...ds,
                          id: value,
                        };
                      }
                      return ds;
                    });
                    dispatch(ModellerReducer.updateModel(updatedModel));
                    dispatch(ModellerReducer.renameCacheFor(dataset.id, value));
                  },
                }));
              },
            },
            {
              key: `${dataset.id}-delete`,
              text: 'Delete',
              disabled: ModellerReducer.getModel(globalState)?.dataSets?.length < 2,
              onClick: () => {
                const updatedModel = { ...ModellerReducer.getModel(globalState) };
                updatedModel.dataSets = (updatedModel.dataSets || []).map((ds) => {
                  if (ds.id === dataset.id) {
                    return undefined;
                  }
                  return ds;
                }).filter((el) => el);
                dispatch(ModellerReducer.updateModel(updatedModel));
                updatedModel.dataSets = [...updatedModel.dataSets]; // Trigger case element mappings
                dispatch(ModellerReducer.clearCacheFor(dataset.id));
              },
            },
          ],
        },
      });
    });
    setCasesItems(fItems);
  }, [
    (ModellerReducer.getModel(globalState)?.dataSets || []).map((ds) => ds.id).join(' '),
  ]);

  React.useEffect(() => {
    const fItems = (ModellerReducer.getModel(globalState)?.dataSets || []).map((dataset) => ({
      key: dataset.id,
      text: dataset.id,
      onClick: () => {
        const caseData = {
          outputData: ModellerReducer.getCachedCalculationResponse(globalState, dataset.id) || {},
          inputData: {},
        };
        const model = ModellerReducer.getModel(globalState);
        const dsData = ModelUtils.getDataset({ model, datasetId: dataset.id });
        if (!dsData || !dsData.observations || !dsData.observations.forEach) {
          return;
        }
        dsData.observations.forEach((observation) => {
          if (observation.constantName) {
            caseData.nodeVariableOverrides = caseData.nodeVariableOverrides || {};
            caseData.nodeVariableOverrides[observation.network] = caseData.nodeVariableOverrides?.[observation.network] || {};
            caseData.nodeVariableOverrides[observation.network][observation.node] = caseData.nodeVariableOverrides[observation.network]?.[observation.node] || {};
            caseData.nodeVariableOverrides[observation.network][observation.node][observation.constantName] = observation.entries[0]?.value;
            return;
          }

          const isSoftEvidence = observation.entries.length > 1;
          if (isSoftEvidence) {
            // Soft evidence
            caseData.softEvidence = caseData.softEvidence || {};
            const oKey = Utils.createNetworkNodeKey(observation.network, observation.node);
            const oMap = observation.entries.reduce((obj, el) => ({ ...obj, [el.value]: el.weight }), {});
            caseData.softEvidence[oKey] = oMap;
          }

          caseData.inputData[Utils.createNetworkNodeKey(observation.network, observation.node)] = {
            network: observation.network,
            node: observation.node,
            selected: isSoftEvidence ? ModellerDefaults.SoftEvidenceInputOption.value : observation.entries?.[0]?.value,
          };
        });

        if (Object.keys(caseData.inputData).length === 0
         && Object.keys(caseData.outputData).length === 0
         && Object.keys(caseData.nodeVariableOverrides).length === 0) {
          dispatch(UiReducer.setGenericUiAttribute(UiReducer.Keys.GeneralSimpleMessageModal, {
            title: 'Case not loaded',
            message: `Case "${dataset.id}" has no data and was not loaded`,
          }));
          return;
        }

        CaseManager.loadCase(dispatch, caseData);
        dispatch(UiReducer.setGenericUiAttribute(UiReducer.Keys.GeneralSimpleMessageModal, {
          title: 'Case loaded',
          message: `Case "${dataset.id}" loaded in App View`,
        }));
      },
    }));
    setAppViewCopyItems(fItems);
  }, [
    (ModellerReducer.getModel(globalState)?.dataSets || []).map((ds) => ds.id).join(' '),
  ]);

  return (
    <>
      <DefaultButton
        text="Data"
        menuProps={{
          className: Styles.menuItemList,
          calloutProps: {
            minPagePadding: 0,
          },
          items: [
            {
              key: 'cases',
              text: 'Cases',
              subMenuProps: {
                items: casesItems,
              },
            },
            globalState.config?.generalConfig?.appViewEnabled && mode === ModellerDefaults.OperationMode.ModellerWebApp ? ({
              key: 'load-case-in-app-view',
              text: 'Load Case in App View',
              subMenuProps: {
                items: appViewCopyItems,
              },
            }) : undefined,
            {
              key: 'export',
              text: 'Export',
              onClick: () => {
                const model = ModellerReducer.getModel(globalState);
                if (!model || !model.dataSets) {
                  dispatch(UiReducer.uiSetErrors(['No case data']));
                  return;
                }

                const dataToExport = [];

                (model?.dataSets || []).forEach((dataset, i) => {
                  const rows = DataUtils.compileSummaryStatisticsForExport(dataset.id, dataset.results || [], i === 0);
                  dataToExport.push(...rows);
                });

                if (dataToExport.length <= 1) {
                  dispatch(UiReducer.uiSetErrors(['No case result data for simulation numeric nodes']));
                  return;
                }

                Utils.saveToFile('results.csv', Utils.toCsv(dataToExport));
              },
            },
          ].filter((el) => el),
        }}
        onMenuClick={(event) => {
          setButton(event.target.closest('button'));
        }}
        onAfterMenuDismiss={() => {
          button.blur();
        }}
      />
      {
        UiReducer.getGenericUiAttribute(globalState, ModellerDefaults.ComponentKeys.ModellerAddCaseDialog) && (
          <InputConfirmationDialog
            uiElementId={ModellerDefaults.ComponentKeys.ModellerAddCaseDialog}
            title={UiReducer.getGenericUiAttribute(globalState, ModellerDefaults.ComponentKeys.ModellerAddCaseDialog)?.title}
            initialValue={UiReducer.getGenericUiAttribute(globalState, ModellerDefaults.ComponentKeys.ModellerAddCaseDialog)?.initialValue}
            text="Enter new case's name"
            primaryButtonText={UiReducer.getGenericUiAttribute(globalState, ModellerDefaults.ComponentKeys.ModellerAddCaseDialog)?.primaryButtonText}
            hideOnAction
            validateValue={(value) => {
              const valueTrimmed = `${value}`.trim();
              if (valueTrimmed === '') {
                throw new Error("Can't be empty value");
              }
              const model = ModellerReducer.getModel(globalState);
              const existingDs = model.dataSets.find((ds) => ds.id.toLowerCase() === valueTrimmed.toLowerCase());
              if (existingDs) {
                throw new Error(`Case "${existingDs.id}" already exists`);
              }
              return valueTrimmed;
            }}
            primaryCallback={UiReducer.getGenericUiAttribute(globalState, ModellerDefaults.ComponentKeys.ModellerAddCaseDialog)?.primaryCallback}
          />
        )
      }
    </>
  );
};
