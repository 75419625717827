import React from 'react';
import {
  Stack, DefaultButton, mergeStyleSets, Shimmer, ShimmerElementType,
} from '@fluentui/react';
import { useNavigate } from 'react-router-dom';
import keycloak from '../../../shared/Keycloak';
import StackTokens from '../../../shared/StackTokens';
import Utils from '../../../shared/Utils';
import Store from '../../../shared/state/Store';
import UiReducer from '../../../shared/state/UiReducer';
import AppReducer from '../../../shared/state/AppReducer';
import AppsApi from '../../../shared/util/AppsApi';
import FileOpenCloudItem from './FileOpenCloudItem';
import User from '../../../shared/User';
import Routing from '../../../shared/Routing';
import Config from '../../../shared/Config';

export default () => {
  const [globalState, dispatch] = Store.useStore();
  const navigate = useNavigate();
  const routingTree = Routing.getTree(globalState).home.routes.webAppDesigner;

  const browseButtonRef = React.useRef(null);
  const [showShimmer, setShowShimmer] = React.useState(true);

  React.useEffect(() => {
    (async () => {
      if (!keycloak.authenticated) {
        return;
      }
      const response = await AppsApi.getApps({ flags: ['QueryOrMode'], 'app.owner': User.getCurrentUser(), 'app.contributors[in]': User.getCurrentUser() });
      setShowShimmer(false);
      if (response.code >= 300) {
        // dispatch(UiReducer.uiSetErrors(response.messages));
      } else if (response) {
        dispatch(UiReducer.setGenericUiAttribute(UiReducer.ListOptions.fileOpenAppsList, response));
      }
    })();
  }, []);

  const styles = mergeStyleSets({
    button: {
      minWidth: '135px',
    },
    bold: {
      fontWeight: 'bold',
    },
  });

  const clickSelectFile = () => {
    // console.log(modelFileRef);
    if (browseButtonRef.current) {
      browseButtonRef.current.click();
    }
  };

  const resetFileBrowseButtonSelection = () => {
    if (browseButtonRef.current) {
      browseButtonRef.current.value = '';
    }
  };

  const loadJsonFromFile = async (file) => {
    const { size } = file;

    if (size > 104857600) {
      dispatch(UiReducer.uiSetErrors(['File size too big, max 100 Mb allowed']));
      return false;
    }

    const content = await Utils.readFileContents(file);
    let json;
    try {
      json = JSON.parse(content);
    } catch (error) {
      dispatch(UiReducer.uiSetErrors(['File does not contain valid JSON', error.message]));
      return false;
    }

    return json;
  };

  const getFile = (event) => {
    if (event.target.files?.length <= 0) {
      dispatch(UiReducer.uiSetErrors(['No file selected']));
      return false;
    }
    return event.target.files[0];
  };

  const validateConfig = (json) => {
    const localErrors = [];
    if (!json.model) {
      localErrors.push('Model missing');
    }

    if (!json.generalConfig) {
      localErrors.push('General config missing');
    }

    if (!json.inputConfig) {
      localErrors.push('Input config missing');
    }

    if (!json.outputConfig) {
      localErrors.push('Output config missing');
    }

    if (localErrors.length > 0) {
      dispatch(UiReducer.uiSetErrors(localErrors));
      return false;
    }
    return true;
  };

  const loadConfig = async (event) => {
    const file = getFile(event);
    const json = await loadJsonFromFile(file);
    if (json) {
      if (validateConfig(json)) {
        dispatch(AppReducer.loadApp(json));
        navigate(routingTree.routes.design.routes.applicationProperties.path);
      }
    }
    resetFileBrowseButtonSelection();
  };

  const cloudApps = UiReducer.getGenericUiAttribute(globalState, UiReducer.ListOptions.fileOpenAppsList) || [];

  const shimmer = (
    <>
      <Shimmer width="200px" />
      <Shimmer
        width="300px"
        shimmerElements={[
          { type: ShimmerElementType.line, width: '100px' },
          { type: ShimmerElementType.gap, width: '10px' },
          { type: ShimmerElementType.line, width: '190px' },
        ]}
      />
      <Shimmer
        width="300px"
        shimmerElements={[
          { type: ShimmerElementType.line, width: '100px' },
          { type: ShimmerElementType.gap, width: '10px' },
          { type: ShimmerElementType.line, width: '190px' },
        ]}
      />
      <Shimmer width="100px" />
      <Shimmer width="300px" />
      <Shimmer width="300px" />
      <Shimmer
        width="270px"
        shimmerElements={[
          { type: ShimmerElementType.line, width: '130px', height: 30 },
          { type: ShimmerElementType.gap, width: '10px', height: 30 },
          { type: ShimmerElementType.line, width: '130px', height: 30 },
        ]}
      />
    </>
  );

  let privateContent = <></>;
  if (keycloak.authenticated && Config.getApplicationMode() !== Config.ApplicationModes.WebAppDesigner) {
    privateContent = (
      <>
        <span className={styles.bold}>Load web app from your online account</span>

        {showShimmer && shimmer }

        {cloudApps.length > 0 && cloudApps.map((json) => (
          <FileOpenCloudItem
            key={json.uuid}
            appJson={json}
          />
        ))}

        {cloudApps.length === 0 && <span>No apps yet. Try configuring your app and then saving it online!</span>}
      </>
    );
  }

  return (
    <Stack style={{ marginTop: '10px' }}>
      <Stack vertical tokens={StackTokens.verticalSpacing}>
        <span className={styles.bold}>Load existing web app from JSON file</span>
        <span>
          <DefaultButton
            text="Load from File"
          // onClick={() => onSubmit(formData)}
            onClick={clickSelectFile}
            className={styles.button}
            iconProps={{
              iconName: 'OpenFile',
            }}
          />
          <input ref={browseButtonRef} type="file" accept="application/json" onChange={loadConfig} style={{ display: 'none' }} />
        </span>
        {privateContent}
      </Stack>
    </Stack>
  );
};
