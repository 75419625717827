export default {
  spacingBetweenGroups: {
    childrenGap: 20,
  },

  horizontalSpacing: {
    childrenGap: '0 10',
  },

  verticalSpacing: {
    childrenGap: '10 0',
  },

  spacing: {
    childrenGap: '10 10',
  },

  spacingWide: {
    childrenGap: '20 20',
  },

  spacing5: {
    childrenGap: '5 5',
  },
};
