import { mergeStyleSets, Stack } from '@fluentui/react';
import React from 'react';
import SectionEntry from './SectionEntry';

const localStyles = mergeStyleSets({
  header: {
    fontSize: '15px !important',
  },
});

export default ({ sectionItem, version, iconUnread }) => (
  <Stack vertical>
    <h2 className={localStyles.header}>{sectionItem.name}</h2>
    {sectionItem.entries.map((sectionEntry) => (
      <SectionEntry
        key={sectionEntry.version + sectionEntry.summary}
        sectionEntry={sectionEntry}
        version={version}
        section={sectionItem.name}
        iconUnread={iconUnread}
      />
    ))}
  </Stack>
);
