import {
  mergeStyleSets,
  TextField,
} from '@fluentui/react';
import React from 'react';

const styles = mergeStyleSets({
  description: {
    fontSize: 'smaller',
    color: 'gray',
    display: 'block',
  },
});

export default (props) => {
  const {
    style,
    placeholder,
    label,
    setValue,
    value: actualValue = '',
    suffix,
    description,
  } = props;

  const [tempValue, setTempValue] = React.useState(actualValue);
  const [editMode, setEditMode] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  const textFieldRef = React.useRef();

  React.useEffect(() => {
    if (textFieldRef.current && editMode) {
      textFieldRef.current.focus();
    }
  }, [editMode]);

  const displayLabel = (
    <span>
      <span>{label}</span>
      <span className={styles.description}>{description}</span>
    </span>
  );

  if (editMode) {
    return (
      <TextField
        label={displayLabel}
        ref={textFieldRef}
        placeholder={placeholder}
        value={tempValue}
        onChange={(event, value) => {
          setErrorMessage('');
          setTempValue(value);
        }}
        onKeyDown={(event) => {
          if (event.keyCode === 13) {
            event.target.blur();
          } else if (event.keyCode === 27) {
            setEditMode(false);
            setErrorMessage('');
            setTempValue(actualValue);
          }
        }}
        onBlur={() => {
          const actionResult = setValue(tempValue || '');
          if (actionResult === undefined || actionResult === true || actionResult.success) {
            // Success is:
            // - nothing returned at all (does not care about errors)
            // - true returned
            // - object with 'success':'true' returned
            setEditMode(false);
            // If object also specifies accepted (modified) value, we use it
            if (typeof actionResult?.value === 'string') {
              setTempValue(actionResult.value);
            }
          } else if (actionResult.error) {
            setErrorMessage(actionResult.error);
          }
        }}
        errorMessage={errorMessage}
        style={style?.edit}
        suffix={suffix}
      />
    );
  }
  return (
    <TextField
      label={displayLabel}
      placeholder={placeholder}
      value={actualValue}
      onClick={() => {
        setEditMode(true);
      }}
      onFocus={() => {
        setEditMode(true);
      }}
      style={style?.view}
      suffix={suffix}
      readOnly
    />
  );
};
