import React from 'react';
import Store from '../../../shared/state/Store';
import FileNew from './FileNew';
import FileOpen from './FileOpen';
import FileSave from './FileSave';
import FileAdvanced from './FileAdvanced';
import MenuPage from '../MenuPage';
import Routing from '../../../shared/Routing';

export default ({ disabledPivotProps }) => {
  const [globalState] = Store.useStore();
  const routingTree = Routing.getTree(globalState).home.routes.webAppDesigner.routes.file;

  return (
    <MenuPage
      requireAppLoaded={false}
      root={routingTree}
      defaultPath={routingTree.routes.new.path}
      elements={[
        <>
          <FileNew />
          {/* <ModelPreview model={modelJson} /> */}
        </>,
        <FileOpen />,
        <FileSave />,
        <FileAdvanced />,
      ]}
      pivotButtonProps={[
        undefined,
        undefined,
        disabledPivotProps,
        disabledPivotProps,
      ]}
    />
  );
};
