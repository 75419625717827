import { Dropdown, DropdownMenuItemType } from '@fluentui/react';
import React from 'react';
import ModellerReducer from '../ModellerReducer';
import Store from '../../shared/state/Store';
import Utils from '../../shared/Utils';
import ModellerDefaults from '../ModellerDefaults';
import GraphUtils from '../GraphUtils';
import TextFieldBuffered from '../../shared/TextFieldBuffered';
import CytoReducer from '../ModelPreview/cytoscape/CytoReducer';
import SoftEvidenceEntrySection from './chart/SoftEvidenceEntrySection';

const componentStylesRaw = {
  label: {
    subComponentStyles: {
      label: {
        root: {
          whiteSpace: 'nowrap',
          overflow: 'visible',
          maxWidth: '100px',
        },
      },
    },
  },
};

export default ({
  nodeConfig, networkId, datasetId, hideLabel, tabIndexValue,
}) => {
  const [globalState, dispatch] = Store.useStore();
  const [softEvidenceEntry, setSoftEvidenceEntry] = React.useState(false);
  const graphicsConfig = nodeConfig.graphics || ModellerDefaults.DefaultGraphics;

  let entrySection = <></>;

  const observation = ModellerReducer.getMappedObservation(globalState, datasetId, networkId, nodeConfig.id);
  const softEvidenceEntered = observation?.entries?.length > 1;
  const currentValue = (softEvidenceEntry || softEvidenceEntered) ? ModellerDefaults.SoftEvidenceInputOption.value : observation?.entries?.[0].value || '';
  const observationDistribution = {};
  if (observation) {
    observation.entries.forEach((entry) => {
      observationDistribution[entry.value] = entry.weight;
    });
  }

  // const currentValue = (ModellerReducer.getMappedObservation(globalState, datasetId, networkId, nodeConfig.id, undefined) || {})?.entries?.[0]?.value || '';
  // const currentValue = (ModellerReducer.getObservation(globalState, datasetId, networkId, nodeConfig.id) || {})?.entries?.[0]?.value;
  const inputMode = GraphUtils.getInputMode(nodeConfig);

  if (inputMode === ModellerDefaults.InputModes.NUMERIC) {
    entrySection = (
      <div key={nodeConfig.id}>
        <TextFieldBuffered
          label={hideLabel ? <>&nbsp;</> : (nodeConfig.name || nodeConfig.id)}
          placeholder={graphicsConfig.input?.placeholder}
          validateValue={(value) => {
            let validatedValue;
            try {
              validatedValue = GraphUtils.validateInputValue({
                value,
                validationRules: graphicsConfig.input?.validationRules || { allowEmpty: true, type: (nodeConfig.configuration.type === 'IntegerInterval') ? 'int' : 'float' },
              });
            } catch (error) {
              throw new Error(error.message);
            }
            return validatedValue;
          }}
          applyValue={(value) => {
            dispatch(ModellerReducer.setObservation(datasetId, networkId, nodeConfig.id, value));
            dispatch(CytoReducer.setRepaintRequested());
          }}
          actualValue={currentValue}
          styles={componentStylesRaw.label}
          tabIndex={tabIndexValue}
        />
      </div>
    );
  } else if (inputMode === ModellerDefaults.InputModes.DROPDOWN) {
    const options = (nodeConfig.configuration?.states || []).map((key) => ({ key, text: Utils.capitalize(key) }));
    entrySection = (
      <Dropdown
        label={hideLabel ? <>&nbsp;</> : (nodeConfig.name || nodeConfig.id)}
        placeholder={ModellerDefaults.InputDefaultOption}
        selectedKey={currentValue}
        options={[
          { key: '', text: ModellerDefaults.InputDefaultOption },
          { key: 'divider_1', text: '-', itemType: DropdownMenuItemType.Divider },
          ...options,
          { key: 'divider_2', text: '-', itemType: DropdownMenuItemType.Divider },
          { key: ModellerDefaults.SoftEvidenceInputOption.value, text: ModellerDefaults.SoftEvidenceInputOption.label },
        ]}
        onChange={(event, item) => {
          if (item.key === ModellerDefaults.SoftEvidenceInputOption.value) {
            setSoftEvidenceEntry(true);
          } else {
            setSoftEvidenceEntry(false);
            dispatch(ModellerReducer.setObservation(datasetId, networkId, nodeConfig.id, item.key));
          }
          dispatch(CytoReducer.setRepaintRequested());
        }}
        styles={componentStylesRaw.label}
        tabIndex={tabIndexValue}
      />
    );
  }

  let softEvidenceEntrySection = <></>;
  if (softEvidenceEntry || softEvidenceEntered) {
    softEvidenceEntrySection = (
      <SoftEvidenceEntrySection
        nodeConfig={nodeConfig}
        networkId={networkId}
        datasetId={datasetId}
        tabIndexValue={tabIndexValue}
      />
    );
  }

  const variableEntries = (nodeConfig.configuration.variables || []).map((nodeVar, varIndex) => (
    <div key={nodeConfig.id + nodeVar.name}>
      <TextFieldBuffered
        label={hideLabel ? <>&nbsp;</> : `${nodeConfig.name || nodeConfig.id}: ${nodeVar.name} (variable)`}
        placeholder={`Default: ${nodeVar.value}`}
        validateValue={(value) => {
          let validatedValue;
          try {
            validatedValue = GraphUtils.validateInputValue({ value, validationRules: { allowEmpty: true, type: 'float' } });
          } catch (error) {
            throw new Error(error.message);
          }
          return validatedValue;
        }}
        applyValue={(value) => {
          dispatch(ModellerReducer.setObservation(datasetId, networkId, nodeConfig.id, value, nodeVar.name));
          dispatch(CytoReducer.setRepaintRequested());
        }}
        actualValue={(ModellerReducer.getMappedObservation(globalState, datasetId, networkId, nodeConfig.id, nodeVar.name) || {})?.entries?.[0]?.value || ''}
        styles={componentStylesRaw.label}
        tabIndex={tabIndexValue + varIndex + 1}
      />
    </div>
  ));

  return (
    <>
      { entrySection }
      { softEvidenceEntrySection }
      { variableEntries }
    </>
  );
};
