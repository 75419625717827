import React from 'react';
import { Stack } from '@fluentui/react';
import {
  Routes,
  Route,
} from 'react-router-dom';
import WebAppDesigner from '../designer/WebAppDesigner';
import PortalFrame from './PortalFrame';
import Store from '../shared/state/Store';
import CloudAppManager from '../appmanager/CloudAppManager';
import Home from './Home';
import Breadcrumbs from './Breadcrumbs';
import ApiServices from '../apiservices/ApiServices';
import Debug from '../admin/debug/Debug';
import Administration from '../admin/Administration';
import Account from '../account/Account';
import User from '../shared/User';
import WebAppWrapper from '../webapp/WebAppWrapper';
import Routing from '../shared/Routing';
import CaseArchived from '../webapp/CaseArchived';
import Modeller from '../modeller/Modeller';

export default () => {
  const [globalState, dispatch] = Store.useStore();
  const routingTree = Routing.getTree(globalState);

  React.useEffect(() => {
    (async () => {
      await User.fetchAccounts({ dispatch });

      // const response = await AppsApi.getAccountInfo({ flags: ['SyncEnabled', 'RequestSync'] });
      // if (response.code >= 300) {
      //   response.messages.unshift('Account data unavailable');
      //   console.log(response);
      //   dispatch(UiReducer.uiSetErrors([response.messages]));
      // } else if (response) {
      //   dispatch(UiReducer.setGenericUiAttribute(UiReducer.Keys.AccountInfo, response));
      // }

      // setTimeout(() => {
      //   path.home.subItems.cloudAppManager.subItems.appView.subItems.publishing.onClick();
      //   setTimeout(() => {
      //     dispatch(UiReducer.setGenericUiAttribute(UiReducer.Keys.CloudAppManagerAppSelected, 'fc4215c2-b6a3-41b2-969c-0f6e96683d7a'));
      //   }, 10);
      // }, 1);

      // setTimeout(() => {
      //   path.home.subItems.account.subItems.accountLicenseKeys.onClick();
      // }, 1);
    })();
  }, []);

  return (
    <PortalFrame>
      <Stack vertical>
        <Breadcrumbs />
        <Routes>
          <Route path={routingTree.home.path} element={<Home />} />
          <Route path={`${routingTree.home.routes.administration.path}/*`} element={<Administration />} />
          <Route path={`${routingTree.home.routes.account.path}/*`} element={<Account />} />
          <Route path={`${routingTree.home.routes.cloudAppManager.path}/*`} element={<CloudAppManager />} />
          <Route path={`${routingTree.home.routes.webAppDesigner.path}/*`} element={<WebAppWrapper><WebAppDesigner /></WebAppWrapper>} />
          <Route path={`${routingTree.home.routes.modeller.path}/*`} element={<Modeller />} />
          <Route path={routingTree.home.routes.apiServices.path} element={<ApiServices />} />
          <Route path={routingTree.home.routes.debug.path} element={<Debug />} />
          <Route path={routingTree.home.routes.archive.path} element={<WebAppWrapper><CaseArchived /></WebAppWrapper>} />
        </Routes>
      </Stack>
    </PortalFrame>
  );
};
