import UiReducer from '../../shared/state/UiReducer';

const Keys = {
  AdminAccountsSelectedItem: 'AdminAccountsSelectedItem',
  AdminAccountsRelatedInfo: 'AdminAccountsRelatedInfo',
};

const getSelectedAccount = (globalState) => UiReducer.getGenericUiAttribute(globalState, Keys.AdminAccountsSelectedItem);

const setSelectedAccount = (dispatch, accountJson) => dispatch(UiReducer.setGenericUiAttribute(Keys.AdminAccountsSelectedItem, accountJson));

const getRelatedAccounts = (globalState) => UiReducer.getGenericUiAttribute(globalState, Keys.AdminAccountsRelatedInfo);

const setRelatedAccounts = (dispatch, relatedAccounts) => dispatch(UiReducer.setGenericUiAttribute(Keys.AdminAccountsRelatedInfo, relatedAccounts));

export default {
  getSelectedAccount,
  setSelectedAccount,
  getRelatedAccounts,
  setRelatedAccounts,
};
