import { DefaultButton, mergeStyleSets, Stack } from '@fluentui/react';
import React from 'react';
import ChartDefaults from '../shared/ChartDefaults';
import LegendItem from '../shared/LegendItem';
import StackTokens from '../shared/StackTokens';
import ModellerReducer from './ModellerReducer';
import Store from '../shared/state/Store';
import Theme from '../shared/Theme';
import GraphUtils from './GraphUtils';
import ModellerDefaults from './ModellerDefaults';
import Styles from './Styles';

const componentStyles = mergeStyleSets({
  root: {
    width: '100%',
    minWidth: `${ModellerDefaults.ComponentDefaults.LeftWidthMin}px`,
  },
  container: {
    height: ModellerDefaults.ComponentDefaults.ComponentHeight,
    // background: 'cyan',
    overflowY: 'auto',
    // paddingRight: '5px',
    marginTop: '7px !important',
    width: '100%',
  },

  listItem: {
    border: 'none',
    outline: 'none !important',
    textAlign: 'left',
    width: '100%',
  },
  active: {
    backgroundColor: Theme.Colors.LightGray,
  },
  listItemWithLegend: {
    paddingLeft: '0px',
    marginLeft: '-5px !important',
  },

  rootMinimised: {
    width: '25px',
    height: ModellerDefaults.ComponentDefaults.ComponentHeight,
    // marginTop: '34px',
  },
  headerMinimised: {
    writingMode: 'vertical-rl',
    textOrientation: 'mixed',
    paddingTop: '5px',
  },
});

export default ({ minimised, setMinimised }) => {
  const [globalState, dispatch] = Store.useStore();
  const model = ModellerReducer.getModel(globalState);
  const colorByNetwork = ModellerReducer.getSetting(globalState, ModellerReducer.SettingKeys.colorByNetwork);

  if (minimised) {
    return (
      <Stack vertical className={componentStyles.rootMinimised}>
        <div>
          <DefaultButton
            text=""
            iconProps={{
              iconName: 'ChevronRight',
            }}
            className={[Theme.styles.outlineControlButton, Styles.sideMinimizeButton].join(' ')}
            onClick={async () => {
              setMinimised(false);
            }}
            title="Expand"
          />
        </div>
        <header className={[Styles.headerSection, componentStyles.headerMinimised].join(' ')}>Explorer</header>
      </Stack>
    );
  }

  return (
    <Stack vertical className={componentStyles.root}>

      <Stack horizontal tokens={StackTokens.spacing5}>
        <DefaultButton
          text=""
          iconProps={{
            iconName: 'ChevronLeft',
          }}
          className={[Theme.styles.outlineControlButton, Styles.sideMinimizeButton].join(' ')}
          onClick={async () => {
            setMinimised(true);
          }}
          title="Minimise"
        />
        <header className={Styles.headerSection}>Explorer</header>
      </Stack>

      <Stack vertical tokens={StackTokens.spacing} className={componentStyles.container}>
        {(model.networks?.length > 1) && (
        <DefaultButton
          text="Model"
          onClick={() => {
            dispatch(ModellerReducer.setSelectedGraphKey(ModellerDefaults.FlatModelGraphKey));
          }}
          className={[(ModellerReducer.getSelectedGraphKey(globalState) === ModellerDefaults.FlatModelGraphKey) ? componentStyles.active : '', componentStyles.listItem].join(' ')}
        />
        )}

        {(model?.networks || []).map((network, index) => (
          <Stack
            horizontal
            key={network.id}
          >
            {colorByNetwork && (
            <LegendItem
              key={network.id}
              color={ChartDefaults.Colors[index] || 'gray'}
              styles={{
                root: {
                  marginTop: '2px',
                },
              }}
            />
            )}
            <DefaultButton
              text={network.name || network.id}
              onClick={() => {
                dispatch(ModellerReducer.setSelectedGraphKey(GraphUtils.escapeSelector(network.id)));
              }}
              className={
                [
                  (ModellerReducer.getSelectedGraphKey(globalState) === GraphUtils.escapeSelector(network.id)) ? componentStyles.active : '',
                  componentStyles.listItem,
                  colorByNetwork ? componentStyles.listItemWithLegend : '',
                ].join(' ')
            }
            />
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};
