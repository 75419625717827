import React from 'react';
import {
  Stack, PrimaryButton,
} from '@fluentui/react';

import InputGroup from './InputGroup';
import calculate from '../../shared/util/apis';
import MultiLineUserText from '../../shared/MultiLineUserText';
import Store from '../../shared/state/Store';
import UiReducer from '../../shared/state/UiReducer';
import DataReducer from '../../shared/state/DataReducer';
import AppReducer from '../../shared/state/AppReducer';
import ConfigReducer from '../../shared/state/ConfigReducer';
import FormReducer from '../../shared/state/FormReducer';
import Utils from '../../shared/Utils';
import CaseManagerPanel from './CaseManagerPanel';
import ModalGeneric from '../../shared/ModalGeneric';
import ModellerDefaults from '../../modeller/ModellerDefaults';

export default ({ reportRef }) => {
  const [globalState, dispatch] = Store.useStore();

  const { model, inputConfig, generalConfig } = ConfigReducer.getConfig(globalState);
  const appData = AppReducer.getAppData(globalState);
  const appId = appData?.uuid;
  const [requestedPercentiles, setRequestedPercentiles] = React.useState([]); // set of percentiles to calculate
  const [dsPercentilesCalculated, setDsPercentilesCalculated] = React.useState({});
  const [responseMessage, setResponseMessage] = React.useState();

  const createObservation = ({
    node, network, selected, constantName,
  }) => ({
    node,
    entries: [
      {
        weight: 1,
        value: selected,
      },
    ],
    network,
    constantName,
  });

  const createSoftObservation = ({ node, network, distribution }) => {
    const observation = {
      node,
      network,
    };
    observation.entries = Object.keys(distribution).map((state) => ({
      value: state,
      weight: distribution[state],
    }));
    return observation;
  };

  const collateRequestedPercentiles = () => {
    // Build a set of all requested custom percentiles
    const fRequestedPercentiles = new Set();
    Object.values(ConfigReducer.getOutputConfigMap(globalState)).forEach((nodeConfig) => {
      if (!nodeConfig.customPercentiles) {
        return;
      }
      nodeConfig.customPercentiles.forEach((percentile) => {
        fRequestedPercentiles.add(percentile);
      });
    });

    // Keep it sorted numerically
    const aRequestedPercentiles = Array.from(fRequestedPercentiles);
    aRequestedPercentiles.sort((a, b) => a - b);

    // Determine if any items mismatch
    let changed = false;
    if (aRequestedPercentiles.length !== requestedPercentiles.length) {
      changed = true;
    } else {
      for (let index = 0; index < aRequestedPercentiles.length; index += 1) {
        if (aRequestedPercentiles[index] !== requestedPercentiles[index]) {
          changed = true;
          break;
        }
      }
    }

    if (changed) {
      // Requested percentiles indeed were changed, record the new set of percentiles
      setRequestedPercentiles(aRequestedPercentiles);
    }
  };

  const calculatePercentiles = async (dsKey, dsData) => {
    // Request to calculate percentiles for a dataset

    if (dsPercentilesCalculated[dsKey]) {
      // Percentiles already calculated for this dataset
      return;
    }

    if (requestedPercentiles.length === 0) {
      // No custom percentiles requested
      return;
    }

    // Record percentiles as calcualted for this dataset
    // We won't keep tetrying if this fails
    setDsPercentilesCalculated({ ...dsPercentilesCalculated, [dsKey]: true });

    const headers = {
      'x-referer': window.location.href,
    };
    const userId = UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.userId);
    if (userId) {
      headers['User-Id'] = userId;
    }

    const calculatedPercentiles = await calculate({
      requestJson: {
        percentiles: requestedPercentiles,
        results: Object.values(dsData),
        'sync-wait': true,
      },
      path: '/public/v1/percentile',
      headers,
    });

    const updatedDsData = { ...dsData };

    if (calculatedPercentiles.results) {
      calculatedPercentiles.results.forEach((nodeResult) => {
        const key = Utils.createNetworkNodeKey(nodeResult.network, nodeResult.node);
        if (updatedDsData[key]) {
          // Update result cache to include calculated percentiles in form of a map rather than array
          const percentiles = nodeResult.percentiles.reduce((aMap, pData) => {
            // eslint-disable-next-line no-param-reassign
            aMap[pData.percentile] = pData.value;
            return aMap;
          }, {});
          updatedDsData[key].percentiles = percentiles;
        }
      });
      dispatch(DataReducer.updateCachedResult(dsKey, updatedDsData));
    }
  };

  const calculatePercentilesForAllDatasets = () => {
    const dataSets = {};
    dataSets[DataReducer.DefaultCaseNames.BASELINE] = DataReducer.getCachedResultsForDataSet(globalState, DataReducer.DefaultCaseNames.BASELINE);
    dataSets[DataReducer.DefaultCaseNames.USER] = DataReducer.getCachedResultsForDataSet(globalState, DataReducer.DefaultCaseNames.USER);

    Object.entries(dataSets).forEach(([dsKey, calculatedDataSet]) => {
      if (!calculatedDataSet) {
        return;
      }
      calculatePercentiles(dsKey, calculatedDataSet);
    });
  };

  const onSubmit = async () => {
    dispatch(UiReducer.uiSetLoading(true));

    // Get the table overrides map and only send those that are actually configured; if none are configured, do not send any
    const tableOverridesMap = UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.webAppInputsOverriddenNptMap) || {};
    const updatedTableMap = [];
    Object.keys(tableOverridesMap).forEach((tKey) => {
      if (tableOverridesMap[tKey]) {
        updatedTableMap.push({
          key: tKey,
          content: tableOverridesMap[tKey],
        });
      }
    });

    const columnOverridesMap = UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.webAppInputsOverriddenNptColumns) || {};
    const updatedColumnsMap = [];
    Object.keys(columnOverridesMap).forEach((tKey) => {
      if (columnOverridesMap[tKey]) {
        updatedColumnsMap.push({
          key: tKey,
          content: columnOverridesMap[tKey],
        });
      }
    });

    // Send form data too
    const inputData = FormReducer.getFormData(globalState);
    const formDataMap = [];
    Object.keys(inputData).forEach((key) => {
      if (inputData[key]) {
        formDataMap.push({
          key,
          content: inputData[key],
        });
      }
    });

    const observations = Object.values(FormReducer.getFormData(globalState)).map((observationData) => {
      let observation;
      if (observationData.selected === ModellerDefaults.SoftEvidenceInputOption.value) {
        observation = createSoftObservation({
          network: observationData.network,
          node: observationData.node,
          distribution: FormReducer.getSoftEvidenceItem(globalState, Utils.createNetworkNodeKey(observationData.network, observationData.node)),
        });
      } else {
        observation = createObservation(observationData);
      }
      return observation;
    });
    const nodeVariableOverrides = FormReducer.getNodeVariableOverrides(globalState);
    Object.keys(nodeVariableOverrides).forEach((networkId) => {
      Object.keys(nodeVariableOverrides[networkId] || {}).forEach((nodeId) => {
        Object.keys(nodeVariableOverrides[networkId][nodeId]).forEach((varName) => {
          observations.push(createObservation({
            network: networkId,
            node: nodeId,
            selected: nodeVariableOverrides[networkId][nodeId][varName],
            constantName: varName,
          }));
        });
      });
    });

    const sendData = {
      ...((!appId || UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.webAppDesignerModelModified)) && { model }),
      ...((updatedTableMap.length > 0 || updatedColumnsMap.length > 0) && {
        tableOverrides: {
          ...(updatedTableMap.length > 0 && { updatedTableMap }),
          ...(updatedColumnsMap.length > 0 && { updatedColumnsMap }),
        },
      }),
      ...(formDataMap.length > 0 && { formDataMap }),
      dataSet: {
        observations,
      },
      'sync-wait': 'true',
      appId,
      ...(generalConfig?.serverModelPathEnabled && generalConfig?.serverModelPath && { modelPath: generalConfig.serverModelPath }),
    };

    if (sendData.modelPath) {
      delete sendData.model;
    }

    if (!model) {
      // Make sure key 'model' is not even sent if there is no model
      delete sendData.model;
    }

    Utils.scrollToRef(reportRef);

    const headers = {
      'x-referer': window.location.href,
    };
    const userId = UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.userId);
    if (userId) {
      headers['User-Id'] = userId;
    }

    if (generalConfig.callback?.enabled) {
      sendData.callback = generalConfig.callback;
    }

    const calculationResults = await calculate({
      requestJson: sendData,
      path: '/public/v1/calculate',
      headers,
      skipPolling: appData?.hideOutputs?.enabled,
    });

    // eslint-disable-next-line no-console
    console.log(calculationResults);

    dispatch(UiReducer.uiSetLoading(false));

    if (appData?.hideOutputs?.enabled) {
      setResponseMessage(appData?.hideOutputs?.message || AppReducer.Defaults.hideOutputsMessage);
      return;
    }

    dispatch(UiReducer.calculationLogUpdate(calculationResults));

    if (calculationResults.status === 'success') {
      dispatch(UiReducer.uiSetErrors([]));

      const baselineCalculated = DataReducer.getCachedResultsForDataSet(globalState, DataReducer.DefaultCaseNames.BASELINE);

      // If baseline is enabled and not yet set, use the results for Baseline
      if (generalConfig.baselineEnabled && !baselineCalculated) {
        dispatch(DataReducer.cacheResults(DataReducer.DefaultCaseNames.BASELINE, calculationResults));
        setDsPercentilesCalculated({ ...dsPercentilesCalculated, [DataReducer.DefaultCaseNames.BASELINE]: false });
      } else {
        dispatch(DataReducer.cacheResults(DataReducer.DefaultCaseNames.USER, calculationResults));
        setDsPercentilesCalculated({ ...dsPercentilesCalculated, [DataReducer.DefaultCaseNames.USER]: false });
      }
    } else if (['failure', 'rejected'].includes(calculationResults.status)) {
      dispatch(UiReducer.uiSetErrors(calculationResults.messages));
    } else if (calculationResults.code === 500) {
      dispatch(UiReducer.uiSetErrors(['Internal Server Error']));
    }
  };

  React.useEffect(() => {
    // Run the initial calculation to get baseline result data
    // We don't ever want this repeated, so array below is empty

    if (!model && !appId) {
      // No model or appId have yet been loaded
      return;
    }

    const baselineCalculated = DataReducer.getCachedResultsForDataSet(globalState, DataReducer.DefaultCaseNames.BASELINE);
    if (generalConfig.baselineEnabled && ConfigReducer.isOutputConfigMapped(globalState) && !baselineCalculated) {
      onSubmit();
    }
    // eslint-disable-next-line
  }, [model, appId]);

  React.useEffect(() => {
    // This will collate all percentiles that need to be calculated into a set,
    // which (if different) would trigger percentile calculation if calculation results are available
    collateRequestedPercentiles();
  }, [
    ConfigReducer.getOutputConfigMap(globalState),
  ]);

  React.useEffect(() => {
    // Invalidate calculated percentiles for all data sets
    setDsPercentilesCalculated({});
    calculatePercentilesForAllDatasets();
  }, [
    requestedPercentiles,
  ]);

  React.useEffect(() => {
    // Calculate percentiles for all eligible datasets (those already calculated should bounce)
    calculatePercentilesForAllDatasets();
  }, [
    dsPercentilesCalculated,
  ]);

  return (
    <>
      <CaseManagerPanel />
      <h2>{generalConfig.inputSectionHeader}</h2>
      <MultiLineUserText>
        {generalConfig.inputSectionDescription}
      </MultiLineUserText>
      {
        inputConfig.map((group) => (
          <InputGroup key={group.groupName} config={group} />
        ))
      }
      <Stack vertical style={{ display: 'flex' }}>
        <MultiLineUserText>{generalConfig.inputSectionCalculateDescription}</MultiLineUserText>
        <Stack.Item>
          <PrimaryButton
            style={{ width: generalConfig.inputSectionCalculatePreventStretch ? 'auto' : '100%' }}
            type="Submit"
            text={generalConfig.inputSectionCalculateButton}
            disabled={!ConfigReducer.isOutputConfigMapped(globalState)}
            onClick={() => onSubmit()}
          />
        </Stack.Item>
      </Stack>

      <ModalGeneric
        initialIsOpen={!!responseMessage}
        title="Submission Accepted"
        message={responseMessage}
        onClose={() => {
          setResponseMessage();
        }}
        key={!!responseMessage}
      />

    </>
  );
};
