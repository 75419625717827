import React from 'react';
import { mergeStyleSets, Panel, PanelType } from '@fluentui/react';
import Store from '../../shared/state/Store';
import ConfigReducer from '../../shared/state/ConfigReducer';
import UiReducer from '../../shared/state/UiReducer';
import OutputItem from '../../designer/configuration/design/configure-outputs/OutputItem';

export const defaultPanelWidth = 420;

const style = mergeStyleSets({
  overlay: {
    background: 'none !important',
  },
  panel: {
    left: '0',
    background: 'red !important',
  },
});

const panelStyles = {
  header: { paddingLeft: '10px' },
  // contentInner: { background: 'lime' },
  content: { padding: '0px 10px' },
  // footer: { background: 'yellow' },
  main: { left: 0 },
  // scrollableContent: { background: 'blue' },
};

export default () => {
  const [globalState, dispatch] = Store.useStore();
  const itemKey = globalState.report?.showSettingsPanelFor?.key;
  const stickToLeft = globalState.report?.showSettingsPanelFor?.stickToLeft;

  const showingItem = ConfigReducer.getOutputConfigMap(globalState)[itemKey];

  if (!showingItem) {
    return <></>;
  }

  const pStyles = { ...panelStyles };

  if (stickToLeft) {
    pStyles.main.left = '0px';
  } else {
    pStyles.main = {};
  }

  return (
    <Panel
      headerText="Configure Output"
      isOpen={showingItem}
      onDismiss={() => {
        dispatch(UiReducer.reportShowSettingsPanelFor());
      }}
      closeButtonAriaLabel="Close"
      type={PanelType.custom}
      customWidth={`${defaultPanelWidth}px`}
      overlayProps={{
        allowTouchBodyScroll: true,
        onClick: () => {
          dispatch(UiReducer.reportShowSettingsPanelFor());
        },
        className: style.overlay,
      }}
    //   isOnRightSide={!stickToLeft}
      styles={pStyles}

    >
      <OutputItem
        groupItem={showingItem.item}
        groupName={showingItem.groupName}
      />
    </Panel>
  );
};
