import { Stack, Text } from '@fluentui/react';
import React from 'react';
import DataUtils from '../../shared/DataUtils';
import ModalGeneric from '../../shared/ModalGeneric';
import StackTokens from '../../shared/StackTokens';
import Store from '../../shared/state/Store';
import UiReducer from '../../shared/state/UiReducer';
import Theme from '../../shared/Theme';
import Utils from '../../shared/Utils';
import CardChartDataTable from '../../shared/CardChartDataTable';
import CardSummaryStatisticsTable from '../../shared/CardSummaryStatisticsTable';
import ModellerDefaults from '../ModellerDefaults';
import ModellerReducer from '../ModellerReducer';

export default () => {
  const [globalState, dispatch] = Store.useStore();
  const uiKey = ModellerDefaults.ComponentKeys.ModellerResultsDialogKey;
  const config = UiReducer.getGenericUiAttribute(globalState, uiKey) || {};
  const model = ModellerReducer.getModel(globalState);

  if (!model || Object.keys(config).length === 0) {
    return <></>;
  }

  const {
    mode, networkId, networkName, node,
  } = config;
  const { simulated } = node.configuration;
  const nodeType = node.configuration.type;

  const nodeGraphics = node.graphics || ModellerDefaults.DefaultGraphics;

  const hide = () => {
    dispatch(UiReducer.setGenericUiAttribute(uiKey, false));
  };

  return (
    <div style={{
      position: 'fixed',
      left: '-9999px',
      width: '0px',
      height: '0px',
    }}
    >
      <ModalGeneric
        initialIsOpen={!!config}
        title={(mode === 'summaryStatistics') ? 'Summary Statistics' : 'Marginal Probabilities'}
        styles={{
          modal: {
            main: {
              width: 'min-content',
            },
          },
        }}
        onClose={() => {
          hide();
        }}
        key={!!config}
      >
        <Stack vertical tokens={StackTokens.spacing}>
          <Text>
            Display for: node
            {' '}
            <b className={Theme.styles.bold}>{Utils.createFriendlyReference(node.id, node.name)}</b>
            {' '}
            in network
            {' '}
            <b className={Theme.styles.bold}>{Utils.createFriendlyReference(networkId, networkName)}</b>
          </Text>

          {(mode === 'summaryStatistics') && (
            <Stack horizontal tokens={{ childrenGap: '10 20' }} wrap styles={{ root: { width: '99%' } }}>
              {model.dataSets.map((dataset) => (
                <CardSummaryStatisticsTable
                  header={`${Utils.createFriendlyReference(node.id, node.name)} in ${Utils.createFriendlyReference(networkId, networkName)}`}
                  dsData={
                    DataUtils.compileSummaryStatistics(
                      dataset.id,
                      ModellerReducer.getMappedResult(globalState, dataset.id, networkId, node.id),
                      { decimalPlaces: nodeGraphics.chart?.decimalPlaces },
                    )
                }
                  key={dataset.id}
                />
              ))}
            </Stack>
          )}

          {(mode === 'marginals') && (
            <Stack vertical tokens={StackTokens.spacing5}>
                {simulated && (
                <Text>The below states were generated during dynamic discretization and can vary between calculations or calculation engine updates.</Text>
                )}
              <Stack horizontal tokens={{ childrenGap: '10 20' }} wrap styles={{ root: { width: '99%' } }}>

                {model.dataSets.map((dataset) => (
                  <CardChartDataTable
                    header={`${Utils.createFriendlyReference(node.id, node.name)} in ${Utils.createFriendlyReference(networkId, networkName)}`}
                    dsData={
                    DataUtils.compileChartDataTable(
                      dataset.id,
                      nodeType,
                      DataUtils.compileChartDataRows(
                        ModellerReducer.getMappedResult(globalState, dataset.id, networkId, node.id),
                        nodeType,
                        { decimalPlaces: nodeGraphics.chart?.decimalPlaces },
                      ),
                    )
                }
                    key={dataset.id}
                  />
                ))}
              </Stack>
            </Stack>
          )}
        </Stack>
      </ModalGeneric>
    </div>
  );
};
