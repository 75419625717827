/* eslint-disable react/no-array-index-key */
import React from 'react';
import ChartDefaults from '../../shared/ChartDefaults';
import OutputData from './OutputData';
import CardStyles from '../../shared/CardStyles';
import Utils from '../../shared/Utils';
import Theme from '../../shared/Theme';

export default ({ config, resultCache, actionRequested }) => {
  const key = Utils.createNetworkNodeKey(config.network, config.node);
  const data = OutputData.compileRawData(resultCache, key, config);
  const tableRef = React.useRef();

  React.useEffect(() => {
    if (!actionRequested?.key) {
      return;
    }

    if (actionRequested.key === 'rawDataSaveCsv') {
      Utils.saveToFile(`${config.node}.csv`, Utils.toCsv([...[data.headers], ...data.rows]));
    }

    if (actionRequested.key === 'rawDataCopy') {
      Utils.copyToClipboard(Utils.toCsv([...[data.headers], ...data.rows], '\t'));
    }
  }, [actionRequested]);

  return (
    <div
      className={[CardStyles.dataTable, 'output-table'].join(' ')}
      style={{
        height: config.height || `${ChartDefaults.CHART_HEIGHT}px`,
      }}
    >
      <table ref={tableRef} className={[Theme.styles.table, Theme.styles.tableNoPadding, CardStyles.prettyTable].join(' ')}>
        <thead>
          <tr>
            {data.headers.map((hdr) => (<th key={hdr}>{hdr}</th>))}
          </tr>
        </thead>
        <tbody>
          {data.rows.map((row) => (
            <tr key={row[0] + row[1]}>
              {row.map((val, i) => (
                <td key={i}>{val}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export const CardInfo = {
  key: 'rawData',
  text: 'Raw Data',
  actions: [
    {
      key: 'rawDataSaveCsv',
      text: 'Save as CSV',
      icon: 'Download',
    },
    {
      key: 'rawDataCopy',
      text: 'Copy to Clipboard',
      icon: 'Copy',
    },
  ],
};
