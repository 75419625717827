import {
  mergeStyleSets, Modal, getTheme, IconButton, FontWeights,
} from '@fluentui/react';
import React from 'react';
import Store from './state/Store';
import UiReducer from './state/UiReducer';

const styles = mergeStyleSets({
  'loading-modal': {
    width: 'auto',
    height: 'auto',
    'min-width': 'auto',
    'min-height': 'auto',
  },

  'loading-modal-spinner': {
    padding: '20px',
    margin: '0',
  },

});

const theme = getTheme();

const iconButtonStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  header: [
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      borderTop: `4px solid ${theme.palette.themePrimary}`,
      color: theme.palette.neutralPrimary,
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '12px 12px 14px 24px',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px',
    overflowY: 'hidden',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
});

export default () => {
  const [globalState, dispatch] = Store.useStore();

  return (
    <div style={{ position: 'fixed' }}>
      <Modal
        titleAriaId="Error"
        isOpen
        onDismiss={() => dispatch(UiReducer.uiSetErrors([]))}
        isBlocking
      >
        <div className={contentStyles.header}>
          <span id="Error">Error</span>
          <IconButton
            styles={iconButtonStyles}
            iconProps={{ iconName: 'Cancel' }}
            aria-label="Close popup modal"
            onClick={() => dispatch(UiReducer.uiSetErrors([]))}
          />
        </div>
        <div className={styles['loading-modal-spinner']}>
          {
            // eslint-disable-next-line react/no-array-index-key
            globalState.ui.errors.map((error, i) => <p key={i}>{error}</p>)
          }
        </div>
      </Modal>
    </div>
  );
};
