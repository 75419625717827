import {
  mergeStyleSets, Pivot, PivotItem, Stack,
} from '@fluentui/react';
import React from 'react';
import {
  useNavigate, useLocation, Routes, Route,
} from 'react-router-dom';
import Theme from '../../shared/Theme';
import Store from '../../shared/state/Store';
import AppReducer from '../../shared/state/AppReducer';
import PlaceholderError from '../../shared/PlaceholderError';
import UiReducer from '../../shared/state/UiReducer';

const styles = mergeStyleSets({
  pivot: {
    width: '97vw',
    maxWidth: '97vw',
  },
  pivotItem: {
    paddingLeft: '0px',
  },
});

export default ({
  root, elements, defaultPath, pivotButtonProps = [], requireAppLoaded = true,
}) => {
  const [globalState, dispatch] = Store.useStore();
  const navigate = useNavigate();
  const location = useLocation();
  const routingTree = root;

  React.useEffect(() => {
    if (location.pathname === routingTree.path) {
      // If no subcomponent selected, navigate to default subcomponent
      const previouslySelected = UiReducer.getGenericUiAttribute(globalState, `nav_forward_${routingTree.path}`);
      let navigateTo = defaultPath;
      if (previouslySelected) {
        navigateTo = previouslySelected;
      }
      if (location.pathname !== navigateTo) {
        navigate(navigateTo, { replace: true });
      }
    }
  }, [location.pathname]);

  let content;
  if (requireAppLoaded && !AppReducer.isAppLoaded(globalState)) {
    content = <PlaceholderError message="No app is currently loaded" />;
  } else {
    content = (
      <Routes>
        {
            Object.values(root.routes).map((pitem, i) => {
              if (elements.length <= i) {
                return <React.Fragment key={pitem.path} />;
              }
              return (
                <Route key={pitem.path} path={`${pitem.getRelativePath(root.path)}/*`} element={elements[i]} />
              );
            })
        }
      </Routes>
    );
  }

  return (
    <Stack vertical>
      <Pivot
        className={styles.pivot}
        selectedKey={`${routingTree.path}/${location.pathname.slice(routingTree.path.length).split('/')[1]}`}
        overflowBehavior="menu"
        overflowAriaLabel="More"
        onLinkClick={(pivotItem) => {
          dispatch(UiReducer.setGenericUiAttribute(`nav_forward_${routingTree.path}`, pivotItem.props.itemKey));
          navigate(pivotItem.props.itemKey);
        }}
        styles={Theme.styleObjects.pivotShiftLeft}
      >
        {
            Object.values(root.routes).map((pitem, i) => {
              if (elements.length <= i) {
                return <React.Fragment key={pitem.path} />;
              }
              return (
                <PivotItem
                  headerText={pitem.text}
                  className={styles.pivotItem}
                  itemKey={pitem.path}
                  key={pitem.path}
                  headerButtonProps={pivotButtonProps[i]}
                />
              );
            })
        }
      </Pivot>
      {content}
    </Stack>
  );
};
