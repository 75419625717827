import {
  mergeStyleSets, Stack, Text, TextField, TooltipHost,
} from '@fluentui/react';
import React from 'react';
import { EditIcon } from '@fluentui/react-icons';
import CheckmarkIconButton from './CheckmarkIconButton';

export default ({
  styles,
  placeholder,
  actualValue,
  validateValue,
  applyValue,
  classNames,
  tooltip = 'Click to edit',
  tooltipAsTitle = false,
  autoSelectOnFocus = false,
  doubleClickToEdit = false,
  showApplyButton = true,
  showEditButton = true,
  dynamicWidth = false,
  onFocus,
  disableLabelSelection = false,
  onKeyDown,
  dataMarker,
  focusLabelOnBlur = false,
}) => {
  const localStyles = mergeStyleSets({
    editIcon: {
      display: 'inline-block',
      marginLeft: '10px',
      position: 'relative',
      top: '-10px',
      right: '-1px',
      fontSize: '80%',
    },

    textLabelWrapper: {
      // outlineColor: '#e3e3e3',
      outline: 'none',
      selectors: {
        ':hover': {
          background: 'white',
          selectors: {
            svg: {
              color: 'rgb(0, 120, 212)',
            },
          },
        },
      },
      cursor: 'pointer',
    },

    textLabel: {
      color: 'black',
      width: '100%',
      display: 'inline-block',
      userSelect: disableLabelSelection ? 'none' : 'auto',
    },

  });


  const [editableText, setEditableText] = React.useState(actualValue);
  const [editMode, setEditMode] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  const [labelWidth, setLabelWidth] = React.useState();

  const labeldRef = React.useRef();
  const textFieldRef = React.useRef();

  React.useEffect(() => {
    if (textFieldRef.current) {
      textFieldRef.current.focus();
    }
    if (!editMode) {
      setEditableText(actualValue);
      if (focusLabelOnBlur && labeldRef.current) {
        labeldRef.current.focus();
      }
    }

    if (labeldRef.current) {
      setLabelWidth(Math.ceil(labeldRef.current.getBoundingClientRect().width));
    }
  }, [editMode]);

  React.useEffect(() => {
    setEditableText(actualValue);
  }, [actualValue]);

  const enableEditMode = () => {
    setEditMode(true);
    if (autoSelectOnFocus) {
      setTimeout(() => {
        if (textFieldRef.current) {
          textFieldRef.current.select();
        }
      }, 50);
    }
  };

  if (editMode) {
    return (
      <Stack horizontal>
        <TextField
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex, jsx-a11y/tabindex-no-positive
          tabIndex="1"
          ref={textFieldRef}
          placeholder={placeholder}
          value={editableText}
          onChange={(event, value) => {
            setErrorMessage('');
            setEditableText(value);
          }}
          onKeyDown={(event) => {
            if (event.keyCode === 13) {
              event.target.blur();
            } else if (event.keyCode === 27) {
              setEditMode(false);
              setErrorMessage('');
              setEditableText(actualValue);
            }
          }}
          onBlur={() => {
            let newValue = editableText;
            try {
              if (typeof validateValue === 'function') {
                newValue = validateValue(editableText);
              }
              applyValue(newValue);
              setEditMode(false);
            } catch (error) {
              if (error instanceof Error) {
                setErrorMessage(error.message);
              } else if (typeof error === 'string') {
                setErrorMessage(error);
              } else {
                setErrorMessage('Invalid value');
              }
            }
          }}
          errorMessage={errorMessage}
          styles={{
            root: {
              ...{
                width: (dynamicWidth && labelWidth > 0) ? labelWidth : '100%',
              },
              ...styles?.input,
            },
          }}
        />

        {
          showApplyButton && (
            <CheckmarkIconButton />
          )
        }

      </Stack>
    );
  }

  return (
    <TooltipHost
      content={tooltipAsTitle ? '' : tooltip}
    >
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */ }
      <div
        className={[localStyles.textLabelWrapper, classNames?.root].join(' ')}
        style={styles?.root}
        ref={labeldRef}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex, jsx-a11y/tabindex-no-positive
        tabIndex="1"
        data-marker={dataMarker}
        onKeyDown={(event) => {
          // console.log('cell div key down', event.key, event);
          if (typeof onKeyDown === 'function') {
            onKeyDown(event);
          }

          if (event.altKey || event.ctrlKey || event.shiftKey || event.metaKey) {
            return;
          }

          if (event.key.length === 1) {
            setEditableText('');
            setEditMode(true);
          } else if (event.keyCode === 13 || event.key === 'Enter' || event.key === 'NumpadEnter') {
            enableEditMode();
          }
        }}
        onFocus={onFocus}
      >
        <Text
          title={tooltipAsTitle ? tooltip : ''}
          className={[localStyles.textLabel, classNames?.text].join(' ')}
          style={styles?.text}
          onClick={(event) => {
            if (!doubleClickToEdit || (doubleClickToEdit && event.detail === 2)) {
              enableEditMode();
            }
          }}
        >
          {actualValue}

          {
            showEditButton && (
              <EditIcon className={[localStyles.editIcon, classNames?.icon].join(' ')} style={styles?.icon} />
            )
          }
        </Text>
      </div>
    </TooltipHost>
  );
};
