import React from 'react';
import WebApp from '../webapp/WebApp';
import AppReducer from '../shared/state/AppReducer';
import Store from '../shared/state/Store';
import PlaceholderError from '../shared/PlaceholderError';

export default () => {
  const [globalState] = Store.useStore();
  if (!AppReducer.isAppLoaded(globalState)) {
    return <PlaceholderError message="No app is currently loaded" />;
  }
  return (
    <div
      style={{
        background: '#aaa',
        width: '100%',
        paddingTop: '20px',
      }}
    >
      <WebApp />
    </div>
  );
};
