import {
  mergeStyleSets, PrimaryButton, Stack,
} from '@fluentui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import StackTokens from '../../shared/StackTokens';
import MultiLineUserText from '../../shared/MultiLineUserText';
import Store from '../../shared/state/Store';
import AppReducer from '../../shared/state/AppReducer';
import Routing from '../../shared/Routing';

const styles = mergeStyleSets({
  appSection: {
    selectors: {
      h5: {
        marginBottom: '0',
        paddingBottom: '0',
      },
    },
    padding: '5px',
    border: '1px solid rgb(237, 235, 233)',
    margin: '10px 0',
  },

  description: {
    marginTop: '0',
    paddingTop: '0',
  },

  logo: {
    maxHeight: '30vmin',
    maxWidth: '100%',
  },
});

export default ({ fullConfig }) => {
  const [globalState, dispatch] = Store.useStore();
  const navigate = useNavigate();
  const routingTree = Routing.getTree(globalState).home.routes.webAppDesigner;
  const { generalConfig } = fullConfig;

  return (
    <Stack
      vertical
      tokens={StackTokens.spacing}
      className={styles.appSection}
    >
      <h5>{generalConfig.appName}</h5>

      {generalConfig.appLogo
        && <span><img src={generalConfig.appLogo} alt={generalConfig.appName} className={styles.logo} /></span>}

      <MultiLineUserText className={styles.description}>{generalConfig.appDescription}</MultiLineUserText>
      <span>
        <PrimaryButton
          text="Load App"
          onClick={() => {
            dispatch(AppReducer.loadApp(fullConfig));
            navigate(routingTree.routes.preview.path);
          }}
        />
      </span>
    </Stack>

  );
};
