import Utils from '../Utils';
import DefaultConfig from '../../designer/configuration/DefaultConfig';
import UiReducer from './UiReducer';
import ChartDefaults from '../ChartDefaults';

/**
 * Reducer, use in dispatch
 */
const mapNetworksNodes = (modelParam) => ({
  reduce: ((prevState) => {
    // const updatedState = Utils.clone(prevState);
    // const model = modelParam || prevState.config?.model;
    // if (!model) {
    //   return prevState;
    // }
    // updatedState.networksNodesMap = Utils.mapNetworksNodes(model);
    // return updatedState;
    const updatedState = { ...prevState };
    const model = modelParam || prevState.config?.model;
    if (!model) {
      return updatedState;
    }
    updatedState.networksNodesMap = Utils.mapNetworksNodes(model);
    return updatedState;
  }),
});
const getNetworksNodesMap = (globalState) => globalState.networksNodesMap || {};

/**
 * Reducer, use in dispatch
 */
const mapOutputConfig = (outputConfigParam) => ({
  reduce: ((prevState) => {
    // const updatedState = Utils.clone(prevState);
    const updatedState = { ...prevState };
    const outputConfig = outputConfigParam || updatedState.config?.outputConfig;
    if (!outputConfig) {
      return prevState;
    }
    updatedState.outputConfigMap = Utils.mapOutputConfig(outputConfig);
    return updatedState;
  }),
});
const getOutputConfigMap = (globalState) => globalState.outputConfigMap || {};
const isOutputConfigMapped = (globalState) => (Object.keys(getOutputConfigMap(globalState)).length > 0);

/**
 * Reducer, use in dispatch
 */
const mapInputConfig = (inputConfigParam) => ({
  reduce: ((prevState) => {
    // const updatedState = Utils.clone(prevState);
    const updatedState = { ...prevState };
    const inputConfig = inputConfigParam || updatedState.config?.inputConfig;
    if (!inputConfig) {
      return prevState;
    }
    updatedState.inputConfigMap = Utils.mapInputConfig(inputConfig);
    return updatedState;
  }),
});
const getInputConfigMap = (globalState) => globalState.inputConfigMap || {};
const isInputConfigMapped = (globalState) => (Object.keys(getInputConfigMap(globalState)).length > 0);

const getDefaultConfig = () => ({
  model: Utils.clone(DefaultConfig.modelJson),
  inputConfig: Utils.clone(DefaultConfig.inputConfig),
  outputConfig: Utils.clone(DefaultConfig.outputConfig),
  generalConfig: Utils.clone(DefaultConfig.generalConfig),
});
const getConfig = (globalState) => globalState?.config || getDefaultConfig();
const getModel = (globalState) => globalState?.config?.model || Utils.clone(DefaultConfig.modelJson);
const getInputConfig = (globalState) => globalState?.config?.inputConfig || Utils.clone(DefaultConfig.inputConfig);
const getOutputConfig = (globalState) => globalState?.config?.outputConfig || Utils.clone(DefaultConfig.outputConfig);
const getGeneralConfig = (globalState) => globalState?.config?.generalConfig || Utils.clone(DefaultConfig.generalConfig);

/**
 * Reducer, use in dispatch
 */
const setModel = (model) => ({
  reduce: ((prevState) => {
    // const updatedState = Utils.clone(prevState);
    // updatedState.config = updatedState.config || getConfig();
    // updatedState.config.model = model;
    // return updatedState;
    const updatedState = { ...prevState };
    updatedState.config = updatedState.config || getDefaultConfig();
    updatedState.config.model = model;
    return updatedState;
  }),
});

/**
 * Reducer, use in dispatch
 */
const setInputConfig = (inputConfig) => ({
  reduce: ((prevState) => {
    // const updatedState = Utils.clone(prevState);
    const updatedState = { ...prevState };
    updatedState.config = updatedState.config || getConfig();
    updatedState.config.inputConfig = inputConfig;
    return updatedState;
  }),
});

/**
 * Reducer, use in dispatch
 */
const setOutputConfig = (outputConfig) => ({
  reduce: ((prevState) => {
    // const updatedState = Utils.clone(prevState);
    const updatedState = { ...prevState };
    updatedState.config = updatedState.config || getConfig();
    updatedState.config.outputConfig = outputConfig;
    return updatedState;
  }),
});

/**
 * Reducer, use in dispatch
 */
const setGeneralConfig = (generalConfig) => ({
  reduce: ((prevState) => {
    // const updatedState = Utils.clone(prevState);
    const updatedState = { ...prevState };
    updatedState.config = updatedState.config || getConfig();
    updatedState.config.generalConfig = generalConfig;
    return updatedState;
  }),
});

/**
 * Reducer, use in dispatch
 */
const setConfig = (config) => ({
  reduce: ((prevState) => {
    // const updatedState = Utils.clone(prevState);
    const updatedState = { ...prevState };
    updatedState.config = {
      model: config.model,
      inputConfig: config.inputConfig,
      outputConfig: config.outputConfig,
      generalConfig: config.generalConfig,
    };
    return updatedState;
  }),
});

/**
 * Reducer, use in dispatch
 */
const resetConfig = () => ({
  reduce: ((prevState) => {
    // const updatedState = Utils.clone(prevState);
    const updatedState = { ...prevState };
    updatedState.app = {};
    updatedState.config = getConfig();
    return updatedState;
  }),
});

/**
 * Reducer, use in dispatch
 */
const removeOutputItem = ({ groupIndex, itemIndex }) => ({
  reduce: ((prevState) => {
    // const updatedState = Utils.clone(prevState);
    const updatedState = { ...prevState };
    updatedState.config.outputConfig = [...updatedState.config.outputConfig];
    updatedState.config.outputConfig[groupIndex].items.splice(itemIndex, 1);
    return updatedState;
  }),
});

/**
 * Reducer, use in dispatch
 */
const createOutputItem = (networkId, nodeId, groupIndex = 0) => ({
  reduce: ((prevState) => {
    const key = Utils.createNetworkNodeKey(networkId, nodeId);
    // const updatedState = Utils.clone(prevState);
    const updatedState = { ...prevState };
    updatedState.config.outputConfig = [...updatedState.config.outputConfig];

    const nodeData = getNetworksNodesMap(prevState)[key];
    if (!nodeData) {
      return UiReducer.uiSetErrors([`Node ${networkId} : ${nodeId} not found`]).reduce(prevState);
    }

    let chartType;
    switch (nodeData.configuration.type) {
      case 'IntegerInterval':
        chartType = ChartDefaults.ChartTypeInteger;
        break;

      case 'ContinuousInterval':
        chartType = ChartDefaults.ChartTypeNumeric;
        break;

      default:
        chartType = ChartDefaults.ChartTypeDiscrete;
    }

    const newItem = {
      node: nodeId,
      network: networkId,
      label: nodeData.name,
      chartType,
      nodeType: nodeData.configuration.type,
      simulated: nodeData.configuration.simulated,
      ...ChartDefaults.ChartTypeDefaults[chartType],
    };

    updatedState.config.outputConfig[groupIndex].items.push(newItem);

    return updatedState;
  }),
});

/**
 * Reducer, use in dispatch
 */
const updateOutputItem = (updatedItem) => ({
  reduce: ((prevState) => {
    // const updatedState = Utils.clone(prevState);
    const updatedState = { ...prevState };
    updatedState.config.outputConfig = [...updatedState.config.outputConfig];
    const key = Utils.createNetworkNodeKey(updatedItem.network, updatedItem.node);
    const existingItem = getOutputConfigMap(prevState)[key];
    if (!existingItem) {
      updatedState.config.outputConfig[0].items.push(updatedItem);
    } else {
      updatedState.config.outputConfig[existingItem.groupIndex].items[existingItem.itemIndex] = updatedItem;
    }
    return updatedState;
  }),
});

/**
 * Reducer, use in dispatch
 */
const toggleOutputItem = (networkId, nodeId) => ({
  reduce: ((prevState) => {
    const key = Utils.createNetworkNodeKey(networkId, nodeId);
    const existingItem = getOutputConfigMap(prevState)[key];

    if (existingItem) {
      return removeOutputItem(existingItem).reduce(prevState);
    }

    return createOutputItem(networkId, nodeId).reduce(prevState);
  }),
});

/**
 * Reducer, use in dispatch
 */
const removeInputItem = ({ groupIndex, itemIndex }) => ({
  reduce: ((prevState) => {
    const updatedState = { ...prevState };
    updatedState.config.inputConfig = [...updatedState.config.inputConfig];
    updatedState.config.inputConfig[groupIndex].items.splice(itemIndex, 1);
    return updatedState;
  }),
});

/**
 * Reducer, use in dispatch
 */
const createInputItem = (networkId, nodeId, groupIndex = 0) => ({
  reduce: ((prevState) => {
    const key = Utils.createNetworkNodeKey(networkId, nodeId);
    const updatedState = { ...prevState };
    updatedState.config.inputConfig = [...updatedState.config.inputConfig];

    const nodeData = getNetworksNodesMap(prevState)[key];
    if (!nodeData) {
      return UiReducer.uiSetErrors([`Node ${networkId} : ${nodeId} not found`]).reduce(prevState);
    }

    const newItem = {
      node: nodeId,
      network: networkId,
      label: nodeData.name,
      inputMode: ChartDefaults.getAvailableInputModes(nodeData)[0].key,
      preventStretch: true,
    };

    if (!nodeData.configuration.simulated) {
      if (['ContinuousInterval', 'IntegerInterval'].includes(nodeData.configuration.type)) {
        const formattedLabels = Utils.formatLabelsRelative({
          labels: nodeData.configuration.states,
          mergeRanges: true,
          returnAsOptions: true,
        });
        newItem.options = formattedLabels;
      } else {
        newItem.options = nodeData.configuration.states.map((state) => ({ key: state, text: Utils.capitalize(state) }));
      }
    }

    if (newItem.inputMode === ChartDefaults.InputModes.NumericInput.key) {
      /* Uncomment this after FluentUI updates onChange to trigger after actual change instead of after each keystroke */
      // newItem.validation = {
      //   type: (nodeData.configuration.type === 'ContinuousInterval') ? 'float' : 'int',
      //   regex: (nodeData.configuration.type === 'ContinuousInterval') ? '[+-0-9eE\\.]+|^$' : '[-\\d]+|^$',
      // };
    }

    updatedState.config.inputConfig[groupIndex].items.push(newItem);

    return updatedState;
  }),
});

/**
 * Reducer, use in dispatch
 */
const updateInputItem = (updatedItem) => ({
  reduce: ((prevState) => {
    const updatedState = { ...prevState };
    updatedState.config.inputConfig = [...updatedState.config.inputConfig];
    const key = Utils.createNetworkNodeKey(updatedItem.network, updatedItem.node);
    const existingItem = getInputConfigMap(prevState)[key];
    if (!existingItem) {
      updatedState.config.inputConfig[0].items.push(updatedItem);
    } else {
      updatedState.config.inputConfig[existingItem.groupIndex].items[existingItem.itemIndex] = updatedItem;
    }
    return updatedState;
  }),
});

/**
 * Reducer, use in dispatch
 */
const toggleInputItem = (networkId, nodeId) => ({
  reduce: ((prevState) => {
    const key = Utils.createNetworkNodeKey(networkId, nodeId);
    const existingItem = getInputConfigMap(prevState)[key];

    if (existingItem) {
      return removeInputItem(existingItem).reduce(prevState);
    }

    return createInputItem(networkId, nodeId).reduce(prevState);
  }),
});

export default {
  mapNetworksNodes,
  getNetworksNodesMap,
  mapOutputConfig,
  getOutputConfigMap,
  isOutputConfigMapped,
  mapInputConfig,
  getInputConfigMap,
  isInputConfigMapped,
  getDefaultConfig,
  getConfig,
  getModel,
  getInputConfig,
  getOutputConfig,
  getGeneralConfig,
  setModel,
  setInputConfig,
  setOutputConfig,
  setGeneralConfig,
  setConfig,
  resetConfig,
  removeOutputItem,
  createOutputItem,
  updateOutputItem,
  toggleOutputItem,
  removeInputItem,
  createInputItem,
  updateInputItem,
  toggleInputItem,
};
