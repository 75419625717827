import { DefaultButton, mergeStyleSets } from '@fluentui/react';
import React from 'react';
import Theme from '../../shared/Theme';

const styles = mergeStyleSets({
  button: {
  },
  menu: {

  },
  menuItemSelected: {
    selectors: {
      button: {
        backgroundColor: Theme.Colors.LightGray,
      },
    },
  },
});

export default ({ buttonText, actions, requestAction }) => {
  const [button, setButton] = React.useState();
  return (
    <DefaultButton
      text={buttonText}
      menuIconProps={{ iconName: 'Download' }}
      title="Save / Copy"
      className={styles.button}
      menuProps={{
        className: styles.menu,
        items: actions.map((action) => ({
          key: action.key,
          text: action.text,
          iconProps: { iconName: action.icon },
          onClick: () => {
            requestAction(action);
          },
        })),
      }}
      onMenuClick={(event) => {
        setButton(event.target.closest('button'));
        requestAction(null);
      }}
      onAfterMenuDismiss={() => {
        button.blur();
      }}
    />
  );
};
