import React from 'react';
import {
  ActionButton, Separator, Stack, Text,
} from '@fluentui/react';
import { useNavigate } from 'react-router-dom';
import User from '../shared/User';
import Theme from '../shared/Theme';
import Store from '../shared/state/Store';
import UiReducer from '../shared/state/UiReducer';
import AppsApi from '../shared/util/AppsApi';
import PlaceholderError from '../shared/PlaceholderError';
import Routing from '../shared/Routing';
import Menu from './Menu';
import StackTokens from '../shared/StackTokens';

export default () => {
  const [globalState, dispatch] = Store.useStore();
  const [error, setError] = React.useState();
  const navigate = useNavigate();

  React.useEffect(() => {
    (async () => {
      if (!User.getCurrentUser()) {
        return;
      }
      const response = await AppsApi.getApps({ flags: ['OwnerOnly', 'ExcludeConfig'] });

      if (response.code >= 300) {
        // dispatch(UiReducer.uiSetErrors(response.messages));
        setError(true);
        // eslint-disable-next-line no-console
        console.log(response.messages);
      } else if (response) {
        dispatch(UiReducer.setGenericUiAttribute(UiReducer.ListOptions.cloudManagerAppsList, response));
      }
    })();
  }, []);

  const cloudApps = UiReducer.getGenericUiAttribute(globalState, UiReducer.ListOptions.cloudManagerAppsList) || [];

  if (error) {
    return (<PlaceholderError message="Server is unavailable at this time, please try again later." />);
  }

  return (
    <Stack vertical>
      <header className={Theme.styles.menuHeader}>Cloud App Manager</header>
      <Stack horizontal tokens={StackTokens.spacing}>
        <Stack vertical>
          <Menu disabled />
        </Stack>
        <Separator vertical />
        <Stack vertical grow>
          <div>
            <Text block>The Cloud App Manager enables you to assign and monitor users of  your App (Access Control) and publish the App online (Publishing).</Text>
            <Text block>Select the App you wish to manage by clicking on the App name in the list below.</Text>
            <table
              className={[Theme.styles.table, Theme.styles.tableNoPadding].join(' ')}
              style={{
                minWidth: '50vw',
                marginTop: '5px',
              }}
            >
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Contributors</th>
                  <th>Users</th>
                </tr>
              </thead>
              <tbody>
                {
            cloudApps.map((app) => (
              <tr key={app.uuid}>
                <td>
                  <ActionButton
                    onClick={() => {
                      navigate(`${Routing.getTree(globalState).home.routes.cloudAppManager.path}/${app.uuid}`);
                    }}
                    text={app.name}
                    className={Theme.styles.linkButton}
                  />
                </td>
                <td>{app.contributors?.length || 0}</td>
                <td>{app.users?.length || 0}</td>
              </tr>
            ))
          }
              </tbody>
            </table>
          </div>
        </Stack>
      </Stack>
    </Stack>
  );
};
