import React from 'react';
import { Stack } from '@fluentui/react';
import Theme from './Theme';
import Utils from './Utils';
import IconButton from './IconButton';
import StackTokens from './StackTokens';
import CardStyles from './CardStyles';

export default ({ dsData, header }) => {
  const ref = React.useRef();
  const [extraHeader, setExtraHeader] = React.useState();

  return (
    <Stack horizontal tokens={StackTokens.spacing}>
      <Stack>
        <table ref={ref} className={[Theme.styles.table, Theme.styles.tableNoPadding, CardStyles.prettyTable].join(' ')}>
          <thead>
            {extraHeader && (
              <tr>
                <th colSpan={2}>
                  {extraHeader}
                </th>
              </tr>
            )}
            <tr>
              <th colSpan={2}>
                {`Case: ${dsData.name}`}
              </th>
            </tr>
            <tr>
              <th>Statistic</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            {dsData?.rows.map((rowData) => (
              <tr key={rowData.statistic + rowData.value}>
                <th>{rowData.statistic}</th>
                <td>{rowData.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Stack>
      <Stack horizontal tokens={StackTokens.spacing} className="output-item-actions">
        <IconButton
          iconName="Copy"
          tooltip={`Copy table for ${dsData.name}`}
          onClick={() => {
            if (header) {
              setExtraHeader(header);
              setTimeout(() => {
                Utils.copyContentsToClipboard(ref.current);
                setTimeout(() => {
                  setExtraHeader();
                }, 10);
              }, 10);
            } else {
              Utils.copyContentsToClipboard(ref.current);
            }
          }}
        />
      </Stack>
    </Stack>
  );
};
