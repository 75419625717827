import React from 'react';
import {
  Stack, TextField, Label, Text, Checkbox,
} from '@fluentui/react';
import { isMobile } from 'react-device-detect';
import PickImageControl from '../../PickImageControl';
import Store from '../../../../shared/state/Store';
import ConfigReducer from '../../../../shared/state/ConfigReducer';
import StackTokens from '../../../../shared/StackTokens';
import HelpButton from '../../../../shared/help/HelpButton';

export default () => {
  const [globalState, dispatch] = Store.useStore();
  const generalConfig = ConfigReducer.getGeneralConfig(globalState);
  return (
    <>
      <Stack vertical style={{ marginTop: '10px' }} tokens={StackTokens.verticalSpacing}>

        <span>The choices you make here determine what will be displayed on the homepage of your app</span>

        <span>
          <TextField
            label="Application Name"
            value={generalConfig.appName}
            onChange={(event, value) => {
              dispatch(ConfigReducer.setGeneralConfig({ ...generalConfig, appName: value }));
            }}
          />
        </span>

        <Stack vertical>
          <Stack horizontal tokens={StackTokens.spacing5}>
            <Text>Select available modes</Text>
            <HelpButton styles={{ root: { marginLeft: '5px' } }} title="Available modes">
              <div style={isMobile ? {} : {
                maxWidth: '45vw',
              }}
              >
                <Text block>When model view is enabled, the whole model including structure and prior probabilities may be visible to the end-users.</Text>
                <Text block>Please take care when sharing model view with others.</Text>
                <Text block>At least one of the viewing modes must be enabled.</Text>
              </div>
            </HelpButton>
          </Stack>
          <Checkbox
            label="App view"
            checked={globalState.config?.generalConfig?.appViewEnabled}
            disabled={!globalState.config?.generalConfig?.modelViewEnabled}
            onChange={(ev, checked) => {
              dispatch(ConfigReducer.setGeneralConfig({ ...generalConfig, appViewEnabled: checked }));
            }}
          />
          <Checkbox
            label="Model view"
            checked={globalState.config?.generalConfig?.modelViewEnabled}
            disabled={!globalState.config?.generalConfig?.appViewEnabled}
            onChange={(ev, checked) => {
              dispatch(ConfigReducer.setGeneralConfig({ ...generalConfig, modelViewEnabled: checked }));
            }}
          />
        </Stack>

        <span>
          <TextField
            label="Application Description"
            placeholder="Optional plain text description to print below the application name"
            value={generalConfig.appDescription}
            multiline
            rows={3}
            onChange={(event, value) => {
              dispatch(ConfigReducer.setGeneralConfig({ ...generalConfig, appDescription: value }));
            }}
          />
        </span>

        <Label>Application Logo or Illustration</Label>
        <PickImageControl
          fileSizeLimit={1048576}
          imageUrl={generalConfig.appLogo}
          setImageUrl={(imageUrl) => {
            dispatch(ConfigReducer.setGeneralConfig({ ...generalConfig, appLogo: imageUrl }));
          }}
        />

      </Stack>

    </>
  );
};
