import React from 'react';
import {
  useNavigate, useLocation, Routes, Route,
} from 'react-router-dom';
import keycloak from './shared/Keycloak';
import Portal from './portal/Portal';
import WebApp from './webapp/WebApp';
import Store from './shared/state/Store';
import ModalLoading from './shared/ModalLoading';
import ModalError from './shared/ModalError';
import Config from './shared/Config';
import Env from './shared/Env';
import WebAppDesigner from './designer/WebAppDesigner';
import UiReducer from './shared/state/UiReducer';
import HelpModal from './shared/help/HelpModal';
import WebAppWrapper from './webapp/WebAppWrapper';
import Routing from './shared/Routing';
import Archive from './shared/archive/Archive';
import ModalSimpleMessage from './shared/ModalSimpleMessage';
// import DevStand from './dev/DevStand';

export default () => {
  const [globalState, dispatch] = Store.useStore();
  const navigate = useNavigate();
  const location = useLocation();
  const routingTree = Routing.getTree(globalState);

  React.useEffect(() => {
    if (location.pathname !== '/' && location.pathname.endsWith('/')) {
      navigate(location.pathname.replace(/\/+$/, ''));
      return;
    }
    if ((location.pathname === '' || location.pathname === '/') && Config.getApplicationMode() === Config.ApplicationModes.WebAppDesigner) {
      navigate(routingTree.home.routes.webAppDesigner.path, { replace: true });
    }
  }, [location.pathname]);

  // React.useEffect(() => {
  //   if (window.env.REACT_APP_PRESTATE) {
  //     dispatch({
  //       reduce: ((prevState) => {
  //         const updatedState = { ...prevState, ...window.env.REACT_APP_PRESTATE };
  //         return updatedState;
  //       }),
  //     });
  //   }
  // }, []);

  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const userId = params.get('user-id');
    if (userId) {
      params.delete('user-id');
      const url = `${window.location.pathname}?${params.toString()}`.replace(/\?$/, '');
      window.history.replaceState(null, document.title, url);
      dispatch(UiReducer.setGenericUiAttribute('userId', userId));
    }
  }, []);

  React.useEffect(() => {
    if (Env.KC_ENABLED && !keycloak.authenticated) {
      keycloak.login();
    }
  }, []);

  let wappdMode = false;
  let content;

  switch (Config.getApplicationMode()) {
    case Config.ApplicationModes.WebAppCloudHosted:
    case Config.ApplicationModes.WebAppSelfHosted:
    case Config.ApplicationModes.WebAppCloudHostedPublic:
      content = <WebAppWrapper><WebApp /></WebAppWrapper>;

      break;
    case Config.ApplicationModes.WebAppDesigner:
      content = <WebAppWrapper><WebAppDesigner /></WebAppWrapper>;
      wappdMode = true;
      break;
    case Config.ApplicationModes.Archive:
      content = <Archive />;
      break;

    case Config.ApplicationModes.Portal:
    default:
      content = <Portal />;
  }

  return (
    <>
      {/* <DevStand /> */}
      {globalState?.ui?.loading && <ModalLoading />}
      {globalState?.ui?.errors?.length > 0 && <ModalError />}
      {UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.GeneralSimpleMessageModal) && <ModalSimpleMessage />}

      <HelpModal />
      {
        wappdMode ? (
          <Routes>
            <Route path={`${routingTree.home.routes.webAppDesigner.path}/*`} element={<WebAppWrapper><WebAppDesigner /></WebAppWrapper>} />
          </Routes>
        )
          : content
      }

    </>
  );
};
