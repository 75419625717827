/* eslint-disable react/no-unescaped-entities */
import {
  Stack, Text,
} from '@fluentui/react';
import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import AppReducer from '../../shared/state/AppReducer';
import UiReducer from '../../shared/state/UiReducer';
import Store from '../../shared/state/Store';
import AppsApi from '../../shared/util/AppsApi';
import User from '../../shared/User';
import LinkButton from '../../shared/LinkButton';
import PlaceholderError from '../../shared/PlaceholderError';
import Routing from '../../shared/Routing';

export default () => {
  const [globalState, dispatch] = Store.useStore();
  const navigate = useNavigate();
  const routingTree = Routing.getTree(globalState).home;

  const app = AppReducer.getAppData(globalState);
  const appOwner = app?.owner === User.getCurrentUser();

  if (!AppReducer.isAppLoaded(globalState)) {
    return <PlaceholderError message="No app is currently loaded" />;
  }

  return (
    <Stack vertical>
      {/* <Text block>
        There are two ways to publish this web app.
      </Text>
      <br /> */}
      <Text block>
        {/* <b>agena.ai cloud portal</b> */}
        <ol>
          {!appOwner && (
          <li>
            <Link to={routingTree.routes.webAppDesigner.routes.file.routes.save.path}>Save</Link>
            {' '}
            this app to your online account at agena.ai cloud portal
          </li>
          )}
          {!appOwner && (<li>Open Publishing section</li>)}
          {appOwner && (
            <li>
              Go to
              {' '}
              <Link to={routingTree.routes.cloudAppManager.routes.app.routes.publishing.path.replace(':id', app?.uuid)}>Cloud App Manager → Publishing</Link>
            </li>
          )}
          <li>Enter a subdomain in agenaai.app domain</li>
          <li>Mark the app as published</li>
          <li>
            Go to
            {' '}
            <Link to={routingTree.routes.cloudAppManager.routes.app.routes.accessControl.path.replace(':id', app?.uuid)}>Cloud App Manager → Access Control</Link>
            {' '}
            and assign end-users granting them to access the published version and send them an invite
          </li>
        </ol>
      </Text>
      {/* <Text block>
        <b>Self Hosted</b>
        <ol>
          <li>Save this app as a JSON</li>
          <li>Make this JSON available via HTTPS (e.g. https://example.com/my-app.json)</li>
          <li>
            <span>Launch the agena.ai cloud portal docker image with environment variables set as:</span>
            <br />
            <span>REACT_APP_APPLICATION_MODE: web-app-self-hosted</span>
            <br />
            <span>REACT_APP_WAPP_CONFIG_URL: https://example.com/my-app.json</span>
          </li>
          <li>The web app's configuration will be automatically downloaded from this URL and loaded on app startup</li>
        </ol>
      </Text> */}
    </Stack>
  );
};
