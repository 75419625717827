import Utils from '../Utils';
import ConfigReducer from './ConfigReducer';

/**
 * Reducer, use in dispatch
 */
const cacheResults = (dataSet, response, outputConfigMapParam) => ({
  reduce: ((prevState) => {
    const updatedState = { ...prevState };
    updatedState.resultCache = updatedState.resultCache || {};
    updatedState.resultCache.raw = updatedState.resultCache.raw || {};
    updatedState.resultCache.raw[dataSet] = response;

    const outputConfigMap = outputConfigMapParam || ConfigReducer.getOutputConfigMap(prevState);
    updatedState.resultCache.formatted = updatedState.resultCache.formatted || {};
    updatedState.resultCache.formatted[dataSet] = Utils.formatResultData(outputConfigMap, response);

    return updatedState;
  }),
});

const updateCachedResult = (dataSet, updatedCache) => ({
  reduce: ((prevState) => {
    const updatedState = { ...prevState };
    updatedState.resultCache = updatedState.resultCache || {};
    updatedState.resultCache.formatted = updatedState.resultCache.formatted || {};
    updatedState.resultCache.formatted[dataSet] = updatedCache;
    return updatedState;
  }),
});

const hasCachedResults = (globalState) => Object.keys(globalState?.resultCache?.formatted || {}).length > 0;

const getCachedResults = (globalState, dataSet, key) => {
  // No data cached
  if (!globalState.resultCache || !globalState.resultCache.formatted) {
    return null;
  }

  // Dataset not requested, return all
  if (!dataSet) {
    return globalState.resultCache.formatted;
  }

  // Dataset requested but does not exist
  if (!globalState.resultCache.formatted[dataSet]) {
    return null;
  }

  // Dataset specified but a data key is not, return all
  if (!key) {
    return globalState.resultCache.formatted[dataSet];
  }

  // Dataset and key specified, but does not exist
  if (!globalState.resultCache.formatted[dataSet][key]) {
    return null;
  }

  return globalState.resultCache.formatted[dataSet][key];
};

const getCachedResultsForDataSet = (globalState, dataSet) => getCachedResults(globalState, dataSet);

const getCachedResultsForDataSetAndKey = (globalState, dataSet, key) => getCachedResults(globalState, dataSet, key);

const getCachedResultsRaw = (globalState, dataSet, key) => {
  // No data cached
  if (!globalState.resultCache || !globalState.resultCache.raw) {
    return null;
  }

  // Dataset not requested, return all
  if (!dataSet) {
    return globalState.resultCache.raw;
  }

  // Dataset requested but does not exist
  if (!globalState.resultCache.raw[dataSet]) {
    return null;
  }

  // Dataset specified but a data key is not, return all
  if (!key) {
    return globalState.resultCache.raw[dataSet];
  }

  // Dataset and key specified, but does not exist
  if (!globalState.resultCache.raw[dataSet][key]) {
    return null;
  }

  return globalState.resultCache.raw[dataSet][key];
};

const getCachedResultsForDataSetRaw = (globalState, dataSet) => getCachedResultsRaw(globalState, dataSet);

const getCachedResultsForDataSetAndKeyRaw = (globalState, dataSet, key) => getCachedResultsRaw(globalState, dataSet, key);

/**
 * Reducer, use in dispatch
 */
const resetCachedResults = (dataSet) => ({
  reduce: ((prevState) => {
    const updatedState = { ...prevState };
    updatedState.resultCache = updatedState.resultCache || {};

    // Dataset unspecified, reset all
    if (!dataSet) {
      updatedState.resultCache.raw = {};
      updatedState.resultCache.formatted = {};
    } else {
      // Reset specific dataset only
      updatedState.resultCache.raw = updatedState.resultCache.raw || {};
      delete updatedState.resultCache.raw[dataSet];
      updatedState.resultCache.formatted = updatedState.resultCache.formatted || {};
      delete updatedState.resultCache.formatted[dataSet];
    }

    return updatedState;
  }),
});

const DefaultCaseNames = {
  USER: 'User',
  BASELINE: 'Baseline',
};

export default {
  DefaultCaseNames,
  cacheResults,
  updateCachedResult,
  getCachedResults,
  getCachedResultsForDataSet,
  getCachedResultsForDataSetAndKey,
  getCachedResultsForDataSetAndKeyRaw,
  getCachedResultsForDataSetRaw,
  getCachedResultsRaw,
  hasCachedResults,
  resetCachedResults,
};
