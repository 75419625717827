import React from 'react';
import { mergeStyleSets } from '@fluentui/react';
import BarChart from './BarChart';
import ContinuousChart from './ContinuousChart';
import ChartDefaults from '../../../shared/ChartDefaults';
import Utils from '../../../shared/Utils';
import GraphUtils from '../../GraphUtils';
import Store from '../../../shared/state/Store';


const componentStyles = mergeStyleSets({
  svgChart: {
    selectors: {
      '.png-charts &': {
        display: 'none !important',
      },
    },
  },

  pngChart: {
    selectors: {
      '.svg-charts &': {
        display: 'none !important',
      },
    },
  },
});

export default ({
  data, nodeConfig, networkId, actionRequested,
}) => {
  const [globalState, dispatch] = Store.useStore();
  const chartRef = React.useRef();
  const { ChartTypes } = ChartDefaults;
  // console.log('cardChart A', data, nodeConfig);

  // const key = Utils.createNetworkNodeKey(nodeConfig.network, nodeConfig.node);

  // React.useEffect(() => {
  //   ModellerReducer.mapNodeToChart(chartRef, networkId, nodeConfig.id);
  // }, [chartRef]);

  React.useEffect(() => {
    if (!actionRequested?.key) {
      return;
    }

    if (actionRequested.key === 'chartSaveSvg') {
      const svg = Utils.getSvg(chartRef);
      Utils.saveSvg(`${nodeConfig.node}.svg`, svg);
    }

    if (actionRequested.key === 'chartSavePng') {
      const svg = Utils.getSvg(chartRef);
      Utils.saveSvgAsPng(`${nodeConfig.node}.png`, svg);
    }

    if (actionRequested.key === 'chartCopy') {
      const svg = Utils.getSvg(chartRef);
      Utils.copyImageToClipboard(svg);
    }
  }, [actionRequested]);

  // const chartImageRef = React.useRef();
  // React.useEffect(() => {
  //   setTimeout(() => {
  //     if (!chartRef.current || !chartImageRef.current) {
  //       return;
  //     }
  //     const svg = Utils.getSvg(chartRef);
  //     if (!svg) {
  //       return;
  //     }
  //     if (chartImageRef.current) {
  //       Utils.svgToPngOperation(svg, async (canvas) => {
  //         if (chartImageRef.current) {
  //           const imgURI = canvas.toDataURL('image/png');
  //           chartImageRef.current.src = imgURI;
  //           // console.log(chartImageRef.current, imgURI);
  //         }
  //       });
  //     }
  //   }, 50);
  // }, [
  //   // UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.webAppShowPrintReport),
  //   // data,
  // ]);

  const chartType = GraphUtils.getChartType(nodeConfig);

  // console.log('cardchart X', nodeConfig.name, data);

  // React.useEffect(() => {
  //   const chartImage = ModellerReducer.getMappedChart(globalState, networkId, nodeConfig.id);
  //   console.log('update node chart', nodeConfig.name);
  //   dispatch(CytoReducer.updateNodeCytoStyle([{
  //     networkId,
  //     nodeId: nodeConfig.id,
  //     updateBody: {
  //       'background-image': `url('${chartImage}')`,
  //     },
  //   }]));
  //   dispatch(CytoReducer.setRepaintRequested());
  // }, [ModellerReducer.getMappedChart(globalState, networkId, nodeConfig.id)]);

  return (
    <>
      {
        ([ChartTypes.Area, ChartTypes.Histogram, ChartTypes.Line, ChartTypes.Scatter, ChartTypes.ScatterLine].includes(chartType)) && (
        <ContinuousChart
          data={data}
          nodeConfig={nodeConfig}
          chartRef={chartRef}
          className={componentStyles.svgChart}
          networkId={networkId}
        />
        )
      }

      {/* {
        ([ChartTypes.VerticalBarChart].includes(config.chartType)) && (
        <VerticalBarChart
          baselineData={baselineData}
          userData={userData}
          config={config}
          generalConfig={generalConfig}
          chartRef={chartRef}
          className={componentStyles.svgChart}
        />
        )
      } */}

      {
        ([ChartTypes.BarChart].includes(chartType)) && (
        <BarChart
          data={data}
          nodeConfig={nodeConfig}
          chartRef={chartRef}
          className={componentStyles.svgChart}
          networkId={networkId}
        />
        )
      }

      {/* <img className={componentStyles.pngChart} ref={chartImageRef} src="" alt="Chart" /> */}
    </>
  );
};

export const CardInfo = {
  key: 'chart',
  text: 'Chart',
  actions: [
    {
      key: 'chartSavePng',
      text: 'Save as PNG',
      icon: 'Download',
    },
    {
      key: 'chartSaveSvg',
      text: 'Save as SVG',
      icon: 'Download',
    },
    {
      key: 'chartCopy',
      text: 'Copy to Clipboard',
      icon: 'Copy',
    },
  ],
};
