const getEffectiveSubscriptionAccount = (relatedAccounts) => {
  const { email } = relatedAccounts.primaryAccount;

  let subscriptionAccount = null;
  relatedAccounts.secondaryAccounts.forEach((secondaryAccount) => {
    if (subscriptionAccount !== null) {
      return;
    }
    secondaryAccount.keys.forEach((key) => {
      if (subscriptionAccount !== null) {
        return;
      }
      if (!key.disabled && key.assignments.indexOf(email) >= 0) {
        subscriptionAccount = secondaryAccount;
      }
    });
  });

  if (subscriptionAccount === null) {
    subscriptionAccount = relatedAccounts.primaryAccount;
  }

  return subscriptionAccount;
};

const getAvailableKeys = (relatedAccounts) => {
  const keys = [];
  if (!relatedAccounts?.primaryAccount) {
    return keys;
  }

  relatedAccounts.primaryAccount.keys.forEach((key) => {
    const copyKey = { ...key };
    if (copyKey.assignments.indexOf(relatedAccounts.primaryAccount.email) >= 0) {
      copyKey.assigned = true;
    }
    copyKey.owner = relatedAccounts.primaryAccount.email;
    keys.push(copyKey);
  });

  relatedAccounts.secondaryAccounts.forEach((secondaryAccount) => {
    secondaryAccount.keys.forEach((key) => {
      const copyKey = { ...key };
      copyKey.disabled = true;
      copyKey.granted = true;
      copyKey.owner = secondaryAccount.email;
      if (copyKey.assignments.indexOf(relatedAccounts.primaryAccount.email) >= 0) {
        copyKey.assigned = true;
      }
      keys.push(copyKey);
    });
  });

  keys.sort((a, b) => new Date(b.expiry) - new Date(a.expiry));

  return keys;
};

const getAssignedKey = (relatedAccounts) => {
  if (!relatedAccounts) {
    return null;
  }

  const { email } = relatedAccounts.primaryAccount;

  let assignedKey = null;

  relatedAccounts.secondaryAccounts.forEach((secondaryAccount) => {
    secondaryAccount.keys.forEach((key) => {
      if (key.assignments.indexOf(email) >= 0) {
        assignedKey = key;
      }
    });
  });

  relatedAccounts.primaryAccount.keys.forEach((key) => {
    if (key.assignments.indexOf(email) >= 0) {
      assignedKey = key;
    }
  });

  return assignedKey;
};

export default {
  getAssignedKey,
  getEffectiveSubscriptionAccount,
  getAvailableKeys,
};
