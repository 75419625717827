import {
  Text,
} from '@fluentui/react';
import React from 'react';
import Article from './Article';

export default () => (
  <Article
    title="Using Web App Designer"
  >
    <Text block>
      <ol>
        <li>Choose and confirm your model</li>
        <li>Choose input and output nodes</li>
        <li>Configure labels, descriptions etc for inputs and outputs</li>
        <li>Arrange inputs and outputs by dragging and dropping them within the layout</li>
        <li>Check the web app preview</li>
        <li>Save to return to configuring later</li>
        <li>Publish your app</li>
      </ol>
    </Text>
  </Article>
);
