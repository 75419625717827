import {
  Text,
} from '@fluentui/react';
import React from 'react';
import Article from './Article';

export default () => (
  <Article
    title="Welcome to agena.ai portal"
  >
    <Text block>
      Welcome to agena.ai portal
    </Text>
  </Article>
);
