import React from 'react';
import Store from '../../../../shared/state/Store';
import InputGroups from './InputGroups';
import InputItems from './InputItems';
import MenuPage from '../../MenuPage';
import Routing from '../../../../shared/Routing';

export default () => {
  const [globalState] = Store.useStore();
  const routingTree = Routing.getTree(globalState).home.routes.webAppDesigner.routes.design.routes.configureInputs;

  return (
    <MenuPage
      root={routingTree}
      defaultPath={routingTree.routes.inputItems.path}
      elements={[
        <InputItems />,
        <InputGroups />,
      ]}
    />
  );
};
