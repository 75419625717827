import React from 'react';
import {
  Checkbox, CommandButton, DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton, Separator, Stack, Text, TextField,
} from '@fluentui/react';
import UiReducer from '../../shared/state/UiReducer';
import AppsApi from '../../shared/util/AppsApi';
import Store from '../../shared/state/Store';
import PlaceholderError from '../../shared/PlaceholderError';
import Theme from '../../shared/Theme';
import StackTokens from '../../shared/StackTokens';

export default () => {
  const [, dispatch] = Store.useStore();
  const [initErrors, setInitErrors] = React.useState();
  const [reservedDomains, setReservedDomains] = React.useState([]);
  const [itemsToDelete, setItemsToDelete] = React.useState([]);
  const [addDialogOpen, setAddDialogOpen] = React.useState(false);
  const [addTermsText, setAddTermsText] = React.useState('');

  React.useEffect(() => {
    (async () => {
      const jResponse = await AppsApi.getSettings();

      if (jResponse.code >= 300) {
        if (jResponse.messages && jResponse.messages.length > 1) {
          setInitErrors(jResponse.messages);
        } else if (jResponse.message) {
          setInitErrors([jResponse.message]);
        } else {
          setInitErrors(['Server is unavailable at this time, please try again later.']);
        }
      } else {
        setReservedDomains(jResponse?.reservedDomains);
      }
    })();
  }, []);

  const update = async (jPatch) => {
    const responseJson = await AppsApi.saveSettings(jPatch);
    if (responseJson.code >= 300) {
      dispatch(UiReducer.uiSetErrors(responseJson.messages));
    } else {
      setItemsToDelete([]);
      setReservedDomains(responseJson.reservedDomains);
    }
  };

  if (initErrors) {
    return (<PlaceholderError messages={initErrors} />);
  }

  return (
    <>
      <Stack horizontal tokens={StackTokens.spacing}>
        <CommandButton
          text="Add"
          iconProps={{
            iconName: 'Add',
          }}
          className={[Theme.styles.outlineControlButton, Theme.styles.commandButton].join(' ')}
          onClick={() => {
            setAddDialogOpen(true);
          }}
        />

        <CommandButton
          text="Delete"
          disabled={itemsToDelete.length === 0}
          iconProps={{
            iconName: 'Clear',
          }}
          className={[Theme.styles.outlineControlButton, Theme.styles.commandButton].join(' ')}
          onClick={async () => {
            const toRemove = new Set(itemsToDelete);
            const patch = { reservedDomains: reservedDomains.filter((e) => !toRemove.has(e)) };
            update(patch);
          }}
        />
      </Stack>

      <Separator styles={Theme.styleObjects.separatorSmallMargin} />

      <Text block style={{ margin: '10px 0 0 0' }}>
        You can add reserved subdomains here so that non-admin users can not assign them to deploy their apps.
      </Text>

      {reservedDomains && (
      <div>
        <table className={Theme.styles.table} style={(reservedDomains.length === 0) ? { display: 'none' } : undefined}>
          <thead>
            <tr>
              <th>
                <Checkbox
                  className={Theme.styles.tableRowCheckbox}
                  checked={itemsToDelete.length === reservedDomains.length && reservedDomains.length > 0}
                  indeterminate={itemsToDelete.length > 0 && itemsToDelete.length < reservedDomains.length}
                  onChange={() => {
                    let updated = [];
                    if (itemsToDelete.length < reservedDomains.length) {
                      // If not all checked, check all
                      updated = [...reservedDomains];
                    } else {
                      // If all checked, uncheck all
                    }
                    setItemsToDelete(updated);
                  }}
                />
              </th>
              <th>Subdomain</th>
            </tr>
          </thead>
          <tbody>
            {
            reservedDomains.map((item) => (
              <tr key={item}>
                <td>
                  <Checkbox
                    className={Theme.styles.tableRowCheckbox}
                    checked={itemsToDelete.includes(item)}
                    onChange={() => {
                      const index = itemsToDelete.indexOf(item);
                      const updated = [...itemsToDelete];
                      if (index >= 0) {
                        updated.splice(index, 1);
                      } else {
                        updated.push(item);
                      }
                      setItemsToDelete(updated);
                    }}
                  />
                </td>
                <td>{item}</td>
              </tr>
            ))
            }
          </tbody>
        </table>
      </div>
      )}


      <div style={{
        position: 'fixed',
        left: '-9999px',
        width: '0px',
        height: '0px',
      }}
      >
        <Dialog
          hidden={!addDialogOpen}
          onDismiss={() => { setAddDialogOpen(false); }}
          dialogContentProps={{
            type: DialogType.normal,
            title: 'Add Reserved Terms',
            closeButtonAriaLabel: 'Cancel',
            subText: 'Add reserved domain per line:',
            styles: {
              subText: {
                marginBottom: '5px',
              },
            },
          }}
          modalProps={{
            titleAriaId: 'addDialogOpen',
            subtitleAriaId: 'Add Reserved Terms',
            isBlocking: true,
            isModeless: false,
          }}
          minWidth="475px"
        >

          <TextField
            multiline
            rows={20}
            value={addTermsText}
            onChange={(event, value) => {
              setAddTermsText(value);
            }}
          />

          <DialogFooter>
            <PrimaryButton
              onClick={() => {
                const toAdd = addTermsText.split('\n').map((t) => t.trim().toLowerCase());
                const updatedSet = Array.from(new Set(toAdd.concat(reservedDomains)));
                const patch = { reservedDomains: updatedSet };
                update(patch);
                setAddDialogOpen(false);
              }}
              text="Add"
            />

            <DefaultButton
              onClick={() => {
                setAddDialogOpen(false);
              }}
              text="Close"
            />
          </DialogFooter>
        </Dialog>
      </div>
    </>
  );
};
