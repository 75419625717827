import { DefaultButton, mergeStyleSets, Stack } from '@fluentui/react';
import React from 'react';
import StackTokens from '../shared/StackTokens';
import ModellerReducer from './ModellerReducer';
import Store from '../shared/state/Store';
import Styles from './Styles';
import DataOutputItem from './io/DataOutputItem';
import ModellerDefaults from './ModellerDefaults';
import Theme from '../shared/Theme';

const componentStyles = mergeStyleSets({
  root: {
    width: '100%',
    minWidth: `${ModellerDefaults.ComponentDefaults.RightWidthMin}px`,
  },
  container: {
    height: ModellerDefaults.ComponentDefaults.ComponentHeight,
    // background: 'lime',
    overflowY: 'auto',
    paddingRight: '5px',
    marginTop: '7px !important',
    width: '100%',
  },

  rootMainWhenMinimised: {
    position: 'absolute',
    right: '10000px',
  },

  rootMinimised: {
    width: '25px',
    height: ModellerDefaults.ComponentDefaults.ComponentHeight,
    // marginTop: '34px',
    position: 'absolute',
    background: 'white',
  },
  headerMinimised: {
    writingMode: 'vertical-rl',
    textOrientation: 'mixed',
    paddingTop: '5px',
  },
});

export default ({ minimised, setMinimised }) => {
  const [globalState] = Store.useStore();
  const model = ModellerReducer.getModel(globalState);

  const minimisedSection = minimised && (
    <Stack vertical className={componentStyles.rootMinimised}>
      <div>
        <DefaultButton
          text=""
          iconProps={{
            iconName: 'ChevronLeft',
          }}
          className={[Theme.styles.outlineControlButton, Styles.sideMinimizeButton].join(' ')}
          onClick={async () => {
            setMinimised(false);
          }}
          title="Expand"
        />
      </div>
      <header className={[Styles.headerSection, componentStyles.headerMinimised].join(' ')}>Charts</header>
    </Stack>
  );

  return (
    <div className={componentStyles.root}>
      {minimisedSection}
      <Stack vertical tokens={StackTokens.spacing} className={minimised ? componentStyles.rootMainWhenMinimised : ''}>
        <Stack horizontal tokens={StackTokens.spacing5}>
          <DefaultButton
            text=""
            iconProps={{
              iconName: 'ChevronRight',
            }}
            className={[Theme.styles.outlineControlButton, Styles.sideMinimizeButton].join(' ')}
            onClick={async () => {
              setMinimised(true);
            }}
            title="Minimise"
          />
          <header className={Styles.headerSection}>Charts</header>
        </Stack>
        <Stack vertical tokens={StackTokens.spacing} className={componentStyles.container}>
          {
          model.networks.map((network) => (
            <React.Fragment key={network.id}>
              <header className={Styles.headerSubsection} key={network.id}>{network.name || network.id}</header>
              {
                network.nodes.map((node) => (
                  (node.graphics?.visible === false)
                    ? <React.Fragment key={node.id} />
                    : (
                      <DataOutputItem
                        key={node.id}
                        networkId={network.id}
                        nodeConfig={node}
                      />
                    )
                ))
              }
            </React.Fragment>
          ))
        }
        </Stack>
      </Stack>
    </div>
  );
};
