import { mergeStyleSets, Stack } from '@fluentui/react';
import React from 'react';
import LegendItem from './LegendItem';

const styles = mergeStyleSets({
  legendVertical: {
    margin: '0',
    padding: '0',
    listStyle: 'none',
    selectors: {
      li: {},
    },
  },
});

export default ({
  className, style, series, vertical,
}) => {
  const items = Object.keys(series).map((name) => {
    const item = <LegendItem name={name} color={series[name]} key={name} />;
    if (vertical) {
      return <li>{item}</li>;
    }
    return item;
  });

  if (vertical) {
    return (
      <ul className={`${className} ${styles.legendVertical}`} style={style}>
        {items}
      </ul>
    );
  }

  return (
    <Stack horizontal className={className} style={style}>
      {items}
    </Stack>
  );
};
