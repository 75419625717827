import {
  DefaultButton,
  mergeStyleSets, Modal, Spinner, SpinnerSize, Stack,
} from '@fluentui/react';
import React from 'react';
import Store from './state/Store';
import UiReducer from './state/UiReducer';
import Theme from './Theme';

const componentStyles = mergeStyleSets({
  'loading-modal': {
    width: 'auto',
    height: 'auto',
    'min-width': 'auto',
    'min-height': 'auto',
  },

  'loading-modal-spinner': {
    padding: '20px',
    margin: '0',
  },

  cancelButtonContainer: {
    padding: '0 0 10px 0',
  },

});

export default () => {
  const [globalState, dispatch] = Store.useStore();
  const cancelAction = globalState?.ui?.loading?.cancelAction;
  return (
    <div style={{ position: 'fixed' }}>
      <Modal
        titleAriaId="Please wait..."
        subtitleAriaId="Calculating"
        isOpen
        // onDismiss = {this._closeModal}
        isBlocking
        containerClassName={componentStyles['loading-modal']}
      >
        <Stack vertical>
          <div className={componentStyles['loading-modal-spinner']}>
            <Spinner
              label="Please wait..."
              ariaLive="assertive"
              labelPosition="right"
              size={SpinnerSize.large}
            />
          </div>

          {(typeof cancelAction === 'function') && (
          <Stack horizontal horizontalAlign="center" className={componentStyles.cancelButtonContainer}>
            <DefaultButton
              text="Cancel"
              className={Theme.styles.outlineControlButton}
              onClick={() => {
                cancelAction();
                dispatch(UiReducer.uiSetLoading(false));
              }}
            />
          </Stack>
          )}
        </Stack>
      </Modal>
    </div>
  );
};
