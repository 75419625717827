import {
  ActionButton, mergeStyleSets, Stack, Text,
} from '@fluentui/react';
import React from 'react';
import LinkButton from '../../shared/LinkButton';
import Theme from '../../shared/Theme';

const styles = mergeStyleSets({
  itemRoot: {
    border: `1px solid ${Theme.Colors.LightGray}`,
    width: '187px',
    padding: '10px',
    position: 'relative',
  },

  buttonRoot: {
    textAlign: 'center',
    border: 'none !important',
    outline: 'none !important',
    fontSize: '15px',
    width: '165px',
    height: '135px',
    display: 'block',
    padding: '5px',
    fontWeight: '600',
    selectors: {
      ':hover': {
        background: '#e4e4e4',
      },
    },
  },

  itemContainer: {
    display: 'block',
  },

  itemIcon: {
    fontSize: '100px',
    lineHeight: '100px',
    height: '100px',
    textAlign: 'center',
  },

  textBlock: {
    textAlign: 'center',
  },

  textBlockDescription: {
    height: '130px',
  },

  textBlockLink: {
    textAlign: 'center',
  },
});

export default ({ itemData }) => (
  <Stack
    vertical
    // tokens={{ childrenGap: 5 }}
    className={styles.itemRoot}
  >
    <ActionButton
      disabled={itemData.disabled}
      key={itemData.key}
      text={itemData.text}
      onClick={(event) => {
        itemData.action();
        event.target.closest('button').blur();
      }}
      iconProps={itemData.icon && {
        iconName: itemData.icon,
      }}
      styles={{
        root: styles.buttonRoot,
        icon: styles.itemIcon,
        flexContainer: styles.itemContainer,
      }}
    />

    <Text className={[styles.textBlock, styles.textBlockDescription].join(' ')} block>{itemData.description}</Text>

    {/* <Text className={[styles.textBlock, styles.textBlockLink].join(' ')} block>
      <LinkButton
        hoverUnderline="dashed"
        text="Learn More"
        onClick={() => {
          console.log('set help content');
        }}
      />
    </Text> */}

    {itemData.overlay || null}

  </Stack>
);
