import { DefaultButton } from '@fluentui/react';
import React from 'react';
import Theme from '../Theme';
import ColumnDistributorUi from './ColumnDistributorUi';

export default ({
  tableData, updateTableData, columnIndex, title,
}) => {
  const [isShowing, setShowing] = React.useState(false);

  const buttonSection = (
    <DefaultButton
      title={title}
      menuIconProps={{ iconName: 'ChevronUnfold10' }}
      className={[Theme.styles.iconButton, Theme.styles.iconButtonClearMinimalistic, Theme.styles.outlineControlButton]}
      onClick={() => {
        setShowing(!isShowing);
      }}
    />
  );

  let popup = <></>;
  if (isShowing) {
    popup = (
      <ColumnDistributorUi
        tableData={tableData}
        updateTableData={updateTableData}
        columnIndex={columnIndex}
        title={title}
        cancel={() => {
          setShowing(false);
        }}
      />
    );
  }

  return (
    <>
      { buttonSection }
      { popup }
    </>
  );
};
