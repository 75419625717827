import React from 'react';
import { DefaultButton, Stack } from '@fluentui/react';
import ConfigView from './ConfigView';
import tokens from '../../shared/StackTokens';
import Store from '../../shared/state/Store';
import ConfigReducer from '../../shared/state/ConfigReducer';

export default () => {
  const [globalState, dispatch] = Store.useStore();

  return (
    <Stack vertical tokens={tokens.spacing}>

      <span>
        <DefaultButton
          text="Reset"
          onClick={() => {
          // eslint-disable-next-line no-alert
            if (window.confirm('This will reset current web app configuration')) {
              dispatch(ConfigReducer.resetConfig());
            }
          }}
          style={{ background: '#d83b01', color: 'white' }}
        />
      </span>

      <p>
        Global State
        <br />
        <textarea
          style={{
            height: '100px',
            width: '600px',
          }}
          readOnly
          value={JSON.stringify(globalState, null, 2)}
        />
      </p>

      <ConfigView
        config={
          ConfigReducer.getConfig(globalState)
        }
      />

    </Stack>
  );
};
