import React, { useState } from 'react';
import {
  Stack, DefaultButton, PrimaryButton, mergeStyleSets, MessageBarType, MessageBarButton, Text,
} from '@fluentui/react';
import { useNavigate } from 'react-router-dom';
import convertModel from '../../../shared/util/apis';
import Store from '../../../shared/state/Store';
import UiReducer from '../../../shared/state/UiReducer';
import ConfigReducer from '../../../shared/state/ConfigReducer';
import DataReducer from '../../../shared/state/DataReducer';
import FormReducer from '../../../shared/state/FormReducer';
import MessageBar from '../../../shared/MessageBar';
import Routing from '../../../shared/Routing';

export default () => {
  const [globalState, dispatch] = Store.useStore();
  const navigate = useNavigate();
  const routingTree = Routing.getTree(globalState).home.routes.webAppDesigner;

  const [modelFile, setModelFile] = useState(null);
  const [modelFileRaw, setModelFileRaw] = useState('');
  const [modelFileBase64, setModelFileBase64] = useState('');

  const [showConfigWarning, setShowConfigWarning] = useState(false);
  const [showMultiNetworkWarning, setShowMultiNetworkWarning] = useState(false);

  React.useEffect(() => {
    try {
      const originalModelJson = JSON.parse(modelFileRaw);
      setShowConfigWarning(originalModelJson.inputConfig || originalModelJson.outputConfig || originalModelJson.generalConfig);
    } catch (error) {
      // Ignore
    }
  }, [modelFileRaw]);

  const modelFileRef = React.useRef(null);

  const styles = mergeStyleSets({
    button: {
      minWidth: '154px',
    },
  });

  const modelFileSelect = () => {
    // console.log(modelFileRef);
    if (modelFileRef.current) {
      modelFileRef.current.click();
    }
  };
  const modelFileSelected = (event) => {
    // console.log(event);
    // console.log(event.target.files);

    setShowConfigWarning(false);

    if (event.target.files?.length > 0) {
      // Check size
      // Set file selection
      const file = event.target.files[0];
      const { size } = file;

      if (size > 26214400) {
        dispatch(UiReducer.uiSetErrors(['File size too big, max 25 Mb allowed']));
        setModelFile(null);
        setModelFileRaw('');
        return;
      }

      const fileReaderText = new FileReader();
      fileReaderText.onload = (e) => {
        setModelFileRaw(e.target.result);
      };
      fileReaderText.readAsText(file);

      const fileReaderBase64 = new FileReader();
      fileReaderBase64.onload = (e) => {
        setModelFileBase64(e.target.result.split(',')[1]);
      };
      fileReaderBase64.readAsDataURL(file);

      setModelFile(file);
    } else {
      // No file chosen
      // Reset file selection
      setModelFile(null);
      setModelFileRaw('');
    }
  };

  const updateModelJson = async (modelFileParam = modelFile, modelFileRawParam = modelFileRaw) => {
    setShowConfigWarning(false);
    dispatch(UiReducer.uiSetLoading(true));

    const requestBody = {
      'sync-wait': true,
      name: modelFileParam.name,
    };

    try {
      const originalModelJson = JSON.parse(modelFileRawParam);
      requestBody.file = originalModelJson;
    } catch (error) {
      requestBody.file = modelFileBase64;
    }

    if (modelFileRef.current) {
      modelFileRef.current.value = '';
    }

    const response = await convertModel({ requestJson: requestBody, path: '/public/v1/tools/modeljson' });

    dispatch(UiReducer.uiSetLoading(false));

    if (response.status === 'success') {
      dispatch(UiReducer.uiSetErrors([]));
      dispatch(ConfigReducer.resetConfig());
      dispatch(ConfigReducer.setModel(response.results.model));
      dispatch(DataReducer.resetCachedResults());
      dispatch(FormReducer.setFormData({}));
      setModelFile(null);
      setModelFileRaw('');
      if (response.results.model?.networks?.length > 1) {
        setShowMultiNetworkWarning(true);
      } else {
        navigate(routingTree.routes.design.routes.selectInputsOutputs.path);
      }
    } else if (response.status === 'failure') {
      dispatch(UiReducer.uiSetErrors(response.messages));
    } else if (response.status === 500) {
      dispatch(UiReducer.uiSetErrors(['Internal Server Error']));
    }
  };

  return (
    <Stack style={{ marginTop: '10px' }}>
      <Stack vertical>
        <Text block>Start your Web App by selecting a previously developed Bayesian network model.</Text>
        <Text block>Select any model file with extension cmpx, cmp, ast or json.</Text>
        <Text block>
          <Text>If the model has more than one BN object but only one is actually used for the app, </Text>
          <Text>it is recommended that you first save a version of the model file with the single BN object you wish to use for your Web App.</Text>
        </Text>

        <p style={{ marginTop: '10px' }}>
          <DefaultButton
            text="Select Model File"
          // onClick={() => onSubmit(formData)}
            onClick={modelFileSelect}
            className={styles.button}
          />
          <input ref={modelFileRef} type="file" onChange={modelFileSelected} style={{ display: 'none' }} />

          { modelFile && (
          <span style={{ marginLeft: '5px' }}>
            File selected:
            {' '}
            {modelFile?.name}
          </span>
          )}
        </p>

        <p>
          <PrimaryButton
            text="Confirm Model"
            onClick={updateModelJson}
            disabled={!modelFile}
            className={styles.button}
          />
        </p>

        { showMultiNetworkWarning && (
        <MessageBar
          messageBarType={MessageBarType.severeWarning}
          styles={{ container: { maxWidth: '620px' } }}
          isMultiline
          text="The model you loaded contains multiple networks. Please take care when assigning inputs and outputs. It may be best to remove any unused networks and reload the model."
          onDismiss={() => {
            navigate(routingTree.routes.design.routes.selectInputsOutputs.path);
          }}
          actions={(
            <div>
              <MessageBarButton
                onClick={() => {
                  setShowMultiNetworkWarning(false);
                  navigate(routingTree.routes.design.routes.selectInputsOutputs.path);
                }}
              >
                Proceed
              </MessageBarButton>
            </div>
          )}
        />
        )}

        { showConfigWarning && (
        <MessageBar
          messageBarType={MessageBarType.warning}
          styles={{ container: { maxWidth: '650px' } }}
          isMultiline
          text="The file you loaded contains the whole app. Did you intend to load the whole app or just the model?"
        />
        )}

      </Stack>
    </Stack>
  );
};
