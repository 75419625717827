import { DefaultButton, mergeStyleSets } from '@fluentui/react';
import React from 'react';
import keycloak from '../shared/Keycloak';
import Store from '../shared/state/Store';
import UiReducer from '../shared/state/UiReducer';
import Config from '../shared/Config';
import Env from '../shared/Env';
import InputConfirmationDialog from '../shared/InputConfirmationDialog';

const styles = mergeStyleSets({
  menu: {
    minWidth: 'auto',
    padding: '0',
    margin: '0',
    border: 'none',
    outline: 'none !important',
    selectors: {
      '& > span': {
        display: 'initial',
      },
      i: {
        fontWeight: 'bold',
        fontSize: '20px',
        lineHeight: '30px',
        color: 'rgb(50, 49, 48)',
      },
    },
  },

  menuItem: {
    border: 'none !important',
    outline: 'none !important',
    selectors: {
      '&:hover i': {
        color: 'rgb(0, 120, 212)',
      },
    },
  },
});

export default ({ style }) => {
  const [globalState, dispatch] = Store.useStore();
  const [menuItems, setMenuItems] = React.useState([]);

  const userId = UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.userId);

  React.useEffect(() => {
    if (!globalState.config?.generalConfig?.appViewEnabled && globalState.config?.generalConfig?.modelViewEnabled) {
      // If app view is not enabled and model view is enabled, show model view by default
      dispatch(UiReducer.setGenericUiAttribute(UiReducer.Keys.WebbAppModelViewSelected, true));
    }
  }, []);

  React.useEffect(() => {
    const items = [];
    if (Config.getApplicationMode() === Config.ApplicationModes.WebAppSelfHosted) {
      items.push({
        key: 'userId',
        text: 'User key:',
        secondaryText: (userId || 'Click to set'),
        iconProps: { iconName: 'Permissions' },
        className: styles.menuItem,
        onClick: () => {
          dispatch(UiReducer.setGenericUiAttribute('AppMenuUserIdDialog', true));
        },
      });
    }

    items.push(
      {
        key: 'Manage Case Data',
        text: 'Manage Case Data',
        iconProps: { iconName: 'DatabaseSync' },
        onClick: () => {
          dispatch(UiReducer.setGenericUiAttribute(UiReducer.Keys.isCaseManagerPanelOpen, true));
        },
        className: styles.menuItem,
      },
      {
        key: 'Print',
        text: 'Print and Copy',
        iconProps: { iconName: 'Print' },
        onClick: () => {
          dispatch(UiReducer.setGenericUiAttribute(UiReducer.Keys.webAppShowPrintDialog, true));
          dispatch(UiReducer.setGenericUiAttribute(UiReducer.Keys.webAppShowPrintReport, true));
        },
        className: styles.menuItem,
      },
    );

    if (globalState.config?.generalConfig?.modelViewEnabled && globalState.config?.generalConfig?.appViewEnabled) {
      if (!UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.WebbAppModelViewSelected)) {
        items.push({
          key: 'View Model',
          text: 'View Model',
          iconProps: { iconName: 'GitGraph' },
          onClick: () => {
            dispatch(UiReducer.setGenericUiAttribute(UiReducer.Keys.WebbAppModelViewSelected, true));
          },
          className: styles.menuItem,
        });
      } else {
        items.push({
          key: 'View App',
          text: 'View App',
          iconProps: { iconName: 'Photo2' },
          onClick: () => {
            dispatch(UiReducer.setGenericUiAttribute(UiReducer.Keys.WebbAppModelViewSelected, false));
          },
          className: styles.menuItem,
        });
      }
    }

    if (Env.KC_ENABLED && [Config.ApplicationModes.WebAppCloudHosted, Config.ApplicationModes.WebAppSelfHosted].includes(Config.getApplicationMode())) {
      // Running as standalone application
      items.push({
        key: 'Log Out',
        text: 'Log Out',
        iconProps: { iconName: 'SignOut' },
        onClick: () => {
          keycloak.logout();
        },
        className: styles.menuItem,
      });
    }

    setMenuItems(items);
  }, [
    UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.WebbAppModelViewSelected),
    globalState.config?.generalConfig?.modelViewEnabled,
    globalState.config?.generalConfig?.appViewEnabled,
  ]);

  const [button, setButton] = React.useState();

  return (
    <>
      <DefaultButton
        text=""
        title="More Actions"
        menuIconProps={{ iconName: 'GlobalNavButton' }}
        className={styles.menu}
        style={style}
        menuProps={{
          className: styles.menu,
          items: menuItems,
        }}
        onMenuClick={(event) => {
          setButton(event.target.closest('button'));
        }}
        onAfterMenuDismiss={() => {
          button.blur();
        }}
      />
      <InputConfirmationDialog
        uiElementId="AppMenuUserIdDialog"
        title="Enter your user key"
        text="Enter your user key provided to you by the app owner, or enter an empty string to clear the key"
        inputPlaceholder="Enter your user key"
        primaryButtonText="Apply"
        hideOnAction
        validateValue={(val) => {
          const value = val.trim();
          return value;
        }}
        primaryCallback={(value) => dispatch(UiReducer.setGenericUiAttribute(UiReducer.Keys.userId, value))}
      />
    </>
  );
};
