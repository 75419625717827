const setObservation = ({
  model, datasetId, networkId, nodeId, value, variableName, distribution,
}) => {
  const datasetIndex = model.dataSets.findIndex((dataset) => dataset.id === datasetId);
  if (datasetIndex < 0) {
    console.log('No such dataset');
    return undefined;
  }
  const dataset = model.dataSets[datasetIndex];
  dataset.observations = dataset.observations || [];
  const observationIndex = dataset.observations.findIndex((observation) => observation.network === networkId && observation.node === nodeId && observation.constantName === variableName);

  let observationValue = value;
  if (typeof value === 'string') {
    observationValue = `${value}`.trim();
  }

  const observation = {
    network: networkId,
    node: nodeId,
  };

  if (variableName) {
    observation.constantName = variableName;
  }

  let observationDistribution = distribution;
  if (typeof distribution !== 'object') {
    observationDistribution = undefined;
  } else {
    observationDistribution = Object.fromEntries(Object.entries(observationDistribution).filter((entry) => Number.parseFloat(entry[1]) > 0));
    if (Object.keys(observationDistribution).length === 0) {
      observationDistribution = undefined;
    }
  }

  if (observationDistribution && variableName) {
    // Observation as ditribution is invalid if this is a node variable observation
    observationDistribution = undefined;
  }

  if (observationValue && observationDistribution) {
    // Having both observationValue and observationDistribution is invalid, assume observationDistribution
    observationValue = undefined;
  }

  if (!observationValue && !observationDistribution) {
    // Empty value
    if (observationIndex >= 0) {
      // Remove existing
      console.log('Clear obs', { ...observation, dataset: datasetId, ...variableName && { constantName: variableName } });
      dataset.observations.splice(observationIndex, 1);
    }
    // Skip
    return observation;
  }

  if (observationValue) {
    observation.entries = [
      {
        weight: 1,
        value: observationValue,
      },
    ];
  } else {
    observation.entries = Object.keys(observationDistribution).map((state) => ({
      value: state,
      weight: observationDistribution[state],
    }));
  }

  console.log('Set obs', observation);

  if (observationIndex < 0) {
    dataset.observations.push(observation);
  } else {
    dataset.observations[observationIndex] = observation;
  }

  return observation;
};

const getObservation = ({
  model, datasetId, networkId, nodeId, variableName,
}) => {
  const datasetIndex = model.dataSets.findIndex((dataset) => dataset.id === datasetId);
  if (datasetIndex < 0) {
    console.log('No such dataset');
    return null;
  }
  const dataset = model.dataSets[datasetIndex];
  if (!dataset.observations) {
    return null;
  }

  const observationIndex = dataset.observations.findIndex((el) => el.network === networkId && el.node === nodeId && variableName === el.constantName);

  if (observationIndex < 0) {
    return null;
  }

  return dataset.observations[observationIndex];
};

const getResult = ({
  model, datasetId, networkId, nodeId,
}) => {
  const datasetIndex = model.dataSets.findIndex((dataset) => dataset.id === datasetId);
  if (datasetIndex < 0) {
    return null;
  }
  const dataset = model.dataSets[datasetIndex];
  const resultIndex = dataset.results.findIndex((el) => el.network === networkId && el.node === nodeId);

  if (resultIndex < 0) {
    return null;
  }

  return dataset.results[resultIndex];
};

const getNetwork = ({ model, networkId }) => {
  if (!model || !model.networks) {
    return null;
  }
  return model.networks.find((network) => network.id === networkId) || null;
};

const getNode = ({ network, nodeId }) => {
  if (!network || !network.nodes) {
    return null;
  }
  return network.nodes.find((node) => node.id === nodeId) || null;
};

const getDataset = ({ model, datasetId }) => {
  if (!model || !model.dataSets) {
    return null;
  }
  return model.dataSets.find((dataset) => dataset.id === datasetId) || null;
};

export default {
  setObservation,
  getObservation,
  getResult,
  getNetwork,
  getNode,
  getDataset,
};
