import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { mergeStyleSets, Stack } from '@fluentui/react';
import Store from '../shared/state/Store';
import AppReducer from '../shared/state/AppReducer';
import Env from '../shared/Env';
import AppsApi from '../shared/util/AppsApi';
import ConfigReducer from '../shared/state/ConfigReducer';
import PrintReport from './print/PrintReport';
import Theme from '../shared/Theme';
import PrintDialog from './print/PrintDialog';
import keycloak from '../shared/Keycloak';
import ArchivingApi from '../shared/util/ArchivingApi';
import PlaceholderError from '../shared/PlaceholderError';
import CaseManager from '../shared/state/CaseManager';
import IconButton from '../shared/IconButton';
import OutputSettingsPanel from './Report/OutputSettingsPanel';

const componentStyles = mergeStyleSets({
  settingsIconButton: {
    display: 'block',
    marginTop: '15px',
    marginRight: '5px',
  },
});

export default () => {
  const [globalState, dispatch] = Store.useStore();
  const [searchParams] = useSearchParams();
  const [message, setMessage] = React.useState('Loading');
  const [error, setError] = React.useState('');
  const [showSettings, setShowSettings] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      if (Env.KC_ENABLED && !keycloak.authenticated) {
        setError('Unauthorized');
        return;
      }

      if (searchParams.get('uuid')) {
        // Load full record details and load shallow app config
        const uuid = searchParams.get('uuid');

        const fullRecord = await ArchivingApi.getRecord(uuid);
        if (fullRecord.responseStatus === 200) {
          const appConfig = await AppsApi.getAppConfig(fullRecord.appId);
          if (appConfig.responseStatus === 200) {
            const { configuration } = appConfig;
            configuration.model = { archived: true };
            configuration.generalConfig.baselineEnabled = false;
            dispatch(AppReducer.loadApp(configuration));
            CaseManager.loadCaseFromRecordData(dispatch, fullRecord);
            return;
          }
        }
        setError('No such record available');
      } else {
        setError('Record ID is required');
      }
    })();
  }, []);

  React.useEffect(() => {
    if (globalState?.config?.model?.archived && ConfigReducer.isInputConfigMapped(globalState) && ConfigReducer.isOutputConfigMapped(globalState)) {
      setError('');
      setMessage('');
    }
  }, [globalState.config?.model]);

  if (error) {
    return (<PlaceholderError message={error} />);
  }

  if (message) {
    return (<PlaceholderError message={message} />);
  }

  return (
    <Stack vertical>
      <PrintDialog
        visible={showSettings}
        setVisible={setShowSettings}
        closeButtonText="Close"
      />

      <PrintReport
        visible
        styles={{
          root: {
            width: '99.5%',
            minWidth: '99.5%',
            paddingBottom: '10px',
          },
        }}
        titleSettingsButton={(
          <IconButton
            className={[Theme.styles.noPrint, componentStyles.settingsIconButton].join(' ')}
            iconName="Settings"
            onClick={() => {
              setShowSettings(true);
            }}
          />
          )}
      />

      <OutputSettingsPanel />
    </Stack>
  );
};
