import {
  Checkbox, ColorPicker, DefaultButton, Label, Separator, Stack, SwatchColorPicker, Text, TextField,
} from '@fluentui/react';
import React from 'react';
import tokens from '../../../../shared/StackTokens';
import ChartDefaults from '../../../../shared/ChartDefaults';
import ExampleChart from '../../../../resources/config-chart-demo.json';
import Area from '../../../../webapp/Report/ContinuousChart';
import CollapsiblePanel from '../../../../shared/CollapsiblePanel';
import Store from '../../../../shared/state/Store';
import ConfigReducer from '../../../../shared/state/ConfigReducer';
import DataReducer from '../../../../shared/state/DataReducer';
import HelpButton from '../../../../shared/help/HelpButton';

const colorPickerStyles = {
  panel: { padding: 0 },
  root: {
    maxWidth: 300,
    minWidth: 300,
  },
  colorRectangle: { height: 300 },
};

const colorSelection = ChartDefaults.Colors.map((hex) => ({ id: hex, color: hex }));

export default () => {
  const [globalState, dispatch] = Store.useStore();
  const generalConfig = ConfigReducer.getGeneralConfig(globalState);

  const setColor = (option, color) => {
    const updatedConfig = ConfigReducer.getGeneralConfig(globalState);

    let updatedColor = color;
    if (updatedColor.hex) {
      updatedColor = `#${updatedColor.hex}`;
    }

    updatedConfig[option] = updatedColor;
    dispatch(ConfigReducer.setGeneralConfig(updatedConfig));
  };

  const resetColor = (option) => {
    const updatedConfig = ConfigReducer.getGeneralConfig(globalState);
    delete updatedConfig[option];
    dispatch(ConfigReducer.setGeneralConfig(updatedConfig));
  };

  return (
    <Stack grow vertical tokens={tokens.verticalSpacing}>
      <TextField
        label="Result section header"
        value={generalConfig.resultSectionHeader}
        onChange={(event, value) => {
          const updatedConfig = ConfigReducer.getGeneralConfig(globalState);
          updatedConfig.resultSectionHeader = value;
          dispatch(ConfigReducer.setGeneralConfig(updatedConfig));
        }}
      />

      <Separator />

      <Label>Chart Settings</Label>

      <Label>Example Chart Preview</Label>
      <Area baselineData={generalConfig.baselineEnabled ? ExampleChart.baselineData : []} userData={ExampleChart.userData} config={ExampleChart.config} generalConfig={generalConfig} />

      <Label>Baseline Case</Label>
      <Stack horizontal>
        <Checkbox
          label="Enable baseline case"
          checked={generalConfig.baselineEnabled}
          onChange={() => {
            const updatedConfig = ConfigReducer.getGeneralConfig(globalState);
            updatedConfig.baselineEnabled = !generalConfig.baselineEnabled;
            dispatch(ConfigReducer.setGeneralConfig(updatedConfig));
            dispatch(DataReducer.resetCachedResults(DataReducer.DefaultCaseNames.BASELINE));
          }}
        />
        <HelpButton styles={{ root: { marginLeft: '5px' } }} title="Enable baseline case">
          <Text block>If enabled, your app will automatically calculate when loaded (as preview or published) without any answers.</Text>
          <Text block>The results of this calculation will be displayed as the Basline.</Text>
        </HelpButton>
      </Stack>
      <CollapsiblePanel
        collapsible
        collapsed
        title="Baseline Case Color"
      >
        <Stack vertical tokens={tokens.verticalSpacing}>
          <SwatchColorPicker
            selectedId={generalConfig.colorBaseline || ChartDefaults.ColorBaseline}
            columnCount={10}
            cellShape="square"
            colorCells={colorSelection}
            onChange={(id, color) => {
              setColor('colorBaseline', color);
            }}
          />
          <ColorPicker
            color={generalConfig.colorBaseline || ChartDefaults.ColorBaseline}
            onChange={(ev, color) => {
              setColor('colorBaseline', color);
            }}
            alphaType="none"
            showPreview
            styles={colorPickerStyles}
          />
          <div>
            <DefaultButton
              text="Reset"
              onClick={() => {
                resetColor('colorBaseline');
              }}
            />
          </div>
        </Stack>
      </CollapsiblePanel>

      <CollapsiblePanel
        collapsible
        collapsed
        title="User Case Color"
      >
        <Stack vertical tokens={tokens.verticalSpacing}>
          <SwatchColorPicker
            selectedId={generalConfig.colorUser || ChartDefaults.ColorUser}
            columnCount={10}
            cellShape="square"
            colorCells={colorSelection}
            onChange={(id, color) => {
              setColor('colorUser', color);
            }}
          />
          <ColorPicker
            color={generalConfig.colorUser || ChartDefaults.ColorUser}
            onChange={(ev, color) => {
              setColor('colorUser', color);
            }}
            alphaType="none"
            showPreview
            styles={colorPickerStyles}
          />
          <div>
            <DefaultButton
              text="Reset"
              onClick={() => {
                resetColor('colorUser');
              }}
            />
          </div>
        </Stack>
      </CollapsiblePanel>

    </Stack>
  );
};
