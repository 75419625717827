import { mergeStyleSets, Stack } from '@fluentui/react';
import React from 'react';
import StackTokens from '../shared/StackTokens';
import ModellerReducer from './ModellerReducer';
import Store from '../shared/state/Store';
import GraphUtils from './GraphUtils';
import DataInputItem from './io/DataInputItem';
import ModellerDefaults from './ModellerDefaults';
import Styles from './Styles';

const componentStyles = mergeStyleSets({
  root: {
    height: `calc(${ModellerDefaults.ComponentDefaults.ComponentHeight} - 10px)`,
  },
  crossColumnHeader: {
    whiteSpace: 'nowrap',
    overflow: 'visible',
    maxWidth: '100px',
  },
  headerCase: {},
  headerNetwork: {},
  headerNode: {},
  headerWide: {},
  inputCell: {
    verticalAlign: 'top',
  },
});

export default ({ selectedGraphKey }) => {
  const [globalState] = Store.useStore();
  const model = ModellerReducer.getModel(globalState);

  let { networks } = model;
  if (selectedGraphKey !== ModellerDefaults.FlatModelGraphKey) {
    networks = networks.filter((network) => GraphUtils.escapeSelector(network.id) === selectedGraphKey);
  }

  const rows = [];

  rows.push(model.dataSets.map((ds) => [
    <th
      className={[Styles.headerSection, componentStyles.headerCase].join(' ')}
      key={ds.id}
    >
      {`Case: ${ds.id}`}
    </th>,
  ]));

  networks.forEach((network) => {
    rows.push([
      <th
        key={network.id}
        className={[Styles.headerSubsection, componentStyles.headerWide, componentStyles.headerNetwork].join(' ')}
        colSpan={model.dataSets.length}
      >
        {network.name || network.id}
      </th>,
    ]);

    network.nodes.forEach((node, nodeIndex) => {
      rows.push(
        model.dataSets.map((dataset, dsIndex) => (
          <td
            key={model.dataSets[dsIndex].id + network.id + node.id}
            className={componentStyles.inputCell}
          >
            <DataInputItem
              datasetId={model.dataSets[dsIndex].id}
              networkId={network.id}
              nodeConfig={node}
              hideLabel={dsIndex > 0}
              tabIndexValue={(nodeIndex + 1) * 100}
            />
          </td>
        )),
      );
    });
  });

  return (
    <Stack horizontal tokens={StackTokens.spacingWide} className={componentStyles.root}>
      <table>
        <tbody>
          {
            // eslint-disable-next-line react/no-array-index-key
            rows.map((row, i) => <tr key={i}>{row}</tr>)
          }
        </tbody>
      </table>
    </Stack>
  );
};
