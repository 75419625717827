import { Stack } from '@fluentui/react';
import React from 'react';
import StackTokens from '../../shared/StackTokens';
import CalculateMenu from './CalculateMenu';
import FileMenu from './FileMenu';
import DataMenu from './DataMenu';

export default ({ mode }) => (
  <Stack horizontal tokens={StackTokens.spacing}>
    <FileMenu mode={mode} />
    <CalculateMenu mode={mode} />
    <DataMenu mode={mode} />
  </Stack>
);
