import {
  Checkbox,
  ColorPicker,
  DefaultButton,
  Dropdown,
  Label,
  mergeStyleSets, Slider, Stack, TextField,
} from '@fluentui/react';
import React from 'react';
import PickImageControl from '../../PickImageControl';
import tokens from '../../../../shared/StackTokens';
import ChartDefaults from '../../../../shared/ChartDefaults';
import CollapsiblePanel from '../../../../shared/CollapsiblePanel';
import ValidatedTextField from '../../../../shared/ValidatedTextField';
import ConfigReducer from '../../../../shared/state/ConfigReducer';
import Store from '../../../../shared/state/Store';
import PercentileList from './PercentileList';
import Utils from '../../../../shared/Utils';

const styles = mergeStyleSets({

  itemCard: {
    // margin: '5px',
    padding: '5px',
    border: '1px solid rgb(237, 235, 233)',
    width: '400px',
    selectors: {
      ':first-of-type': {
        // marginLeft: '0',
        // paddingLeft: '0',
      },
      ':last-of-type': {
        // marginRight: '0',
        // paddingRight: '0',
      },
    },
  },

  headerSection: {
    maxWidth: '195px',
  },

  headerSpan: {
    width: '60px',
    display: 'inline-block',
  },

  iconPicker: {
    marginLeft: '10px !important',
    selectors: {
      '> div:last-of-type': {
        minWidth: '130px',
      },
    },
  },

  slider: {
    selectors: {
      '.ms-Slider-value': {
        width: '10px',
      },
    },
  },

  inputLabel: {
    paddingBottom: 0,
    paddingTop: '5px',
  },

  errorCodeStyle: {
    display: 'inline-block',
    background: 'rgb(243, 242, 241)',
  },

  collapsibleHeader: {
    height: 'fit-content',
  },

});

const colorPickerStyles = {
  panel: { padding: 0 },
  root: {
    maxWidth: '98%',
    minWidth: '98%',
  },
  colorRectangle: { height: 300 },
};

export default ({ groupName, groupItem, defaultAdvancedOpen }) => {
  const [globalState, dispatch] = Store.useStore();

  const key = Utils.createNetworkNodeKey(groupItem.network, groupItem.node);
  const modelNodeType = ConfigReducer.getNetworksNodesMap(globalState)[key]?.configuration?.type;
  const isContinuousNode = ['ContinuousInterval', 'IntegerInterval'].includes(modelNodeType);

  return (
    <Stack className={styles.itemCard} key={key} vertical>
      <Stack vertical>
        <Stack horizontal>
          <Stack vertical grow className={styles.headerSection}>
            <Label>
              <span className={styles.headerSpan}>Network </span>
              :
              {' '}
              {groupItem.network}
            </Label>
            <Label>
              <span className={styles.headerSpan}>Node </span>
              :
              {' '}
              {groupItem.node}
            </Label>

            <TextField
              label="Label"
              value={groupItem.label}
              placeholder="Label to display"
              onChange={(event, value) => {
                const updatedItem = { ...groupItem };
                updatedItem.label = value;
                dispatch(ConfigReducer.updateOutputItem(updatedItem));
              }}
            />
          </Stack>

          <PickImageControl
            className={styles.iconPicker}
            buttonText="Set Icon"
            width="80px"
            height="80px"
            fileSizeLimit={262144}
            imageUrl={groupItem.icon}
            setImageUrl={(imageUrl) => {
              const updatedItem = { ...groupItem };
              updatedItem.icon = imageUrl;
              dispatch(ConfigReducer.updateOutputItem(updatedItem));
            }}
          />

        </Stack>

        <Stack horizontal tokens={tokens.spacing} grow>
          <Stack.Item grow={2.6}>
            <Dropdown
              label="Chart type"
              selectedKey={groupItem.chartType}
                        // dropdownWidth={100}
              options={
                        Object.entries(['ContinuousInterval', 'IntegerInterval'].includes(groupItem.nodeType)
                          ? ChartDefaults.ChartTypesContinuous
                          : ChartDefaults.ChartTypesDiscrete)
                          .map(([name, value]) => ({ key: value, text: name }))
                      }
              onChange={(e, item) => {
                const updatedItem = { ...groupItem };
                updatedItem.chartType = item.key;
                dispatch(ConfigReducer.updateOutputItem(updatedItem));
              }}
            />
          </Stack.Item>

          <Stack.Item grow>
            <div style={{ width: '100%', paddingTop: '5px' }}>
              <Slider
                label={<span style={{ padding: '0 0 7px 7px ', display: 'inline-block' }}>Decimal places (0 - 6)</span>}
                value={(groupItem.decimalPlaces !== undefined) ? groupItem.decimalPlaces : ChartDefaults.decimalPlaces}
                className={styles.slider}
                min={0}
                max={6}
                step={1}
                showValue
                onChange={(value) => {
                  const updatedItem = { ...groupItem };
                  updatedItem.decimalPlaces = value;
                  dispatch(ConfigReducer.updateOutputItem(updatedItem));
                }}
              />
            </div>
          </Stack.Item>

        </Stack>

        <Stack horizontal tokens={tokens.spacing}>

          <Stack.Item>

            <ValidatedTextField
              placeholder="Auto"
              label="Fix width"
              description="px, %, wv (default: px)"
              initialValue={groupItem.width}
              suffix="--"
              value={groupItem.width}
              setValue={(userInput) => {
                let value = userInput.trim();
                const updatedItem = { ...groupItem };

                if (value === updatedItem.width) {
                // No changes, do nothing
                  return { success: true };
                }

                if (value.toLowerCase() === 'auto') {
                  value = '';
                }

                if (value === '') {
                  delete updatedItem.width;
                  dispatch(ConfigReducer.updateOutputItem(updatedItem));
                  return { success: true, value };
                }

                if (value.match(/^[0-9]+$/)) {
                  value += 'px';
                }

                if (!CSS.supports('width', value)) {
                  return { success: false, error: 'Invalid value' };
                }

                updatedItem.width = value;
                dispatch(ConfigReducer.updateOutputItem(updatedItem));
                return { success: true, value };
              }}
            />
          </Stack.Item>
          <Stack.Item>
            <ValidatedTextField
              placeholder="Auto"
              label="Fix height"
              description="px, wh (default: px)"
              initialValue={groupItem.height}
              suffix="--"
              value={groupItem.height}
              setValue={(userInput) => {
                let value = userInput.trim();
                const updatedItem = { ...groupItem };

                if (value === updatedItem.height) {
                // No changes, do nothing
                  return { success: true };
                }

                if (value.toLowerCase() === 'auto') {
                  value = '';
                }

                if (value === '') {
                  delete updatedItem.height;
                  dispatch(ConfigReducer.updateOutputItem(updatedItem));
                  return { success: true, value };
                }

                if (value.match(/^[0-9]+$/)) {
                  value += 'px';
                }

                if (!CSS.supports('height', value)) {
                  return { success: false, error: 'Invalid value' };
                }

                if (value.match(/%$/)) {
                  return {
                    success: false,
                    error: (
                      <span>
                        <span className={styles.errorCodeStyle}>%</span>
                        {' '}
                        not allowed, try
                        {' '}
                        <span className={styles.errorCodeStyle}>vh</span>
                        {' '}
                        instead
                      </span>
                    ),
                  };
                }

                updatedItem.height = value;
                dispatch(ConfigReducer.updateOutputItem(updatedItem));
                return { success: true, value };
              }}
            />
          </Stack.Item>

        </Stack>

        <Stack horizontal tokens={tokens.spacing}>

          <Stack.Item>

            <ValidatedTextField
              placeholder="Auto"
              label="Left padding"
              initialValue={groupItem.paddingLeft}
              suffix="px"
              value={groupItem.paddingLeft}
              setValue={(userInput) => {
                let value = userInput.trim();
                const updatedItem = { ...groupItem };

                if (value === updatedItem.paddingLeft) {
                // No changes, do nothing
                  return { success: true };
                }

                if (value === '') {
                  delete updatedItem.paddingLeft;
                  dispatch(ConfigReducer.updateOutputItem(updatedItem));
                  return { success: true, value };
                }

                value = value.replace(/px$/ig, '');
                if (value.match(/^[0-9]+$/)) {
                  value += 'px';
                } else {
                  value = '';
                }

                updatedItem.paddingLeft = value;
                dispatch(ConfigReducer.updateOutputItem(updatedItem));
                return { success: true, value };
              }}
            />
          </Stack.Item>
          <Stack.Item>

            <ValidatedTextField
              placeholder="Auto"
              label="Right padding"
              initialValue={groupItem.paddingRight}
              suffix="px"
              value={groupItem.paddingRight}
              setValue={(userInput) => {
                let value = userInput.trim();
                const updatedItem = { ...groupItem };

                if (value === updatedItem.paddingRight) {
                // No changes, do nothing
                  return { success: true };
                }

                if (value === '') {
                  delete updatedItem.paddingRight;
                  dispatch(ConfigReducer.updateOutputItem(updatedItem));
                  return { success: true, value };
                }

                value = value.replace(/px$/ig, '');
                if (value.match(/^[0-9]+$/)) {
                  value += 'px';
                } else {
                  value = '';
                }

                updatedItem.paddingRight = value;
                dispatch(ConfigReducer.updateOutputItem(updatedItem));
                return { success: true, value };
              }}
            />
          </Stack.Item>

        </Stack>

        {isContinuousNode && (
          <>
            <PercentileList config={groupItem} dispatch={dispatch} />
            <Checkbox
              label="Plot percentiles"
              checked={!!groupItem.plotPercentiles}
              styles={{ root: { paddingTop: '10px' } }}
              onChange={(ev, checked) => {
                const updatedItem = groupItem;
                if (checked) {
                  updatedItem.plotPercentiles = true;
                } else {
                  delete updatedItem.plotPercentiles;
                }
                dispatch(ConfigReducer.updateOutputItem(updatedItem));
              }}
            />

            <Checkbox
              label="Plot mean"
              checked={!!groupItem.plotMean}
              styles={{ root: { paddingTop: '10px' } }}
              onChange={(ev, checked) => {
                const updatedItem = groupItem;
                if (checked) {
                  updatedItem.plotMean = true;
                } else {
                  delete updatedItem.plotMean;
                }
                dispatch(ConfigReducer.updateOutputItem(updatedItem));
              }}
            />

            <Checkbox
              label="Plot median"
              checked={!!groupItem.plotMedian}
              styles={{ root: { paddingTop: '10px' } }}
              onChange={(ev, checked) => {
                const updatedItem = groupItem;
                if (checked) {
                  updatedItem.plotMedian = true;
                } else {
                  delete updatedItem.plotMedian;
                }
                dispatch(ConfigReducer.updateOutputItem(updatedItem));
              }}
            />
          </>
        )}


        <CollapsiblePanel
          collapsible
          collapsed={!defaultAdvancedOpen}
          title="More Settings"
          keyOverride={key}
          classNames={{ header: styles.collapsibleHeader }}
        >

          <Stack horizontal tokens={tokens.spacing}>

            <Stack.Item>

              <ValidatedTextField
                placeholder="Auto"
                label="Top padding"
                initialValue={groupItem.paddingTop}
                suffix="px"
                value={groupItem.paddingTop}
                setValue={(userInput) => {
                  let value = userInput.trim();
                  const updatedItem = { ...groupItem };

                  if (value === updatedItem.paddingTop) {
                  // No changes, do nothing
                    return { success: true };
                  }

                  if (value === '') {
                    delete updatedItem.paddingTop;
                    dispatch(ConfigReducer.updateOutputItem(updatedItem));
                    return { success: true, value };
                  }

                  if (value.match(/^[0-9]+$/)) {
                    value += 'px';
                  }

                  if (!value.match(/^[0-9]+px$/)) {
                    return { success: false, error: 'Unsupported value' };
                  }

                  updatedItem.paddingTop = value;
                  dispatch(ConfigReducer.updateOutputItem(updatedItem));
                  return { success: true, value };
                }}
              />
            </Stack.Item>
            <Stack.Item>
              <ValidatedTextField
                placeholder="Auto"
                label="Bottom padding"
                initialValue={groupItem.paddingBottom}
                suffix="px"
                value={groupItem.paddingBottom}
                setValue={(userInput) => {
                  let value = userInput.trim();
                  const updatedItem = { ...groupItem };

                  if (value === updatedItem.paddingBottom) {
                  // No changes, do nothing
                    return { success: true };
                  }

                  if (value === '') {
                    delete updatedItem.paddingBottom;
                    dispatch(ConfigReducer.updateOutputItem(updatedItem));
                    return { success: true, value };
                  }

                  if (value.match(/^[0-9]+$/)) {
                    value += 'px';
                  }

                  if (!value.match(/^[0-9]+px$/)) {
                    return { success: false, error: 'Unsupported value' };
                  }

                  updatedItem.paddingBottom = value;
                  dispatch(ConfigReducer.updateOutputItem(updatedItem));
                  return { success: true, value };
                }}
              />
            </Stack.Item>

          </Stack>

          {['ContinuousInterval', 'IntegerInterval'].includes(groupItem.nodeType) && (
          <Stack horizontal tokens={tokens.spacing} grow>
            <Stack.Item grow={1}>
              <Dropdown
                label="Smoothing function"
                selectedKey={groupItem.curveFunctionName || ((groupItem.nodeType && groupItem.nodeType === 'IntegerInterval') ? ChartDefaults.CurveInteger : ChartDefaults.CurveContinuous)}
                options={Object.keys(ChartDefaults.CurveFunctions).map((name) => ({ key: name, text: name }))}
                onChange={(e, item) => {
                  const updatedItem = { ...groupItem };
                  updatedItem.curveFunctionName = item.key;
                  dispatch(ConfigReducer.updateOutputItem(updatedItem));
                }}
              />
            </Stack.Item>
          </Stack>
          )}

          <Stack.Item grow>
            <TextField
              label="Description"
              multiline
              rows={3}
              value={groupItem.description}
              placeholder="Appears above graph"
              onChange={(event, value) => {
                const updatedItem = { ...groupItem };
                updatedItem.description = value;
                dispatch(ConfigReducer.updateOutputItem(updatedItem));
              }}
            />
          </Stack.Item>

          <Stack vertical tokens={tokens.verticalSpacing}>
            <Label className={styles.inputLabel}>Chart outer background color</Label>
            <ColorPicker
              color={groupItem.backgroundColor || ChartDefaults.OutputItemBackgroundColor}
              onChange={(ev, color) => {
                const updatedItem = { ...groupItem };
                updatedItem.backgroundColor = `#${color.hex}`;
                dispatch(ConfigReducer.updateOutputItem(updatedItem));
              }}
              alphaType="none"
              showPreview
              styles={colorPickerStyles}
            />
            <div>
              <DefaultButton
                text="Reset"
                onClick={() => {
                  const updatedItem = { ...groupItem };
                  updatedItem.backgroundColor = ChartDefaults.OutputItemBackgroundColor;
                  dispatch(ConfigReducer.updateOutputItem(updatedItem));
                }}
              />
            </div>
          </Stack>

        </CollapsiblePanel>

      </Stack>
    </Stack>
  );
};
