import React from 'react';
import {
  DefaultButton, mergeStyleSets,
} from '@fluentui/react';
import { useNavigate, useLocation } from 'react-router-dom';
import Store from '../shared/state/Store';
import Routing from '../shared/Routing';

const styles = mergeStyleSets({
  menuButton: {
    border: 'none',
    width: '40px',
    minWidth: 'auto',
    height: '40px',
    selectors: {
      i: {
        fontSize: '20px',
      },
      ':hover': {
        border: 'none !important',
        outline: 'none !important',
      },
    },
  },

  menuItemList: {
    selectors: {
      'li>button:hover': {
        border: 'none !important',
        outline: 'none !important',
      },
    },
  },

  menuItemSelected: {
    backgroundColor: '#f3f2f1',
  },

});

export default () => {
  const [button, setButton] = React.useState();
  const [globalState] = Store.useStore();
  const navigate = useNavigate();
  const location = useLocation();
  const routingTree = Routing.getTree(globalState).home;

  const items = [
    {
      key: routingTree.path,
      text: routingTree.text,
      onClick: () => navigate(routingTree.path),
      className: (location.pathname === routingTree.path) ? styles.menuItemSelected : '',
      // iconProps: { iconName: 'Home' },
    },
    ...Object.values(routingTree.routes)
      .filter((item) => item.skip !== true)
      .map((sitem) => ({
        key: sitem.path,
        text: sitem.text,
        disabled: sitem.disabled !== undefined && sitem.disabled(globalState),
        onClick: () => navigate(sitem.path),
        className: (location.pathname.startsWith(sitem.path)) ? styles.menuItemSelected : '',
      })),
  ];

  return (
    <>
      <DefaultButton
        menuIconProps={{
          iconName: 'GlobalNavButton',
        }}
        title="Portal Menu"
        className={styles.menuButton}
        menuProps={{
          className: styles.menuItemList,
          //   styles: {
          //     root: styles.menuItemList,
          //   },
          calloutProps: {
            minPagePadding: 0,
          },
          items,
        }}
        onMenuClick={(event) => {
          setButton(event.target.closest('button'));
        }}
        onAfterMenuDismiss={() => {
          button.blur();
        }}
      />
    </>
  );
};
