import {
  Label, mergeStyleSets, Stack,
} from '@fluentui/react';
import React from 'react';
import ModellerReducer from '../ModellerReducer';
import Store from '../../shared/state/Store';
import ModellerDefaults from '../ModellerDefaults';
import CardChart from './chart/CardChart';

export default ({ nodeConfig, networkId }) => {
  const [globalState] = Store.useStore();
  const nodeGraphics = nodeConfig.graphics || ModellerDefaults.DefaultGraphics;

  const chartData = ModellerReducer
    .getModel(globalState).dataSets
    .map((dataset) => (ModellerReducer.getMappedResult(globalState, dataset.id, networkId, nodeConfig.id)))
    .filter((el) => el !== undefined);

  const componentStyles = mergeStyleSets({
    root: {
      display: 'block',
    },
    container: {
      width: nodeGraphics?.graph?.width || ModellerDefaults.DefaultGraphics.graph.width,
      height: nodeGraphics?.graph?.height || ModellerDefaults.DefaultGraphics.graph.height,
    },
  });

  // React.useEffect(() => {
  //   console.log('update individual chart',
  //   ModellerReducer.getMappedGraph(globalState, ModellerDefaults.FlatModelGraphKey), ModellerReducer.getMappedGraph(globalState, GraphUtils.escapeSelector(networkId)), networkId, nodeConfig.id);
  //   const netNodeKey = Utils.createNetworkNodeKey(networkId, nodeConfig.id);
  //   const selector = `[selector="${GraphUtils.escapeSelector(netNodeKey)}"].mode_chart`;
  //   [
  //     ModellerReducer.getMappedGraph(globalState, ModellerDefaults.FlatModelGraphKey),
  //     ModellerReducer.getMappedGraph(globalState, GraphUtils.escapeSelector(networkId)),
  //   ].forEach((graph) => {
  //     if (!graph) {
  //       return;
  //     }
  //     console.log(selector);
  //     graph.style().selector(selector).style({ background: 'red' }).update();
  //   });
  // }, [ModellerReducer.getCachedNodeChart(globalState, networkId, nodeConfig.id)]);

  /*
    [
      {
        dataset: {dsId},
        node: {nodeId},
        network: {networkId},
        resultValues: [
          {
            label: {stateLabel},
            value: {probabilityMass}
          }
        ],
        summaryStatistics: {...}
      }
    ]
  */

  return (
    <Stack vertical className={componentStyles.root}>
      <Label>{nodeConfig.name || nodeConfig.id}</Label>
      <div className={componentStyles.container}>
        <CardChart
          data={chartData}
          nodeConfig={nodeConfig}
          networkId={networkId}
        />
      </div>
    </Stack>
  );
};
