import {
  DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton, Stack, TextField,
} from '@fluentui/react';
import React from 'react';
import Store from './state/Store';
import UiReducer from './state/UiReducer';

export default ({
  uiElementId, title, text,
  closeButtonAriaLabel = 'Cancel',
  inputPlaceholder = 'Enter a value',
  initialValue,
  validateValue, validateOnlyPrimary = true,
  noInputMode,
  multiline, rows,
  primaryButtonText, secondaryButtonText = 'Cancel',
  primaryButtonIcon,
  hideOnAction, primaryCallback, secondaryCallback,
  styles,
  extraButtons = [],
  inputExtRef, primaryButtonRef,
}) => {
  const [globalState, dispatch] = Store.useStore();
  const [inputValue, setInputValue] = React.useState(initialValue || '');
  const [errorText, setErrorText] = React.useState('');
  const inputRef = inputExtRef || React.useRef();

  const hide = () => {
    dispatch(UiReducer.setGenericUiAttribute(uiElementId, false));
    setInputValue('');
    setErrorText('');
  };

  const useCallback = (callback) => {
    let value = inputValue;
    if (!noInputMode && callback && validateValue && ((validateOnlyPrimary && callback === primaryCallback) || !validateOnlyPrimary)) {
      try {
        value = validateValue(value);
      } catch (error) {
        setErrorText(error.message);
        if (inputRef.current) {
          inputRef.current.focus();
        }
        return;
      }
    }

    if (callback) {
      callback(value);
    }

    if (hideOnAction) {
      hide();
    }
  };

  const dialogStyles = styles || { main: { maxWidth: 450 } };

  const dialogContentProps = {
    type: DialogType.normal,
    title,
    closeButtonAriaLabel,
    subText: text,
    styles: {
      subText: {
        marginBottom: '5px',
      },
    },
  };

  const modalProps = {
    titleAriaId: uiElementId,
    subtitleAriaId: text,
    isBlocking: false,
    styles: dialogStyles,
  };

  let input = <></>;
  if (!noInputMode) {
    input = (
      <Stack horizontal>
        <Stack.Item grow>
          <TextField
            multiline={multiline}
            rows={rows}
            ref={inputRef}
            placeholder={inputPlaceholder}
            value={inputValue}
            onChange={(event, value) => {
              setInputValue(value);
              setErrorText('');
            }}
            onKeyDown={(event) => {
              if (event.keyCode === 13 && (event.ctrlKey || !multiline)) {
                useCallback(primaryCallback);
              }
            }}
            errorMessage={errorText}
          />
        </Stack.Item>
      </Stack>
    );
  }

  return (
    <div style={{
      position: 'fixed',
      left: '-9999px',
      width: '0px',
      height: '0px',
    }}
    >
      <Dialog
        hidden={!UiReducer.getGenericUiAttribute(globalState, uiElementId)}
        onDismiss={hide}
        dialogContentProps={dialogContentProps}
        modalProps={modalProps}
      >

        {input}

        <DialogFooter>

          {extraButtons}

          <PrimaryButton
            componentRef={primaryButtonRef}
            onClick={() => {
              useCallback(primaryCallback);
            }}
            text={primaryButtonText}
            iconProps={primaryButtonIcon ? { iconName: primaryButtonIcon } : undefined}
          />
          <DefaultButton
            onClick={() => {
              useCallback(secondaryCallback);
            }}
            text={secondaryButtonText}
          />
        </DialogFooter>
      </Dialog>
    </div>
  );
};
