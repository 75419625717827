import structuredClone from 'core-js/stable/structured-clone';
import {
  DefaultButton, Stack, Text,
} from '@fluentui/react';
import React from 'react';
import CheckmarkIconButton from '../../../../shared/CheckmarkIconButton';
import Table from '../../../../shared/npt/Table';
import Popup from '../../../../shared/Popup';
import StackTokens from '../../../../shared/StackTokens';
import TextFieldBuffered from '../../../../shared/TextFieldBuffered';

export default ({
  inputConfig, updateInputConfig, tableData, setIsOpen, isOpen, initialSelection,
}) => {
  const [selectedRange, setSelectedRange] = React.useState(initialSelection);
  const [questionsMap, setQuestionsMap] = React.useState({});

  React.useEffect(() => {
    const map = {};
    (inputConfig.semanticTableEditing || []).forEach((question) => {
      map[`${question.row}.${question.col}`] = question;
    });
    setQuestionsMap(map);
  }, [inputConfig.semanticTableEditing]);

  if (!isOpen) {
    return (<></>);
  }

  return (
    <Popup
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title="Create semantic table column override"
    >
      <Stack vertical tokens={StackTokens.spacing}>

        <TextFieldBuffered
          multiline
          label="Instructions displayed to user"
          placeholder="Enter a value between 0 and 1 inclusive."
          actualValue={inputConfig.semanticTableEditingInstructions || ''}
          validateValue={(value) => `${value}`.trim()}
          applyValue={(value) => {
            const updatedConfig = structuredClone(inputConfig);
            updatedConfig.semanticTableEditingInstructions = value;
            updateInputConfig(updatedConfig);
          }}
        />

        <Stack vertical tokens={StackTokens.spacing} style={{ maxWidth: '460px' }}>
          <Text block>Choose a cell to define a question for.</Text>
        </Stack>

        <Table
          data={tableData}
          optionsEnabled={false}
          selectionEnabled={!initialSelection}
          rangeSelectedCallback={setSelectedRange}
          initialSelection={initialSelection}
        />

        {
          selectedRange && (
            <Stack horizontal tokens={StackTokens.spacing}>

              <Stack.Item grow>
                <Stack horizontal verticalAlign="start">
                  <Stack.Item grow>
                    <TextFieldBuffered
                      label="Question"
                      placeholder="What is the chance of this?"
                      actualValue={questionsMap[`${selectedRange.cell1.row}.${selectedRange.cell1.col}`]?.question || ''}
                      validateValue={(value) => {
                        const validatedValue = `${value}`.trim();

                        if (validatedValue === '') {
                          throw new Error('Question can not be empty');
                        }

                        return validatedValue;
                      }}
                      applyValue={(value) => {
                        const updatedConfig = structuredClone(inputConfig);
                        updatedConfig.semanticTableEditing = updatedConfig.semanticTableEditing || [];

                        // Find index to update and whether a question for this column is already defined
                        let indexToUpdate = -1;
                        // let foundColumnQuestion;
                        // let foundColumnQuestionIndex = -1;
                        updatedConfig.semanticTableEditing.forEach((question, index) => {
                          // if (question.col === selectedRange.cell1.col) {
                          //   foundColumnQuestion = question;
                          //   foundColumnQuestionIndex = index;
                          // }

                          if (question.row === selectedRange.cell1.row && question.col === selectedRange.cell1.col) {
                            indexToUpdate = index;
                          }
                        });

                        // if (foundColumnQuestion && foundColumnQuestion.row !== selectedRange.cell1.row) {
                        //   // Question is being set for a column that already has a question
                        //   throw new Error(`Multiple questions per column are not allowed. See question ${foundColumnQuestionIndex + 1}`);
                        // }

                        // const indexToUpdate = updatedConfig.semanticTableEditing.findIndex((q) => q.row === selectedRange.cell1.row && q.col === selectedRange.cell1.col);
                        const updatedQuestion = {
                          row: selectedRange.cell1.row,
                          col: selectedRange.cell1.col,
                          question: value,
                        };
                        if (indexToUpdate >= 0) {
                          updatedConfig.semanticTableEditing[indexToUpdate] = updatedQuestion;
                        } else {
                          updatedConfig.semanticTableEditing.push(updatedQuestion);
                        }

                        updateInputConfig(updatedConfig);
                      }}
                    />
                  </Stack.Item>
                  <Stack.Item>
                    <CheckmarkIconButton styles={{ button: { marginTop: '31px' } }} />
                  </Stack.Item>
                </Stack>
              </Stack.Item>

            </Stack>
          )
        }

        <Stack horizontal tokens={StackTokens.spacing} horizontalAlign="end">
          <DefaultButton
            onClick={() => {
              setIsOpen(false);
            }}
            text="Close"
          />
        </Stack>

      </Stack>
    </Popup>
  );
};
