import {
  mergeStyleSets, Pivot, PivotItem, Stack,
} from '@fluentui/react';
import React from 'react';
import TabTable from './TabTable';
import GraphDataLayer from './ModelPreview/cytoscape/GraphDataLayer';
import Store from '../shared/state/Store';
import ModellerReducer from './ModellerReducer';
import Styles from './Styles';
import StackTokens from '../shared/StackTokens';
import ModellerDefaults from './ModellerDefaults';
import GraphUtils from './GraphUtils';

const componentStyles = mergeStyleSets({
  root: {
    // width: '100%',
    // minWidth: ModellerDefaults.ComponentDefaults.MiddleWidth,
    paddingLeft: '5px',
    height: '100%',
  },
});

export default () => {
  const [globalState, dispatch] = Store.useStore();

  const model = ModellerReducer.getModel(globalState);

  const selectedTabKey = ModellerReducer.getSetting(globalState, ModellerReducer.SettingKeys.tabSelected);
  const selectedGraphKey = ModellerReducer.getSelectedGraphKey(globalState);

  const getDisplayValue = (item, key) => {
    if (item === 'table' && selectedTabKey === ModellerReducer.SettingKeys.tabSelectedTable) {
      return 'block';
    }

    if (item === 'graph' && key === selectedGraphKey && selectedTabKey === ModellerReducer.SettingKeys.tabSelectedGraph) {
      return 'block';
    }

    return 'none';
  };

  let { networks } = model;
  if (selectedGraphKey !== ModellerDefaults.FlatModelGraphKey) {
    networks = networks.filter((network) => GraphUtils.escapeSelector(network.id) === selectedGraphKey);
  }

  return (
    <Stack vertical className={componentStyles.root}>

      <Stack horizontal tokens={StackTokens.spacing}>
        {/* <header className={Styles.headerSection}>Data:</header> */}
        <Pivot
          selectedKey={selectedTabKey}
          overflowBehavior="menu"
          overflowAriaLabel="More"
          onLinkClick={(pivotItem) => {
            dispatch(ModellerReducer.setSetting(ModellerReducer.SettingKeys.tabSelected, pivotItem.props.itemKey));
          }}
          styles={{
            root: {
              // background: 'lime',
              height: '24px',
              // marginTop: '2px'
            },
            link: {
              // color: 'green',
              height: '24px',
            },
          }}
        >
          <PivotItem
            headerText="Graph"
            className={Styles.sectionTabHandle}
            itemKey={ModellerReducer.SettingKeys.tabSelectedGraph}
          />

          <PivotItem
            headerText="Table"
            className={Styles.sectionTabHandle}
            itemKey={ModellerReducer.SettingKeys.tabSelectedTable}
          />
        </Pivot>
      </Stack>

      <div style={{ display: getDisplayValue('graph', ModellerDefaults.FlatModelGraphKey) }}>
        <GraphDataLayer
          graphKey={ModellerDefaults.FlatModelGraphKey}
        />
      </div>

      {
        networks.map((network) => (
          <div style={{ display: getDisplayValue('graph', GraphUtils.escapeSelector(network.id)) }} key={network.id}>
            <GraphDataLayer
              graphKey={GraphUtils.escapeSelector(network.id)}
            />
          </div>
        ))
      }

      <div style={{ display: getDisplayValue('table') }}>
        <TabTable selectedGraphKey={selectedGraphKey} />
      </div>

    </Stack>
  );
};
