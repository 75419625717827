import React from 'react';
import {
  Checkbox, Dropdown, Icon, Stack, Text,
} from '@fluentui/react';
import StackTokens from '../shared/StackTokens';
import Store from '../shared/state/Store';
import UiReducer from '../shared/state/UiReducer';
import AppReducer from '../shared/state/AppReducer';
import AppsApi from '../shared/util/AppsApi';
import Theme from '../shared/Theme';
import Env from '../shared/Env';
import TextFieldBuffered from '../shared/TextFieldBuffered';
import CheckmarkIconButton from '../shared/CheckmarkIconButton';
import User from '../shared/User';
import PlaceholderError from '../shared/PlaceholderError';

export default () => {
  const [globalState, dispatch] = Store.useStore();

  const uuid = UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.CloudAppManagerAppSelected);
  const cloudApps = UiReducer.getGenericUiAttribute(globalState, UiReducer.ListOptions.cloudManagerAppsList) || [];
  const app = cloudApps.filter((a) => a.uuid === uuid)[0];

  const subdomainRegex = `(^[0-9${Env.CLOUD_APP_DOMAIN_ALLOW_DOTS ? '\\.' : ''}a-z-]+$)|(^$)`;

  if (!app) {
    return <PlaceholderError message="No such app is available" />;
  }

  const updateApp = async (patch) => {
    const responseJson = await AppsApi.saveApp(patch, uuid);
    if (responseJson.code >= 300) {
      dispatch(UiReducer.uiSetErrors(responseJson.messages));
    } else {
      dispatch(UiReducer.setGenericUiListItem(UiReducer.ListOptions.cloudManagerAppsList, responseJson, (a) => a.uuid === uuid));
      if (uuid === AppReducer.getAppData(globalState)?.uuid) {
        // Editing currently loaded app
        dispatch(AppReducer.setAppData(responseJson));
      }
    }
  };

  const domainSuffix = app.public ? Env.CLOUD_APP_PUBLIC_DOMAIN_SUFFIX : Env.CLOUD_APP_DOMAIN_SUFFIX;
  const domainValue = (app.domain || '').replace(new RegExp(domainSuffix.replaceAll('.', '\\.')), '');

  const [showCheckmarkIconButton, setShowCheckmarkIconButton] = React.useState(false);

  return (
    <Stack vertical tokens={StackTokens.spacing}>
      <Text block>
        <span>Apps can be published on an </span>
        <b className={Theme.styles.bold}>{domainSuffix}</b>
        <span> subdomain (subject to availability).</span>
      </Text>

      {User.getEnabledFeatures(globalState).includes('publicPublishing')
      && (
      <div>
        <Text block>When switching between public and private publishing modes, the app becomes unpublished and loses its associated domain.</Text>
        <Text block>Privately published apps are only accessible to logged in users based on access-control assignments.</Text>
        <Text block>Publicly published apps are accessible to any anonymous user.</Text>

        <Stack horizontal>
          <Dropdown
            label="Access mode"
            selectedKey={!!app.public}
            options={[
              { key: false, text: 'Private' },
              { key: true, text: 'Public' },
            ]}
            onChange={async (e, item) => {
              updateApp({ public: item.key });
            }}
          />
        </Stack>
      </div>
      )}

      <Stack horizontal>
        <TextFieldBuffered
          key={app.public + app.domain}
          actualValue={domainValue || ''}
          placeholder="Enter domain"
          prefix="https://"
          suffix={domainSuffix}
          validateValue={(value) => {
            const validatedValue = value.trim().toLowerCase();
            if (!validatedValue.match(subdomainRegex)) {
              throw new Error('Invalid characters detected');
            }
            return validatedValue;
          }}
          applyValue={async (value) => {
            if (value === domainValue || (`${value}`.trim().length === 0 && domainValue === null)) {
              return;
            }
            const updateValue = value ? (value + domainSuffix) : '';
            const jResponse = await AppsApi.saveApp({ domain: `${updateValue}` }, uuid);

            if (jResponse.code >= 300) {
              let errorText = 'Failed to update domain assignment, please try again later';
              if (jResponse.message) {
                errorText = jResponse.message;
              }
              if (jResponse.messages && jResponse.messages.length > 1) {
                dispatch(UiReducer.uiSetErrors(jResponse.messages));
              }
              throw errorText;
            }

            if (uuid === AppReducer.getAppData(globalState)?.uuid) {
              // Editing currently loaded app
              dispatch(AppReducer.setAppData(jResponse));
            }
            dispatch(UiReducer.setGenericUiListItem(UiReducer.ListOptions.cloudManagerAppsList, jResponse, (a) => a.uuid === uuid));
          }}
          styles={{
            root: {
              width: '400px',
            },

          }}
          onFocus={() => { setShowCheckmarkIconButton(true); }}
          onBlur={() => { setShowCheckmarkIconButton(false); }}
        />

        {showCheckmarkIconButton && <CheckmarkIconButton />}

      </Stack>

      <Checkbox
        label="Publishing is enabled"
        disabled={!(domainValue && domainValue.length > 0)}
        checked={!!app.published}
        onChange={async () => {
          updateApp({ published: !app.published });
        }}
      />

      {!!app.published && !!domainValue && (
      <Text block>
        <span>Your app is published here: </span>
        <a href={`https://${app.domain}`} target="_blank" rel="noopener noreferrer">
          <span>
            {app.domain}
            {' '}
          </span>
          <sub>
            <Icon iconName="OpenInNewTab" />
          </sub>
        </a>
      </Text>
      )}

    </Stack>
  );
};
