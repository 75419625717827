import React from 'react';
import { Stack } from '@fluentui/react';
import Legend from '../../shared/Legend';
import ChartDefaults from '../../shared/ChartDefaults';
import OutputGroup from './OutputGroup';
import Log from './Log';

import Store from '../../shared/state/Store';
import ConfigReducer from '../../shared/state/ConfigReducer';
import DataReducer from '../../shared/state/DataReducer';
import OutputSettingsPanel from './OutputSettingsPanel';

export default ({ reportRef }) => {
  const [globalState] = Store.useStore();

  // React.useLayoutEffect(() => {
  //   scrollToRef(ref);
  // }, []);

  // console.log(outputConfigMap);


  if (!DataReducer.hasCachedResults(globalState)) {
    return (<></>);
  }

  const calculationLog = globalState.log;
  // const [fname, setFname] = useState(ChartDefaults.CurveContinuous);

  // if (!config.curveFunction) {
  //   if (config.nodeType === 'ContinuousInterval') {
  //     config.curveFunction = generalConfig.curveFunctionContinuous ? generalConfig.curveFunctionContinuous : 'Cardinal Open 1';
  //   }
  //   if (config.nodeType === 'IntegerInterval') {
  //     config.curveFunction = generalConfig.curveFunctionInteger ? generalConfig.curveFunctionInteger : 'Step After';
  //   }
  // }

  return (
    <div ref={reportRef} className="svg-charts">
      {/* <Dropdown
        label="fname"
        selectedKey={fname}
        options={Object.keys(ChartDefaults.CurveFunctions).map((f) => ({ key: f, text: f }))}
        onChange={(e, item) => {
          setFname(item.key);
        }}
      /> */}
      {/* {scrollToRef(ref) || <h2 ref={ref}>{ConfigReducer.getGeneralConfig(globalState).resultSectionHeader}</h2>} */}
      {<h2>{ConfigReducer.getGeneralConfig(globalState).resultSectionHeader}</h2>}

      {
      !!ConfigReducer.getGeneralConfig(globalState).baselineEnabled
       && (
       <Legend
         series={{
           Baseline: ConfigReducer.getGeneralConfig(globalState).colorBaseline || ChartDefaults.ColorBaseline,
           User: ConfigReducer.getGeneralConfig(globalState).colorUser || ChartDefaults.ColorUser,
         }}
       />
       )
       }

      <Stack
        vertical
      >
        {
        ConfigReducer.getOutputConfig(globalState).map((group) => (
          <OutputGroup
            key={group.groupName}
            config={group}
          />
        ))
        }

        <Log log={calculationLog} />

      </Stack>

      <OutputSettingsPanel />

    </div>
  );
};
