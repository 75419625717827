import { IconButton } from '@fluentui/react';
import React from 'react';
import Store from '../../shared/state/Store';
import UiReducer from '../../shared/state/UiReducer';
import Utils from '../../shared/Utils';
import { defaultPanelWidth } from './OutputSettingsPanel';

export default ({ config }) => {
  const [globalState, dispatch] = Store.useStore();

  return (
    <IconButton
      iconProps={{ iconName: 'Settings' }}
      title="Settings"
      onClick={(event) => {
        const showingKey = globalState.report?.showSettingsPanelFor;
        const thisKey = Utils.createNetworkNodeKey(config.network, config.node);
        const stickToLeft = (event.clientX + defaultPanelWidth >= window.innerWidth);

        if (thisKey === showingKey) {
          dispatch(UiReducer.reportShowSettingsPanelFor());
        } else {
          dispatch(UiReducer.reportShowSettingsPanelFor({ key: thisKey, stickToLeft }));
        }

        event.target.closest('button').blur();
      }}
    />
  );
};
