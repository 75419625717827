import {
  DefaultButton, mergeStyleSets, PrimaryButton, Stack, Text, TextField,
} from '@fluentui/react';
import React from 'react';
import StackTokens from '../../shared/StackTokens';
import TextFieldBuffered from '../../shared/TextFieldBuffered';
import GraphUtils from '../../modeller/GraphUtils';
import Popup from '../../shared/Popup';

const componentStyles = mergeStyleSets({
  contentRoot: {
    width: '100%',
    maxHeight: '40vh',
    overflow: 'auto',
  },
  table: {
    width: '100%',
  },
});

export default ({
  nodeStates,
  inputConfig,
  softEvidenceEntryOpen,
  setSoftEvidenceEntryOpen,
  setSoftEvidence,
  getSoftEvidence,
}) => {
  const [editMode, setEditMode] = React.useState(false);
  const [actualDistribution, setActualDistribution] = React.useState({});
  const [editableDistribution, setEditableDistribution] = React.useState({});
  const editableRef = React.useRef();

  React.useEffect(() => {
    if (softEvidenceEntryOpen) {
      setActualDistribution(getSoftEvidence());
    }
  }, [softEvidenceEntryOpen]);

  React.useEffect(() => {
    if (editMode) {
      setEditableDistribution(actualDistribution);
    }
  }, [editMode]);

  const enqueueFocusInput = (index) => {
    setTimeout(() => {
      if (editableRef.current) {
        const el = editableRef.current.querySelectorAll('input[type=number]')[index];
        if (el) {
          el.focus();
        }
      }
    }, 100);
  };

  let component = <></>;

  if (!editMode) {
    component = (
      <table className={componentStyles.table}>
        <tbody>
          {
            (nodeStates || []).map((state, i) => (
              <tr key={state}>
                <th>{state}</th>
                <td>
                  <TextField
                    type="number"
                    placeholder="0.0"
                    readOnly
                    onClick={() => {
                      setEditMode(true);
                      enqueueFocusInput(i);
                    }}
                    onFocus={async () => {
                      setEditMode(true);
                      enqueueFocusInput(i);
                    }}
                    value={actualDistribution[state] || ''}
                  />
                </td>
              </tr>
            ))
           }
        </tbody>
      </table>
    );
  } else {
    component = (
      <Stack vertical tokens={StackTokens.spacing}>
        <table ref={editableRef} className={componentStyles.table}>
          <tbody>
            {
              (nodeStates || []).map((state) => (
                <tr key={state}>
                  <th>{state}</th>
                  <td>
                    <TextFieldBuffered
                      type="number"
                      placeholder="0.0"
                      validateValue={(value) => {
                        let validatedValue;
                        try {
                          validatedValue = GraphUtils.validateInputValue({
                            value,
                            validationRules: { type: 'float', allowEmpty: true, min: 0 },
                          });
                        } catch (error) {
                          throw new Error(error.message);
                        }
                        return validatedValue;
                      }}
                      applyValue={(value) => {
                        const updatedDistribution = { ...editableDistribution };
                        updatedDistribution[state] = value;
                        setEditableDistribution(updatedDistribution);
                      }}
                      actualValue={editableDistribution[state] || ''}
                    />
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </Stack>
    );
  }

  const setIsOpen = () => {
    if (softEvidenceEntryOpen) {
      setEditableDistribution(actualDistribution);
      setEditMode(false);
      setSoftEvidenceEntryOpen(false);
    } else {
      setSoftEvidenceEntryOpen(true);
    }
  };

  return (
    <Popup
      isOpen={softEvidenceEntryOpen}
      setIsOpen={setIsOpen}
      title={`Enter soft evidence for ${inputConfig.label}`}
      showFooter={false}
      showXButton
    >
      <Stack vertical tokens={StackTokens.spacing}>
        <Stack vertical tokens={StackTokens.spacing} style={{ maxWidth: '460px' }}>
          {
          (inputConfig.softEvidenceEntryInstructions || 'Assign likelihoods for different answers for the same question').split('\n').map((line, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Text key={index} block>{line}</Text>
          ))
          }
        </Stack>
        <div className={componentStyles.contentRoot}>
          {component}
        </div>

        <Stack horizontal tokens={StackTokens.spacing} horizontalAlign="end">
          <PrimaryButton
            text="Submit"
            onClick={() => {
              let updatedDistribution = Object.fromEntries(Object.entries(editableDistribution).filter((entry) => Number.parseFloat(entry[1]) > 0));
              setSoftEvidence(updatedDistribution);
              updatedDistribution = updatedDistribution || {};
              setActualDistribution(updatedDistribution);
              setEditableDistribution(updatedDistribution);
              setEditMode(false);
              setSoftEvidenceEntryOpen(false);
            }}
          />
          <DefaultButton
            text="Cancel"
            onClick={() => {
              setIsOpen(false);
            }}
          />
        </Stack>
      </Stack>
    </Popup>
  );
};
