/* eslint-disable max-len */
import {
  Checkbox,
  Dialog, DialogFooter, DialogType, PrimaryButton, Stack,
} from '@fluentui/react';
import React from 'react';
import StackTokens from '../../shared/StackTokens';
import Store from '../../shared/state/Store';
import UiReducer from '../../shared/state/UiReducer';
import TextFieldBuffered from '../../shared/TextFieldBuffered';
import Theme from '../../shared/Theme';
import GraphUtils from '../GraphUtils';
import ModellerDefaults from '../ModellerDefaults';
import ModellerReducer from '../ModellerReducer';

export default () => {
  const [globalState, dispatch] = Store.useStore();
  const model = ModellerReducer.getModel(globalState);
  const uiKey = ModellerDefaults.ComponentKeys.ModellerModelPropertiesPopupKey;

  const hide = () => {
    dispatch(UiReducer.setGenericUiAttribute(uiKey, false));
  };

  if (!model || !UiReducer.getGenericUiAttribute(globalState, uiKey)) {
    return <></>;
  }

  return (
    <div style={{
      position: 'fixed',
      left: '-9999px',
      width: '0px',
      height: '0px',
    }}
    >
      <Dialog
        hidden={!UiReducer.getGenericUiAttribute(globalState, uiKey)}
        onDismiss={hide}
        dialogContentProps={{
          type: DialogType.normal,
          title: 'Model Properties',
          styles: {
            subText: {
              marginBottom: '5px',
            },
          },
        }}
        modalProps={{
          titleAriaId: uiKey,
          isBlocking: false,
          styles: {
            main: {
              maxWidth: 450,
            },
          },
        }}
      >

        <Stack vertical tokens={StackTokens.spacing}>
          <TextFieldBuffered
            label="Maximum number of iterations"
            validateValue={(value) => {
              let validatedValue;
              try {
                validatedValue = GraphUtils.validateInputValue({
                  value,
                  validationRules: { type: 'int', allowEmpty: false, min: 1 },
                });
              } catch (error) {
                throw new Error(error.message);
              }
              return validatedValue;
            }}
            applyValue={(value) => {
              model.settings.iterations = value;
              dispatch(ModellerReducer.updateModel(model));
            }}
            actualValue={model.settings.iterations}
          />

          <TextFieldBuffered
            label="Simulation convergence"
            validateValue={(value) => {
              let validatedValue;
              try {
                validatedValue = GraphUtils.validateInputValue({
                  value,
                  validationRules: { type: 'float', allowEmpty: false, min: 1e-50 },
                });
              } catch (error) {
                throw new Error(error.message);
              }
              return validatedValue;
            }}
            applyValue={(value) => {
              model.settings.convergence = value;
              dispatch(ModellerReducer.updateModel(model));
            }}
            actualValue={model.settings.convergence}
          />

          <TextFieldBuffered
            label="Evidence tolerance (%)"
            validateValue={(value) => {
              let validatedValue;
              try {
                validatedValue = GraphUtils.validateInputValue({
                  value,
                  validationRules: {
                    type: 'float', allowEmpty: false, min: 1e-50, max: 100,
                  },
                });
              } catch (error) {
                throw new Error(error.message);
              }
              return validatedValue;
            }}
            applyValue={(value) => {
              model.settings.tolerance = value;
              dispatch(ModellerReducer.updateModel(model));
            }}
            actualValue={model.settings.tolerance}
          />

          <TextFieldBuffered
            label="Sample size for ranked nodes"
            validateValue={(value) => {
              let validatedValue;
              try {
                validatedValue = GraphUtils.validateInputValue({
                  value,
                  validationRules: { type: 'int', allowEmpty: false, min: 1 },
                });
              } catch (error) {
                throw new Error(error.message);
              }
              return validatedValue;
            }}
            applyValue={(value) => {
              model.settings.sampleSizeRanked = value;
              dispatch(ModellerReducer.updateModel(model));
            }}
            actualValue={model.settings.sampleSizeRanked}
          />

          <Checkbox
            label="Discretize tails"
            checked={model.settings.discreteTails}
            onChange={() => {
              model.settings.discreteTails = !model.settings.discreteTails;
              dispatch(ModellerReducer.updateModel(model));
            }}
          />

        </Stack>

        <DialogFooter>

          <PrimaryButton
            className={Theme.styles.outlineControl}
            onClick={() => {
              hide();
            }}
            text="Close"
          />
        </DialogFooter>
      </Dialog>
    </div>
  );
};
