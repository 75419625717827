import React from 'react';
import { mergeStyleSets, Stack } from '@fluentui/react';
import AppReducer from '../shared/state/AppReducer';
import Store from '../shared/state/Store';
import ConfigReducer from '../shared/state/ConfigReducer';
import Theme from '../shared/Theme';

const styles = mergeStyleSets({
  common: {
    selectors: {
      'h1, h2, h3, h4': {
        'margin-bottom': '0px',
        'padding-bottom': '0px',
      },
    },
  },

  page: {
    alignItems: 'left',
    paddingLeft: '10px',
    paddingRight: '10px',
  },

  header: {
    fontSize: '5vh',
  },
});

export default ({ children }) => {
  const [globalState] = Store.useStore();
  const cloudAppData = AppReducer.getAppData(globalState);
  const webAppData = ConfigReducer.getGeneralConfig(globalState);

  let visibleAppName;
  if (globalState.config?.model) {
    visibleAppName = (cloudAppData && Object.keys(cloudAppData).length > 0) ? `${cloudAppData.name} - Cloud` : `${webAppData.appName} - Local`;
  }

  return (
    <Stack
      vertical
      className={[styles.page, styles.common]}
    >
      <Stack vertical style={{ margin: '0 0 5px 0' }}>
        <header className={[Theme.styles.menuHeader, Theme.styles.noPadding].join(' ')}>Web App Designer</header>
      </Stack>
      <p style={{ margin: '0', padding: '0' }}>{visibleAppName}</p>
      {children}
    </Stack>
  );
};
