import React from 'react';
import {
  mergeStyleSets, Pivot, PivotItem,
} from '@fluentui/react';
import {
  useNavigate, useLocation, useSearchParams, Routes, Route,
} from 'react-router-dom';
import keycloak from '../shared/Keycloak';
import Layout from './Layout';
import Preview from './Preview';
import Design from './configuration/design/Design';
import Publish from './configuration/Publish';
import Library from './configuration/Library';
import File from './configuration/file/File';
import DefaultConfig from './configuration/DefaultConfig';
import ConfigReducer from '../shared/state/ConfigReducer';
import Store from '../shared/state/Store';
import Config from '../shared/Config';
import Theme from '../shared/Theme';
import Env from '../shared/Env';
import User from '../shared/User';
import AppReducer from '../shared/state/AppReducer';
import AppsApi from '../shared/util/AppsApi';
// import HelpSystem from '../shared/help/HelpSystem';
// import devcon from '../dev/printReport.json';
import Routing from '../shared/Routing';
import GettingStarted from './GettingStarted';

export default () => {
  const [globalState, dispatch] = Store.useStore();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const routingRoot = Routing.getTree(globalState);
  const routingTree = routingRoot.home.routes.webAppDesigner;

  const defaultPath = routingTree.routes.gettingStarted.path;

  // React.useEffect(() => {
  //   dispatch(AppReducer.loadApp(devcon));
  //   setTimeout(() => {
  //     // subPath.subItems.design.subItems.configureInputs.subItems.inputGroups.onClick();
  //     // navigate(routingTree.routes.design.routes.configureInputs.routes.inputItems.path, { replace: true });
  //     navigate(routingTree.routes.preview.path, { replace: true });
  //     // navigate(routingTree.routes.design.routes.applicationProperties.routes.dataProcessing.path, { replace: true });
  //   }, 1000);
  // }, []);

  React.useEffect(() => {
    dispatch(ConfigReducer.mapNetworksNodes());
  }, [globalState.config?.model]);

  React.useEffect(() => {
    dispatch(ConfigReducer.mapInputConfig());
  }, [globalState.config?.inputConfig]);

  // React.useEffect(HelpSystem.registerHelpLayer(dispatch, HelpSystem.Catalog.WebAppDesigner), []);

  React.useEffect(() => {
    // For most Web App Designer paths, we require an app being loaded
    if (!AppReducer.isAppLoaded(globalState) && !searchParams.get('app')) {
      // The page is not loaded with `app` get param, which would trigger loading the app
      const currentPathObject = routingRoot.getPathObject(routingRoot.home, location.pathname);
      if (currentPathObject?.requireApp === true || currentPathObject?.requireApp === undefined) {
        // If app is explicitly required or if requirement is not specified (implicitly required)
        navigate(routingTree.path, { replace: true });
        // Navigate to WAPPD root
      }
    }
  }, []);

  React.useEffect(() => {
    (async () => {
      if (Env.KC_ENABLED && !keycloak.authenticated) {
        if (location.pathname !== defaultPath) {
          navigate(defaultPath, { replace: true });
        }
        return;
      }

      if (searchParams.get('app') && !AppReducer.isAppLoaded(globalState)) {
        // App to load is specified and no app is currently loaded
        const response = await AppsApi.getApp(searchParams.get('app'));
        if (response.code === 200) {
          dispatch(AppReducer.loadApp(response.configuration));
          dispatch(AppReducer.setAppData(response));
          return;
        }
      }

      if (location.pathname !== routingTree.path) {
        return;
      }

      if (Config.getApplicationMode() === Config.ApplicationModes.WebAppDesigner) {
        navigate(defaultPath, { replace: true });
      }

      if (Config.getApplicationMode() !== Config.ApplicationModes.WebAppDesigner) {
        const response = await AppsApi.getApps({
          flags: ['QueryOrMode'],
          'app.owner': User.getCurrentUser(),
          'app.contributors[in]': User.getCurrentUser(),
          $group: 'count',
        });
        if (response?.count > 0) {
          if (location.pathname !== routingTree.routes.file.routes.open.path) {
            navigate(routingTree.routes.file.routes.open.path, { replace: true });
          }
        } else if (location.pathname !== defaultPath) {
          navigate(defaultPath, { replace: true });
        }
      }
    })();
  }, [location.pathname]);

  const styles = mergeStyleSets({
    pivot: {
      width: '97vw',
      maxWidth: '97vw',
    },
    pivotItem: {
      paddingTop: '10px',
      paddingLeft: '0px',
    },
    signOutLink: {
      position: 'absolute',
      right: '20px',
    },
  });

  const [disabledPivotProps, setDisabledPivotProps] = React.useState(DefaultConfig.disabledPivotProps);
  React.useEffect(() => {
    if (!AppReducer.isAppLoaded(globalState)) {
      setDisabledPivotProps(DefaultConfig.disabledPivotProps);
    } else {
      setDisabledPivotProps(undefined);
    }
  }, [globalState.config?.model]);

  return (
    <Layout>

      <Pivot
        className={styles.pivot}
        selectedKey={`${routingTree.path}/${location.pathname.slice(routingTree.path.length).split('/')[1]}`}
        overflowBehavior="menu"
        overflowAriaLabel="More"
        onLinkClick={(pivotItem) => {
          if (pivotItem.props.itemKey === 'logOut') {
            keycloak.logout();
            return;
          }
          navigate(pivotItem.props.itemKey);
        }}
        styles={Theme.styleObjects.pivotShiftLeft}
      >
        <PivotItem
          headerText="File"
          className={styles.pivotItem}
          itemKey={routingTree.routes.file.path}
        />

        <PivotItem
          headerText="Design"
          className={styles.pivotItem}
          itemKey={routingTree.routes.design.path}
          headerButtonProps={disabledPivotProps}
        />

        <PivotItem
          headerText="Preview"
          className={styles.pivotItem}
          itemKey={routingTree.routes.preview.path}
          headerButtonProps={disabledPivotProps}
        />

        <PivotItem
          headerText="Publish"
          className={styles.pivotItem}
          itemKey={routingTree.routes.publish.path}
          headerButtonProps={disabledPivotProps}
        />

        <PivotItem
          headerText="Library"
          className={styles.pivotItem}
          itemKey={routingTree.routes.library.path}
        />

        <PivotItem
          headerText="Getting Started"
          className={styles.pivotItem}
          itemKey={routingTree.routes.gettingStarted.path}
        />

        {
          (Config.getApplicationMode() === Config.ApplicationModes.WebAppDesigner && Env.KC_ENABLED) && (
            <PivotItem
              headerText="Log Out"
              className={styles.pivotItem}
              itemKey="logOut"
            />
          )
        }

      </Pivot>

      <Routes>
        <Route path={routingTree.routes.library.getRelativePath(routingTree.path)} element={<Library />} />
        <Route path={`${routingTree.routes.file.getRelativePath(routingTree.path)}/*`} element={<File disabledPivotProps={disabledPivotProps} />} />
        <Route path={`${routingTree.routes.design.getRelativePath(routingTree.path)}/*`} element={<Design />} />
        <Route path={routingTree.routes.preview.getRelativePath(routingTree.path)} element={<Preview />} />
        <Route path={routingTree.routes.publish.getRelativePath(routingTree.path)} element={<Publish />} />
        <Route path={routingTree.routes.gettingStarted.getRelativePath(routingTree.path)} element={<GettingStarted />} />
      </Routes>

    </Layout>
  );
};
