import React, { useEffect } from 'react';
import { Separator, Stack } from '@fluentui/react';
import {
  useLocation, useNavigate, useParams, Routes, Route,
} from 'react-router-dom';
import StackTokens from '../shared/StackTokens';
import Store from '../shared/state/Store';
import UiReducer from '../shared/state/UiReducer';
import AppReducer from '../shared/state/AppReducer';
import AppsApi from '../shared/util/AppsApi';
import Menu, { DefaultNavOption } from './Menu';
import Overview from './Overview';
import EditableText from '../shared/EditableText';
import AccessControl from './AccessControl';
import Publishing from './Publishing';
import Routing from '../shared/Routing';
import User from '../shared/User';
import PlaceholderError from '../shared/PlaceholderError';
import DataProcessing from './data-processing/DataProcessing';
import Archive from '../shared/archive/Archive';

export default () => {
  const [globalState, dispatch] = Store.useStore();
  const navigate = useNavigate();
  const location = useLocation();
  const routingTree = Routing.getTree(globalState).home.routes.cloudAppManager.routes.app;

  const uuid = useParams().id;
  const [error, setError] = React.useState();

  useEffect(() => {
    const stateUuid = UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.CloudAppManagerAppSelected);
    if (stateUuid !== uuid) {
      dispatch(UiReducer.setGenericUiAttribute(UiReducer.Keys.CloudAppManagerAppSelected, uuid));
    }
  }, [globalState.ui[UiReducer.Keys.CloudAppManagerAppSelected]]);

  const cloudApps = UiReducer.getGenericUiAttribute(globalState, UiReducer.ListOptions.cloudManagerAppsList) || [];
  const app = cloudApps.filter((a) => a.uuid === uuid)[0];

  React.useEffect(() => {
    (async () => {
      if (!User.getCurrentUser() || app) {
        return;
      }
      const response = await AppsApi.getApps({ 'app.uuid': uuid, flags: ['OwnerOnly', 'ExcludeConfig'] });
      if (response.code >= 300) {
        setError(true);
        console.log(response.messages);
      } else if (response.length < 1) {
        setError(true);
      } else if (response) {
        dispatch(UiReducer.setGenericUiAttribute(UiReducer.ListOptions.cloudManagerAppsList, response));
      }
    })();
  }, []);

  React.useEffect(() => {
    if (app && location.pathname === routingTree.path.replace(':id', uuid)) {
      // If no subcomponent selected, navigate to default subcomponent
      navigate(DefaultNavOption.replace(':id', uuid), { replace: true });
    }
  }, [app, location.pathname]);

  if (!app || error) {
    return <PlaceholderError message="No such app is available" />;
  }

  return (
    <Stack vertical>
      <EditableText
        actualValue={app.name}
        placeholder="Enter app name"
        validateValue={(value) => {
          const validatedValue = value.trim();
          if (validatedValue === '') {
            throw new Error('Please provide a name');
          }
          return validatedValue;
        }}
        applyValue={async (value) => {
          const jResponse = await AppsApi.saveApp({ name: value }, uuid);
          if (jResponse.code >= 300) {
            const errors = ['Failed to update app name, please try again later'];
            dispatch(UiReducer.uiSetErrors(jResponse.messages ? errors.concat(jResponse.messages) : errors));
            return;
          }
          if (uuid === AppReducer.getAppData(globalState)?.uuid) {
            // Editing currently loaded app
            dispatch(AppReducer.setAppData(jResponse));
          }
          dispatch(UiReducer.setGenericUiListItem(UiReducer.ListOptions.cloudManagerAppsList, jResponse, (a) => a.uuid === uuid));
        }}
        styles={{
          text: {
            fontSize: 'larger',
          },
          root: {
            paddingLeft: '8px',
            paddingBottom: '5px',
          },
          input: {
            maxWidth: '300px',
          },
        }}
      />

      <Stack horizontal tokens={StackTokens.spacing}>
        <Stack vertical>
          <Menu />
        </Stack>
        <Separator vertical />
        <Stack vertical grow>
          <Routes>
            <Route element={<Overview app={app} />} path={routingTree.routes.overview.getRelativePath(routingTree.path)} />
            <Route element={<AccessControl />} path={routingTree.routes.accessControl.getRelativePath(routingTree.path)} />
            <Route element={<Publishing />} path={routingTree.routes.publishing.getRelativePath(routingTree.path)} />
            <Route element={<DataProcessing />} path={routingTree.routes.dataProcessing.getRelativePath(routingTree.path)} />
            {
              app.archivingEnabled && (
                <Route element={<Archive fixedFilters={{ appId: app.uuid }} />} path={routingTree.routes.archive.getRelativePath(routingTree.path)} />
              )
            }
          </Routes>
        </Stack>
      </Stack>
    </Stack>

  );
};
