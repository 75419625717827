import { DefaultButton, mergeStyleSets, Stack } from '@fluentui/react';
import React from 'react';
import IconButton from '../../IconButton';
import StackTokens from '../../StackTokens';
import Store from '../../state/Store';
import Theme from '../../Theme';
import HelpSystem from '../HelpSystem';

const localStyles = mergeStyleSets({
  title: {
    fontWeight: '600',
    fontSize: 'larger',
  },
});

export default ({ children, title }) => {
  const [, dispatch] = Store.useStore();
  const hide = () => {
    HelpSystem.hideModal(dispatch);
  };

  return (
    <Stack vertical tokens={StackTokens.spacing5}>
      <Stack horizontal>
        <Stack horizontal horizontalAlign="start">
          <header className={localStyles.title}>{title}</header>
        </Stack>
        <Stack grow={100} />
        <Stack horizontal horizontalAlign="end">
          <IconButton
            iconName="Cancel"
            tooltip="Close"
            onClick={() => hide()}
            styles={{
              icon: {
                verticalAlign: 'middle',
                lineHeight: '25px',
              },
            }}
          />
        </Stack>
      </Stack>

      <div>
        {children}
      </div>

      <Stack horizontal horizontalAlign="end" className={localStyles.footer}>
        <DefaultButton
          className={Theme.styles.outlineControlButton}
          onClick={() => {
            hide();
          }}
          text="Close"
        />
      </Stack>

    </Stack>
  );
};
