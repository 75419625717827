import {
  Checkbox,
  DefaultButton,
  DetailsList,
  DetailsListLayoutMode,
  Dropdown,
  IconButton,
  Label,
  mergeStyleSets,
  SearchBox,
  SelectionMode,
  Stack,
  Text,
  TextField,
  TooltipHost,
} from '@fluentui/react';
import React from 'react';
import { GripperDotsVerticalIcon } from '@fluentui/react-icons';
import CollapsiblePanel from '../../../../shared/CollapsiblePanel';
import EditableLabel from '../../../../shared/EditableLabel';
// https://www.npmjs.com/package/array.prototype.findindex
import ConfigReducer from '../../../../shared/state/ConfigReducer';
import Store from '../../../../shared/state/Store';
import StackTokens from '../../../../shared/StackTokens';

const styles = mergeStyleSets({

  column1: {
    width: '150px',
    paddingRight: '25px',
  },

  column3: {
    paddingRight: '50px',
  },

  thead: {
    selectors: {
      th: {
        position: '-webkit-sticky',
        // eslint-disable-next-line no-dupe-keys
        position: 'sticky',
        top: '0',
        backgroundColor: 'white',
        'z-index': 9999,
        padding: '5px 0',
      },
    },
  },

  tbody: {
    selectors: {
      'tr:hover': {
        backgroundColor: 'rgb(225, 223, 221)',
      },
      td: {
        verticalAlign: 'top',
      },
    },
  },

  detailList: {
    selectors: {
      '.ms-DetailsHeader': {
        paddingTop: '0',
      },
    },
  },

  detailListGripper: {
    cursor: 'pointer',
    height: '20px !important',
    width: '20px !important',
    marginTop: '6px',
  },

  groupsListDescription: {
    width: '100%',
  },

  sectionLabel: {
    marginTop: '10px !important',
  },

  rolledOverItem: {
    borderTop: '1px solid rgb(0, 120, 212)',
  },

  hidden: {
    display: 'none',
  },

});

export default () => {
  const [globalState, dispatch] = Store.useStore();

  const [networkFilter, setNetworkFilter] = React.useState('');
  const [nodeFilter, setNodeFilter] = React.useState('');
  const [newGroupName, setNewGroupName] = React.useState('');
  const [newGroupNameError, setNewGroupNameError] = React.useState('');

  const createGroup = () => {
    if (!newGroupName) {
      return;
    }
    const name = newGroupName.trim();
    if (name === '') {
      return;
    }

    const inputConfig = ConfigReducer.getInputConfig(globalState);

    const existingGroup = inputConfig.find((group) => group.groupName === name);
    if (existingGroup) {
      setNewGroupNameError('Input group with such name already exists');
      return;
    }

    const updatedConfig = [...inputConfig];
    updatedConfig.push({
      groupName: name,
      items: [],
    });

    dispatch(ConfigReducer.setInputConfig(updatedConfig));

    setNewGroupName('');
    setNewGroupNameError('');
  };

  const mapGroupOptions = () => ConfigReducer.getInputConfig(globalState).map((group) => ({ key: group.groupName, text: group.groupName }));

  const groupColumns = [
    {
      key: 'column0', fieldName: 'gripper', name: '', minWidth: 20, maxWidth: 20,
    },
    {
      key: 'column1', fieldName: 'delete', name: '', minWidth: 20, maxWidth: 20,
    },
    {
      key: 'column2', fieldName: 'name', name: 'Group Name', minWidth: 300, maxWidth: 300, isResizable: true,
    },
    {
      key: 'column3', fieldName: 'collapsible', name: 'Collapsible Options', minWidth: 200, maxWidth: 200,
    },
    {
      key: 'column4', fieldName: 'description', name: 'Description', minWidth: 500, maxWidth: 500, multiline: true, isResizable: true,
    },
  ];

  const compileGroupItems = () => {
    const inputConfig = ConfigReducer.getInputConfig(globalState);
    return (inputConfig.map((group, gIndex) => ({
      key: group.groupName,
      gripper: (
        <TooltipHost
          content="Drag to reorder"
        >
          <GripperDotsVerticalIcon className={styles.detailListGripper} />
        </TooltipHost>
      ),
      name: (
        <div>
          <EditableLabel
            initialValue={group.groupName}
            getValue={() => group.groupName}
            setValue={(value) => {
              const text = value.trim();

              if (text === '') {
                return { success: false, error: 'Please provide a name' };
              }

              if (text === group.groupName) {
              // Did not rename, do nothing
                return { success: true };
              }

              const existingGroup = inputConfig.find((grp) => grp.groupName === text);
              if (existingGroup) {
                return { success: false, error: `Input Group "${text}" already exists` };
              }

              const updatedConfig = [...inputConfig];
              updatedConfig[gIndex].groupName = text;
              dispatch(ConfigReducer.setInputConfig(updatedConfig));
              return { success: true };
            }}
          />
        </div>
      ),
      collapsible: (
        <div style={{ paddingTop: '6px' }}>
          <Stack horizontal tokens={StackTokens.horizontalSpacing}>
            <Checkbox
              label="Collapsible"
              checked={group.collapsible}
              onChange={() => {
                const updatedConfig = [...inputConfig];
                const oldChecked = updatedConfig[gIndex].collapsible;
                updatedConfig[gIndex].collapsible = !oldChecked;
                if (oldChecked) {
                  updatedConfig[gIndex].collapsed = false;
                }
                dispatch(ConfigReducer.setInputConfig(updatedConfig));
              }}
            />

            <Checkbox
              label="Collapsed"
              checked={group.collapsed}
              disabled={!group.collapsible}
              onChange={() => {
                const updatedConfig = [...inputConfig];
                updatedConfig[gIndex].collapsed = !updatedConfig[gIndex].collapsed;
                dispatch(ConfigReducer.setInputConfig(updatedConfig));
              }}
            />
          </Stack>
        </div>
      ),
      description: (
        <div className={styles.groupsListDescription}>
          <EditableLabel
            placeholder="Click to add description"
            initialValue={group.description}
            getValue={() => group.description}
            setValue={(value) => {
              const text = value.trim();
              const updatedConfig = [...inputConfig];
              updatedConfig[gIndex].description = text;
              dispatch(ConfigReducer.setInputConfig(updatedConfig));
            }}
          />
        </div>
      ),
      delete: (
        <div>
          <TooltipHost
            content={`Delete: ${group.groupName}`}
          >
            <IconButton
              className={(inputConfig.length < 2) ? styles.hidden : ''}
              disabled={inputConfig.length < 2}
              iconProps={{
                iconName: 'Delete',
              }}
              onClick={() => {
                if (inputConfig.length < 2) {
                  return false;
                }

                const updatedConfig = [...inputConfig];
                let newIndex = 0;
                if (newIndex === gIndex) {
                  newIndex += 1;
                }

                updatedConfig[newIndex].items.push(...updatedConfig[gIndex].items);
                updatedConfig.splice(gIndex, 1);

                dispatch(ConfigReducer.setInputConfig(updatedConfig));

                return true;
              }}
            />
          </TooltipHost>
        </div>
      ),
    }))
    );
  };
  const [groupOptions, setGroupOptions] = React.useState([]);

  const nodeListColumns = [
    {
      key: 'column0', fieldName: 'gripper', name: '', minWidth: 20, maxWidth: 20,
    },
    {
      key: 'column1', fieldName: 'delete', name: '', minWidth: 20, maxWidth: 20,
    },
    {
      key: 'column2', fieldName: 'group', name: 'Input Group', minWidth: 250, isResizable: true,
    },
    {
      key: 'column3', fieldName: 'label', name: 'Node', minWidth: 300, isResizable: true,
    },
    {
      key: 'column4', fieldName: 'network', name: 'Network ID', isResizable: true,
    },
  ];

  const compileNodeListItems = () => {
    const inputConfig = ConfigReducer.getInputConfig(globalState);
    return (inputConfig.map((group, gIndex) => group.items
      .map((gItem, iIndex) => ({
        key: `${gItem.label}${gItem.node}`,
        gripper: (
          <TooltipHost
            content="Drag to reorder"
          >
            <GripperDotsVerticalIcon className={styles.detailListGripper} />
          </TooltipHost>
        ),
        delete: (
          <div>
            <TooltipHost
              content={`Delete from inputs: ${gItem.label}`}
            >
              <IconButton
                iconProps={{
                  iconName: 'Delete',
                }}
                onClick={() => {
                // eslint-disable-next-line
                if (!window.confirm(`Remove '${gItem.label}' from inputs?\nIts individual input customization will be lost.`)) {
                    return;
                  }
                  const updatedConfig = [...inputConfig];
                  updatedConfig[gIndex].items.splice(iIndex, 1);
                  dispatch(ConfigReducer.setInputConfig(updatedConfig));
                }}
              />
            </TooltipHost>
          </div>
        ),
        group: (
          <Dropdown
            selectedKey={group.groupName}
            options={groupOptions}
            onChange={(e, item) => {
              const updatedConfig = [...inputConfig];
              const newGName = item.key;
              const newGIndex = updatedConfig.findIndex((grp) => grp.groupName === newGName);
              updatedConfig[newGIndex].items.push(gItem);
              updatedConfig[gIndex].items.splice(iIndex, 1);
              dispatch(ConfigReducer.setInputConfig(updatedConfig));
            }}
          />
        ),
        network: gItem.network,
        label: `${gItem.label} [${gItem.node}]`,
        node: gItem.node,
        groupName: group.groupName,
      }))).flat()
    );
  };
  const [groupListItems, setGroupListItems] = React.useState([]);
  const [nodeListItems, setNodeListItems] = React.useState([]);

  React.useEffect(() => {
    setGroupOptions(mapGroupOptions());
    setGroupListItems(compileGroupItems());
    setNodeListItems(compileNodeListItems());
  }, []);

  React.useEffect(() => {
    setGroupOptions(mapGroupOptions());
    setGroupListItems(compileGroupItems());
    setNodeListItems(compileNodeListItems());
  }, [globalState.config?.inputConfig]);

  React.useEffect(() => {
    setNodeListItems(compileNodeListItems());
  }, [globalState.config?.inputConfig, networkFilter, nodeFilter, groupOptions]);

  /* <-- Input Group list drag and drop */
  const [draggedGroupListItem, setDraggedGroupListItem] = React.useState(undefined);
  const groupDragDropEvents = {
    canDrop: (/* dropContext, dragContext */) => true,
    canDrag: (/* item */) => true,
    onDragEnter: (/* item, event */) => styles.rolledOverItem,
    onDragLeave: (/* item, event */) => {},
    onDrop: (rolledOverItem/* , event */) => {
      if (draggedGroupListItem) {
        const inputConfig = ConfigReducer.getInputConfig(globalState);
        const draggedGroup = inputConfig.find((grp) => (grp.groupName === draggedGroupListItem.key));
        const updatedConfig = inputConfig.filter((grp) => grp !== draggedGroup);
        const insertIndex = updatedConfig.findIndex((grp) => (grp.groupName === rolledOverItem.key));
        updatedConfig.splice(insertIndex, 0, draggedGroup);
        dispatch(ConfigReducer.setInputConfig(updatedConfig));
      }
    },
    onDragStart: (item/* , itemIndex, selectedItems, event */) => {
      setDraggedGroupListItem(item);
    },
    onDragEnd: (/* item, event */) => {
      setDraggedGroupListItem(undefined);
    },
  };
  /* Input Group list drag and drop  --> */

  /* <-- Node list drag and drop */
  const [draggedNodeListItem, setDraggedNodeListItem] = React.useState(undefined);
  const nodeDragDropEvents = {
    canDrop: (/* dropContext, dragContext */) => true,
    canDrag: (/* item */) => true,
    onDragEnter: (rolledOverItem /* event */) => {
      if (rolledOverItem.groupName === draggedNodeListItem.groupName) {
        return styles.rolledOverItem;
      }
      return '';
    },
    onDragLeave: (/* item, event */) => {},
    onDrop: (rolledOverItem /* event */) => {
      if (!draggedNodeListItem || rolledOverItem.groupName !== draggedNodeListItem.groupName) {
        return;
      }
      const inputConfig = ConfigReducer.getInputConfig(globalState);
      const updatedConfig = [...inputConfig];
      const gIndex = updatedConfig.findIndex((grp) => (grp.groupName === rolledOverItem.groupName));
      const insertIndex = updatedConfig[gIndex].items.findIndex((gItem) => gItem.node === rolledOverItem.node && gItem.network === rolledOverItem.network);
      const draggedIndex = updatedConfig[gIndex].items.findIndex((gItem) => gItem.node === draggedNodeListItem.node && gItem.network === draggedNodeListItem.network);
      const draggedQuestion = updatedConfig[gIndex].items[draggedIndex];

      updatedConfig[gIndex].items[draggedIndex] = undefined;
      updatedConfig[gIndex].items.splice(insertIndex, 0, draggedQuestion);
      updatedConfig[gIndex].items.splice(updatedConfig[gIndex].items.indexOf(undefined), 1);

      dispatch(ConfigReducer.setInputConfig(updatedConfig));
    },
    onDragStart: (item/* , itemIndex, selectedItems, event */) => {
      setDraggedNodeListItem(item);
    },
    onDragEnd: (/* item, event */) => {
      setDraggedNodeListItem(undefined);
    },
  };
    /* Node list drag and drop  --> */

  const nodeFilterTrimmed = nodeFilter.trim();
  const networkFilterTrimmed = networkFilter.trim();

  return (
    <Stack vertical>
      <Text style={{ marginTop: '5px' }} block>You can arrange sets of inputs into groups here. This is useful to improve the user interface especially when there are many inputs.</Text>
      <Text block>First, create additional input groups reorder them as necessary.</Text>
      <Text block>Then, assign individual inputs to relevant groups and reorder as necessary.</Text>
      <CollapsiblePanel
        collapsible
        title="Create and manage Input Groups"
      >
        <Stack vertical>
          <Label>Create Input Groups</Label>
          <Stack horizontal tokens={StackTokens.horizontalSpacing}>
            <TextField
              style={{ width: '250px' }}
              placeholder="Enter new Input Group name"
              value={newGroupName}
              errorMessage={newGroupNameError}
              onChange={(event, value) => {
                setNewGroupName(value);
                setNewGroupNameError('');
              }}
              onKeyDown={(event) => {
                if (event.keyCode === 13) {
                  createGroup();
                } else if (event.keyCode === 27) {
                  setNewGroupName('');
                  setNewGroupNameError('');
                  event.target.blur();
                }
              }}
            />
            <DefaultButton
              text="Create"
              disabled={newGroupName.trim() === ''}
              onClick={() => {
                createGroup();
              }}
            />
          </Stack>

          <Label className={styles.sectionLabel}>Manage Input Groups</Label>
          <Text>Click on Input Group name or description to edit.</Text>
          <Text>Drag and drop to reorder Input Groups.</Text>
          <Text>Deleting an Input Group will move all assigned Inputs to another Group.</Text>
          <Text>Duplicate or empty Input Group names are not allowed.</Text>
          <Text>Input Groups can be collapsible and optionally collapsed by default.</Text>

          <DetailsList
            items={groupListItems}
            columns={groupColumns}
            selectionMode={SelectionMode.none}
            className={styles.detailList}
            dragDropEvents={groupDragDropEvents}
          />
        </Stack>
      </CollapsiblePanel>

      <CollapsiblePanel
        collapsible
        title="Assign nodes to Input Groups"
      >
        <Stack vertical tokens={StackTokens.verticalSpacing}>
          <div
            style={{
              width: '250px',
            }}
          >
            <Stack horizontal tokens={StackTokens.spacing}>
              <SearchBox
                placeholder="Filter by node name or ID"
                type="search"
                iconProps={{
                  iconName: 'Filter',
                }}
                onChange={(ev, newValue) => setNodeFilter(newValue ? newValue.toLowerCase() : '')}
                onSearch={(newValue) => setNodeFilter(newValue ? newValue.toLowerCase() : '')}
                value={nodeFilter}
              />

              <SearchBox
                placeholder="Filter by network ID"
                type="search"
                iconProps={{
                  iconName: 'Filter',
                }}
                onChange={(ev, newValue) => setNetworkFilter(newValue ? newValue.toLowerCase() : '')}
                onSearch={(newValue) => setNetworkFilter(newValue ? newValue.toLowerCase() : '')}
                value={networkFilter}
              />
            </Stack>
          </div>

          <Stack vertical>
            <Text>Drag and drop to reorder items within an Input Group.</Text>
            <Text>Assigning to a different Input Group will put the input at the end of the Group.</Text>
            <Text>Deleting an Input will remove it from Inputs and its individual input customization will be lost.</Text>
          </Stack>

          <DetailsList
            layoutMode={DetailsListLayoutMode.fixedColumns}
            items={
              nodeListItems.filter((gItem) => {
                if (!nodeFilterTrimmed && !networkFilterTrimmed) {
                  return true;
                }

                if (networkFilterTrimmed && `${gItem.network}`.toLowerCase().indexOf(networkFilterTrimmed) < 0) {
                  return false;
                }

                if (nodeFilterTrimmed && `${gItem.label}${gItem.node}`.toLowerCase().indexOf(nodeFilterTrimmed) < 0) {
                  return false;
                }

                return true;
              })
            }
            columns={nodeListColumns}
            selectionMode={SelectionMode.none}
            className={styles.detailList}
            dragDropEvents={nodeDragDropEvents}
          />

        </Stack>
      </CollapsiblePanel>
    </Stack>
  );
};
