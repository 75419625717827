import React from 'react';
import { mergeStyleSets } from '@fluentui/react';
import ChartDefaults from '../../shared/ChartDefaults';
import ContinuousChart from './ContinuousChart';
import BarChart from './BarChart';
import Utils from '../../shared/Utils';
import Store from '../../shared/state/Store';
import DataReducer from '../../shared/state/DataReducer';
import ConfigReducer from '../../shared/state/ConfigReducer';
import VerticalBarChart from './VerticalBarChart';
import UiReducer from '../../shared/state/UiReducer';

const componentStyles = mergeStyleSets({
  svgChart: {
    selectors: {
      '.png-charts &': {
        display: 'none !important',
      },
    },
  },

  pngChart: {
    selectors: {
      '.svg-charts &': {
        display: 'none !important',
      },
    },
  },
});

export default ({
  config, actionRequested,
}) => {
  const [globalState] = Store.useStore();
  const chartRef = React.useRef();
  const { ChartTypes } = ChartDefaults;

  const key = Utils.createNetworkNodeKey(config.network, config.node);
  const baselineData = DataReducer.getCachedResultsForDataSetAndKey(globalState, DataReducer.DefaultCaseNames.BASELINE, key)?.resultValues;
  const userData = DataReducer.getCachedResultsForDataSetAndKey(globalState, DataReducer.DefaultCaseNames.USER, key)?.resultValues;
  const generalConfig = ConfigReducer.getGeneralConfig(globalState);

  React.useEffect(() => {
    if (!actionRequested?.key) {
      return;
    }

    if (actionRequested.key === 'chartSaveSvg') {
      const svg = Utils.getSvg(chartRef);
      Utils.saveSvg(`${config.node}.svg`, svg);
    }

    if (actionRequested.key === 'chartSavePng') {
      const svg = Utils.getSvg(chartRef);
      Utils.saveSvgAsPng(`${config.node}.png`, svg);
    }

    if (actionRequested.key === 'chartCopy') {
      const svg = Utils.getSvg(chartRef);
      Utils.copyImageToClipboard(svg);
    }
  }, [actionRequested]);

  const chartImageRef = React.useRef();
  React.useEffect(() => {
    setTimeout(() => {
      if (!chartRef.current || !chartImageRef.current) {
        return;
      }
      const svg = Utils.getSvg(chartRef);
      if (!svg) {
        return;
      }
      if (chartImageRef.current) {
        Utils.svgToPngOperation(svg, async (canvas) => {
          if (chartImageRef.current) {
            const imgURI = canvas.toDataURL('image/png');
            chartImageRef.current.src = imgURI;
            // console.log(chartImageRef.current, imgURI);
          }
        });
      }
    }, 50);
  }, [
    UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.webAppShowPrintReport),
  ]);

  return (
    <>
      {
        ([ChartTypes.Area, ChartTypes.Histogram, ChartTypes.Line, ChartTypes.Scatter, ChartTypes.ScatterLine].includes(config.chartType)) && (
        <ContinuousChart
          baselineData={baselineData}
          userData={userData}
          config={config}
          generalConfig={generalConfig}
          chartRef={chartRef}
          className={componentStyles.svgChart}
        />
        )
      }

      {
        ([ChartTypes.VerticalBarChart].includes(config.chartType)) && (
        <VerticalBarChart
          baselineData={baselineData}
          userData={userData}
          config={config}
          generalConfig={generalConfig}
          chartRef={chartRef}
          className={componentStyles.svgChart}
        />
        )
      }

      {
        ([ChartTypes.BarChart].includes(config.chartType)) && (
        <BarChart
          baselineData={baselineData}
          userData={userData}
          config={config}
          generalConfig={generalConfig}
          chartRef={chartRef}
          className={componentStyles.svgChart}
        />
        )
      }

      <img className={componentStyles.pngChart} ref={chartImageRef} src="" alt="Chart" />
    </>
  );
};

export const CardInfo = {
  key: 'chart',
  text: 'Chart',
  actions: [
    {
      key: 'chartSavePng',
      text: 'Save as PNG',
      icon: 'Download',
    },
    {
      key: 'chartSaveSvg',
      text: 'Save as SVG',
      icon: 'Download',
    },
    {
      key: 'chartCopy',
      text: 'Copy to Clipboard',
      icon: 'Copy',
    },
  ],
};
