import GeneralApi from './GeneralApi';

const { makeRequest } = GeneralApi;

const getRecords = async (params) => makeRequest({
  path: '/public/v1/archiving/records',
  method: 'GET',
  params,
});

const getRecord = async (uuid, params) => makeRequest({
  path: `/public/v1/archiving/record/${uuid}`,
  method: 'GET',
  params,
});


export default {
  getRecords,
  getRecord,
};
