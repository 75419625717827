import structuredClone from 'core-js/stable/structured-clone';
import {
  DefaultButton, mergeStyleSets, Stack, Text,
} from '@fluentui/react';
import React from 'react';
import { Resizable } from 'react-resizable';
import StackTokens from '../StackTokens';
import 'react-resizable/css/styles.css';
import ChartDefaults from '../ChartDefaults';
import Theme from '../Theme';
import Utils from '../Utils';
import Popup from '../Popup';
import TableData from './TableData';

const DEV = false;

const componentStyles = mergeStyleSets({
  column: {
    width: 'fit-content',
    selectors: {
      '.cellContainer': {
        border: `1px solid ${Theme.Colors.Blackish}`,
      },

      '.virtual-row:not(:last-of-type)>.cellContainer': {
        borderBottom: 'none',
      },
    },
  },
  cellContainer: {
    width: 'fit-content',
  },
  labelContainer: {
    minWidth: '50px',
    padding: '0 5px',
    borderRight: 'none !important',
    fontWeight: 600,
    whiteSpace: 'nowrap',
  },
  cell: {
    height: '100%',
    width: '100%',
    selectors: DEV ? {
      ':hover': {
        boxShadow: 'inset 2px 2px 2px red',
      },
    } : {},
  },
  valueLabel: {
    margin: '0',
    padding: '0 0 0 5px',
    lineHeight: '25px',
    // background: 'lime',
    width: '100%',
    overflowX: 'clip',
    whiteSpace: 'nowrap',
  },

});

export default ({
  tableData, updateTableData, columnIndex, cancel,
}) => {
  const [isShowing, setShowing] = React.useState(true);
  // console.log(tableData);

  const rowTextBuffer = 25;
  const rowHeight = 25;
  const numRows = tableData.rows.length;
  const totalHeight = numRows * rowHeight;
  const valueToHeight = (value) => value * totalHeight + rowTextBuffer;
  const heightToValue = (height) => Math.min(1, Math.max(0, (height - rowTextBuffer) / totalHeight));

  const [columnData, setColumnData] = React.useState(TableData.normalize(tableData.rows.map((row) => row[columnIndex])));
  const [rowHeights, setRowHeights] = React.useState(columnData.map((value) => valueToHeight(value)));
  const [rowLabels, setRowLabels] = React.useState([...columnData]);

  React.useEffect(() => {
    const newColumnData = TableData.normalize(tableData.rows.map((row) => row[columnIndex]));
    setColumnData(newColumnData);
    setRowHeights(newColumnData.map((value) => valueToHeight(value)));
    setRowLabels([...newColumnData]);
  }, [tableData.rows]);

  // console.log('check', columnData, rowHeights, rowLabels);

  // console.log('totalHeight', totalHeight);

  const [snapShotHeights, setSnapshotHeights] = React.useState([...rowHeights]);

  if (!isShowing) {
    return (
      <></>
    );
  }

  return (
    <>
      <Popup
        isOpen={isShowing}
        setIsOpen={(isOpen) => {
          setShowing(isOpen);
          if (typeof cancel === 'function') {
            cancel();
          }
        }}
        title="Manual Adjustment"
        showXButton
        styles={{
          main: {
            minWidth: 'unset',
          },
        }}
      >
        <Stack vertical tokens={StackTokens.spacing}>
          <div>
            <Text style={{ maxWidth: '205px' }} block>Drag the boundary between two cells to adjust the probabilities.</Text>
          </div>
          <Stack
            className={componentStyles.column}
          >
            {columnData.map((value, rowIndex) => {
              const lastRow = rowIndex === columnData.length - 1;
              return (
                // eslint-disable-next-line react/no-array-index-key
                <Stack horizontal className="virtual-row" key={`${value}.${rowIndex}`}>
                  <Stack.Item
                    className={['cellContainer', componentStyles.labelContainer].join(' ')}
                    grow={3}
                  >
                    {tableData.rowHeaders[rowIndex]}
                  </Stack.Item>
                  <Stack.Item
                    className={[componentStyles.cellContainer, 'cellContainer'].join(' ')}
                    style={{
                      background: DEV ? ChartDefaults.Colors[rowIndex] : '',
                    }}
                  >
                    {/* <Resizable
            axis="y"
            width={50}
            height={tempHeight[rowIndex]}
            minConstraints={[0, 0]}
            maxConstraints={[Infinity, 100]}
            resizeHandles={['s', 'n']}
            onResize={(e, data) => {
              const updatedTempHeight = [...tempHeight];
              updatedTempHeight[rowIndex] = data.size;
              setTempHeight(updatedTempHeight);
            }}
            onResizeStop={(e, data) => {
              // console.log('stop', data);
            }}
          >
            <div>
              {value}
            </div>
          </Resizable> */}
                    {/* <ResizableBox
              className={componentStyles.cell}

              resizable={lastRow ? 'false' : 'true'}
              axis="y"
              width={50}
              height={rowHeights[rowIndex]}
              minConstraints={[0, rowTextBuffer]}
              maxConstraints={[Infinity, snapShotHeights[rowIndex] + (lastRow ? 0 : snapShotHeights[rowIndex + 1] - rowTextBuffer)]}
              resizeHandles={lastRow ? [] : ['s']}

              onResizeStart={(e, data) => {
                // console.log('start', data);
                setSnapshotHeights([...rowHeights]);
                // console.log('starting heights', [...rowHeights]);
              }}

              onResize={(e, data) => {
                // console.log('on resize');
                //   const updatedTempHeight = [...tempHeight];
                //   updatedTempHeight[rowIndex] = data.size;
                //   setTempHeight(updatedTempHeight);
                const deltaHeight = data.size.height - snapShotHeights[rowIndex];
                // console.log('r delta height', deltaHeight);
                const updatedRowHeights = [...snapShotHeights];
                // console.log(rowIndex + 1, updatedRowHeights[rowIndex + 1], '->', updatedRowHeights[rowIndex + 1] - deltaHeight);
                updatedRowHeights[rowIndex] += deltaHeight;
                updatedRowHeights[rowIndex + 1] -= deltaHeight;
                // console.log('update', updatedRowHeights);
                setRowHeights(updatedRowHeights);

                const updatedLabels = [...rowLabels];
                updatedLabels[rowIndex] = heightToValue(updatedRowHeights[rowIndex]);
                updatedLabels[rowIndex + 1] = heightToValue(updatedRowHeights[rowIndex + 1]);
                setRowLabels(updatedLabels);
              }}

              onResizeStop={(e, data) => {
                // console.log('stop', data);
                const deltaHeight = data.size.height - snapShotHeights[rowIndex];
                // console.log('final delta height', deltaHeight);
                // const updatedRowHeights = [...rowHeights];
                // updatedRowHeights[rowIndex] = data.size.height;
                // // console.log('final heights', updatedRowHeights);
                // setRowHeights(updatedRowHeights);
                setColumnData(rowHeights.map((height) => heightToValue(height)));
              }}
            >
              <p className={componentStyles.valueLabel}>
                {Utils.roundFormat(rowLabels[rowIndex], 4)}
              </p>
            </ResizableBox> */}

                    <Resizable
                      className={componentStyles.cell}

                      resizable={lastRow ? 'false' : 'true'}
                      axis="y"
                      width={50}
                      height={rowHeights[rowIndex]}
                      minConstraints={[0, rowTextBuffer]}
                      maxConstraints={[Infinity, snapShotHeights[rowIndex] + (lastRow ? 0 : snapShotHeights[rowIndex + 1] - rowTextBuffer)]}
                      resizeHandles={lastRow ? [] : ['s']}

                      onResizeStart={(e, data) => {
                        // console.log('start', data);
                        setSnapshotHeights([...rowHeights]);
                        // console.log('starting heights', [...rowHeights]);
                      }}

                      onResize={(e, data) => {
                        // console.log('on resize');
                        //   const updatedTempHeight = [...tempHeight];
                        //   updatedTempHeight[rowIndex] = data.size;
                        //   setTempHeight(updatedTempHeight);
                        const deltaHeight = data.size.height - snapShotHeights[rowIndex];
                        // console.log('r delta height', deltaHeight);
                        const updatedRowHeights = [...snapShotHeights];
                        // console.log(rowIndex + 1, updatedRowHeights[rowIndex + 1], '->', updatedRowHeights[rowIndex + 1] - deltaHeight);
                        updatedRowHeights[rowIndex] += deltaHeight;
                        updatedRowHeights[rowIndex + 1] -= deltaHeight;
                        // console.log('update', updatedRowHeights);
                        setRowHeights(updatedRowHeights);

                        const updatedLabels = [...rowLabels];
                        updatedLabels[rowIndex] = heightToValue(updatedRowHeights[rowIndex]);
                        updatedLabels[rowIndex + 1] = heightToValue(updatedRowHeights[rowIndex + 1]);
                        setRowLabels(updatedLabels);
                      }}

                      onResizeStop={(e, data) => {
                        // console.log('stop', data);
                        const deltaHeight = data.size.height - snapShotHeights[rowIndex];
                        // console.log('final delta height', deltaHeight);
                        // const updatedRowHeights = [...rowHeights];
                        // updatedRowHeights[rowIndex] = data.size.height;
                        // // console.log('final heights', updatedRowHeights);
                        // setRowHeights(updatedRowHeights);
                        setColumnData(rowHeights.map((height) => heightToValue(height)));
                      }}
                    >
                      <div
                        style={{
                          width: '50px',
                          height: `${rowHeights[rowIndex]}px`,
                        }}
                      >
                        <p className={componentStyles.valueLabel}>
                          {Utils.roundFormat(rowLabels[rowIndex], 4)}
                        </p>
                      </div>
                    </Resizable>

                  </Stack.Item>
                </Stack>
              );
            })}
          </Stack>
          <Stack horizontal tokens={StackTokens.spacing}>
            <Stack horizontal horizontalAlign="start">
              <DefaultButton
                className={Theme.styles.outlineControlButton}
                onClick={() => {
                  const updatedData = structuredClone(tableData);
                  columnData.forEach((value, rowIndex) => {
                    updatedData.rows[rowIndex][columnIndex] = value;
                  });
                  updateTableData(updatedData);
                }}
                text="Apply"
              />
            </Stack>
            <Stack horizontal horizontalAlign="end">
              <DefaultButton
                className={Theme.styles.outlineControlButton}
                onClick={() => {
                  setShowing(false);
                  if (typeof cancel === 'function') {
                    cancel();
                  }
                }}
                text="Cancel"
              />
            </Stack>
          </Stack>
        </Stack>
      </Popup>
    </>
  );
};
