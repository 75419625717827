import { mergeStyleSets } from '@fluentui/react';

const Colors = {
  Blackish: '#323232',
  Blue: 'rgb(0, 120, 212)',
  Gray: '#e4e4e4',
  LightGray: '#edebe9',
  ChartAxis: '#212529',
  LightYellow: '#fcf7b6',
};

const styles = mergeStyleSets({

  displayHidden: {
    display: 'none !important',
  },

  header: {
    fontWeight: '600',
    fontSize: 'larger',
  },

  menuHeader: {
    padding: '0 0 5px 8px',
    fontWeight: '400',
    fontSize: 'larger',
  },

  noPadding: {
    padding: '0',
  },

  noBottomMargin: {
    marginBottom: '0',
  },

  menuSubHeader: {
    padding: '0 0 0 20px',
    fontWeight: '400',
  },

  sectionHeader: {
    fontWeight: '400',
    fontSize: 'medium',
    padding: '10px 0 0 0',
  },

  table: {
    selectors: {
      th: {
        fontWeight: 400,
      },
      td: {
        fontWeight: 300,
      },
      'th, td': {
        color: Colors.Blackish,
        padding: '0 5px',
      },
    },
  },

  tableNoPadding: {
    selectors: {
      'tr>first-child, tr>td:first-child, tr>th:first-child': {
        paddingLeft: '0',
      },
      'tr>th:last-child, tr>td:last-child, tr>th:last-child': {
        paddingRight: '0',
      },
    },
  },

  tableBorderRows: { // incomplete
    selectors: {
      'td,th': {
        borderBottom: '1px solid ',
      },
    },
  },

  inputItem: {
    padding: '0',
    margin: '0',
    selectors: {
      label: {
        padding: '0 !important',
        margin: '0 !important',
      },
    },
  },

  tableRowCheckbox: {
    top: '6px',
  },

  tableText: {
    selectors: {
      'td, th': {
        color: 'black',
        fontSize: '14px',
      },
    },
  },

  tableRowHighlight: {
    selectors: {
      'tr:hover': {
        background: Colors.LightGray,
      },
    },
  },

  linkButton: {
    height: 'auto',
    color: Colors.Blue,
    border: 'none',
    margin: '0',
    padding: '0',

    selectors: {
      span: {
        margin: '0',
        padding: '0',
      },
      ':hover': {
        textDecoration: 'underline',
        outline: 'none',
      },
    },
  },

  capitalize: {
    textTransform: 'capitalize',
  },

  iconButton: {
    width: 'auto',
    height: 'auto',
    color: Colors.Blackish,
    background: 'none',
    border: 'none',
    margin: '0',
    padding: '0',
    outline: 'none !important',
    minWidth: 'auto',
    selectors: {
      ':hover': {
        background: 'none',
      },
      ':hover i': {
        color: Colors.Blue,
      },
      i: {
        fontSize: '16px',
        margin: 0,
      },
    },
  },

  iconButtonClearMinimalistic: {
    border: 'none',
    padding: 'none',
    minWidth: 'auto',
    background: 'none !important',
  },

  commandButton: {
    selectors: {
      ':hover:enabled': {
        background: Colors.Gray,
      },
    },
  },

  outlineControl: {
    selectors: {
      button: {
        outline: 'none !important',
      },
    },
  },

  messageBar: {
    selectors: {
      button: {
        outline: 'none !important',
      },
      '.ms-MessageBar-icon': {
        marginLeft: '5px',
      },
    },
  },

  outlineControlButton: {
    outline: 'none !important',
  },

  outlineControlListButton: {
    selectors: {
      'li>button': {
        outline: 'none !important',
      },
    },
  },

  bold: {
    fontWeight: 600,
  },
  boldLighter: {
    fontWeight: 400,
  },

  majorSection: {
    padding: '0 0 0 8px',
    margin: '0',
  },

  codeBlock: {
    fontFamily: 'monospace',
    background: '#f6f6f6',
    padding: '10px',
    margin: '0',
  },

  list: {
    marginBottom: '0',
  },

  noPageBreak: {
    pageBreakInside: 'avoid',
    breakInside: 'avoid',
  },

  noPrint: {
    selectors: {
      '@media print': {
        display: 'none !important',
      },
    },
  },

});

const styleObjects = {
  separatorSmallMargin: {
    root: { fontSize: '1px' },
  },

  iconInText: {
    root: {
      verticalAlign: 'middle',
      fontSize: 'x-large',
      margin: '0 5px 0 5px',
    },
  },

  pivotShiftLeft: {
    root: {
      position: 'relative',
      left: '-8px',
    },
  },
};

export default {
  Colors,
  styleObjects,
  styles,
};
