import UiReducer from '../shared/state/UiReducer';

const getSelectedAppId = (globalState) => UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.CloudAppManagerAppSelected);

const getSelectedCloudAppSummary = (globalState) => {
  const appId = getSelectedAppId(globalState);
  const appList = UiReducer.getGenericUiAttribute(globalState, UiReducer.ListOptions.cloudManagerAppsList) || [];
  if (appList?.length === 0) {
    return null;
  }
  const appData = appList.filter((a) => a.uuid === appId);
  if (appData?.length === 0) {
    return null;
  }
  return appData[0];
};

const getSelectedCloudAppString = (globalState) => {
  const appData = getSelectedCloudAppSummary(globalState);
  if (!appData) {
    return 'Unsaved app';
  }

  const { name, uuid } = appData;
  return `${name} (${uuid})`;
};

export default {
  getSelectedAppId,
  getSelectedCloudAppSummary,
  getSelectedCloudAppString,
};
