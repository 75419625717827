import { Icon, mergeStyleSets, Stack } from '@fluentui/react';
import React from 'react';

const componentStyles = mergeStyleSets({
  dataCheckIcon: {
    color: 'green',
    fontSize: '12px',
  },
});

export default ({ record, loadingPrecondition }) => {
  if (!loadingPrecondition) {
    return (
      <></>
    );
  }

  if (record.hasInputs && record.hasResults) {
    return (
      <Icon
        iconName="SkypeCheck"
        className={componentStyles.dataCheckIcon}
      />
    );
  }
  return (
    <Stack vertical>
      {!record.hasResults && <span>Missing outputs</span>}
      {!record.hasInputs && <span>Missing inputs</span>}
    </Stack>
  );
};
