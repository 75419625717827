import React from 'react';
import { Separator, Stack } from '@fluentui/react';
import {
  useNavigate, useLocation, Routes, Route,
} from 'react-router-dom';
import StackTokens from '../shared/StackTokens';
import Store from '../shared/state/Store';
import Theme from '../shared/Theme';
import Menu, { DefaultNavOption } from './Menu';
import ReservedDomains from './settings/ReservedDomains';
import Routing from '../shared/Routing';
import Archive from '../shared/archive/Archive';
import Accounts from './accounts/Accounts';

export default () => {
  const [globalState] = Store.useStore();
  const navigate = useNavigate();
  const location = useLocation();
  const routingTree = Routing.getTree(globalState).home.routes.administration;

  React.useEffect(() => {
    if (location.pathname === routingTree.path) {
      navigate(DefaultNavOption, { replace: true });
    }
  }, []);

  return (
    <Stack vertical>
      <header className={Theme.styles.menuHeader}>Portal Administration</header>
      <Stack horizontal tokens={StackTokens.spacing}>
        <Stack vertical>
          <Menu />
        </Stack>
        <Separator vertical />
        <Stack vertical grow>
          <Routes>
            {/* <Route element={<ReservedDomains />} path="" />
            <Route element={<ReservedDomains />} path={routingTree.routes.settings.getRelativePath(routingTree.path)} /> */}
            <Route element={<ReservedDomains />} path={routingTree.routes.settings.routes.reservedDomains.getRelativePath(routingTree.path)} />
            <Route element={<Archive extraFields />} path={routingTree.routes.archive.getRelativePath(routingTree.path)} />
            <Route element={<Accounts />} path={`${routingTree.routes.accounts.getRelativePath(routingTree.path)}/*`} />
          </Routes>
        </Stack>
      </Stack>
    </Stack>

  );
};
