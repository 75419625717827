import React from 'react';
import {
  BarChart, Bar, XAxis, YAxis, ResponsiveContainer,
} from 'recharts';
import ChartDefaults from '../../shared/ChartDefaults';
import Utils from '../../shared/Utils';

const labelFormatter = (value) => `${value}%`;

const formatData = (baselineData, userData, decimalPlaces) => {
  // Determine which dataset will drive the map
  const array = baselineData || userData;
  if (!array || array.length === 0) {
    return [];
  }

  return array.map((result, i) => {
    const dataPoint = {
      name: result.label,
    };

    if (baselineData) {
      dataPoint.baseline = Utils.roundFormat(baselineData[i].value * 100, decimalPlaces, ChartDefaults.decimalPlaces);
    }

    if (userData) {
      dataPoint.user = Utils.roundFormat(userData[i].value * 100, decimalPlaces, ChartDefaults.decimalPlaces);
    }

    return dataPoint;
  });
};

export default ({
  baselineData, userData, config, generalConfig, chartRef, className,
}) => {
  const chartData = formatData(baselineData, userData, (config.decimalPlaces !== undefined) ? config.decimalPlaces : 2);

  const heightCss = config.height || `${chartData.length * 80}px`;

  const margins = {};
  ['Left', 'Right', 'Top', 'Bottom'].forEach((p) => {
    const paddingKey = `padding${p}`;
    if (config[paddingKey]) {
      margins[p.toLowerCase()] = parseInt(config[paddingKey], 10);
    }
    // const marginKey2 = `margin${p}`;
    // if (config[marginKey2]) {
    //   margins[p.toLowerCase()] = parseInt(config[marginKey2], 10);
    // }
  });

  const [paddingBottomAuto, setPaddingBottomAuto] = React.useState(0);
  const [paddingLeftAuto, setPaddingLeftAuto] = React.useState(undefined);
  const [paddingRightAuto, setPaddingRightAuto] = React.useState(undefined);

  const adjustMargins = (svg) => {
    if (!svg) {
      return;
    }

    const canvasLeft = svg.getBoundingClientRect().x;
    const yAxis = svg.getElementsByClassName('recharts-yAxis')[0];
    const yAxisLeft = yAxis.getBoundingClientRect().x;
    const yAxisClipped = canvasLeft - yAxisLeft;
    if (yAxisClipped > 0) {
      setPaddingLeftAuto(yAxisClipped);
    }

    const canvasBottom = svg.getBoundingClientRect().y + svg.getBoundingClientRect().height;
    const xAxis = svg.getElementsByClassName('recharts-xAxis')[0];
    const xAxisBottom = xAxis.getBoundingClientRect().y + xAxis.getBoundingClientRect().height;
    const xAxisClipped = xAxisBottom - canvasBottom;
    if (xAxisClipped > 0) {
      setPaddingBottomAuto(paddingBottomAuto + xAxisClipped);
    }

    const canvasRight = svg.getBoundingClientRect().x + svg.getBoundingClientRect().width;
    const currentPaddingRight = margins.right || 0;
    let deltaRight = 0;
    [].forEach.call(svg.getElementsByClassName('recharts-bar'), ((el) => {
      const elRight = el.getBoundingClientRect().x + el.getBoundingClientRect().width;
      const deltaElRight = currentPaddingRight + elRight - canvasRight;
      deltaRight = Math.max(deltaRight, deltaElRight);
    }));

    if (deltaRight < 0) {
      deltaRight = undefined;
    }
    setPaddingRightAuto(deltaRight);
  };

  React.useEffect(() => {
    setTimeout(() => {
      adjustMargins(Utils.getSvg(chartRef));
    }, 10);
  }, [baselineData, userData, config]);

  margins.bottom = margins.bottom !== undefined ? margins.bottom : paddingBottomAuto;
  margins.left = margins.left !== undefined ? margins.left : paddingLeftAuto;
  margins.right = margins.right !== undefined ? margins.right : paddingRightAuto;

  return (
    <>
      <div
        style={{
          width: '100%',
          height: heightCss,
          background: 'white',
        }}
        ref={chartRef}
        className={className}
      >
        <ResponsiveContainer>
          <BarChart
            margin={margins}
            data={chartData}
            layout="vertical"
            style={{
              fontFamily: '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
            }}
          >
            <XAxis type="number" domain={[0, 100]} />
            <YAxis type="category" dataKey="name" interval={0} />
            {baselineData && (
              <Bar
                isAnimationActive={false}
                dataKey="baseline"
                fill={generalConfig.colorBaseline || ChartDefaults.ColorBaseline}
                label={{ position: 'right', formatter: labelFormatter, style: { fontWeight: 'bold' } }}
              />
            )}
            {userData && (
              <Bar
                isAnimationActive={false}
                dataKey="user"
                fill={generalConfig.colorUser || ChartDefaults.ColorUser}
                label={{ position: 'right', formatter: labelFormatter, style: { fontWeight: 'bold' } }}
              />
            )}
          </BarChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};
