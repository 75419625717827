import React from 'react';
import {
  mergeStyleSets, Panel, PanelType,
} from '@fluentui/react';
import CaseManager from './CaseManager';
import Store from '../../shared/state/Store';
import UiReducer from '../../shared/state/UiReducer';

const styles = mergeStyleSets({
  linkButton: {
    'margin-left': '0',
    'padding-left': '0',
    color: 'rgb(0, 120, 212)',
    fontSize: 'smaller',
    marginTop: '0px !important',
    selectors: {
      span: {
        margin: '0',
      },
    },
  },
  panel: {
    left: '0',
    background: 'red !important',
  },
});

const panelStyles = {
  header: { paddingLeft: '10px' },
  content: { padding: '0px 10px' },
  main: { left: '0px' },
};

export default () => {
  const [globalState, dispatch] = Store.useStore();
  const setIsOpen = (isOpen, event) => {
    if (!isOpen && event && event.composedPath && Array.isArray(event.composedPath())) {
      let clickedOnModal = false;
      let clickedOnButton = false;
      event.composedPath().forEach((selector) => {
        const classes = `${selector.className}`;
        if (classes.match('ms-Modal')) {
          clickedOnModal = true;
        }
        if (classes.match('ms-Button')) {
          clickedOnButton = true;
        }
      });
      if (clickedOnModal && clickedOnButton) {
        // Do not close panel if there was an error message that the user dismissed
        return;
      }
    }
    dispatch(UiReducer.setGenericUiAttribute(UiReducer.Keys.isCaseManagerPanelOpen, isOpen));
  };
  return (
    <>
      <Panel
        headerText="Manage Cases"
        isOpen={UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.isCaseManagerPanelOpen)}
        onDismiss={(e) => {
          setIsOpen(false, e);
        }}
        closeButtonAriaLabel="Close"
        type={PanelType.smallFixedNear}
        isHiddenOnDismiss
        isLightDismiss={false}
        overlayProps={{
          allowTouchBodyScroll: true,
          className: styles.overlay,
        }}
        styles={panelStyles}
      >
        <CaseManager />
      </Panel>
    </>
  );
};
