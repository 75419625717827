import { mergeStyleSets, Stack } from '@fluentui/react';
import React from 'react';
import LinkButton from './LinkButton';
import StackTokens from './StackTokens';

const componentStyles = mergeStyleSets({
  activePage: {
    selectors: {
      button: {
        fontWeight: '500 !important',
        color: 'black !important',
      },
    },
  },
});

export default ({
  makeRequest, filters, totalCount,
}) => {
  const itemsPerPage = 20;
  const jump = 5;
  const pageCount = Math.ceil(totalCount / itemsPerPage);
  const pages = [];

  const goToPage = (page) => {
    const updatedFilters = { ...filters };
    updatedFilters.page = page;
    makeRequest(updatedFilters);
  };

  if (pageCount < 2) {
    return <></>;
  }

  for (let i = -2; i <= 2; i += 1) {
    const nPage = filters.page + i;
    if (nPage < 0 || nPage > pageCount) {
      continue;
    }
    pages.push(nPage);
  }

  return (
    <Stack horizontal tokens={StackTokens.spacing}>

      {(filters.page > 1) && (
      <>
        <LinkButton
          text="<<"
          onClick={() => {
            goToPage(Math.max(1, filters.page - jump));
          }}
        />

        <LinkButton
          text="<"
          onClick={() => {
            goToPage(Math.max(1, filters.page - 1));
          }}
        />
      </>
      )}

      {(pages[0] > 2) && (
        <>
          <LinkButton
            text="1"
            onClick={() => {
              goToPage(1);
            }}
          />
          <p>...</p>
        </>
      )}

      {
        pages.map((nPage) => {
          if (nPage <= 0 || nPage > pageCount) {
            return <React.Fragment key={nPage} />;
          }

          return (
            <LinkButton
              key={nPage}
              text={nPage}
              className={(nPage === filters.page) ? componentStyles.activePage : ''}
              onClick={() => {
                goToPage(nPage);
              }}
            />
          );
        })
      }

      {(pages[pages.length - 1] < pageCount - 2) && (
      <>
        <p>...</p>
        <LinkButton
          text={pageCount}
          onClick={() => {
            goToPage(pageCount);
          }}
        />
      </>
      )}

      {(filters.page < pageCount) && (
      <>
        <LinkButton
          text=">"
          onClick={() => {
            goToPage(Math.min(pageCount, filters.page + 1));
          }}
        />

        <LinkButton
          text=">>"
          onClick={() => {
            goToPage(Math.min(pageCount, filters.page + jump));
          }}
        />
      </>
      )}

    </Stack>
  );
};
