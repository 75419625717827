import { MessageBar, MessageBarType } from '@fluentui/react';
import React from 'react';
import Theme from './Theme';

export default ({
  text, styles, isMultiline, messageBarType = MessageBarType.info, children,
  onDismiss, actions,
}) => {
  const [open, setOpen] = React.useState(true);

  let onDismissFunction = () => {
    setOpen(false);
    if (typeof onDismiss === 'function') {
      onDismiss();
    }
  };
  if (onDismiss === null) {
    onDismissFunction = null;
  }

  if (!open) {
    return <></>;
  }
  return (
    <div style={styles?.container}>
      <MessageBar
        isMultiline={isMultiline}
        messageBarType={messageBarType}
        styles={{ ...styles }}
        dismissButtonAriaLabel="Close"
        onDismiss={onDismissFunction}
        className={Theme.styles.messageBar}
        actions={actions}
      >
        {text}
        {children}
      </MessageBar>
    </div>
  );
};
