import {
  Checkbox,
  mergeStyleSets,
  SearchBox,
  Stack,
  Text,
} from '@fluentui/react';
import React from 'react';
import Store from '../../../shared/state/Store';
import ConfigReducer from '../../../shared/state/ConfigReducer';
import Utils from '../../../shared/Utils';

const styles = mergeStyleSets({

  column1: {
    width: '75px',
  },

  column3: {
    paddingRight: '50px',
  },

  thead: {
    selectors: {
      th: {
        position: '-webkit-sticky',
        // eslint-disable-next-line no-dupe-keys
        position: 'sticky',
        top: '0',
        backgroundColor: 'white',
        'z-index': 9999,
        padding: '5px 0',
      },
    },
  },

  tbody: {
    selectors: {
      'tr:hover': {
        backgroundColor: 'rgb(225, 223, 221)',
      },
      td: {
        verticalAlign: 'top',
      },
    },
  },

});

export default () => {
  const [globalState, dispatch] = Store.useStore();

  const [nodeFilter, setNodeFilter] = React.useState('');
  const [networkFilter, setNetworkFilter] = React.useState('');
  const [items, setItems] = React.useState([]);

  if (Object.keys(ConfigReducer.getNetworksNodesMap(globalState)) > 0) {
    return <>The model appears to be empty</>;
  }

  const filterItems = () => {
    const nodeFilterTrimmed = nodeFilter.trim();
    const networkFilterTrimmed = networkFilter.trim();
    const fItems = [];
    (globalState?.config?.model?.networks || [])
      .filter((network) => !networkFilterTrimmed || (networkFilterTrimmed && `${network.name}${network.id}`.toLowerCase().indexOf(networkFilterTrimmed) >= 0))
      .forEach((network) => {
        network.nodes
          .filter((node) => !nodeFilterTrimmed || (nodeFilterTrimmed && `${node.name}${node.id}`.toLowerCase().indexOf(nodeFilterTrimmed) >= 0))
          .forEach((node) => {
            const key = Utils.createNetworkNodeKey(network.id, node.id);
            fItems.push(
              <tr key={key}>
                <td className={styles.column1}>
                  <Checkbox
                    checked={!!ConfigReducer.getInputConfigMap(globalState)[key]}
                    onChange={() => {
                      dispatch(ConfigReducer.toggleInputItem(network.id, node.id));
                    }}
                  />
                </td>
                <td className={styles.column1}>
                  <Checkbox
                    checked={!!ConfigReducer.getOutputConfigMap(globalState)[key]}
                    onChange={() => {
                      dispatch(ConfigReducer.toggleOutputItem(network.id, node.id));
                    }}
                  />
                </td>

                {
                  globalState?.model?.networks?.length > 1
                    ? <td className={styles.column3}>{`${network.name} [${network.id}]`}</td>
                    : false
              }

                <td>{`${node.name} [${node.id}]`}</td>
                <td>{`${network.name} [${network.id}]`}</td>
              </tr>,
            );
          });
      });
    setItems(fItems);
  };

  React.useEffect(() => {
    filterItems();
  }, [nodeFilter, networkFilter, globalState.inputConfigMap, globalState.outputConfigMap]);

  return (
    <Stack vertical style={{ marginTop: '10px' }}>
      <Text block>Select whichever nodes you wish to display as inputs and output nodes.</Text>
      <Text block>Input nodes are those for which you allow the end user to enter a value.</Text>
      <Text block>Output nodes are those whose resulting probability values will be displayed to the end user.</Text>
      <Text block>A node can be both an input and an output node.</Text>

      <div>
        <table>

          <thead className={styles.thead}>
            <tr>
              <th>&nbsp;</th>
              <th>&nbsp;</th>
              <th style={{ paddingRight: '5px' }}>
                <SearchBox
                  placeholder="Filter by node name or ID"
                  type="search"
                  iconProps={{
                    iconName: 'Filter',
                  }}
                  onChange={(ev, newValue) => setNodeFilter(newValue ? newValue.toLowerCase() : '')}
                  onSearch={(newValue) => setNodeFilter(newValue ? newValue.toLowerCase() : '')}
                  value={nodeFilter}
                />
              </th>
              <th>
                <SearchBox
                  placeholder="Filter by network name or ID"
                  type="search"
                  iconProps={{
                    iconName: 'Filter',
                  }}
                  onChange={(ev, newValue) => setNetworkFilter(newValue ? newValue.toLowerCase() : '')}
                  onSearch={(newValue) => setNetworkFilter(newValue ? newValue.toLowerCase() : '')}
                  value={networkFilter}
                />
              </th>
            </tr>
            <tr>
              <th className={styles.column1}>Input</th>
              <th className={styles.column1}>Output</th>

              {
                  globalState?.model?.networks?.length > 1
                    ? <th>Network</th>
                    : false
              }

              <th>Node</th>
              <th>Network</th>
            </tr>
          </thead>

          <tbody className={styles.tbody}>
            {
            items
          }
          </tbody>

        </table>
      </div>
    </Stack>

  );
};
