import {
  DefaultButton, mergeStyleSets, PrimaryButton, Stack, TextField,
} from '@fluentui/react';
import React from 'react';
import Store from '../../../shared/state/Store';
import ModellerReducer from '../../ModellerReducer';
import TextFieldBuffered from '../../../shared/TextFieldBuffered';
import GraphUtils from '../../GraphUtils';
import CytoReducer from '../../ModelPreview/cytoscape/CytoReducer';
import StackTokens from '../../../shared/StackTokens';

const componentStyles = mergeStyleSets({
  stateLabel: {
    fontWeight: 300,
    paddingRight: '5px',
  },
  fontSize: {
    fontSize: 'smaller',
  },
  button: {
    height: 'fitContent',
    padding: '5px',
    minWidth: 'auto',
  },
});

export default ({
  datasetId, networkId, nodeConfig, tabIndexValue,
}) => {
  const [globalState, dispatch] = Store.useStore();
  const [editMode, setEditMode] = React.useState(false);
  const [actualDistribution, setActualDistribution] = React.useState({});
  const [editableDistribution, setEditableDistribution] = React.useState({});
  const editableRef = React.useRef();

  React.useEffect(() => {
    const aD = {};
    const observation = ModellerReducer.getMappedObservation(globalState, datasetId, networkId, nodeConfig.id);
    (observation?.entries || []).forEach((entry) => {
      aD[entry.value] = entry.weight;
    });
    setActualDistribution(aD);
  }, []);

  React.useEffect(() => {
    if (editMode) {
      setEditableDistribution(actualDistribution);
    }
  }, [editMode]);

  const enqueueFocusInput = (index) => {
    setTimeout(() => {
      if (editableRef.current) {
        const el = editableRef.current.querySelectorAll('input[type=number]')[index];
        if (el) {
          el.focus();
        }
      }
    }, 100);
  };

  let component = <></>;

  if (!editMode) {
    component = (
      <table>
        <tbody>
          {
            (nodeConfig?.configuration?.states || []).map((state, i) => (
              <tr key={state}>
                <th className={componentStyles.stateLabel}>{state}</th>
                <td>
                  <TextField
                    inputClassName={componentStyles.fontSize}
                    type="number"
                    placeholder="0.0"
                    readOnly
                    onClick={() => {
                      setEditMode(true);
                      enqueueFocusInput(i);
                    }}
                    onFocus={async () => {
                      setEditMode(true);
                      enqueueFocusInput(i);
                    }}
                    value={actualDistribution[state] || ''}
                    tabIndex={tabIndexValue}
                  />
                </td>
              </tr>
            ))
           }
        </tbody>
      </table>
    );
  } else {
    component = (
      <Stack vertical tokens={StackTokens.spacing}>
        <table ref={editableRef}>
          <tbody>
            {
              (nodeConfig?.configuration?.states || []).map((state) => (
                <tr key={state}>
                  <th className={componentStyles.stateLabel}>{state}</th>
                  <td>
                    <TextFieldBuffered
                      inputClassName={componentStyles.fontSize}
                      type="number"
                      placeholder="0.0"
                      validateValue={(value) => {
                        let validatedValue;
                        try {
                          validatedValue = GraphUtils.validateInputValue({
                            value,
                            validationRules: { type: 'float', allowEmpty: true, min: 0 },
                          });
                        } catch (error) {
                          throw new Error(error.message);
                        }
                        return validatedValue;
                      }}
                      applyValue={(value) => {
                        const updatedDistribution = { ...editableDistribution };
                        updatedDistribution[state] = value;
                        setEditableDistribution(updatedDistribution);
                      }}
                      actualValue={editableDistribution[state] || ''}
                      tabIndex={tabIndexValue}
                    />
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
        <Stack horizontal tokens={StackTokens.spacing}>
          <PrimaryButton
            className={[componentStyles.fontSize, componentStyles.button].join(' ')}
            text="Apply"
            onClick={() => {
              let updatedDistribution = Object.fromEntries(Object.entries(editableDistribution).filter((entry) => Number.parseFloat(entry[1]) > 0));
              if (Object.keys(updatedDistribution).length === 0) {
                updatedDistribution = undefined;
              }
              dispatch(ModellerReducer.setObservationDistribution(datasetId, networkId, nodeConfig.id, updatedDistribution));
              dispatch(CytoReducer.setRepaintRequested());
              updatedDistribution = updatedDistribution || {};
              setActualDistribution(updatedDistribution);
              setEditableDistribution(updatedDistribution);
              setEditMode(false);
            }}
            tabIndex={tabIndexValue}
          />
          <DefaultButton
            className={[componentStyles.fontSize, componentStyles.button].join(' ')}
            text="Cancel"
            onClick={() => {
              setEditableDistribution(actualDistribution);
              setEditMode(false);
            }}
            tabIndex={tabIndexValue}
          />
        </Stack>
      </Stack>
    );
  }

  return (
    <div className={componentStyles.fontSize}>
      {component}
    </div>
  );
};
