/* eslint-disable max-len */
import {
  DefaultButton,
  Dialog, DialogFooter, DialogType, mergeStyleSets, PrimaryButton, Stack,
  Text,
} from '@fluentui/react';
import React from 'react';
import StackTokens from '../../shared/StackTokens';
import Store from '../../shared/state/Store';
import UiReducer from '../../shared/state/UiReducer';
import TextFieldBuffered from '../../shared/TextFieldBuffered';
import Theme from '../../shared/Theme';
import GraphUtils from '../GraphUtils';
import ModellerDefaults from '../ModellerDefaults';
import ModellerReducer from '../ModellerReducer';
import Utils from '../../shared/Utils';
import CytoReducer from '../ModelPreview/cytoscape/CytoReducer';

const componentStyles = mergeStyleSets({
  tableContainer: {
    maxHeight: '40vh',
    overflowY: 'auto',
  },
});

export default () => {
  const [globalState, dispatch] = Store.useStore();
  const uiKey = ModellerDefaults.ComponentKeys.ModellerDataEntryPopupKey;
  const [dialogData, setDialogData] = React.useState(undefined);
  const [formData, setFormData] = React.useState({});

  React.useEffect(() => {
    const data = UiReducer.getGenericUiAttribute(globalState, uiKey) || {};
    setDialogData(data);
    const observation = ModellerReducer.getMappedObservation(globalState, data.datasetId, data.networkId, data.nodeId, undefined);
    const observationMap = {};
    if (observation) {
      observation.entries.forEach((entry) => {
        observationMap[entry.value] = entry.weight;
      });
    }
    setFormData(observationMap);
  }, [UiReducer.getGenericUiAttribute(globalState, uiKey)]);

  if (dialogData?.type !== 'soft') {
    return <></>;
  }

  const hide = () => {
    dispatch(UiReducer.setGenericUiAttribute(uiKey, false));
  };

  return (
    <div style={{
      position: 'fixed',
      left: '-9999px',
      width: '0px',
      height: '0px',
    }}
    >
      <Dialog
        hidden={!UiReducer.getGenericUiAttribute(globalState, uiKey)}
        onDismiss={hide}
        dialogContentProps={{
          type: DialogType.normal,
          title: `Enter data for case: ${dialogData?.datasetId || ''}`,
          styles: {
            subText: {
              marginBottom: '5px',
            },
          },
        }}
        modalProps={{
          titleAriaId: uiKey,
          isBlocking: false,
          styles: {
            main: {
              maxWidth: 450,
            },
          },
        }}
      >

        <Stack vertical tokens={StackTokens.spacing}>
          <Text block>
            {`Enter soft evidence for node "${Utils.createFriendlyReference(dialogData.nodeId, dialogData.nodeName)}" in network "${Utils.createFriendlyReference(dialogData.networkId, dialogData.networkName)}"`}
          </Text>

          <div className={componentStyles.tableContainer}>
            <table>
              <tbody>
                {(dialogData?.nodeData?.states || []).map((state) => (
                  <tr key={state}>
                    <th>{state}</th>
                    <td>
                      <TextFieldBuffered
                        placeholder="0.0"
                        validateValue={(value) => {
                          let validatedValue;
                          try {
                            validatedValue = GraphUtils.validateInputValue({
                              value,
                              validationRules: { type: 'float', allowEmpty: true, min: 0 },
                            });
                          } catch (error) {
                            throw new Error(error.message);
                          }
                          return validatedValue;
                        }}
                        applyValue={(value) => {
                          const updatedFormData = { ...formData };
                          updatedFormData[state] = value;
                          setFormData(updatedFormData);
                        }}
                        actualValue={formData[state] || ''}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

        </Stack>

        <DialogFooter>
          <PrimaryButton
            onClick={() => {
              dispatch(ModellerReducer.setObservationDistribution(dialogData.datasetId, dialogData.networkId, dialogData.nodeId, formData));
              dispatch(CytoReducer.setRepaintRequested());
              hide();
            }}
            text="Submit"
          />
          <DefaultButton
            className={Theme.styles.outlineControl}
            onClick={() => {
              hide();
            }}
            text="Cancel"
          />
        </DialogFooter>
      </Dialog>
    </div>
  );
};
