/* eslint-disable max-len */
import {
  DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton, TextField,
} from '@fluentui/react';
import React from 'react';
import Utils from '../../shared/Utils';
import Store from '../../shared/state/Store';
import UiReducer from '../../shared/state/UiReducer';

export default () => {
  const [globalState, dispatch] = Store.useStore();

  const hidden = !UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.CloudAppManagerAppInviteDialogOpen);
  const copyButtonRef = React.useRef();
  const inputRef = React.useRef();
  const [copied, setCopied] = React.useState(false);
  const [text, setText] = React.useState('');
  const [recipient, setRecipient] = React.useState('');
  const [subject, setSubject] = React.useState('');

  const hide = () => {
    dispatch(UiReducer.setGenericUiAttribute(UiReducer.Keys.CloudAppManagerAppInviteDialogOpen, false));
    setCopied(false);
  };

  const openNewMail = (email, body) => {
    window.open(`mailto:${email}?subject=${subject}&body=${encodeURIComponent(body)}`, '_blank');
  };

  React.useEffect(() => {
    if (hidden) {
      return;
    }
    const { app, user, role } = { ...UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.CloudAppManagerAppInviteDialogOpen) };
    setRecipient(user);

    let compiledText = `
        Greetings!

        I am pleased to invite you to ${(role === 'user') ? 'check out' : 'contribute to'} my web app "${app.name}" ${app.published ? `published at https://${app.domain}` : ''}
    `;

    if (role === 'user') {
      compiledText += `
        To gain access, simply open the app and log in under the email ${user} (or register and verify your email address if not yet registered).
        `;
    } else if (role === 'contributor') {
      compiledText += `
        To contribute to this app, please go to agena.ai portal at https://portal.agena.ai and log in under the email ${user} (or register and verify your email address if not yet registered).
        
        Then, open Web App Designer and go to File → Open. You will see this app as "${app.name}" in the list of all web apps that you own or contribute to.
        
        Any changes you save will automatically sync to my current version of the app.
        `;
    }

    compiledText = compiledText.trim().replaceAll(/\n[ \t]+/g, '\n');
    setText(compiledText);
    setSubject(`App shared with you: ${app.name}`);
  }, [hidden]);

  if (hidden) {
    return <></>;
  }

  return (
    <div style={{
      position: 'fixed',
      left: '-9999px',
      width: '0px',
      height: '0px',
    }}
    >
      <Dialog
        hidden={!UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.CloudAppManagerAppInviteDialogOpen)}
        onDismiss={hide}
        dialogContentProps={{
          type: DialogType.normal,
          title: 'App Invitation',
          closeButtonAriaLabel: 'Cancel',
          subText: 'Edit app invitation:',
          styles: {
            subText: {
              marginBottom: '5px',
            },
          },
        }}
        modalProps={{
          titleAriaId: UiReducer.Keys.CloudAppManagerAppInviteDialogOpen,
          subtitleAriaId: 'Edit app invitation',
          isBlocking: true,
          isModeless: false,
        }}
        minWidth="475px"
      >

        <TextField
          multiline
          rows={20}
          ref={inputRef}
          value={text}
          onChange={(event, value) => {
            setText(value);
            setCopied(false);
          }}
        />

        <DialogFooter>
          <PrimaryButton
            componentRef={copyButtonRef}
            iconProps={{
              iconName: copied ? 'CheckMark' : 'Copy',
            }}
            onClick={() => {
              Utils.copyToClipboard(text);
              if (copyButtonRef.current) {
                copyButtonRef.current.focus();
              }
              setCopied(true);
            }}
            onBlur={() => {
              setCopied(false);
            }}
            text="Copy Invitation"
            styles={{
              root: {
                top: '3px',
                outline: 'none',
              },
            }}
          />

          <PrimaryButton
            iconProps={{
              iconName: 'EditMail',
            }}
            onClick={() => {
              openNewMail(recipient, text);
            }}
            text="Compose Email"
            styles={{
              root: {
                top: '3px',
              },
            }}
          />

          <DefaultButton
            onClick={() => {
              hide();
            }}
            text="Close"
          />
        </DialogFooter>
      </Dialog>
    </div>
  );
};
