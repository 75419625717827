import React from 'react';
import {
  Stack,
} from '@fluentui/react';
import ChartDefaults from '../../shared/ChartDefaults';
import OutputData from './OutputData';
import CardStyles from '../../shared/CardStyles';
import Utils from '../../shared/Utils';
import CardSummaryStatisticsTable from '../../shared/CardSummaryStatisticsTable';

export default ({ config, resultCache, actionRequested }) => {
  const key = Utils.createNetworkNodeKey(config.network, config.node);
  const data = OutputData.compileSummaryStatistics(resultCache, key, config);
  const containerRef = React.useRef();

  React.useEffect(() => {
    if (!actionRequested?.key) {
      return;
    }

    if (actionRequested.key === 'summaryStatisticsCopy') {
      if (containerRef.current) {
        Utils.copyContentsToClipboard(containerRef.current);
      }
    }
  }, [actionRequested]);

  return (
    <div
      className={[CardStyles.tableView, 'output-table'].join(' ')}
      style={{
        height: config.height || `${ChartDefaults.CHART_HEIGHT}px`,
      }}
      ref={containerRef}
    >
      <Stack horizontal tokens={{ childrenGap: '10 20' }} wrap styles={{ root: { width: '99%' } }}>
        {data.map((dsData) => <CardSummaryStatisticsTable dsData={dsData} key={dsData.name} />)}
      </Stack>
    </div>
  );
};

export const CardInfo = {
  key: 'summaryStatistics',
  text: 'Summary Statistics',
  actions: [
    {
      key: 'summaryStatisticsCopy',
      text: 'Copy all tables to clipboard',
      icon: 'Copy',
    },
  ],
};
