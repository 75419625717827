import React from 'react';
import Store from '../shared/state/Store';
import IconButton from '../shared/IconButton';
import HelpSystem from '../shared/help/HelpSystem';

export default () => {
  const [globalState, dispatch] = Store.useStore();

  const context = HelpSystem.getContext(globalState);

  if (!context) {
    return <></>;
  }

  return (
    <span style={{
      verticalAlign: 'middle',
      height: '20px',
      marginTop: '10px',
      marginLeft: '5px',
    }}
    >
      <IconButton
        iconName="UnknownSolid"
        tooltip={context || 'Show Help'}
        onClick={() => {
          HelpSystem.showModal(dispatch);
        }}
        styles={{
          icon: {
            fontSize: '20px !important',
            height: '20px !important',
            lineHeight: '20px !important',
          },
        }}
      />
    </span>
  );
};
