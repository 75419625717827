/* eslint-disable prefer-destructuring */
import ChartDefaults from '../../shared/ChartDefaults';
import Utils from '../../shared/Utils';


const mergeDataSets = ({
  dataSet1, dataSet2,
  dataSet1Name = 'baseline', dataSet2Name = 'user',
  decimalPlaces = ChartDefaults.decimalPlaces,
}) => {
  const round = (value) => Utils.roundFormat(parseFloat(value) * 100, decimalPlaces);

  const ds1 = [...dataSet1];
  const ds2 = [...dataSet2];
  let i1 = 0;
  let i2 = 0;
  const mergedDataSet = [];
  while (ds1[i1] || ds2[i2]) {
    let lower1;
    let upper1;
    let range1;
    let value1;

    let lower2;
    let upper2;
    let range2;
    let value2;

    if (ds1[i1]) {
      const isInterval = ds1[i1].label.indexOf(' - ') >= 0;
      const parts = ds1[i1].label.split(' - ');
      lower1 = parts[0];
      if (isInterval) {
        upper1 = parts[1];
      } else {
        upper1 = lower1;
      }
      range1 = upper1 - lower1;
      value1 = ds1[i1].value;
    }

    if (ds1[i1]?.label === ds2[i2]?.label) {
      // Same boundaries
      mergedDataSet.push({
        name: `${round(lower1)} - ${round(upper1)}`,
        [dataSet1Name]: round(ds1[i1].value),
        [dataSet2Name]: round(ds2[i2].value),
      });
      i1 += 1;
      i2 += 1;
      continue;
    }

    // Parsing ds2 label here because we may avoid doing this if the above condition returned
    if (ds2[i2]) {
      const isInterval = ds2[i2].label.indexOf(' - ') >= 0;
      const parts = ds2[i2].label.split(' - ');
      lower2 = parts[0];
      if (isInterval) {
        upper2 = parts[1];
      } else {
        upper2 = lower2;
      }
      range2 = upper2 - lower2;
      value2 = ds2[i2].value;
    }

    if (ds1[i1] && !ds2[i2]) {
      // More states left in ds1; ds2 is empty
      mergedDataSet.push({
        name: `${round(lower1)} - ${round(upper1)}`,
        [dataSet1Name]: round(ds1[i1].value),
        [dataSet2Name]: 0,
      });
      i1 += 1;
      continue;
    }

    if (!ds1[i1] && ds2[i2]) {
      // More states left in ds2; ds1 is empty
      mergedDataSet.push({
        name: `${round(lower2)} - ${round(upper2)}`,
        [dataSet1Name]: 0,
        [dataSet2Name]: round(ds2[i2].value),
      });
      i2 += 1;
      continue;
    }

    range1 = upper1 - lower1;
    range2 = upper2 - lower2;

    // At this point, we have two non-matching marginals
    if (lower1 <= lower2) {
      if (upper1 <= lower2) {
        // 111
        //    222
        mergedDataSet.push({
          name: `${round(lower1)} - ${round(upper1)}`,
          [dataSet1Name]: round(value1),
          [dataSet2Name]: 0,
        });
        i1 += 1;
        continue;
      }

      if (lower1 === lower2 && upper1 > upper2) {
        // 11111
        // 222
        // Split 1
        // Add splinter 1 and 2
        // Value for 1 is split proportional to new range size
        const splitRangeL = range2;
        const splitRangeR = upper1 - upper2;
        mergedDataSet.push({
          name: `${round(lower2)} - ${round(upper2)}`,
          [dataSet1Name]: round((value1 * splitRangeL) / range1),
          [dataSet2Name]: round(value2),
        });
        ds1[i1] = {
          label: `${upper2} - ${upper1}`,
          value: (value1 * splitRangeR) / range1,
        };
        i2 += 1;
        continue;
      }

      if (lower1 < lower2 && upper2 <= upper1) {
        // 11111
        //   222
        // or
        // 11111
        //  222
        // Split 1
        // Add splinter 1
        const splitRangeL = lower2 - lower1;
        const splitRangeR = upper1 - lower2;
        mergedDataSet.push({
          name: `${round(lower1)} - ${round(lower2)}`,
          [dataSet1Name]: round((value1 * splitRangeL) / range1),
          [dataSet2Name]: 0,
        });
        ds1[i1] = {
          label: `${lower2} - ${upper1}`,
          value: (value1 * splitRangeR) / range1,
        };
        // Not incrementing any index
      }
    }

    if (lower2 <= lower1) {
      if (upper2 <= lower1) {
        // 222
        //    111
        mergedDataSet.push({
          name: `${round(lower2)} - ${round(upper2)}`,
          [dataSet1Name]: 0,
          [dataSet2Name]: value2,
        });
        i2 += 1;
        continue;
      }

      if (lower2 === lower1 && upper2 > upper1) {
        // 22222
        // 111
        // Split 2
        const splitRangeL = range1;
        const splitRangeR = upper2 - upper1;
        mergedDataSet.push({
          name: `${round(lower1)} - ${round(upper1)}`,
          [dataSet1Name]: round(value1),
          [dataSet2Name]: round((value2 * splitRangeL) / range2),
        });
        ds2[i2] = {
          label: `${upper1} - ${upper2}`,
          value: (value2 * splitRangeR) / range2,
        };
        i1 += 1;
        continue;
      }

      if (lower1 < lower2 && upper2 <= upper1) {
        // 22222
        //   111
        // or
        // 22222
        //  111
        // Split 2
        const splitRangeL = lower1 - lower2;
        const splitRangeR = upper2 - lower1;
        mergedDataSet.push({
          name: `${round(lower2)} - ${round(lower1)}`,
          [dataSet1Name]: 0,
          [dataSet2Name]: round((value2 * splitRangeL) / range2),
        });
        ds2[i2] = {
          label: `${lower1} - ${upper2}`,
          value: (value2 * splitRangeR) / range2,
        };
        // Not incrementing any index
      }
    }

    i1 += 1;
    i2 += 1;
  }

  return mergedDataSet;
};

export default {
  mergeDataSets,
};
