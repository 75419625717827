import DataUtils from '../../shared/DataUtils';

const compileSummaryStatistics = (resultCache, resultCacheKey, config) => {
  const dataSets = [];
  Object.keys(resultCache).forEach((dsName) => {
    const dsData = DataUtils.compileSummaryStatistics(dsName, resultCache[dsName][resultCacheKey], config);
    dataSets.push(dsData);
  });
  return dataSets;
};

const compileChartData = (resultCache, resultCacheKey, config) => {
  const data = [];
  Object.keys(resultCache).forEach((dsName) => {
    data.push(DataUtils.compileChartDataTable(
      dsName,
      config.nodeType,
      DataUtils.compileChartDataRows(
        resultCache[dsName][resultCacheKey],
        config.nodeType,
        config,
      ),
    ));
  });
  return data;
};

const compileRawData = (resultCache, resultCacheKey, config) => {
  const isIntervalNode = ['ContinuousInterval', 'IntegerInterval'].includes(config.nodeType);
  const integerOffset = (config.nodeType && config.nodeType === 'IntegerInterval') ? 1 : 0;

  const data = {
    headers: ['Case', 'State', 'P(State)'],
    rows: [],
  };

  if (isIntervalNode) {
    data.headers.push.apply(data.headers, ['Lower Bound', 'Upper Bound', 'Midpoint', 'Density']);
  }

  Object.keys(resultCache).forEach((dsName) => {
    resultCache[dsName][resultCacheKey].resultValues.forEach((result) => {
      const { label, value } = result;
      const row = [dsName, label, value];

      if (isIntervalNode) {
        const isInterval = label.indexOf(' - ') >= 0;
        const rangeSplit = label.split(' - ');
        let lower = parseFloat(rangeSplit[0]);
        let upper = parseFloat(rangeSplit[1]);

        if (!isInterval) {
          upper = lower;
        }

        if (!Number.isFinite(lower)) {
          lower = Number.MAX_VALUE * ((lower < 0) ? -1 : 1);
        }

        if (!Number.isFinite(upper)) {
          upper = Number.MAX_VALUE * ((upper < 0) ? -1 : 1);
        }

        let pState = parseFloat(value);
        if (!Number.isFinite(value)) {
          pState = Number.MAX_VALUE * ((value < 0) ? -1 : 1);
        }

        let midPoint;
        if (isInterval) {
          midPoint = (lower + upper) / 2;
        } else {
          midPoint = lower;
        }

        const range = (upper - lower) + integerOffset;
        const density = (range > 0) ? pState / range : NaN;

        row.push.apply(row, [lower, upper, midPoint, density]);
      }

      data.rows.push(row);
    });
  });
  return data;
};

export default {
  compileSummaryStatistics,
  compileChartData,
  compileRawData,
};
