import * as d3 from 'd3-shape';
import Theme from './Theme';

const CHART_HEIGHT = 240;

const CurveContinuous = 'Cardinal 1';
const CurveInteger = 'Step After';

const ChartTypes = {
  Area: 'area',
  BarChart: 'barchart',
  Histogram: 'histogram',
  Line: 'line',
  Scatter: 'scatter',
  ScatterLine: 'scatterline',
  VerticalBarChart: 'verticalbarchart',
};

const ChartTypesDiscrete = {
  BarChart: ChartTypes.BarChart,
  'Vertical Bar Chart': ChartTypes.VerticalBarChart,
};

const ChartTypesContinuous = {
  Area: ChartTypes.Area,
  Histogram: ChartTypes.Histogram,
  Line: ChartTypes.Line,
  Scatter: ChartTypes.Scatter,
  'Scatter Line': ChartTypes.ScatterLine,
  'Vertical Bar Chart': ChartTypes.VerticalBarChart,
};

const ChartTypeInteger = ChartTypes.Histogram;
const ChartTypeNumeric = ChartTypes.Area;
const ChartTypeDiscrete = ChartTypes.BarChart;

const ChartTypeDefaultsContinuous = {
  // paddingLeft: '10px',
};

const ChartTypeDefaults = {
  [ChartTypes.BarChart]: {
    // paddingLeft: '10px',
    // paddingRight: '65px',
  },
  [ChartTypes.Area]: ChartTypeDefaultsContinuous,
  [ChartTypes.Histogram]: ChartTypeDefaultsContinuous,
  [ChartTypes.Line]: ChartTypeDefaultsContinuous,
  [ChartTypes.Scatter]: ChartTypeDefaultsContinuous,
  [ChartTypes.ScatterLine]: ChartTypeDefaultsContinuous,
  [ChartTypes.VerticalBarChart]: {},
};

const CurveFunctions = {
  // https://github.com/d3/d3-shape#curves

  Linear: d3.curveLinear,
  Natural: d3.curveNatural,
  'Step Before': d3.curveStepBefore,
  'Step Middle': d3.curveStep,
  'Step After': d3.curveStepAfter,
  'Cardinal 0': d3.curveCardinal.tension(0),
  'Cardinal 0.25': d3.curveCardinal.tension(0.25),
  'Cardinal 0.5': d3.curveCardinal.tension(0.5),
  'Cardinal 0.75': d3.curveCardinal.tension(0.75),
  'Cardinal 1': d3.curveCardinal.tension(1),

  'Cardinal Open 0': d3.curveCardinalOpen.tension(0),
  'Cardinal Open 0.25': d3.curveCardinalOpen.tension(0.25),
  'Cardinal Open 0.5': d3.curveCardinalOpen.tension(0.5),
  'Cardinal Open 0.75': d3.curveCardinalOpen.tension(0.75),
  'Cardinal Open 1': d3.curveCardinalOpen.tension(1),

  'Catmull-Rom 0': d3.curveCatmullRom.alpha(0),
  'Catmull-Rom 0.25': d3.curveCatmullRom.alpha(0.25),
  'Catmull-Rom 0.5': d3.curveCatmullRom.alpha(0.5),
  'Catmull-Rom 0.75': d3.curveCatmullRom.alpha(0.75),
  'Catmull-Rom 1': d3.curveCatmullRom.alpha(1),

  'Catmull-Rom Open 0': d3.curveCatmullRomOpen.alpha(0),
  'Catmull-Rom Open 0.25': d3.curveCatmullRomOpen.alpha(0.25),
  'Catmull-Rom Open 0.5': d3.curveCatmullRomOpen.alpha(0.5),
  'Catmull-Rom Open 0.75': d3.curveCatmullRomOpen.alpha(0.75),
  'Catmull-Rom Open 1': d3.curveCatmullRomOpen.alpha(1),
};

const Colors = [
  '#25ad31', // AR 1
  '#4c6bf8', // AR 2

  '#d83b01',
  '#2b579a',
  '#217346',
  '#7719aa',
  '#a4373a',
  '#0078d4',
  '#b7472a',
  '#077568',
  '#6264a7',
  '#5c005c',
  '#3955a3',
  '#31752f',
  '#106ebe',
  '#008272',

  '#19CDD7',
  '#DDB27C',
  '#88572C',
  '#FF991F',
  '#F15C17',
  '#223F9A',
  '#DA70BF',
  '#4DC19C',
  '#12939A',
  '#B7885E',
  '#FFCB99',
  '#F89570',
  '#E79FD5',
  '#89DAC1',
];

const ColorBaseline = Colors[1]; // Was 23
const ColorUser = Colors[0]; // Was 7

const decimalPlaces = 3;

const OutputItemBackgroundColor = '#FFFFFF';
const OutputItemBorderColor = '#E1E1E1';
const OutputItemBorder = {
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: OutputItemBorderColor,
};

const SummaryStats = {
  mean: {
    name: 'mean',
    displayName: 'Mean',
  },
  median: {
    name: 'median',
    displayName: 'Median',
  },
  standardDeviation: {
    name: 'standardDeviation',
    displayName: 'Standard Deviation',
  },
  variance: {
    name: 'variance',
    displayName: 'Variance',
  },
  lowerPercentile: {
    name: 'lowerPercentile',
    displayName: 'Lower Percentile',
  },
  upperPercentile: {
    name: 'upperPercentile',
    displayName: 'Upper Percentile',
  },
  entropy: {
    name: 'entropy',
    displayName: 'Entropy Error',
  },
};

const ThemeColors = {
  AxisLine: Theme.Colors.ChartAxis,
  AxisTick: Theme.Colors.ChartAxis,
  DataLabel: Theme.Colors.ChartAxis,
};

const InputModes = {
  NumericInput: { key: 'numericInput', text: 'Numeric Input' },
  Dropdown: { key: 'dropdown', text: 'Dropdown' },
};

const getAvailableInputModes = (node) => {
  const inputModes = [];

  if (['ContinuousInterval', 'IntegerInterval'].includes(node.configuration.type)) {
    inputModes.push({ ...InputModes.NumericInput });
  }

  if (!node.configuration.simulated) {
    inputModes.push({ ...InputModes.Dropdown });
  }

  return inputModes;
};

export default {
  CHART_HEIGHT,
  CurveContinuous,
  CurveInteger,
  ChartTypes,
  ChartTypesDiscrete,
  ChartTypesContinuous,
  ChartTypeInteger,
  ChartTypeNumeric,
  ChartTypeDiscrete,
  ChartTypeDefaultsContinuous,
  ChartTypeDefaults,
  CurveFunctions,
  Colors,
  ColorBaseline,
  ColorUser,
  decimalPlaces,
  OutputItemBackgroundColor,
  OutputItemBorderColor,
  OutputItemBorder,
  SummaryStats,
  ThemeColors,
  InputModes,
  getAvailableInputModes,
};
