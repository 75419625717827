import React from 'react';
import {
  DefaultButton, mergeStyleSets, Separator, Stack,
} from '@fluentui/react';
import { useNavigate, useLocation } from 'react-router-dom';
import Store from '../shared/state/Store';
import Theme from '../shared/Theme';
import Routing from '../shared/Routing';
import AccountsState from './accounts/AccountsState';

export const DefaultNavOption = Routing.getTree({}).home.routes.administration.routes.settings.routes.reservedDomains.path;

const styles = mergeStyleSets({
  menuItem: {
    textAlign: 'left',
    border: 'none !important',
    whiteSpace: 'nowrap',
    selectors: {
      ':hover': {
        outline: 'none !important',
        backgroundColor: '#e4e4e4',
      },

      span: {
        fontWeight: 'normal !important',
      },
    },
  },

  menuItemSelected: {
    backgroundColor: '#e4e4e4',
  },

  subButton: {
    fontSize: '13px',
    paddingLeft: '20px',
  },
});

export default () => {
  const [globalState] = Store.useStore();
  const navigate = useNavigate();
  const location = useLocation();
  const routingTree = Routing.getTree(globalState).home.routes.administration.routes;

  const menuItems = [
    {
      type: 'subHeader',
      text: 'Settings',
    },
    {
      type: 'button',
      text: routingTree.settings.routes.reservedDomains.text,
      key: routingTree.settings.routes.reservedDomains.path,
      action: () => navigate(routingTree.settings.routes.reservedDomains.path),
    },
    {
      type: 'subHeader',
      text: 'Data',
    },
    {
      type: 'button',
      text: routingTree.archive.text,
      key: routingTree.archive.path,
      action: () => navigate(routingTree.archive.path),
    },
    {
      type: 'subHeader',
      text: 'Users',
    },
    {
      type: 'button',
      text: routingTree.accounts.text,
      key: routingTree.accounts.path,
      action: () => navigate(routingTree.accounts.path),
    },
  ];

  if (location.pathname.indexOf(routingTree.accounts.path) === 0 && AccountsState.getRelatedAccounts(globalState)?.primaryAccount) {
    const { uuid } = AccountsState.getRelatedAccounts(globalState).primaryAccount;
    menuItems.push(...[
      {
        type: 'button',
        text: routingTree.accounts.routes.account.routes.subscription.text,
        key: routingTree.accounts.routes.account.routes.subscription.path.replace(':id', uuid),
        action: () => navigate(routingTree.accounts.routes.account.routes.subscription.path.replace(':id', uuid)),
        className: styles.subButton,
      },
      {
        type: 'button',
        text: routingTree.accounts.routes.account.routes.licenseKeys.text,
        key: routingTree.accounts.routes.account.routes.licenseKeys.path.replace(':id', uuid),
        action: () => navigate(routingTree.accounts.routes.account.routes.licenseKeys.path.replace(':id', uuid)),
        className: styles.subButton,
      },
    ]);
  }

  const selectedMenuItem = location.pathname || DefaultNavOption;

  const renderedItems = menuItems.map(((menuItemData) => {
    if (menuItemData.type === 'button') {
      let className = styles.menuItem;
      if (menuItemData.key === selectedMenuItem) {
        className += ` ${styles.menuItemSelected}`;
      }
      if (menuItemData.className) {
        className += ` ${menuItemData.className}`;
      }

      return (
        <DefaultButton
          key={menuItemData.key}
          text={menuItemData.text}
          className={className}
          onClick={(event) => {
            menuItemData.action();
            event.target.closest('button').blur();
          }}
        />
      );
    }
    if (menuItemData.type === 'subHeader') {
      return (
        <div key={menuItemData.text}>
          <Separator horizontal styles={Theme.styleObjects.separatorSmallMargin} />
          <header className={Theme.styles.menuSubHeader}>{menuItemData.text}</header>
        </div>
      );
    }
    return <></>;
  }));

  return (
    <Stack vertical>
      {renderedItems}
    </Stack>
  );
};
