import { mergeStyleSets, Stack } from '@fluentui/react';
import React from 'react';
import MultiLineUserText from '../../shared/MultiLineUserText';
import User from '../../shared/User';

const componentStyles = mergeStyleSets({
  header: {
    fontSize: '14px !important',
    fontWeight: 600,
  },
});

export default ({
  sectionEntry,
  version,
  section,
  iconUnread,
}) => {
  const storageKeyPrefix = 'ar-wappd-change-log-';
  const storageKey = storageKeyPrefix + version + section + sectionEntry.summary + sectionEntry.version;
  // eslint-disable-next-line eqeqeq
  const seen = localStorage.getItem(storageKey) != undefined;

  if (!seen) {
    localStorage.setItem(storageKey, true);
  }

  const showImplVersion = sectionEntry.version && User.isCloudAdmin();
  let { text } = sectionEntry;
  if (Array.isArray(sectionEntry.text)) {
    text = text.join('\n');
  }

  return (
    <Stack vertical>
      <h3 className={componentStyles.header}>
        {sectionEntry.summary}
        {' '}
        {showImplVersion ? `(${sectionEntry.version}) ` : ''}
        {!seen ? iconUnread : ''}
      </h3>
      <MultiLineUserText>{text}</MultiLineUserText>
    </Stack>
  );
};
