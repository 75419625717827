import React from 'react';
import {
  Checkbox,
  DefaultButton,
  Dialog, DialogType, PrimaryButton, Stack,
} from '@fluentui/react';
import Store from '../../shared/state/Store';
import UiReducer from '../../shared/state/UiReducer';
import PrintConfigDefaults from './PrintConfigDefaults';
import TextFieldBuffered from '../../shared/TextFieldBuffered';
import StackTokens from '../../shared/StackTokens';

const componentStylesRaw = {
  textFieldRoot: {
    wrapper: {
      marginTop: 'none !important',
    },
  },
  checkbox: {
    label: {
      margin: '0',
    },
  },
};

export default ({ visible, setVisible, closeButtonText }) => {
  const [globalState, dispatch] = Store.useStore();

  const setField = (fieldName, fieldValue) => {
    const updatedConfig = {
      ...UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.webAppPrintReportConfig) || PrintConfigDefaults.appDefaults(globalState),
    };
    updatedConfig[fieldName] = fieldValue;
    dispatch(UiReducer.setGenericUiAttribute(UiReducer.Keys.webAppPrintReportConfig, updatedConfig));
  };

  const getField = (fieldName) => {
    const printConfig = UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.webAppPrintReportConfig) || PrintConfigDefaults.appDefaults(globalState);
    return printConfig[fieldName] || '';
  };

  const hide = () => {
    dispatch(UiReducer.setGenericUiAttribute(UiReducer.Keys.webAppShowPrintDialog, false));
    if (setVisible) {
      setVisible(false);
    }
  };

  const popupOpen = UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.webAppShowPrintDialog) || visible;
  if (!popupOpen) {
    return <></>;
  }

  return (
    <>
      <Dialog
        hidden={!popupOpen}
        onDismiss={() => {
          hide();
          dispatch(UiReducer.setGenericUiAttribute(UiReducer.Keys.webAppShowPrintReport, false));
        }}
        dialogContentProps={{
          type: DialogType.normal,
          title: 'Print Case Summary',
          closeButtonAriaLabel: 'Cancel',
          subText: 'Choose print options:',
          styles: {
            subText: {
              marginBottom: '5px',
            },
            topButton: {
              selectors: {
                button: {
                  outline: 'none !important',
                },
              },
            },
          },
        }}
        modalProps={{
          isBlocking: true,
          //   isModeless: true,
          styles: {
            main: {
              width: '450px !important',
              maxWidth: '95vw !important',
            //   background: 'lime',
            },
          },
        }}
      >

        <Stack vertical tokens={StackTokens.spacing}>

          <TextFieldBuffered
            label="Report header"
            actualValue={getField('reportHeader')}
            placeholder="Report header"
            validateValue={(value) => {
              const validatedValue = value.trim();
              return validatedValue;
            }}
            applyValue={async (value) => {
              setField('reportHeader', value);
            }}
            styles={componentStylesRaw.textFieldRoot}
          />

          <Checkbox
            label="Show app description"
            checked={!!getField('showAppDescription')}
            onChange={async () => {
              setField('showAppDescription', !getField('showAppDescription'));
            }}
            styles={componentStylesRaw.checkbox}
          />

          <TextFieldBuffered
            label="Input section name"
            actualValue={getField('inputSectionName')}
            placeholder="Input section name"
            validateValue={(value) => {
              const validatedValue = value.trim();
              return validatedValue;
            }}
            applyValue={async (value) => {
              setField('inputSectionName', value);
            }}
            styles={componentStylesRaw.textFieldRoot}
          />

          <TextFieldBuffered
            label="No value string"
            actualValue={getField('noValueString')}
            placeholder="No value string"
            validateValue={(value) => {
              const validatedValue = value.trim();
              return validatedValue;
            }}
            applyValue={async (value) => {
              setField('noValueString', value);
            }}
            styles={componentStylesRaw.textFieldRoot}
          />

          <TextFieldBuffered
            label="Output section name"
            actualValue={getField('outputSectionName')}
            placeholder="Output section name"
            validateValue={(value) => {
              const validatedValue = value.trim();
              return validatedValue;
            }}
            applyValue={async (value) => {
              setField('outputSectionName', value);
            }}
            styles={componentStylesRaw.textFieldRoot}
          />

          <Checkbox
            label="Show versioning"
            checked={!!getField('showVersioning')}
            onChange={async () => {
              setField('showVersioning', !getField('showVersioning'));
            }}
            styles={componentStylesRaw.checkbox}
          />

          <Checkbox
            label="Show timestamp"
            checked={!!getField('showTimestamp')}
            onChange={async () => {
              setField('showTimestamp', !getField('showTimestamp'));
            }}
            styles={componentStylesRaw.checkbox}
          />

          <Stack horizontal horizontalAlign="end" tokens={StackTokens.spacing}>
            <PrimaryButton
              onClick={() => {
                hide();
                const docTitle = document.title;
                setTimeout(() => {
                  window.print();
                  dispatch(UiReducer.setGenericUiAttribute(UiReducer.Keys.webAppShowPrintReport, false));
                  document.title = docTitle;
                }, 100);
                dispatch(UiReducer.setGenericUiAttribute(UiReducer.Keys.webAppShowPrintReport, true));
              }}
              text="Print"
              iconProps={{ iconName: 'Print' }}
            />

            <PrimaryButton
              onClick={() => {
                hide();
                dispatch(UiReducer.setGenericUiAttribute(UiReducer.Keys.webAppPrintReportCopyRequested, true));
              }}
              text="Copy"
              iconProps={{ iconName: 'Copy' }}
            />

            <DefaultButton
              onClick={() => {
                hide();
                dispatch(UiReducer.setGenericUiAttribute(UiReducer.Keys.webAppShowPrintReport, false));
              }}
              text={closeButtonText || 'Cancel'}
            />
          </Stack>

        </Stack>
      </Dialog>
    </>
  );
};
