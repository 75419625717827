import { Link, mergeStyleSets, Stack } from '@fluentui/react';
import React from 'react';

const styles = mergeStyleSets({
  footer: {
    marginTop: 'auto',
    paddingRight: '50px',
    paddingBottom: '20px',
  },
});

export default () => (
  <Stack horizontal className={styles.footer} horizontalAlign="end" tokens={{ childrenGap: 20 }}>
    <Link href="https://www.agena.ai/_files/ugd/8f9118_1163461e625649789e6744932605ef80.pdf" target="_blank">privacy and data policy</Link>
    <Link href="https://agena.ai" target="_blank">
      &copy;
      {' '}
      {new Date().getFullYear()}
      {' '}
      agena.ai
    </Link>
  </Stack>
);
