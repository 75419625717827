import React from 'react';
import Store from '../../../../shared/state/Store';
import General from './General';
import Inputs from './Inputs';
import Outputs from './Outputs';
import MenuPage from '../../MenuPage';
import Routing from '../../../../shared/Routing';
import DataProcessing from './DataProcessing';
import Config from '../../../../shared/Config';

export default () => {
  const [globalState] = Store.useStore();
  const routingTree = Routing.getTree(globalState).home.routes.webAppDesigner.routes.design.routes.applicationProperties;

  const elements = [
    <General />,
    <Inputs />,
    <Outputs />,
  ];

  if (Config.getApplicationMode() === Config.ApplicationModes.WebAppDesigner) {
    elements.push(<DataProcessing />);
  }

  return (
    <MenuPage
      root={routingTree}
      defaultPath={routingTree.routes.general.path}
      elements={elements}
    />
  );
};
