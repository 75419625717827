/* eslint-disable max-len */
import {
  DefaultButton,
  mergeStyleSets, Modal, Stack,
} from '@fluentui/react';
import React from 'react';
import IconButton from './IconButton';
import StackTokens from './StackTokens';
import Theme from './Theme';

const localStyles = mergeStyleSets({
  container: {
    position: 'fixed',
    left: '-9999px',
    width: '0px',
    height: '0px',
  },
  footer: {
    paddingTop: '10px',
  },
  title: {
    fontWeight: '600',
    fontSize: 'larger',
  },
});

export default ({
  isOpen, setIsOpen, title, children, isBlocking = false, skipRenderIfClosed = false,
  showXButton = true, showFooter = false,
  styles,
}) => {
  if (skipRenderIfClosed && !isOpen) {
    return <></>;
  }

  return (
    <div className={localStyles.container}>
      <Modal
        titleAriaId={title}
        isOpen={isOpen}
        onDismiss={() => {
          setIsOpen(false);
        }}
        isBlocking={isBlocking}
        styles={{
          main: {
            minHeight: 'auto',
            height: 'auto',
            padding: '10px',
            ...((styles?.main) ? styles.main : {}),
          },
        }}
      >
        <Stack vertical tokens={StackTokens.spacing}>

          <Stack horizontal tokens={StackTokens.spacing}>
            <Stack horizontal horizontalAlign="start">
              {title && (
              <header className={localStyles.title}>{title}</header>
              )}
            </Stack>
            <Stack grow={100} />
            <Stack horizontal horizontalAlign="end">
              {showXButton && (
              <IconButton
                iconName="Cancel"
                tooltip="Close"
                onClick={() => setIsOpen(false)}
                styles={{
                  icon: {
                    verticalAlign: 'middle',
                    lineHeight: '28px',
                  },
                }}
                className={Theme.styles.iconButtonClearMinimalistic}
              />
              )}

            </Stack>
          </Stack>

          {children}

          {showFooter && (
          <Stack horizontal horizontalAlign="end" className={localStyles.footer}>
            <DefaultButton
              className={Theme.styles.outlineControlButton}
              onClick={() => setIsOpen(false)}
              text="Close"
            />
          </Stack>
          )}

        </Stack>
      </Modal>
    </div>

  );
};
