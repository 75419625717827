import React from 'react';
import { Stack } from '@fluentui/react';
import {
  Routes, Route,
} from 'react-router-dom';
import StackTokens from '../shared/StackTokens';
import Store from '../shared/state/Store';
import AppView from './AppView';
import AppsList from './AppsList';
import Routing from '../shared/Routing';

export default () => {
  const [globalState] = Store.useStore();
  const routingTree = Routing.getTree(globalState).home.routes.cloudAppManager;

  return (
    <Stack vertical tokens={StackTokens.spacing}>
      <Routes>
        <Route element={<AppsList />} path="" />
        <Route element={<AppView />} path={`${routingTree.routes.app.getRelativePath(routingTree.path)}/*`} />
      </Routes>
    </Stack>
  );
};
