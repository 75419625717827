import agena from 'agena-api-js';
import ModellerReducer from './ModellerReducer';
import UiReducer from '../shared/state/UiReducer';
import keycloak from '../shared/Keycloak';

const calculate = async (globalState, dispatch, isInterrupted, cleanup, appId, modelPath) => {
  // agena.init({ api: { server: 'https://api.onprem.demevgen.org', debug: true, debugLevel: 10 } });
  agena.init({ api: { server: window.env.REACT_APP_API_ROOT } });

  const model = ModellerReducer.getModel(globalState);
  if (!model?.networks) {
    dispatch(UiReducer.uiSetErrors(['No model to calculate']));
    return;
  }

  dispatch(UiReducer.uiSetErrors());

  const headers = {
    'x-referer': window.location.href,
  };
  const userId = UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.userId);
  if (userId) {
    headers['User-Id'] = userId;
  }

  const responses = {};
  const errors = [];
  const results = await agena.calculateBatch({
    resolveBearerToken: () => {
      if (keycloak.authenticated && keycloak.token) {
        return keycloak.token;
      }
      return '';
    },
    ...(appId && { appId }),
    ...(modelPath && { modelPath }),
    ...(!modelPath && {
      model: {
        ...model,
        dataSets: [],
        graphics: {},
        riskTable: {},
      },
    }),
    dataSets: model.dataSets.map((ds) => ({ ...ds, results: [] })),
    errorsArray: errors,
    isInterrupted,
    headers,
    rawResponses: responses,
  });

  console.log('results:', results);
  console.log('errors:', errors);
  console.log('responses', responses);

  Object.keys(responses).forEach((datasetId) => {
    dispatch(ModellerReducer.cacheCalculationResponse(datasetId, responses[datasetId]));
  });

  if (typeof isInterrupted === 'function' && !isInterrupted()) {
    // Ignore errors if request interrupted by user
    if (errors.length > 0) {
      console.log('errors.length > 0');
      // Show errors and possibly proceed
      dispatch(UiReducer.uiSetErrors(errors));

      if (!results || results.length === 0) {
        // Only exit if there are no calculated datasets
        dispatch(UiReducer.uiSetLoading(false));
        if (typeof cleanup === 'function') {
          cleanup();
        }
        return;
      }
    }
  }

  if (results) {
    const updatedModel = { ...model };
    const idIndexMap = {};
    const indexIdMap = {};

    model.dataSets.forEach((dataset, i) => {
      idIndexMap[dataset.id] = i;
      indexIdMap[i] = dataset.id;
    });

    results.forEach((dataset) => {
      // console.log('update ds ', dataset.id, ' on index ', idIndexMap[dataset.id], 'to ', dataset.results);
      updatedModel.dataSets[idIndexMap[dataset.id]].results = dataset.results;
      updatedModel.dataSets = [...updatedModel.dataSets];
    });
    dispatch(ModellerReducer.updateModel(updatedModel));
    console.log('Calculated model', updatedModel);
  }

  dispatch(UiReducer.uiSetLoading(false));
  if (typeof cleanup === 'function') {
    cleanup();
  }
};

export default {
  calculate,
};
