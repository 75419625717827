import React from 'react';
import {
  Stack, DefaultButton, mergeStyleSets, Text,
} from '@fluentui/react';
import keycloak from '../../../shared/Keycloak';
import tokens from '../../../shared/StackTokens';
import Store from '../../../shared/state/Store';
import UiReducer from '../../../shared/state/UiReducer';
import AppsApi from '../../../shared/util/AppsApi';
import AppReducer from '../../../shared/state/AppReducer';
import InputConfirmationDialog from '../../../shared/InputConfirmationDialog';
import ReactiveButton from '../../../shared/ReactiveButton';
import PlaceholderError from '../../../shared/PlaceholderError';
import Config from '../../../shared/Config';

export default () => {
  const [globalState, dispatch] = Store.useStore();

  if (!AppReducer.isAppLoaded(globalState)) {
    return <PlaceholderError message="No app is currently loaded" />;
  }

  const styles = mergeStyleSets({
    button: {
      minWidth: '135px',
    },

    bold: {
      fontWeight: 'bold',
    },
  });

  const saveConfig = () => {
    const content = JSON.stringify(globalState.config);

    const a = document.createElement('a');
    const file = new Blob([content], { type: 'file' });
    a.href = URL.createObjectURL(file);
    a.download = 'web-app-config.json';
    a.click();
    URL.revokeObjectURL(a.href);
    a.remove();
  };

  let privateContent = <></>;
  if (keycloak.authenticated && Config.getApplicationMode() !== Config.ApplicationModes.WebAppDesigner) {
    const appData = AppReducer.getAppData(globalState);
    const appId = appData?.uuid;
    const appName = appData?.name;

    privateContent = (
      <>
        <span className={styles.bold}>Save to your online account</span>

        <span>
          <DefaultButton
            text="Save as"
            onClick={() => {
              dispatch(UiReducer.setGenericUiAttribute('FileCloudSaveAsDialog', true));
            }}
            className={styles.button}
            iconProps={{
              iconName: 'CloudAdd',
            }}
          />
        </span>

        <InputConfirmationDialog
          uiElementId="FileCloudSaveAsDialog"
          title="Save as New App to Cloud"
          text="Save your current app as a new app with the below name:"
          inputPlaceholder="Enter new app's name"
          primaryButtonText="Save"
          hideOnAction
          validateValue={(val) => {
            const value = val.trim();
            if (value === '') {
              throw new Error('App name should not be empty');
            }
            return value;
          }}
          primaryCallback={async (name) => {
            const response = await AppsApi.saveApp({ name, configuration: globalState.config });
            if (response.code >= 300) {
              dispatch(UiReducer.uiSetErrors(response.messages));
            } else {
              dispatch(AppReducer.setAppData(response));
            }
          }}
        />

        <span>
          <ReactiveButton
            text="Save"
            iconDefault="Cloud"
            iconSuccess="CheckMark"
            disabled={!appId}
            className={styles.button}
            onClick={async () => {
              const response = await AppsApi.saveApp({ configuration: globalState.config }, appId);
              if (response.code >= 300) {
                dispatch(UiReducer.uiSetErrors(response.messages));
                return false;
              }

              dispatch(AppReducer.setAppData(response));
              return true;
            }}
          />
        </span>

        {appId && (
          <Text block>{`Saving will overwrite app: ${appName} (${appId})`}</Text>
        )}

      </>
    );
  }

  return (
    <Stack style={{ marginTop: '10px' }}>
      <Stack vertical tokens={tokens.verticalSpacing}>
        <span className={styles.bold}>Save to JSON file on your machine</span>
        <Text block>
          Note if your browser is configured to save downloaded files automatically without confirming file name or location,
          it will just save the file into your configured download folder
        </Text>
        <span>
          <DefaultButton
            text="Save as"
            onClick={() => {
              saveConfig();
            }}
            className={styles.button}
            iconProps={{
              iconName: 'Save',
            }}
          />
        </span>
        {privateContent}
      </Stack>
    </Stack>
  );
};
