const modelJson = {};
const inputConfig = [
  {
    groupName: 'Enter details',
    items: [],
  },
];
const outputConfig = [
  {
    groupName: 'Results',
    items: [],
  },
];
const generalConfig = {
  appName: 'My Web App',
  appDescription: 'This is an optional description for this Web App',
  appLogo: '',
  inputSectionHeader: 'Inputs',
  inputSectionDescription: 'Answer as many or few questions as you like. The more you answer, the more accurate the estimates.',
  inputSectionCalculateDescription: 'Click the blue button below to calculate predictions based on your answers:',
  inputSectionCalculateButton: 'Calculate',
  resultSectionHeader: 'Results',
  baselineEnabled: false,
  inputSectionCalculatePreventStretch: true,
  // curveFunctionContinuous: 'Cardinal Open 1',
  // curveFunctionInteger: 'Step After',
  appViewEnabled: true,
};

const disabledPivotProps = {
  disabled: 'true',
  style: { color: 'grey' },
};

export default {
  modelJson,
  inputConfig,
  outputConfig,
  generalConfig,
  disabledPivotProps,
};
