/* eslint-disable react/no-array-index-key */
import { DefaultButton, mergeStyleSets } from '@fluentui/react';
import React from 'react';
import EditableText from '../EditableText';
import Theme from '../Theme';
import Utils from '../Utils';
import ColumnEditorButton from './ColumnEditorButton';
import TableData from './TableData';

const componentStylesRaw = {
  editable: {
    input: {
      height: 'fit-content !important',
      selectors: {
        '.ms-TextField-fieldGroup': {
          height: 'fit-content !important',
        },
        input: {
          padding: '0 0 0 7px',
          margin: '0',
          height: '26px',
        },
      },
    },
  },

  header: {
    text: {
      fontWeight: 600,
      whiteSpace: 'nowrap',
    },
  },
};

const componentStyles = mergeStyleSets({
  container: {

  },
  table: {
    selectors: {
      th: {
        padding: '5px',
      },
      'th, td': {
        border: `1px solid ${Theme.Colors.LightGray}`,
        fontWeight: 600,
      },

      '&.selection-enabled td': {
        cursor: 'pointer',
      },

      'th.selected, td.selected': {
        background: Theme.Colors.Gray,
        // dropShadow: 'inset 0px 0px 1px 2px #217346',
      },
    },
  },

  editable: {
    selectors: {
      ':hover': {
        background: '#f0f0f0 !important',
      },
      selectors: {
        '&>*': {
          padding: '7px',
        },
      },
    },
  },

  headerWithButtons: {
    float: 'left',
  },

  buttonsPanel: {
    float: 'right',
    paddingTop: '3px',
    // paddingRight: '6px',
  },

  menuItemSelected: {
    selectors: {
      button: {
        backgroundColor: Theme.Colors.LightGray2,
      },
    },
  },

  buttonRowCell: {
    borderRight: 'none !important',
    borderBottom: 'none !important',
    borderLeft: 'none !important',
  },

  selectionDisabled: {
    userSelect: 'none',
  },

  hide: {
    display: 'none',
  },

});

export default ({
  data, updateTable, editableHeaders, editableValues, className, showColumnAdjuster = false, optionsEnabled = true,
  selectionEnabled, rangeSelectionEnabled, rangeSelectedCallback,
  initialSelection,
  showHeaderRow = true,
}) => {
  const tableRef = React.useRef();
  const menuButtonRef = React.useRef();
  const [button, setButton] = React.useState();
  const [selectedRange, setSelectedRange] = React.useState(initialSelection);
  const generalSelectionEnabled = selectionEnabled || rangeSelectionEnabled;
  const [mouseDownShift, setMouseDownShift] = React.useState(false);

  React.useEffect(() => {
    // Make sure we clear selection after Table is rendered, because otherwise last cell rendered will have triggered onFocus,
    // which causes it to be selected
    if (!initialSelection) {
      setSelectedRange();
    }
  }, []);

  const selectRange = ({ cell, modifiers = {} }) => {
    if (!generalSelectionEnabled) {
      setSelectedRange();
      return;
    }

    setSelectedRange((prevRange) => {
      if (rangeSelectionEnabled && modifiers?.shift && prevRange?.cell1) {
        // Selecting a range
        if (prevRange.cell1.row === cell.row && prevRange.cell1.col === cell.col) {
          // Same cell, so range has a single cell
          return { cell1: cell, singleCell: true };
        }
        return { cell1: prevRange.cell1, cell2: cell, singleCell: false };
      }
      return { cell1: cell, singleCell: true };
    });
  };

  const isCellSelected = (row, col) => {
    // console.log('isCellSelected', selectedRange);
    if (!selectedRange) {
      return false;
    }

    if (selectedRange.singleCell || !selectedRange.cell2) {
      return selectedRange.cell1.row === row && selectedRange.cell1.col === col;
    }

    const rowBetween = (selectedRange.cell1.row - row) * (selectedRange.cell2.row - row) <= 0;
    const colBetween = (selectedRange.cell1.col - col) * (selectedRange.cell2.col - col) <= 0;

    return rowBetween && colBetween;
  };

  const getSelectedBounds = () => {
    if (!selectedRange) {
      return {};
    }

    if (selectedRange.singleCell || !selectedRange.cell2) {
      return {
        startCell: {
          row: selectedRange.cell1.row,
          col: selectedRange.cell1.col,
        },
        endCell: {
          row: selectedRange.cell1.row,
          col: selectedRange.cell1.col,
        },
      };
    }

    return {
      startCell: {
        row: Math.min(selectedRange.cell1.row, selectedRange.cell2.row),
        col: Math.min(selectedRange.cell1.col, selectedRange.cell2.col),
      },
      endCell: {
        row: Math.max(selectedRange.cell1.row, selectedRange.cell2.row),
        col: Math.max(selectedRange.cell1.col, selectedRange.cell2.col),
      },
    };
  };

  const focusCell = (row, col) => {
    if (tableRef.current) {
      const cellTd = tableRef.current.querySelector(`[data-marker="${row}.${col}"]`);
      if (cellTd) {
        cellTd.focus();
      }
    }
  };

  const shiftSelection = (rowDir, colDir) => {
    const selectionBounds = getSelectedBounds();
    const initialCell = selectionBounds.startCell || { row: 0, col: 0 };

    let newRow = initialCell.row + rowDir;
    if (newRow < 0) {
      newRow = 0;
    } else if (newRow >= data.rows.length) {
      newRow = data.rows.length - 1;
    }

    let newCol = initialCell.col + colDir;
    if (newCol < 0) {
      newCol = 0;
    } else if (newCol >= data.rows[0].length) {
      newCol = data.rows[0].length - 1;
    }

    focusCell(newRow, newCol);
  };

  const getSelectedData = () => {
    if (!selectedRange) {
      return '';
    }

    if (selectedRange.singleCell || !selectedRange.cell2) {
      return data.rows[selectedRange.cell1.row][selectedRange.cell1.col];
    }

    const selectionBounds = getSelectedBounds();
    // console.log(selectionBounds);

    const arData = [];
    for (let rowIndex = selectionBounds.startCell.row; rowIndex <= selectionBounds.endCell.row; rowIndex += 1) {
      const arRow = [];
      for (let colIndex = selectionBounds.startCell.col; colIndex <= selectionBounds.endCell.col; colIndex += 1) {
        const tableValue = data.rows[rowIndex][colIndex];
        arRow.push(tableValue);
      }
      arData.push(arRow.join('\t'));
    }
    return arData.join('\n');
  };

  const pasteToTable = async (startRow = 0, startCol = 0) => {
    const text = await navigator.clipboard.readText();
    const updatedRows = TableData.apply(data.rows, TableData.parseTabulatedData(text), startRow, startCol);
    const updatedTable = { ...data };
    updatedTable.rows = updatedRows;
    updateTable(updatedTable);
  };

  React.useEffect(() => {
    if (typeof rangeSelectedCallback === 'function') {
      rangeSelectedCallback(selectedRange);
    }
  }, [selectedRange]);

  const buttons = (
    <div ref={menuButtonRef} className={componentStyles.buttonsPanel}>
      <DefaultButton
        menuIconProps={{ iconName: 'Settings' }}
        className={[Theme.styles.iconButton, Theme.styles.iconButtonClearMinimalistic, Theme.styles.outlineControlButton]}
        menuProps={{
          items: [
            {
              key: 'copyTableWithHeaders',
              text: 'Copy Table with Headers',
              iconProps: { iconName: 'Copy' },
              onClick: () => {
                Utils.copyContentsToClipboard(tableRef.current, [menuButtonRef.current]);
              },
            },
            {
              key: 'copyTable',
              text: 'Copy Table',
              iconProps: { iconName: 'Copy' },
              onClick: () => {
                Utils.copyToClipboard(TableData.toTabulatedData(data.rows));
              },
            },
            {
              key: 'copySelection',
              text: 'Copy Selection',
              iconProps: { iconName: 'Copy' },
              onClick: () => {
                Utils.copyToClipboard(getSelectedData());
              },
            },
            {
              key: 'pasteTable',
              text: 'Paste Table',
              iconProps: { iconName: 'Paste' },
              onClick: () => {
                pasteToTable(0, 0);
              },
            },

            {
              key: 'paste',
              text: 'Paste',
              iconProps: { iconName: 'Paste' },
              onClick: () => {
                const selectionBounds = getSelectedBounds();
                pasteToTable(selectionBounds.startCell?.row || 0, selectionBounds.startCell?.col || 0);
              },
            },
          ],
        }}
        onMenuClick={(event) => {
          setButton(event.target.closest('button'));
        }}
        onAfterMenuDismiss={() => {
          button.blur();
        }}
      />
    </div>
  );

  return (
    <div className={className}>
      <table ref={tableRef} className={[componentStyles.table, generalSelectionEnabled ? 'selection-enabled' : ''].join(' ')}>
        <thead>
          {data.columnHeaders.map((row, rowIndex) => (
            <tr key={rowIndex} className={(!showHeaderRow && rowIndex === 0) ? componentStyles.hide : ''}>
              {row.map((cell, colIndex) => (
                <th
                  key={colIndex}
                  colSpan={cell.colSpan || 1}
                  className={componentStyles.selectionDisabled}
                  // Commented out column-highlight feature, not sure if we will need it.
                  // onClick={
                  //    // When the header is the very last row of headers (immediate column headers)
                  //    // And if there is more than one header row
                  //    // Then bind highlight on click
                  //    rangeSelectionEnabled && data.columnHeaders.length > 1 && rowIndex === data.columnHeaders.length - 1 ? (event) => {
                  //      highlight(colIndex);
                  //    } : undefined
                  //   }

                  // // colIndex must be -1 because row headers are not counted
                   // className={(highlightedColumnIndex === colIndex - 1 && rowIndex !== 0 && rowIndex === data.columnHeaders.length - 1) ? 'highlighted' : ''}
                >
                  {
                    optionsEnabled && (
                    // Buttons panel will float right
                      (rowIndex === 0 && colIndex === 0) ? (
                        buttons
                      ) : undefined
                    )
                  }

                  {
                        editableHeaders ? (
                          <EditableText
                            autoSelectOnFocus
                            actualValue={cell.label}
                            applyValue={(updatedValue) => {
                              const updatedTable = { ...data };

                              const updatedStateIndex = cell.index;
                              if (cell.index !== undefined) {
                                // Update all column headers in the row that share state index
                                for (let cIndex = 0; cIndex < updatedTable.columnHeaders[rowIndex].length; cIndex += 1) {
                                  const itemStateIndex = updatedTable.columnHeaders[rowIndex][cIndex].index;
                                  if (updatedStateIndex === itemStateIndex) {
                                    updatedTable.columnHeaders[rowIndex][cIndex].label = updatedValue;
                                  }
                                }
                              }

                              updatedTable.columnHeaders[rowIndex][colIndex].label = updatedValue;
                              updateTable(updatedTable);
                            }}
                            validateValue={(value) => value.trim()}
                            styles={{ ...componentStylesRaw.editable, ...componentStylesRaw.header }}
                            classNames={{ root: [componentStyles.editable, (rowIndex === 0 && colIndex === 0) ? componentStyles.headerWithButtons : undefined].join(' ') }}
                            tooltipAsTitle
                            disableLabelSelection
                          />
                        )
                          : cell.label
                    }
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {data.rows.map((row, rIndex) => (
            <tr key={rIndex}>
              <th
                className={componentStyles.selectionDisabled}
              >
                {
                    editableHeaders ? (
                      <EditableText
                        autoSelectOnFocus
                        actualValue={data.rowHeaders[rIndex]}
                        applyValue={(updatedValue) => {
                          const updatedTable = { ...data };
                          updatedTable.rowHeaders[rIndex] = updatedValue;
                          updateTable(updatedTable);
                        }}
                        validateValue={(value) => value.trim()}
                        styles={{ ...componentStylesRaw.editable, ...componentStylesRaw.header }}
                        classNames={{ root: componentStyles.editable }}
                        tooltipAsTitle
                        disableLabelSelection
                      />
                    )
                      : data.rowHeaders[rIndex]
                  }
              </th>

              {row.map((cell, cIndex) => (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
                <td
                  data-row={rIndex}
                  data-col={cIndex}
                  key={cIndex}
                  onMouseDown={(event) => {
                    setMouseDownShift(event.shiftKey);
                  }}
                  onClick={(event) => {
                    // console.log('td click');
                    if (generalSelectionEnabled) {
                      if (editableValues) {
                        focusCell(rIndex, cIndex);
                      } else {
                        const rData = {
                          cell: {
                            row: rIndex,
                            col: cIndex,
                          },
                          modifiers: {
                            shift: event.shiftKey,
                          },
                        };
                        selectRange(rData);
                      }
                    }
                    setMouseDownShift(false);
                  }}
                  className={isCellSelected(rIndex, cIndex) ? 'selected' : ''}
                >
                  {
                        editableValues ? (
                          <EditableText
                            doubleClickToEdit={generalSelectionEnabled}
                            dynamicWidth
                            showApplyButton={false}
                            showEditButton={false}
                            disableLabelSelection
                            autoSelectOnFocus
                            dataMarker={`${rIndex}.${cIndex}`}
                            focusLabelOnBlur
                            actualValue={cell}
                            applyValue={(updatedValue) => {
                              const updatedTable = { ...data };
                              updatedTable.rows[rIndex][cIndex] = updatedValue;
                              updateTable(updatedTable);
                              setTimeout(() => {
                                shiftSelection(1, 0);
                              }, 100);
                            }}
                            validateValue={(value) => {
                              let uValue = `${value}`.trim();
                              if (uValue === '') {
                                throw new Error('Value required');
                              }

                              uValue = Number.parseFloat(uValue);
                              if (Number.isNaN(uValue) || !Number.isFinite(uValue)) {
                                throw new Error('Must be a number');
                              }

                              return uValue;
                            }}
                            styles={componentStylesRaw.editable}
                            classNames={{ root: componentStyles.editable }}
                            tooltipAsTitle
                            onFocus={() => {
                              // console.log('label focus');
                              if (generalSelectionEnabled) {
                                const rData = {
                                  cell: {
                                    row: rIndex,
                                    col: cIndex,
                                  },
                                  modifiers: {
                                    shift: mouseDownShift,
                                  },
                                };
                                selectRange(rData);
                              }
                            }}

                            onKeyDown={(event) => {
                              // setMouseDownShift(event.shiftKey);
                              // console.log('keyDown from Table');
                              if (event.metaKey || event.ctrlKey) {
                                if (event.keyCode === 67 || event.key === 'c' || event.key === 'C') {
                                  // Copy selection
                                  event.preventDefault();
                                  event.stopPropagation();
                                  Utils.copyToClipboard(getSelectedData());
                                }

                                if (event.keyCode === 86 || event.key === 'v' || event.key === 'V') {
                                  // Paste to start of selection
                                  event.preventDefault();
                                  event.stopPropagation();
                                  const selectionBounds = getSelectedBounds();
                                  pasteToTable(selectionBounds.startCell?.row || 0, selectionBounds.startCell?.col || 0);
                                }

                                if (event.keyCode === 65 || event.key === 'a' || event.key === 'A') {
                                  // Select full table
                                  event.preventDefault();
                                  event.stopPropagation();
                                  setSelectedRange({
                                    cell1: {
                                      row: 0,
                                      col: 0,
                                    },
                                    cell2: {
                                      row: data.rows.length - 1,
                                      col: data.rows[0].length - 1,
                                    },
                                    singleCell: false,
                                  });
                                }
                              }

                              if (['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(event.key)) {
                                event.preventDefault();
                                event.stopPropagation();
                                const selectDirection = { rowDir: 0, colDir: 0 };
                                switch (event.key) {
                                  case 'ArrowLeft': selectDirection.colDir = -1; break;
                                  case 'ArrowRight': selectDirection.colDir = 1; break;
                                  case 'ArrowUp': selectDirection.rowDir = -1; break;
                                  case 'ArrowDown': selectDirection.rowDir = 1; break;
                                  default:
                                }
                                shiftSelection(selectDirection.rowDir, selectDirection.colDir);
                              }

                              if (event.key === 'Home') {
                                event.preventDefault();
                                event.stopPropagation();
                                selectRange({
                                  cell: {
                                    row: getSelectedBounds()?.startCell?.row || 0,
                                    col: 0,
                                  },
                                  modifiers: { shift: event.shiftKey },
                                });
                              }

                              if (event.key === 'End') {
                                event.preventDefault();
                                event.stopPropagation();
                                selectRange({
                                  cell: {
                                    row: getSelectedBounds()?.startCell?.row || 0,
                                    col: data.rows[0].length - 1,
                                  },
                                  modifiers: { shift: event.shiftKey },
                                });
                              }

                              if (event.key === 'PageUp') {
                                event.preventDefault();
                                event.stopPropagation();
                                selectRange({
                                  cell: {
                                    row: 0,
                                    col: getSelectedBounds()?.startCell?.col || 0,
                                  },
                                  modifiers: { shift: event.shiftKey },
                                });
                              }

                              if (event.key === 'PageDown') {
                                event.preventDefault();
                                event.stopPropagation();
                                selectRange({
                                  cell: {
                                    row: data.rows.length - 1,
                                    col: getSelectedBounds()?.startCell?.col || 0,
                                  },
                                  modifiers: { shift: event.shiftKey },
                                });
                              }
                            }}

                          />
                        )
                          : cell
                  }
                </td>
              ))}
            </tr>
          ))}

          {
            showColumnAdjuster && (
            <tr>
              <td className={componentStyles.buttonRowCell}>&nbsp;</td>
              {
              data.rows[0].map((cell, colIndex) => (
                <td
                  key={cell + colIndex}
                  className={componentStyles.buttonRowCell}
                >
                  <ColumnEditorButton
                    tableData={data}
                    updateTableData={updateTable}
                    columnIndex={colIndex}
                    title="Adjust probabilities in the column"
                  />
                </td>
              ))
            }
            </tr>
            )
          }
        </tbody>
      </table>
    </div>
  );
};
