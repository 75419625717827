import React from 'react';
import { Link, Text } from '@fluentui/react';
import PlaceholderError from '../shared/PlaceholderError';
import Store from '../shared/state/Store';
import UiReducer from '../shared/state/UiReducer';
import User from '../shared/User';
import KeyRow from './license-key-list/KeyRow';
import Theme from '../shared/Theme';
import AssignDialog from './license-key-list/AssignDialog';
import Env from '../shared/Env';

export default () => {
  const [globalState, dispatch] = Store.useStore();

  React.useEffect(() => {
    (async () => {
      await User.fetchAccounts({ dispatch, displayErrors: false });
    })();
  }, []);

  const accountInfo = UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.AccountInfo);
  const accountInfoServerError = UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.AccountInfoServerError);
  const availableKeys = User.getAvailableKeys(globalState);

  if (accountInfoServerError) {
    return (<PlaceholderError message="Server is unavailable at this time, please try again later." />);
  }

  if (!accountInfo || Object.keys(accountInfo).length === 0) {
    return (<PlaceholderError message="Loading account data..." />);
  }

  if (availableKeys.length === 0) {
    return (<PlaceholderError message="No agena.ai licenses registered or assigned to your account." />);
  }

  return (
    <div>
      <Text block style={{ margin: '10px 0 0 0' }}>
        You can assign your license keys here to specific users. A valid agena.ai modeller key will grant them Creator access allowing to create and publish apps.
      </Text>
      <Text block style={{ margin: '0 0 0 0' }}>
        When assigning, if an email is already assigned to a key in your account, it will be reassigned automatically,
      </Text>
      <Text block style={{ margin: '0 0 0 0' }}>
        However if the email is already assigned to a key in a different account, it will only be reassigned if that key is expired or disabled.
      </Text>
      <Text block style={{ margin: '0 0 10px 0' }}>
        New users can register
        {' '}
        <Link target="_blank" href={Env.REGISTER_URL}>here</Link>
        {' '}
        and existing users can log in
        {' '}
        <Link target="_blank" href="https://portal.agena.ai">here</Link>
        .
      </Text>
      <table className={[Theme.styles.table, Theme.styles.tableNoPadding, Theme.styles.tableText, Theme.styles.tableRowHighlight].join(' ')}>
        <thead>
          <tr>
            <th>License Key</th>
            <th>&nbsp;</th>
            <th>Expires</th>
            <th colSpan={2}>Assigned</th>
            <th>Product</th>
            <th>Condition</th>
            <th>Type</th>
            <th>&nbsp;</th>
            <th>Owner</th>
          </tr>
        </thead>
        <tbody>
          {
            availableKeys.map((keyData) => <KeyRow keyData={keyData} key={keyData.key} />)
          }
        </tbody>
      </table>
      <AssignDialog />
    </div>
  );
};
