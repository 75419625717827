import { mergeStyleSets } from '@fluentui/react';

export default mergeStyleSets({
  menuItemList: {
    selectors: {
      'li>button:hover': {
        border: 'none !important',
        outline: 'none !important',
      },
    },
  },

  menuItemSelected: {
    backgroundColor: '#f3f2f1',
  },

  headerSection: {
    fontWeight: 400,
    fontSize: '18px',
  },

  headerSubsection: {
    fontWeight: 400,
    fontSize: '15px',
  },

  sectionTabHandle: {
    paddingTop: '10px',
    paddingLeft: '0px',
  },

  sideMinimizeButton: {
    width: '24px',
    minWidth: 'auto',
    height: '22px',
    padding: '0',
    paddingTop: '4px',
    paddingBottom: '4px',
    paddingLeft: '8px',
    marginTop: '3px',

    selectors: {
      i: {
        fontSize: '12px',
      },
    },
  },
});
