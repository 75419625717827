import React from 'react';
import Store from '../../../shared/state/Store';
import ApplicationProperties from './application-properties/ApplicationProperties';
import SelectInputsOutputs from './SelectInputsOutputs';
import ConfigureInputs from './configure-inputs/ConfigureInputs';
import ConfigureOutputs from './configure-outputs/ConfigureOutputs';
import MenuPage from '../MenuPage';
import Routing from '../../../shared/Routing';

export default ({ disabledPivotProps }) => {
  const [globalState] = Store.useStore();
  const routingTree = Routing.getTree(globalState).home.routes.webAppDesigner.routes.design;

  return (
    <MenuPage
      root={routingTree}
      defaultPath={routingTree.routes.selectInputsOutputs.path}
      elements={[
        <ApplicationProperties />,
        <SelectInputsOutputs />,
        <ConfigureInputs />,
        <ConfigureOutputs />,
      ]}
      pivotButtonProps={[
        disabledPivotProps,
        disabledPivotProps,
        disabledPivotProps,
        disabledPivotProps,
      ]}
    />
  );
};
