import React from 'react';
import { Stack } from '@fluentui/react';
import Output from './Output';
import CollapsiblePanel from '../../shared/CollapsiblePanel';
import Utils from '../../shared/Utils';

export default ({
  config,
}) => {
  const {
    groupName, description, items, collapsible, collapsed,
  } = config;

  return (
    <CollapsiblePanel
      collapsible={collapsible}
      collapsed={collapsed}
      title={groupName}
      description={description}
      keyOverride={groupName}
      renderContentsWhenCollapsed={false}
    >
      <Stack
        horizontal
        wrap
        tokens={{
          childrenGap: '10 10',
        }}
      >
        {
        items.map((output) => {
          const key = Utils.createNetworkNodeKey(output.network, output.node);
          return (
            <Output
              key={key}
              config={output}
            />
          );
        })
      }
      </Stack>
    </CollapsiblePanel>
  );
};
