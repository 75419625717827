import React from 'react';
import {
  LabelList,
  BarChart, Bar, XAxis, YAxis, ResponsiveContainer,
} from 'recharts';

import ChartDefaults from '../../shared/ChartDefaults';
import Utils from '../../shared/Utils';
import VerticalBarChartHelper from './VerticalBarChartHelper';

const labelFormatter = (value) => {
  if (value === 0) {
    return '';
  }
  return `${value}%`;
};

export default ({
  baselineData, userData, config, generalConfig, chartRef, className,
}) => {
  const isIntervalNode = ['ContinuousInterval', 'IntegerInterval'].includes(config.nodeType);
  const multipleDataSets = !!(baselineData && userData);
  const [rotateXAxisTicks, setRotateXAxisTicks] = React.useState(false);
  const [rotateBarLabels, setRotateBarLabels] = React.useState(false);

  const formatData = (decimalPlaces) => {
    // Determine which dataset will drive the map
    const array = baselineData || userData;
    if (!array || array.length === 0) {
      return [];
    }

    if (isIntervalNode && baselineData && userData) {
      return VerticalBarChartHelper.mergeDataSets({
        dataSet1: baselineData,
        dataSet1Name: 'baseline',
        dataSet2: userData,
        dataSet2Name: 'user',
        decimalPlaces,
      });
    }

    return array.map((result, i) => {
      const dataPoint = {
        name: result.label,
      };

      if (isIntervalNode) {
        const isInterval = result.label.indexOf(' - ') >= 0;
        if (isInterval) {
          const rangeSplit = result.label.split(' - ');
          const lower = Utils.roundFormat(parseFloat(rangeSplit[0]), decimalPlaces, ChartDefaults.decimalPlaces);
          const upper = Utils.roundFormat(parseFloat(rangeSplit[1]), decimalPlaces, ChartDefaults.decimalPlaces);
          dataPoint.name = `${lower} - ${upper}`;
        } else {
          dataPoint.name = Utils.roundFormat(parseFloat(result.label), decimalPlaces, ChartDefaults.decimalPlaces);
        }
      }

      if (baselineData) {
        dataPoint.baseline = Utils.roundFormat(baselineData[i].value * 100, decimalPlaces, ChartDefaults.decimalPlaces);
      }

      if (userData) {
        dataPoint.user = Utils.roundFormat(userData[i].value * 100, decimalPlaces, ChartDefaults.decimalPlaces);
      }

      return dataPoint;
    });
  };

  const CustomXAxisTick = (props) => {
    const {
      x, y, payload,
    } = props;

    let xOffset = 0;
    let yOffset = 15;
    let rotation = 0;
    let anchor = 'middle';
    let style = {};

    if (isIntervalNode || rotateXAxisTicks) {
      xOffset = 0;
      yOffset = 5;
      rotation = -45;
      anchor = 'end';
      style = { fontSize: '13px' };
    }

    return (
      <g transform={`translate(${x + xOffset},${y + yOffset}) rotate(${rotation})`}>
        <text
          x={0}
          y={0}
          textAnchor={anchor}
          fill={ChartDefaults.ThemeColors.AxisTick}
          style={style}
        >
          {payload?.value}
        </text>
      </g>
    );
  };

  const BarLabel = (props) => {
    const {
      x, y, width, value,
    } = props;

    let xOffset;
    let yOffset;
    let rotation;
    let anchor;

    if ((isIntervalNode && multipleDataSets) || rotateBarLabels) {
      xOffset = width / 2;
      yOffset = -5;
      rotation = -65;
      anchor = 'start';
    } else {
      xOffset = width / 2;
      yOffset = -5;
      rotation = 0;
      anchor = 'middle';
    }

    return (
      <text
        transform={`translate(${x + xOffset},${y + yOffset}) rotate(${rotation})`}
        fill={ChartDefaults.ThemeColors.DataLabel}
        style={{ fontWeight: 'bold', fontSize: '12px' }}
        textAnchor={anchor}
      >
        {labelFormatter(value)}
      </text>
    );
  };

  const chartData = formatData((config.decimalPlaces !== undefined) ? config.decimalPlaces : 2);

  const heightCss = config.height || `${ChartDefaults.CHART_HEIGHT}px`;

  const margins = {};
  ['Left', 'Right', 'Top', 'Bottom'].forEach((p) => {
    const paddingKey = `padding${p}`;
    if (config[paddingKey]) {
      margins[p.toLowerCase()] = parseInt(config[paddingKey], 10);
    }
    // const marginKey2 = `margin${p}`;
    // if (config[marginKey2]) {
    //   margins[p.toLowerCase()] = parseInt(config[marginKey2], 10);
    // }
  });

  const [paddingTopAuto, setPaddingTopAuto] = React.useState(0);
  const [paddingBottomAuto, setPaddingBottomAuto] = React.useState(0);
  const [paddingLeftAuto, setPaddingLeftAuto] = React.useState(undefined);
  const [paddingRightAuto, setPaddingRightAuto] = React.useState(undefined);

  const adjustMargins = (svg) => {
    if (!svg) {
      return;
    }

    if (!isIntervalNode && !rotateBarLabels) {
      // Handle if likely bar label collision
      let overlap = false;
      [].forEach.call(svg.getElementsByClassName('recharts-bar'), ((dataSetLayer) => {
        const barSectionWidth = dataSetLayer.getElementsByClassName('recharts-bar-rectangles')[0].getBoundingClientRect().width;
        const barLabelsWidth = dataSetLayer.getElementsByClassName('recharts-label-list')[0].getBoundingClientRect().width;
        if (barLabelsWidth > barSectionWidth) {
          overlap = true;
        }
      }));
      if (overlap) {
        setRotateBarLabels(true);
        return;
      }
    }

    // Handle likely X-axis tick collision
    if (!isIntervalNode && !rotateXAxisTicks) {
      const xAxis = svg.getElementsByClassName('recharts-xAxis')[0];
      const xAxisWidth = xAxis.getBoundingClientRect().width;
      let cumulativeTickWidth = 0;
      [].forEach.call(xAxis.getElementsByClassName('recharts-cartesian-axis-tick'), ((el) => {
        cumulativeTickWidth += el.getBoundingClientRect().width;
      }));
      if (cumulativeTickWidth > (xAxisWidth + 10)) {
        setRotateXAxisTicks(true);
        return;
      }
    }

    const canvasLeft = svg.getBoundingClientRect().x;
    const yAxis = svg.getElementsByClassName('recharts-yAxis')[0];
    const yAxisLeft = yAxis.getBoundingClientRect().x;
    const yAxisClipped = canvasLeft - yAxisLeft;
    if (yAxisClipped > 0) {
      setPaddingLeftAuto(yAxisClipped);
    }

    const canvasBottom = svg.getBoundingClientRect().y + svg.getBoundingClientRect().height;
    const xAxis = svg.getElementsByClassName('recharts-xAxis')[0];
    const xAxisBottom = xAxis.getBoundingClientRect().y + xAxis.getBoundingClientRect().height;
    const xAxisClipped = xAxisBottom - canvasBottom;
    if (xAxisClipped > 0) {
      setPaddingBottomAuto(paddingBottomAuto + xAxisClipped);
    } else {
      setPaddingBottomAuto(paddingBottomAuto - (canvasBottom - xAxisBottom));
    }

    const canvasTop = svg.getBoundingClientRect().y;
    const currentPaddingTop = margins.top || 0;
    let deltaTop = 0;
    [].forEach.call(svg.getElementsByClassName('recharts-bar'), ((el) => {
      const elTop = el.getBoundingClientRect().y;
      const deltaElTop = canvasTop + currentPaddingTop - elTop;
      deltaTop = Math.max(deltaTop, deltaElTop);
    }));

    if (deltaTop < 0) {
      deltaTop = undefined;
    }
    setPaddingTopAuto(deltaTop);
  };

  React.useEffect(() => {
    setTimeout(() => {
      try {
        adjustMargins(Utils.getSvg(chartRef));
      } catch (error) {
        console.log(error);
      }
    }, 10);
  }, [baselineData, userData, config, rotateXAxisTicks, rotateBarLabels]);

  margins.top = margins.top !== undefined ? margins.top : paddingTopAuto;
  margins.bottom = margins.bottom !== undefined ? margins.bottom : paddingBottomAuto;
  margins.left = margins.left !== undefined ? margins.left : paddingLeftAuto;
  margins.right = margins.right !== undefined ? margins.right : paddingRightAuto;

  return (
    <>
      <div
        style={{
          width: '100%',
          height: heightCss,
          background: 'white',
        }}
        ref={chartRef}
        className={className}
      >
        <ResponsiveContainer>
          <BarChart
            margin={margins}
            data={chartData}
            layout="horizontal"
            style={{
              fontFamily: '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
            }}
          >
            <YAxis
              type="number"
              stroke={ChartDefaults.ThemeColors.AxisLine}
              tick={{ fill: ChartDefaults.ThemeColors.AxisTick }}
            />

            <XAxis
              type="category"
              stroke={ChartDefaults.ThemeColors.AxisLine}
              dataKey="name"
              interval={0}
              angle={-90}
              tick={<CustomXAxisTick />}
            />

            {baselineData && (
              <Bar
                isAnimationActive={false}
                dataKey="baseline"
                fill={generalConfig.colorBaseline || ChartDefaults.ColorBaseline}
                // label={{ position: 'top', formatter: labelFormatter, style: { fontWeight: 'bold', fontSize: '12px' } }}
              >
                <LabelList dataKey="baseline" content={BarLabel} position="top" />
              </Bar>
            )}
            {userData && (
              <Bar
                isAnimationActive={false}
                dataKey="user"
                fill={generalConfig.colorUser || ChartDefaults.ColorUser}
                // label={{ position: 'top', formatter: labelFormatter, style: { fontWeight: 'bold' } }}
              >
                <LabelList dataKey="user" content={BarLabel} position="top" />
              </Bar>
            )}
          </BarChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};
