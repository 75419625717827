import React from 'react';
import { Separator, Stack } from '@fluentui/react';
import PlaceholderError from '../shared/PlaceholderError';
import Store from '../shared/state/Store';
import UiReducer from '../shared/state/UiReducer';
import User from '../shared/User';
import Theme from '../shared/Theme';
import SubscriptionItem from './subscription/SubscriptionItem';
import CollapsiblePanel from '../shared/CollapsiblePanel';

export default () => {
  const [globalState, dispatch] = Store.useStore();

  React.useEffect(() => {
    (async () => {
      await User.fetchAccounts({ dispatch, displayErrors: false });
    })();
  }, []);

  const accountInfo = UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.AccountInfo);
  const accountInfoServerError = UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.AccountInfoServerError);
  const availableKeys = User.getAvailableKeys(globalState);

  if (accountInfoServerError) {
    return (<PlaceholderError message="Server is unavailable at this time, please try again later." />);
  }

  if (!accountInfo || Object.keys(accountInfo).length === 0) {
    return (<PlaceholderError message="Loading account data..." />);
  }

  if (availableKeys.length === 0) {
    return (<PlaceholderError message="No agena.ai licenses registered or assigned to your account." />);
  }

  const effectiveSubscriptionAccount = User.getEffectiveSubscriptionAccount(globalState);

  let ownSubscriptionSection;
  if (effectiveSubscriptionAccount.email !== accountInfo.primaryAccount.email) {
    // Effective subscription different from own subscription
    ownSubscriptionSection = (
      <>
        <Separator />
        <CollapsiblePanel
          collapsible
          collapsed
          title="Own Subscription"
          styles={{ header: { fontSize: 'initial' }, icon: { fontSize: 'smaller', marginTop: '3px' } }}
        >
          <SubscriptionItem subscriptionAccount={accountInfo.primaryAccount} />
        </CollapsiblePanel>
      </>
    );
  }

  return (
    <Stack vertical>
      <header className={Theme.styles.sectionHeader}>Effective Subscription</header>
      <SubscriptionItem subscriptionAccount={effectiveSubscriptionAccount} includeUsage />
      {ownSubscriptionSection}
    </Stack>
  );
};
