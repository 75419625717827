import React from 'react';
import Layout from './Layout';
import Report from './Report/Report';
import Form from './InputForm/Form';
import Store from '../shared/state/Store';
import DataReducer from '../shared/state/DataReducer';
import AppReducer from '../shared/state/AppReducer';
import Config from '../shared/Config';
import Env from '../shared/Env';
import LoadingScreen from './LoadingScreen';
import UiReducer from '../shared/state/UiReducer';
import AppsApi from '../shared/util/AppsApi';
import ConfigReducer from '../shared/state/ConfigReducer';
import PrintReport from './print/PrintReport';
import Theme from '../shared/Theme';
import PrintDialog from './print/PrintDialog';
import ModellerReducer from '../modeller/ModellerReducer';

export default () => {
  const [globalState, dispatch] = Store.useStore();
  const reportRef = React.useRef(null);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    if (!loading) {
      return;
    }

    if (!UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.WebbAppModelViewSelected) && (ConfigReducer.isInputConfigMapped(globalState) || ConfigReducer.isOutputConfigMapped(globalState))) {
      setLoading(false);
    }
  }, [
    ConfigReducer.isInputConfigMapped(globalState),
    ConfigReducer.isOutputConfigMapped(globalState),
  ]);

  React.useEffect(() => {
    if (!loading) {
      return;
    }
    if (UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.WebbAppModelViewSelected) && ModellerReducer.getModel(globalState)) {
      setLoading(false);
    }
  }, [
    ModellerReducer.getModel(globalState),
  ]);

  React.useEffect(() => {
    // Load web app config if cloud or self hosted
    (async () => {
      if (Config.ApplicationModes.WebAppCloudHosted === Config.getApplicationMode()) {
      // Instructed to load a cloud app directly
        const domain = window.location.hostname;
        const response = await AppsApi.getApps({ 'app.domain': domain });
        setLoading(false);
        if (response.code >= 300) {
          dispatch(UiReducer.uiSetErrors(response.messages));
        } else {
          if (response.length === 0) {
            dispatch(UiReducer.uiSetErrors(['App not found']));
            return;
          }

          const { configuration } = response[0];
          dispatch(AppReducer.loadApp(configuration));
          dispatch(AppReducer.setAppData({ ...response[0], model: undefined }));
        }
      }

      if (Config.ApplicationModes.WebAppCloudHostedPublic === Config.getApplicationMode()) {
        // Instructed to load a public cloud app directly
        const domain = window.location.hostname;
        const response = await AppsApi.getPublicApps({ 'app.domain': domain });
        setLoading(false);
        if (response.code >= 300) {
          dispatch(UiReducer.uiSetErrors(response.messages));
        } else {
          if (response.length === 0) {
            dispatch(UiReducer.uiSetErrors(['App not found']));
            return;
          }

          const { configuration } = response[0];
          dispatch(AppReducer.loadApp(configuration));
          dispatch(AppReducer.setAppData({ ...response[0], model: undefined }));
        }
      }

      if (Config.ApplicationModes.WebAppSelfHosted === Config.getApplicationMode()) {
        // Instructed to load a self-hosted app directly
        const location = Env.CONFIG_LOCATION;
        fetch(location)
          .then(async (response) => {
            setLoading(false);
            let json = {};
            try {
              json = await response.json();
              dispatch(AppReducer.loadApp(json));
            } catch (error) {
            // Not JSON
              dispatch(UiReducer.uiSetErrors([
                'Invalid app config format',
                error?.message,
              ]));
            }
          })
          .catch((error) => {
            let { message } = error;
            if (message === 'Failed to fetch') {
              message = 'Server or resource is unavailable at this time, please try again later.';
            }
            dispatch(UiReducer.uiSetErrors([message]));
          });
      }
    })();
  }, []);

  if (loading) {
    return <LoadingScreen />;
  }

  if (!ConfigReducer.isInputConfigMapped(globalState) || !ConfigReducer.isOutputConfigMapped(globalState)) {
    return <></>;
  }

  return (
    <>
      <PrintDialog />
      <PrintReport />
      <Layout className={Theme.styles.noPrint}>
        <Form reportRef={reportRef} />

        {(DataReducer.hasCachedResults(globalState)) && (
        <Report reportRef={reportRef} />
        )}

      </Layout>
    </>
  );
};
