import { mergeStyleSets } from '@fluentui/react';
import React from 'react';
import CollapsiblePanel from '../../shared/CollapsiblePanel';

const styles = mergeStyleSets({
  table: {
    selectors: {
      th: {
        border: '1px solid gray',
        textAlign: 'center',
        padding: '0 20px',
      },

      td: {
        border: '1px solid gray',
        padding: '0 5px',
      },
    },
  },
});

export default ({ log }) => (
  <CollapsiblePanel
    title="Calculation Log"
    styles={{
      header: {
        fontSize: 'small',
      },
    }}
    collapsible
    collapsed
  >
    <table className={styles.table}>
      <thead>
        <tr>
          <th rowSpan={2}>Time</th>
          <th rowSpan={2}>ID</th>
          <th colSpan={3}>Time (s)</th>
          <th colSpan={3}>RAM (MiB)</th>
        </tr>
        <tr>
          <th>Calc</th>
          <th>Wait</th>
          <th>Total</th>

          <th>Free</th>
          <th>Max</th>
          <th>Total</th>

        </tr>
      </thead>
      <tbody>
        {log && log.map((row) => (
          <tr key={row.time}>
            <td>{row.time}</td>
            <td>{row.id}</td>

            <td>{row.duration.calculation / 1000 || ''}</td>
            <td>{(row.duration.turnaround - row.duration.calculation) / 1000 || ''}</td>
            <td>{row.duration.turnaround / 1000 || ''}</td>

            <td>{row.memory.free}</td>
            <td>{row.memory.max}</td>
            <td>{row.memory.total}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </CollapsiblePanel>
);
