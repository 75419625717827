import React from 'react';
import { Separator, Stack } from '@fluentui/react';
import {
  useNavigate, useLocation, Routes, Route,
} from 'react-router-dom';
import Store from '../shared/state/Store';
import LicenseKeyList from './LicenseKeyList';
import Menu, { DefaultNavOption } from './Menu';
import Subscription from './Subscription';
import StackTokens from '../shared/StackTokens';
import Theme from '../shared/Theme';
import Routing from '../shared/Routing';

export default () => {
  const [globalState] = Store.useStore();
  const navigate = useNavigate();
  const location = useLocation();
  const routingTree = Routing.getTree(globalState).home.routes.account;

  React.useEffect(() => {
    if (location.pathname === routingTree.path) {
      // If no subcomponent selected, navigate to default subcomponent
      navigate(DefaultNavOption, { replace: true });
    }
  }, [location.pathname]);

  return (
    <Stack vertical>
      <header className={Theme.styles.menuHeader}>My Portal Account</header>

      <Stack horizontal tokens={StackTokens.spacing}>
        <Stack vertical>
          <Menu />
        </Stack>
        <Separator vertical />
        <Stack vertical grow>
          <Routes>
            <Route element={<Subscription />} path={routingTree.routes.subscription.getRelativePath(routingTree.path)} />
            <Route element={<LicenseKeyList />} path={routingTree.routes.licenseKeys.getRelativePath(routingTree.path)} />
          </Routes>
        </Stack>
      </Stack>
    </Stack>
  );
};
