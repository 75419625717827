import UiReducer from '../state/UiReducer';

const StateKeys = {
  HelpSystemShowModal: 'HelpSystemShowModal',
  HelpSystemContextStack: 'HelpSystemContextStack',
  HelpSystemContextOverride: 'HelpSystemContextOverride',
};

const Catalog = {
  WebAppDesigner: 'About Web App Designer',
};

const registerHelpLayer = (dispatch, key) => () => {
  dispatch(UiReducer.pushToList(StateKeys.HelpSystemContextStack, key));
  return () => {
    dispatch(UiReducer.popFromList(StateKeys.HelpSystemContextStack));
  };
};

const getContext = (globalState) => UiReducer.getGenericUiListLastItem(globalState, StateKeys.HelpSystemContextStack);

const showModal = (dispatch) => {
  dispatch(UiReducer.setGenericUiAttribute(StateKeys.HelpSystemShowModal, true));
};

const hideModal = (dispatch) => {
  dispatch(UiReducer.setGenericUiAttribute(StateKeys.HelpSystemShowModal, false));
  dispatch(UiReducer.setGenericUiAttribute(StateKeys.HelpSystemContextOverride, undefined));
};

const isShowModal = (globalState) => !!UiReducer.getGenericUiAttribute(globalState, StateKeys.HelpSystemShowModal);

const isContentOverridden = (globalState) => !!UiReducer.getGenericUiAttribute(globalState, StateKeys.HelpSystemContextOverride);

const getOverrideContent = (globalState) => UiReducer.getGenericUiAttribute(globalState, StateKeys.HelpSystemContextOverride);

const overrideContent = (dispatch, content) => {
  dispatch(UiReducer.setGenericUiAttribute(StateKeys.HelpSystemContextOverride, content));
  showModal(dispatch);
};

export default {
  Catalog,
  registerHelpLayer,
  getContext,
  showModal,
  hideModal,
  isShowModal,
  isContentOverridden,
  getOverrideContent,
  overrideContent,
};
