import {
  mergeStyleSets, Stack,
} from '@fluentui/react';
import React from 'react';
// import example from '../resources/examples/asia.json';
// import example from '../dev/River Flooding.json';
// import example from '../dev/cont-chart.json';
import example from '../resources/modeller-examples/car-costs-model.json';
import StackTokens from '../shared/StackTokens';
import ModellerReducer from './ModellerReducer';
import MessageBar from '../shared/MessageBar';
import Store from '../shared/state/Store';
import Menu from './menu/Menu';
import CytoReducer from './ModelPreview/cytoscape/CytoReducer';
import ModellerComponents from './ModellerComponents';
import Dialogs from './windows/Dialogs';
import ModellerDefaults from './ModellerDefaults';

const componentStyles = mergeStyleSets({
  root: {
    paddingLeft: '5px',
    width: '100vw',
    maxWidth: '100%',
    height: '89vh',
    overflowY: 'auto',
    // background: 'red',
  },
});

export default ({ mode }) => {
  const [globalState, dispatch] = Store.useStore();
  const [model, setModel] = React.useState();

  React.useEffect(() => {
    // TEMP FOR DEMO
    if (mode === ModellerDefaults.OperationMode.ModellerWebApp) {
      return;
    }
    dispatch(ModellerReducer.loadModel(example.model));
  }, []);

  React.useEffect(() => {
    setModel(ModellerReducer.getModel(globalState));
  }, [ModellerReducer.getModel(globalState)]);

  React.useEffect(() => {
    dispatch(ModellerReducer.mapResults(model, Date.now()));
    dispatch(ModellerReducer.mapObservations(model));
    setTimeout(() => {
      // Allow for charts to update
      dispatch(CytoReducer.setRepaintRequested());
    }, 200);
  }, [model?.dataSets]);

  let errorMessage;

  if (!model) {
    errorMessage = 'No valid model loaded';
  } else if (!model.networks || Object.keys(model.networks).length <= 0) {
    errorMessage = 'No networks in the model';
  }

  return (
    <Stack
      vertical
      tokens={StackTokens.spacing}
      className={componentStyles.root}
    >

      <Menu mode={mode} />

      {
        errorMessage && (
          <MessageBar
            text={errorMessage}
            onDismiss={null}
          />
        )
      }

      {
        !errorMessage && <ModellerComponents />
      }

      <Dialogs />
    </Stack>
  );
};
