import React from 'react';
import { Resizable } from 'react-resizable';
import { DefaultButton, mergeStyleSets, Stack } from '@fluentui/react';
import 'react-resizable/css/styles.css';
import Charts from './Charts';
import MiddleSection from './MiddleSection';
import Explorer from './Explorer';
import ModellerDefaults from './ModellerDefaults';
import Styles from './Styles';
import Theme from '../shared/Theme';
import './ModellerComponents.css';

const componentStyles = mergeStyleSets({
  root: {
    // width: '100vw',
  },
  leftContainer: {
    overflowX: 'auto',
  },
  left: {
  },
  middleContainer: {
    width: ModellerDefaults.ComponentDefaults.MiddleWidth,
    marginLeft: '5px !important',
    marginRight: '5px !important',
  },
  middle: {
    width: '100%',
    overflow: 'auto',
  },
  rightContainer: {
    overflowX: 'auto',
    // paddingLeft: '5px',
    // marginLeft: '-24px !important',
  },
  right: {

  },

  handle: {
    background: 'red',
    width: '2px',
    height: '100%',
  },
  handleLeft: {
  },
  handleRight: {
  },
  sizeResetButton: {
    position: 'absolute',
    right: '5px',
    zIndex: 1,
  },
  minimiseRight: {
    position: 'relative',
    left: '224px',
    float: 'left',
  },
  minimised: {
    overflowX: 'hidden',
  },
});

export default () => {
  const [leftMinimised, setLeftMinimised] = React.useState();
  const [rightMinimised, setRightMinimised] = React.useState();

  const defaultWidths = () => {
    const wViewport = window.innerWidth;
    const left = ModellerDefaults.ComponentDefaults.LeftWidthMin + 20;
    const right = ModellerDefaults.ComponentDefaults.RightWidthMin + 20;
    const middle = wViewport - left - right;
    return {
      left, middle, right,
    };
  };

  const [widths, setWidths] = React.useState({ ...defaultWidths() });

  const leftRef = React.useRef();
  const middleRef = React.useRef();
  const rightRef = React.useRef();

  const resetSizes = () => {
    setWidths({ ...defaultWidths() });
  };

  React.useEffect(() => {
    resetSizes();
  }, []);

  const resize = (event, handle) => {
    if (handle === 'w') {
      const leftRect = leftRef.current.getBoundingClientRect();
      const leftRightEnd = leftRect.left + leftRect.width;
      const leftDelta = event.pageX - leftRightEnd;
      const leftNewWidth = leftRect.width + leftDelta;

      const middleRect = middleRef.current.getBoundingClientRect();
      const middleNewWidth = middleRect.width - leftDelta;

      setWidths({
        ...widths,
        left: leftNewWidth,
        middle: middleNewWidth,
      });
    }

    if (handle === 'e') {
      const rightRect = rightRef.current.getBoundingClientRect();
      const rightLeftEnd = rightRect.left;
      const rightDelta = event.pageX - rightLeftEnd;
      const rightNewWidth = rightRect.width - rightDelta;
      const rightNewWidthLimited = Math.max(rightNewWidth, 25);

      const limitDelta = rightNewWidthLimited - rightNewWidth;

      const middleRect = middleRef.current.getBoundingClientRect();
      const middleNewWidth = middleRect.width + rightDelta - limitDelta;

      setWidths({
        ...widths,
        right: rightNewWidthLimited,
        middle: middleNewWidth,
      });
    }
  };

  const resizeMiddleFor = (handle, sideMinimised) => {
    let multiplier = sideMinimised ? 1 : -1;
    if (sideMinimised === undefined) {
      multiplier = 0;
    }

    if (handle === 'w') {
      const leftWidthDiff = (widths.left || defaultWidths().left) - ModellerDefaults.ComponentDefaults.MinimisedWidth;

      const middleRect = middleRef.current.getBoundingClientRect();
      const middleNewWidth = middleRect.width + leftWidthDiff * multiplier;

      setWidths({
        ...widths,
        middle: middleNewWidth,
      });
    }

    if (handle === 'e') {
      const rightWidthDiff = (widths.right || defaultWidths().right) - ModellerDefaults.ComponentDefaults.MinimisedWidth;

      const middleRect = middleRef.current.getBoundingClientRect();
      const middleNewWidth = middleRect.width + rightWidthDiff * multiplier;

      setWidths({
        ...widths,
        middle: middleNewWidth,
      });
    }
  };

  React.useEffect(() => {
    resizeMiddleFor('w', leftMinimised);
  }, [leftMinimised]);

  React.useEffect(() => {
    resizeMiddleFor('e', rightMinimised);
  }, [rightMinimised]);

  //   const leftHandleRef = React.useRef();
  //   const rightHandleRef = React.useRef();

  //   const getHandle = (location) => {
  //     if (location === 'w' && leftHandleRef.current){
  //         return leftHandleRef;
  //     }

  //     else if (location === 'e' && rightHandleRef.current){
  //         return rightHandleRef;
  //     }

  //     return undefined;
  //   }

  // React.useEffect(() => {
  //   if (!leftRef.current || middleRef.current || rightRef.current) {
  //     return;
  //   }

  //   setWidths({
  //     left: leftRef.current.getBoundingClientRect().width,
  //     middle: middleRef.current.getBoundingClientRect().width,
  //     right: rightRef.current.getBoundingClientRect().width,
  //   });
  // }, []);

  const resizeHandles = [];
  if (!leftMinimised) {
    resizeHandles.push('w');
  }
  if (!rightMinimised) {
    resizeHandles.push('e');
  }

  const windowResizeListener = () => {
    resetSizes();
  };
  React.useEffect(() => {
    window.addEventListener('resize', windowResizeListener);
    return () => {
      document.removeEventListener('resize', windowResizeListener);
    };
  }, []);

  return (
    <Stack
      horizontal
      // tokens={StackTokens.spacing}
      className={componentStyles.root}
    >

      <div
        ref={leftRef}
        className={[componentStyles.leftContainer, leftMinimised ? componentStyles.minimised : ''].join(' ')}
        style={{
          width: `${leftMinimised ? ModellerDefaults.ComponentDefaults.MinimisedWidth : (widths.left || defaultWidths().left)}px`,
        }}
      >
        <Explorer
          className={componentStyles.left}
          minimised={leftMinimised}
          setMinimised={setLeftMinimised}
        />
      </div>

      <Resizable
          // resizable
        axis="x"
        width={Math.ceil(window.innerWidth * ModellerDefaults.ComponentDefaults.MiddleWidthMultiplier)}
          // height='100%'
        minConstraints={[100, 100]}
          // maxConstraints={[window.innerWidth* 0.98, Infinity]}
        maxConstraints={[Infinity, Infinity]}
        resizeHandles={resizeHandles}
        // handle={<div style={{width: '10px', height: '10px', background: 'blue'}}/>}
        handleSize={[2, window.innerHeight * ModellerDefaults.ComponentDefaults.ComponentHeightMultipler]}

        onResizeStart={(e, data) => {
          console.log('start', e, data);
          // setSnapshotHeights([...rowHeights]);
          // // console.log('starting heights', [...rowHeights]);
        }}

        onResize={(e, data) => {
          console.log('on resize', e, data);
          // //   const updatedTempHeight = [...tempHeight];
          // //   updatedTempHeight[rowIndex] = data.size;
          // //   setTempHeight(updatedTempHeight);
          // const deltaHeight = data.size.height - snapShotHeights[rowIndex];
          // // console.log('r delta height', deltaHeight);
          // const updatedRowHeights = [...snapShotHeights];
          // // console.log(rowIndex + 1, updatedRowHeights[rowIndex + 1], '->', updatedRowHeights[rowIndex + 1] - deltaHeight);
          // updatedRowHeights[rowIndex] += deltaHeight;
          // updatedRowHeights[rowIndex + 1] -= deltaHeight;
          // // console.log('update', updatedRowHeights);
          // setRowHeights(updatedRowHeights);

          // const updatedLabels = [...rowLabels];
          // updatedLabels[rowIndex] = heightToValue(updatedRowHeights[rowIndex]);
          // updatedLabels[rowIndex + 1] = heightToValue(updatedRowHeights[rowIndex + 1]);
          // setRowLabels(updatedLabels);
        }}

        onResizeStop={(e, data) => {
          console.log('stop', e, data);
          // const deltaHeight = data.size.height - snapShotHeights[rowIndex];
          // // console.log('final delta height', deltaHeight);
          // // const updatedRowHeights = [...rowHeights];
          // // updatedRowHeights[rowIndex] = data.size.height;
          // // // console.log('final heights', updatedRowHeights);
          // // setRowHeights(updatedRowHeights);
          // setColumnData(rowHeights.map((height) => heightToValue(height)));
          resize(e, data.handle);
        }}
      >
        <div
          ref={middleRef}
          className={componentStyles.middleContainer}
          style={{
            width: `${widths.middle}px` || ModellerDefaults.ComponentDefaults.MiddleWidth,
          }}
        >
          <div className={componentStyles.sizeResetButton}>
            <DefaultButton
              text=""
              iconProps={{
                iconName: 'Embed',
              }}
              className={[Theme.styles.outlineControlButton, Styles.sideMinimizeButton].join(' ')}
              onClick={async () => {
                setLeftMinimised(false);
                setRightMinimised(false);
                setTimeout(() => {
                  resetSizes();
                }, 50);
              }}
              title="Reset panel size"
            />
          </div>
          <div className={componentStyles.middle}>
            <MiddleSection />
          </div>
        </div>
      </Resizable>

      <div
        ref={rightRef}
        className={[componentStyles.rightContainer, rightMinimised ? componentStyles.minimised : ''].join(' ')}
        style={{
          width: `${rightMinimised ? ModellerDefaults.ComponentDefaults.MinimisedWidth : (widths.right || defaultWidths().right)}px`,
        }}
      >
        {/* <div className={componentStyles.minimiseRight}>
          <DefaultButton
            text=""
            iconProps={{
              iconName: 'ChevronRight',
            }}
            className={[Theme.styles.outlineControlButton, Styles.sideMinimizeButton].join(' ')}
            onClick={async () => {
              setRightMinimised(true);
            }}
            title="Minimise"
          />
        </div> */}
        <Charts
          className={componentStyles.right}
          minimised={rightMinimised}
          setMinimised={setRightMinimised}
        />
      </div>

    </Stack>
  );
};
