import { Icon, mergeStyleSets } from '@fluentui/react';
import React from 'react';

const styles = mergeStyleSets({
  key: {
    display: 'inline-block',
    margin: '2px 0 2px 0',
  },

  bold: {
    fontWeight: 600,
  },

  assignedIcon: {
    cursor: 'pointer',
  },

  hide: {
    display: 'none',
  },


});

export default ({ keyData }) => {
  const {
    key, expiry, policy, condition, product, assignable, allowsCreators, disabled, disabledReason, size, assignments, granted, owner, assigned,
  } = keyData;

  return (
    <tr>
      {/* <td>v</td> */}
      <td><span className={[styles.key, assigned ? styles.bold : undefined].join(' ')}>{key}</span></td>
      <td>
        <Icon
          iconName="EditCreate"
          className={(!disabled && allowsCreators && (assigned || granted)) ? styles.assignedIcon : styles.hide}
          title="This key grants Creator access"
        />
      </td>
      <td>{new Date(expiry).toLocaleDateString(navigator.languages[0], { dateStyle: 'medium' })}</td>
      <td>{`${assignments.length} / ${assignable ? size : 0}`}</td>
      <td>{/* assignable && !disabled && <AssignButton licenseKey={keyData} /> */}</td>
      <td>{`${product}`.toLowerCase()}</td>
      <td>{condition}</td>
      <td>{policy}</td>
      <td>{disabledReason}</td>
      <td><span className={granted ? undefined : styles.bold}>{owner}</span></td>
    </tr>
  );
};
