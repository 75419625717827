import React from 'react';
import Utils from '../../shared/Utils';
import LibraryItem from './LibraryItem';

const apps = Utils.importAll(require.context('../../resources/examples/', false, /\.json/));

export default () => (
  <div>
    <p>You are welcome to load an example web app configuration.</p>

    {
      Object.values(apps).map((json) => (
        <LibraryItem
          key={json.generalConfig.appName}
          fullConfig={json}
        />
      ))
    }

  </div>
);
