import React from 'react';
import { Stack } from '@fluentui/react';
import {
  Routes, Route,
} from 'react-router-dom';
import Store from '../../shared/state/Store';
import Routing from '../../shared/Routing';
import StackTokens from '../../shared/StackTokens';
import AccountsList from './AccountsList';
import AccountView from './AccountView';


export default () => {
  const [globalState] = Store.useStore();
  const routingTree = Routing.getTree(globalState).home.routes.administration.routes.accounts;

  return (
    <Stack vertical tokens={StackTokens.spacing}>
      <Routes>
        <Route element={<AccountsList />} path="" />
        <Route element={<AccountView />} path={`${routingTree.routes.account.getRelativePath(routingTree.path)}/*`} />
      </Routes>
    </Stack>
  );
};
