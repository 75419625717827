import ConfigReducer from '../../shared/state/ConfigReducer';

const Defaults = {
  showAppDescription: true,
  reportHeader: 'Report',
  inputSectionName: 'Inputs',
  noValueString: '<No value>',
  outputSectionName: 'Outputs',
  showVersioning: false,
  showTimestamp: false,
};

const appDefaults = (globalState) => {
  const { generalConfig } = ConfigReducer.getConfig(globalState);
  return {
    ...Defaults,
    ...{
      inputSectionName: generalConfig.inputSectionHeader,
      reportHeader: generalConfig.appName,
      outputSectionName: generalConfig.resultSectionHeader,
    },
  };
};

export default {
  Defaults,
  appDefaults,
};
