import { mergeStyleSets, Stack } from '@fluentui/react';
import React from 'react';
import SectionItem from './SectionItem';
import User from '../../shared/User';

const localStyles = mergeStyleSets({
  header: {
    fontSize: '16px !important',
  },
});

export default ({ versionItem, iconUnread }) => (
  <Stack vertical className="version-item">
    <h1 className={localStyles.header}>
      Version
      {' '}
      {versionItem.version}
    </h1>
    {versionItem.sections
      .filter((section) => !section.visibility || (section.visibility && User.getRealmGroups().filter((el) => section.visibility.includes(el)).length > 0))
      .map((sectionItem) => (
        <SectionItem
          key={sectionItem.version + sectionItem.name}
          sectionItem={sectionItem}
          version={versionItem.version}
          iconUnread={iconUnread}
        />
      ))}
  </Stack>
);
