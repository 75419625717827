import GeneralApi from './GeneralApi';

const { makeRequest } = GeneralApi;

const getAccounts = async (params) => makeRequest({
  path: '/private/v1/admin/accounts',
  method: 'GET',
  params,
});

const getAccount = async (uuid, params) => makeRequest({
  path: `/private/v1/admin/account/${uuid}`,
  method: 'GET',
  params,
});

const updateAccount = async (uuid, update) => makeRequest({
  path: `/private/v1/admin/account/${uuid}`,
  method: 'PUT',
  data: update,
});

const getRelatedAccounts = async (params) => makeRequest({
  path: '/private/v1/admin/related-accounts',
  method: 'GET',
  params,
});

const updateSubscription = async (uuid, update) => makeRequest({
  path: `/private/v1/admin/subscription/${uuid}`,
  method: 'POST',
  data: update,
});

export default {
  getAccounts,
  getAccount,
  updateAccount,
  getRelatedAccounts,
  updateSubscription,
};
