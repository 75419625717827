import {
  Icon, Stack, Text,
} from '@fluentui/react';
import React from 'react';
import Theme from './Theme';

export default ({ message, messages }) => (
  <Stack
    horizontalAlign="center"
    vertical
  >
    <Text block>
      <Icon
        iconName="Error"
        styles={Theme.styleObjects.iconInText}
      />
      <span>{message}</span>
    </Text>
    {
      messages?.map((msg) => (
        <Text key={msg} block>{msg}</Text>
      ))
    }
  </Stack>
);
