import React from 'react';
import {
  DefaultButton, Text,
} from '@fluentui/react';
import User from '../../shared/User';
import Store from '../../shared/state/Store';
import AppsApi from '../../shared/util/AppsApi';
import UiReducer from '../../shared/state/UiReducer';
import MessageBar from '../../shared/MessageBar';

export default () => {
  const [globalState, dispatch] = Store.useStore();

  const isActiveCreator = User.isActiveCreatorAccount(globalState);

  if (isActiveCreator) {
    return null;
  }

  const unusedCreatorKey = User.getAvailableKeys(globalState).find((key) => !key.disabled && key.allowsCreators && key.assignments.length < key.size);

  if (!unusedCreatorKey) {
    return null;
  }

  return (
    <MessageBar
      isMultiline={false}
      styles={{
        container: { width: '100%' },
      }}
      text={(
        <>
          <Text>You have an unused Creator key. Would you like to use it to access Creator features?</Text>
        </>
      )}
      actions={(
        <div>
          <DefaultButton
            text="Yes"
            onClick={async () => {
              const updatedAssignments = [...unusedCreatorKey.assignments, User.getCurrentUser()];
              const updatedKey = { key: unusedCreatorKey.key, assignments: updatedAssignments };
              const responseJson = await AppsApi.assignKeys(updatedKey);
              if (responseJson?.code >= 300 || responseJson?.error?.code >= 300) {
                dispatch(UiReducer.uiSetErrors(responseJson.messages));
              } else {
                dispatch(UiReducer.setGenericUiAttribute(UiReducer.Keys.AccountInfo, responseJson));
                dispatch(UiReducer.uiSetErrors([]));
              }
            }}
          />
        </div>
      )}
    />
  );
};
