import React from 'react';
import {
  Stack, Image, ImageFit, mergeStyleSets, IconButton,
} from '@fluentui/react';

import tokens from '../shared/StackTokens';
import './styles/common.css';
import MultiLineUserText from '../shared/MultiLineUserText';
import ConfigReducer from '../shared/state/ConfigReducer';
import Store from '../shared/state/Store';
import AppMenu from './AppMenu';
import UiReducer from '../shared/state/UiReducer';
import Modeller from '../modeller/Modeller';
import ModellerDefaults from '../modeller/ModellerDefaults';
import HeaderWithTrailingContent from '../shared/HeaderWithTrailingContent';
import Theme from '../shared/Theme';

const componentStyles = mergeStyleSets({
  root: {
    selectors: {
      'h1, h2, h3, h4': {
        'margin-bottom': '0px',
        'padding-bottom': '0px',
      },
    },
    background: 'white',
    overflowX: 'hidden',
    padding: '0 0 10px 10px',
  },
  headerButton: {
    marginTop: '13px',
    paddingRight: '10px',
    width: '40px',
    height: '40px',
    selectors: {
      i: {
        fontSize: '40px',
      },
    },
  },
});

export default ({ children, className }) => {
  const [globalState, dispatch] = Store.useStore();
  const generalConfig = ConfigReducer.getGeneralConfig(globalState);

  let switchViewButtons;

  if (globalState.config?.generalConfig?.modelViewEnabled && globalState.config?.generalConfig?.appViewEnabled) {
    if (!UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.WebbAppModelViewSelected)) {
      switchViewButtons = (
        <IconButton
          className={[Theme.styles.outlineControlButton, componentStyles.headerButton].join(' ')}
          iconProps={{ iconName: 'GitGraph' }}
          aria-label="View Model"
          title="View Model"
          onClick={() => {
            dispatch(UiReducer.setGenericUiAttribute(UiReducer.Keys.WebbAppModelViewSelected, true));
          }}
        />
      );
    } else {
      switchViewButtons = (
        <IconButton
          className={[Theme.styles.outlineControlButton, componentStyles.headerButton].join(' ')}
          iconProps={{ iconName: 'Photo2' }}
          aria-label="View App"
          title="View App"
          onClick={() => {
            dispatch(UiReducer.setGenericUiAttribute(UiReducer.Keys.WebbAppModelViewSelected, false));
          }}
        />
      );
    }
  }

  return (
    <Stack
      vertical
      className={[componentStyles.root, className].join(' ')}
      tokens={tokens.spacingBetweenGroups}
    >
      <Stack horizontal>
        <Stack.Item>
          <AppMenu style={{ height: '100%', marginTop: '2px', marginRight: '5px' }} />
        </Stack.Item>
        <HeaderWithTrailingContent
          text={generalConfig.appName}
          trailing={switchViewButtons}
          styles={{
            root: {
              width: '100%',
              maxWidth: 'auto',
              minWidth: 'auto',
            },
            header: {
              fontSize: '40px',
              fontWeight: '500',
            },
          }}
        />
      </Stack>

      {UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.WebbAppModelViewSelected) && (
        <Modeller mode={ModellerDefaults.OperationMode.ModellerWebApp} />
      )}

      {!UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.WebbAppModelViewSelected) && (
        <>
          <div>
            <Image
              alt={generalConfig.appName}
              src={generalConfig.appLogo}
              imageFit={ImageFit.none}
              maximizeFrame
              style={{
                maxWidth: '98vw',
              }}
            />
          </div>

          <MultiLineUserText>
            {generalConfig.appDescription}
          </MultiLineUserText>

          {children}
        </>
      )}

    </Stack>

  );
};
