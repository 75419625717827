import {
  SearchBox, Stack,
} from '@fluentui/react';
import React from 'react';
import tokens from '../../../../shared/StackTokens';
import ConfigReducer from '../../../../shared/state/ConfigReducer';
import Store from '../../../../shared/state/Store';
import Utils from '../../../../shared/Utils';
import InputItem from './InputItem';

export default () => {
  const [globalState] = Store.useStore();

  const [filter, setFilter] = React.useState('');
  const [items, setItems] = React.useState([]);

  const filterItems = () => {
    const inputConfig = ConfigReducer.getInputConfig(globalState);
    const fItems = [];
    inputConfig.forEach((group) => {
      group.items.forEach((groupItem) => {
        let keep = true;
        if (filter && filter.trim() !== '') {
          const toString = `${groupItem.node}${groupItem.label}`;
          keep = toString.toLowerCase().indexOf(filter) >= 0;
        }
        if (keep) {
          const key = Utils.createNetworkNodeKey(groupItem.network, groupItem.node);
          fItems.push(
            <InputItem
              key={key}
              groupItem={groupItem}
              groupName={group.groupName}
            />,
          );
        }
      });
    });
    setItems(fItems);
  };

  React.useEffect(() => {
    filterItems();
  }, [filter, globalState.inputConfigMap]);

  return (
    <Stack vertical tokens={tokens.spacing} style={{ paddingTop: '10px' }}>
      <div
        style={{
          width: '200px',
        }}
      >
        <SearchBox
          placeholder="Filter by name or ID"
          type="search"
          iconProps={{
            iconName: 'Filter',
          }}
          onChange={(ev, newValue) => setFilter(newValue.toLowerCase())}
          onSearch={(newValue) => setFilter(newValue.toLowerCase())}
          onEscape={() => {
            setFilter('');
          }}
          onClear={() => {
            setFilter('');
          }}
          value={filter}
        />
      </div>

      <Stack horizontal wrap disableShrink tokens={tokens.spacing}>
        {items}
      </Stack>

    </Stack>

  );
};
