import React from 'react';
import {
  Stack,
} from '@fluentui/react';
import { Label } from 'reactstrap';
import Store from '../../../../shared/state/Store';
import StackTokens from '../../../../shared/StackTokens';
import Callback from '../../../../shared/callback/Callback';
import AppReducer from '../../../../shared/state/AppReducer';
import User from '../../../../shared/User';
import PlaceholderError from '../../../../shared/PlaceholderError';
import ConfigReducer from '../../../../shared/state/ConfigReducer';
import Utils from '../../../../shared/Utils';

export default () => {
  const [globalState, dispatch] = Store.useStore();
  const generalConfig = ConfigReducer.getGeneralConfig(globalState);

  const updateCallback = (fieldName, fieldValue) => {
    const updatedConfig = Utils.clone(generalConfig);
    updatedConfig.callback = updatedConfig.callback || {};
    updatedConfig.callback[fieldName] = fieldValue;
    dispatch(ConfigReducer.setGeneralConfig(updatedConfig));
  };

  const app = AppReducer.getAppData(globalState);
  const appOwner = app?.owner === User.getCurrentUser();

  if (app?.uuid && !appOwner) {
    return (
      <PlaceholderError message="This section is only available to the app owner" />
    );
  }

  return (
    <>
      <Stack vertical style={{ marginTop: '10px' }} tokens={StackTokens.verticalSpacing}>

        <Label>You can customise how data is processed by your app here.</Label>

        <Callback
          config={generalConfig.callback || {}}
          updateConfig={updateCallback}
        />

      </Stack>
    </>
  );
};
