import {
  Checkbox, DefaultButton, mergeStyleSets, Stack, Text,
} from '@fluentui/react';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { Label } from 'reactstrap';
import HelpButton from '../help/HelpButton';
import StackTokens from '../StackTokens';
import TextFieldBuffered from '../TextFieldBuffered';
import Theme from '../Theme';
import CallbackHeader from './CallbackHeader';

const componentStylesRaw = {
  textFieldRoot: {
    wrapper: {
      marginTop: 'none !important',
    },
  },
  checkbox: {
    label: {
      margin: '0',
    },
  },
};

const componentStyles = mergeStyleSets({

  callbackHeaders: {
    marginTop: '0 !important',
    selectors: {
      label: {
        padding: 0,
      },
    },
  },

  callbackHeaderAddButton: {
    width: '34px',
    minWidth: 'auto',
    height: '32px',
    padding: '0',
    paddingTop: '4px',
    paddingBottom: '4px',
    paddingLeft: '8px',
    // height: '30px',
    // paddingLeft: '0px',
    // selectors: {
    //   i: {
    //     marginLeft: '0px',
    //   },
    // },
  },


});

export default ({ config, updateConfig }) => {
  const [newHeaderName, setNewHeaderName] = React.useState('');
  const [newHeaderValue, setNewHeaderValue] = React.useState('');

  return (
    <>
      <Checkbox
        label={(
          <>
            <Stack horizontal tokens={StackTokens.spacing5}>
              <Text>Enable callback</Text>
              <HelpButton styles={{ root: { marginLeft: '5px' } }} title="Enable callback">
                <div style={isMobile ? {} : {
                  maxWidth: '45vw',
                }}
                >
                  <Text block>When enabled, inputs and outputs will be sent to the provided URL by POST request using headers you provide.</Text>
                </div>
              </HelpButton>
            </Stack>
          </>
        )}
        checked={config.enabled}
        onChange={() => {
          updateConfig('enabled', !config.enabled);
        }}
        styles={componentStylesRaw.checkbox}
      />

      { config.enabled && (
      <>
        <TextFieldBuffered
          label="Callback URL"
          actualValue={config.url || ''}
          validateValue={(value) => {
            const validatedValue = value.trim();
            if (!validatedValue.startsWith('http://') && !validatedValue.startsWith('https://')) {
              throw new Error('Invalid format');
            }
            return validatedValue;
          }}
          applyValue={async (value) => {
            updateConfig('url', value);
          }}
          styles={componentStylesRaw.textFieldRoot}
        />

        <Label className={Theme.styles.noBottomMargin}>Custom headers</Label>
        <Stack horizontal className={componentStyles.callbackHeaders} tokens={StackTokens.spacing} verticalAlign="end">

          <TextFieldBuffered
            label="Header"
            actualValue={newHeaderName}
            validateValue={(value) => {
              const validatedValue = value.trim();
              return validatedValue;
            }}
            applyValue={async (value) => {
              setNewHeaderName(value);
            }}
            styles={componentStylesRaw.textFieldRoot}
          />

          <TextFieldBuffered
            label="Value"
            actualValue={newHeaderValue}
            validateValue={(value) => {
              const validatedValue = value.trim();
              return validatedValue;
            }}
            applyValue={async (value) => {
              setNewHeaderValue(value);
            }}
            styles={componentStylesRaw.textFieldRoot}
          />

          <DefaultButton
            text=""
            iconProps={{
              iconName: 'Add',
            }}
            className={[Theme.styles.outlineControlButton, componentStyles.callbackHeaderAddButton].join(' ')}
            onClick={async () => {
              if (!newHeaderName || !newHeaderValue) {
                return;
              }

              const updatedHeaders = config.headers || {};
              updatedHeaders[newHeaderName] = newHeaderValue;
              updateConfig('headers', updatedHeaders);
              setNewHeaderName('');
              setNewHeaderValue('');
            }}
            title="Add new header"
          />
        </Stack>

        <Stack vertical tokens={StackTokens.spacing5}>
          {
            Object.entries((config.headers || {})).map(([headerName, headerValue]) => (
              <CallbackHeader
                key={headerName}
                headerName={headerName}
                headerValue={headerValue}
                apply={(hName, hValue) => {
                  if (!hName || !hValue) {
                    return;
                  }
                  const updatedHeaders = config.headers || {};
                  updatedHeaders[hName] = hValue;
                  updateConfig('headers', updatedHeaders);
                }}
                remove={(hName) => {
                  const updatedHeaders = config.headers || {};
                  delete updatedHeaders[hName];
                  updateConfig('headers', updatedHeaders);
                }}
              />
            ))
           }
        </Stack>
      </>
      )}
    </>
  );
};
