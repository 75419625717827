import React from 'react';
import {
  BarChart, Bar, XAxis, YAxis, ResponsiveContainer,
} from 'recharts';
import ChartDefaults from '../../../shared/ChartDefaults';
import Store from '../../../shared/state/Store';
import Utils from '../../../shared/Utils';
import GraphUtils from '../../GraphUtils';
import CytoReducer from '../../ModelPreview/cytoscape/CytoReducer';
import ModellerDefaults from '../../ModellerDefaults';

const labelFormatter = (value) => ((value === 0) ? '' : `${value}%`);

const formatData = (data, decimalPlaces) => {
  const chartData = [];

  if (!data || data.length === 0) {
    return chartData;
  }

  for (let datasetIndex = 0; datasetIndex < data.length; datasetIndex += 1) {
    const dsData = data[datasetIndex];
    const dsKey = GraphUtils.convertDatasetIdToKey(dsData.dataset);
    for (let valueIndex = 0; valueIndex < dsData.resultValues.length; valueIndex += 1) {
      const { label, value } = dsData.resultValues[valueIndex];
      if (datasetIndex === 0) {
        chartData.push({
          name: label,
        });
      }
      chartData[valueIndex][dsKey] = Utils.roundFormat(value * 100, decimalPlaces, ChartDefaults.decimalPlaces);
    }
  }

  return chartData;
};

export default ({
  data, nodeConfig, chartRef, className, networkId,
}) => {
  const [, dispatch] = Store.useStore();
  const nodeGraphics = nodeConfig.graphics || ModellerDefaults.DefaultGraphics;
  const chartData = formatData(data, (nodeGraphics.chart?.decimalPlaces !== undefined) ? nodeGraphics.chart.decimalPlaces : 2);
  const heightCss = `${nodeGraphics.graph?.height || ModellerDefaults.DefaultGraphics.graph.height}px`;

  // console.log('Barchart', nodeConfig.name, chartData);

  const margins = {};
  ['Left', 'Right', 'Top', 'Bottom'].forEach((p) => {
    const paddingKey = `padding${p}`;
    if (nodeGraphics.chart?.[paddingKey]) {
      margins[p.toLowerCase()] = parseInt(nodeGraphics.chart[paddingKey], 10);
    }
  });

  const [paddingBottomAuto, setPaddingBottomAuto] = React.useState(0);
  const [paddingLeftAuto, setPaddingLeftAuto] = React.useState(undefined);
  const [paddingRightAuto, setPaddingRightAuto] = React.useState(undefined);

  const adjustMargins = (svg) => {
    if (!svg) {
      return;
    }

    const canvasLeft = svg.getBoundingClientRect().x;
    const yAxis = svg.getElementsByClassName('recharts-yAxis')[0];
    const yAxisLeft = yAxis.getBoundingClientRect().x;
    const yAxisClipped = canvasLeft - yAxisLeft;
    if (yAxisClipped > 0) {
      setPaddingLeftAuto(yAxisClipped);
    }

    const canvasBottom = svg.getBoundingClientRect().y + svg.getBoundingClientRect().height;
    const xAxis = svg.getElementsByClassName('recharts-xAxis')[0];
    if (xAxis) {
      const xAxisBottom = xAxis.getBoundingClientRect().y + xAxis.getBoundingClientRect().height;
      const xAxisClipped = xAxisBottom - canvasBottom;
      if (xAxisClipped > 0) {
        setPaddingBottomAuto(paddingBottomAuto + xAxisClipped);
      }
    }

    const canvasRight = svg.getBoundingClientRect().x + svg.getBoundingClientRect().width;
    const currentPaddingRight = margins.right || 0;
    let deltaRight = 0;
    [].forEach.call(svg.getElementsByClassName('recharts-bar'), ((el) => {
      const elRight = el.getBoundingClientRect().x + el.getBoundingClientRect().width;
      const deltaElRight = currentPaddingRight + elRight - canvasRight;
      deltaRight = Math.max(deltaRight, deltaElRight);
    }));

    if (deltaRight < 0) {
      deltaRight = undefined;
    }
    setPaddingRightAuto(deltaRight);

    // setTimeout(() => {
    // console.log('generate chart', nodeConfig.name);
    dispatch(CytoReducer.updateNodeChart(chartRef, networkId, nodeConfig.id));
    // }, 100);
  };

  React.useEffect(() => {
    setTimeout(() => {
      if (data && data.length > 0) { adjustMargins(Utils.getSvg(chartRef)); }
    }, 10);
  }, [data[0]?.refreshedAt, nodeConfig.graphics?.chart]);

  margins.bottom = margins.bottom !== undefined ? margins.bottom : paddingBottomAuto;
  margins.left = margins.left !== undefined ? margins.left : paddingLeftAuto;
  margins.right = margins.right !== undefined ? margins.right : paddingRightAuto;

  return (
    <div
      style={{
        width: '100%',
        height: heightCss,
        background: 'white',
      }}
      ref={chartRef}
      className={className}
    >
      <ResponsiveContainer>
        <BarChart
          margin={margins}
          data={chartData}
          layout="vertical"
          style={{
            fontFamily: '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
          }}
        >
          <XAxis type="number" domain={[0, 100]} style={{ fontSize: '10px' }} />
          <YAxis type="category" dataKey="name" interval={0} style={{ fontSize: '10px' }} />

          {
              data.map((dsData, dsIndex) => (
                <Bar
                  key={dsData.dataset}
                  isAnimationActive={false}
                  dataKey={GraphUtils.convertDatasetIdToKey(dsData.dataset)}
                  fill={ChartDefaults.Colors[dsIndex]}
                  label={{
                    position: 'right',
                    formatter: labelFormatter,
                    style: { fontSize: '10px', fontWeight: 'bold' },
                  }}
                />
              ))
            }
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};
