import React from 'react';
import {
  CommandButton, Separator, Stack, Text,
} from '@fluentui/react';
import { useNavigate } from 'react-router-dom';
import Theme from '../shared/Theme';
import Store from '../shared/state/Store';
import UiReducer from '../shared/state/UiReducer';
import AppReducer from '../shared/state/AppReducer';
import AppsApi from '../shared/util/AppsApi';
import InputConfirmationDialog from '../shared/InputConfirmationDialog';
import Routing from '../shared/Routing';

export default ({ app }) => {
  const [globalState, dispatch] = Store.useStore();
  const navigate = useNavigate();
  const routingTree = Routing.getTree(globalState).home.routes.cloudAppManager;

  let selectedApp;
  if (app) {
    selectedApp = app;
  } else {
    const uuid = UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.CloudAppManagerAppSelected);
    const cloudApps = UiReducer.getGenericUiAttribute(globalState, UiReducer.ListOptions.cloudManagerAppsList) || [];
    // eslint-disable-next-line prefer-destructuring
    selectedApp = cloudApps.filter((a) => a.uuid === uuid)[0];
  }

  if (!selectedApp) {
    return (
      <p>No content selected</p>
    );
  }

  const deleteDialogId = `delete-app-${(selectedApp.uuid.replaceAll('-', ''))}`;

  return (
    <>
      <Stack horizontal>
        <CommandButton
          text="Delete"
          iconProps={{
            iconName: 'Delete',
          }}
          className={[Theme.styles.outlineControlButton, Theme.styles.commandButton].join(' ')}
          onClick={async () => {
            dispatch(UiReducer.setGenericUiAttribute(deleteDialogId, true));
          }}
        />
      </Stack>

      <InputConfirmationDialog
        uiElementId={deleteDialogId}
        title="Delete Cloud App"
        text={(
          <>
            <Text block>
              {`Delete app "${selectedApp.name}"?`}
            </Text>
            <Text>App data will be stored for a short time so you can request it being restored.</Text>
          </>
          )}
        primaryButtonText="Delete"
        hideOnAction
        noInputMode
        primaryCallback={async () => {
          const response = await AppsApi.deleteApp(selectedApp.uuid);
          if (response.code >= 300) {
            dispatch(UiReducer.uiSetErrors(response.messages));
          } else {
            if (selectedApp.uuid === AppReducer.getAppData(globalState)?.uuid) {
              // Deleted app currently loaded globally (e.g. for web app designer)
              dispatch(AppReducer.resetAppData());
            }
            dispatch(UiReducer.setGenericUiAttribute(UiReducer.Keys.CloudAppManagerAppSelected, false));
            navigate(routingTree.path);
          }
        }}
      />
      <Separator styles={Theme.styleObjects.separatorSmallMargin} />
      <div style={{ margin: '10px 0 0 0' }}>
        <table className={[Theme.styles.table, Theme.styles.tableNoPadding].join(' ')}>
          <tbody>
            <tr>
              <th>ID</th>
              <td>:</td>
              <td>{selectedApp.uuid}</td>
            </tr>
            <tr>
              <th>Name</th>
              <td>:</td>
              <td>{selectedApp.name}</td>
            </tr>
            <tr>
              <th>Contributors</th>
              <td>:</td>
              <td>{selectedApp.contributors?.length || 0}</td>
            </tr>
            <tr>
              <th>Users</th>
              <td>:</td>
              <td>{selectedApp.users?.length || 0}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};
