const ListOptions = {
  fileOpenAppsList: 'fileOpenAppsList',
  cloudManagerAppsList: 'cloudManagerAppsList',
};

const Options = {
  GlobalNavItemSelected: 'GlobalNavItemSelected',
};

const Keys = {
  CloudAppManagerAppSelected: 'CloudAppManagerAppSelected',
  CloudAppManagerAppInviteDialogOpen: 'CloudAppManagerAppInviteDialogOpen',
  CloudAppManagerAddRoleAssignmentDialogOpen: 'CloudAppManagerAddRoleAssignmentDialogOpen',
  CloudAppManagerAddRoleAssignmentDialogOpenForRole: 'CloudAppManagerAddRoleAssignmentDialogOpenForRole',
  userId: 'userId',
  AccountInfo: 'AccountInfo',
  AccountInfoServerError: 'AccountInfoServerError',
  AccountKeyAssignmentDialogOpen: 'AccountKeyAssignmentDialogOpen',
  webAppInputsOverriddenNptMap: 'webAppInputsOverriddenNptMap',
  webAppInputsOverriddenNptColumns: 'webAppInputsOverriddenNptColumns',
  isCaseManagerPanelOpen: 'isCaseManagerPanelOpen',
  webAppShowPrintDialog: 'webAppShowPrintDialog',
  webAppShowPrintReport: 'webAppShowPrintReport',
  webAppPrintReportConfig: 'webAppPrintReportConfig',
  webAppPrintReportCopyRequested: 'webAppPrintReportCopyRequested',
  webAppPrintReportCopyReady: 'webAppPrintReportCopyReady',
  webAppDesignerModelModified: 'webAppDesignerModelModified',
  WebbAppModelViewSelected: 'WebbAppModelViewSelected',
  GeneralSimpleMessageModal: 'GeneralSimpleMessageModal',
};

const uiSetLoading = (loading) => ({
  reduce: ((prevState) => {
    const updatedState = { ...prevState };
    updatedState.ui = updatedState.ui || {};
    updatedState.ui.loading = loading;
    return updatedState;
  }),
});

const uiSetErrors = (errors) => ({
  reduce: ((prevState) => {
    const updatedState = { ...prevState };
    updatedState.ui = updatedState.ui || {};
    updatedState.ui.errors = errors;
    return updatedState;
  }),
});

const calculationLogUpdate = (response) => ({
  reduce: ((prevState) => {
    const updatedState = { ...prevState };

    const row = {
      time: new Date().toISOString(),
      id: response.uuid,
      duration: response.duration || {},
      memory: response.memory || {},
    };

    updatedState.log = updatedState.log || [];
    updatedState.log.push(row);

    return updatedState;
  }),
});

const calculationLogReset = () => ({
  reduce: ((prevState) => {
    const updatedState = { ...prevState };
    updatedState.log = [];
    return updatedState;
  }),
});

const reportShowSettingsPanelFor = (infoForPanel) => ({
  reduce: ((prevState) => {
    const updatedState = { ...prevState };
    updatedState.report = updatedState.report || {};
    updatedState.report.showSettingsPanelFor = infoForPanel;
    return updatedState;
  }),
});

const changeLogUpdate = (versionList) => ({
  reduce: ((prevState) => {
    const updatedState = { ...prevState };
    updatedState.changeLog = updatedState.changeLog || {};
    updatedState.changeLog.versionList = versionList;
    return updatedState;
  }),
});

const changeLogSetOpen = (isOpen) => ({
  reduce: ((prevState) => {
    const updatedState = { ...prevState };
    updatedState.changeLog = updatedState.changeLog || {};
    updatedState.changeLog.isOpen = isOpen;
    return updatedState;
  }),
});

const changeLogSetUnread = (isUnread) => ({
  reduce: ((prevState) => {
    const updatedState = { ...prevState };
    updatedState.changeLog = updatedState.changeLog || {};
    updatedState.changeLog.isUnread = isUnread;
    return updatedState;
  }),
});

const setGenericUiAttribute = (attributeName, value) => ({
  reduce: ((prevState) => {
    const updatedState = { ...prevState };
    updatedState.ui = updatedState.ui || {};
    if (value === undefined) {
      delete updatedState.ui[attributeName];
    } else {
      updatedState.ui[attributeName] = value;
    }
    return updatedState;
  }),
});

const getGenericUiAttribute = (globalState, attributeName) => {
  if (!globalState?.ui) {
    return undefined;
  }
  return globalState.ui[attributeName];
};

const setGenericUiListItem = (listName, value, filter) => ({
  reduce: ((prevState) => {
    const updatedState = { ...prevState };
    updatedState.ui = updatedState.ui || {};
    updatedState.ui[listName] = updatedState.ui[listName] || [];

    const updatedList = [...updatedState.ui[listName]];

    if (value === undefined) {
      // Return the list without the item that matches the filter
      updatedState.ui[listName] = updatedList.filter(!filter);
    } else {
      // Return the list with the item that matches the filter replaced by provided item
      updatedState.ui[listName] = updatedList.map((item) => {
        if (filter(item)) {
          return value;
        }
        return item;
      });
    }
    return updatedState;
  }),
});

const getGenericUiListLastItem = (globalState, listName) => {
  if (!globalState.ui) {
    return undefined;
  }

  const array = globalState.ui[listName];
  if (!array) {
    return undefined;
  }
  return array[array.length - 1];
};

const pushToList = (listName, value) => ({
  reduce: ((prevState) => {
    const updatedState = { ...prevState };
    updatedState.ui = updatedState.ui || {};
    updatedState.ui[listName] = updatedState.ui[listName] || [];

    const updatedList = [...updatedState.ui[listName]];
    updatedList.push(value);
    updatedState.ui[listName] = updatedList;

    return updatedState;
  }),
});

const popFromList = (listName) => ({
  reduce: ((prevState) => {
    const updatedState = { ...prevState };
    updatedState.ui = updatedState.ui || {};
    updatedState.ui[listName] = updatedState.ui[listName] || [];

    const updatedList = [...updatedState.ui[listName]];
    updatedList.pop();
    updatedState.ui[listName] = updatedList;

    return updatedState;
  }),
});

export default {
  ListOptions,
  Options,
  Keys,
  uiSetLoading,
  uiSetErrors,
  calculationLogUpdate,
  calculationLogReset,
  reportShowSettingsPanelFor,
  changeLogUpdate,
  changeLogSetOpen,
  changeLogSetUnread,
  setGenericUiAttribute,
  getGenericUiAttribute,
  setGenericUiListItem,
  getGenericUiListLastItem,
  pushToList,
  popFromList,
};
