import AccountsState from '../admin/accounts/AccountsState';
import CloudAppManagerHelper from '../appmanager/CloudAppManagerHelper';
import User from './User';

const getTree = (globalState) => {
  const tree = {
    home: {
      path: '/',
      text: 'Home',
      routes: {
        administration: {
          path: '/administration',
          text: 'Portal Administration',
          skip: !User.isCloudAdmin(),
          bcDisabled: true,
          routes: {
            settings: {
              path: '/administration/settings',
              text: 'Settings',
              bcDisabled: true,
              routes: {
                reservedDomains: {
                  path: '/administration/settings/reserved-domains',
                  text: 'Reserved Domains',
                },
              },
            },
            archive: {
              path: '/administration/archive',
              text: 'Archive',
              // bcDisabled: true,
            },
            accounts: {
              path: '/administration/accounts',
              text: 'Accounts',
              routes: {
                account: {
                  path: '/administration/accounts/:id',
                  dynpath: `/administration/accounts/${(AccountsState.getRelatedAccounts(globalState)?.primaryAccount || {}).uuid}`,
                  text: (AccountsState.getRelatedAccounts(globalState)?.primaryAccount || {}).email,
                  bcDisabled: true,
                  routes: {
                    subscription: {
                      path: '/administration/accounts/:id/subscription',
                      text: 'Subscription',
                      dynpath: `/administration/accounts/${(AccountsState.getRelatedAccounts(globalState)?.primaryAccount || {}).uuid}/subscription`,
                    },
                    licenseKeys: {
                      path: '/administration/accounts/:id/license-keys',
                      text: 'License Keys',
                      dynpath: `/administration/accounts/${(AccountsState.getRelatedAccounts(globalState)?.primaryAccount || {}).uuid}/license-keys`,
                    },
                  },
                },
              },
            },
          },
        },
        account: {
          path: '/account',
          text: 'My Portal Account',
          bcDisabled: true,
          routes: {
            subscription: {
              path: '/account/subscription',
              text: 'Subscription',
            },
            licenseKeys: {
              path: '/account/license-keys',
              text: 'License Keys',
            },
          },
        },
        modeller: {
          path: '/modeller',
          text: 'Model Viewer',
          disabled: (globalStateArg) => !User.isActiveCreatorAccount(globalStateArg),
        },
        webAppDesigner: {
          path: '/web-app-designer',
          text: 'Web App Designer',
          disabled: (globalStateArg) => !User.isActiveCreatorAccount(globalStateArg),
          routes: {
            library: {
              path: '/web-app-designer/library',
              text: 'Library',
              requireApp: false,
            },
            file: {
              path: '/web-app-designer/file',
              text: 'File',
              routes: {
                new: {
                  path: '/web-app-designer/file/new',
                  text: 'New',
                  requireApp: false,
                },
                open: {
                  path: '/web-app-designer/file/open',
                  text: 'Open',
                  requireApp: false,
                },
                save: {
                  path: '/web-app-designer/file/save',
                  text: 'Save',
                },
                advanced: {
                  path: '/web-app-designer/file/advanced',
                  text: 'Advanced',
                },
              },
            },
            design: {
              path: '/web-app-designer/design',
              text: 'Design',
              routes: {
                applicationProperties: {
                  path: '/web-app-designer/design/application-properties',
                  text: 'Application Properties',
                  routes: {
                    general: {
                      path: '/web-app-designer/design/application-properties/general',
                      text: 'General',
                    },
                    inputs: {
                      path: '/web-app-designer/design/application-properties/inputs',
                      text: 'Inputs',
                    },
                    outputs: {
                      path: '/web-app-designer/design/application-properties/outputs',
                      text: 'Outputs',
                    },
                    dataProcessing: {
                      path: '/web-app-designer/design/application-properties/data-processing',
                      text: 'Data Processing',
                    },
                  },
                },
                selectInputsOutputs: {
                  path: '/web-app-designer/design/select-inputs-outputs',
                  text: 'Select Inputs & Outputs',
                },
                configureInputs: {
                  path: '/web-app-designer/design/configure-inputs',
                  text: 'Configure Inputs',
                  routes: {
                    inputItems: {
                      path: '/web-app-designer/design/configure-inputs/input-items',
                      text: 'Input Items',
                    },
                    inputGroups: {
                      path: '/web-app-designer/design/configure-inputs/input-groups',
                      text: 'Input Groups',
                    },
                  },
                },
                configureOutputs: {
                  path: '/web-app-designer/design/configure-outputs',
                  text: 'Configure Outputs',
                  routes: {
                    outputItems: {
                      path: '/web-app-designer/design/configure-outputs/output-items',
                      text: 'Output Items',
                    },
                    outputGroups: {
                      path: '/web-app-designer/design/configure-outputs/output-groups',
                      text: 'Output Groups',
                    },
                  },
                },
              },
            },
            preview: {
              path: '/web-app-designer/preview',
              text: 'Preview',
            },
            publish: {
              path: '/web-app-designer/publish',
              text: 'Publish',
            },
            gettingStarted: {
              path: '/web-app-designer/getting-started',
              text: 'Getting Started',
              requireApp: false,
            },
          },
        },
        cloudAppManager: {
          path: '/cloud-app-manager',
          text: 'Cloud App Manager',
          disabled: (globalStateArg) => !User.isActiveCreatorAccount(globalStateArg),
          routes: {
            app: {
              path: '/cloud-app-manager/:id',
              dynpath: `/cloud-app-manager/${CloudAppManagerHelper.getSelectedAppId(globalState)}`,
              text: CloudAppManagerHelper.getSelectedCloudAppString(globalState),
              bcDisabled: true,
              routes: {
                overview: {
                  path: '/cloud-app-manager/:id/overview',
                  text: 'Overview',
                  dynpath: `/cloud-app-manager/${CloudAppManagerHelper.getSelectedAppId(globalState)}/overview`,
                },
                accessControl: {
                  path: '/cloud-app-manager/:id/access-control',
                  text: 'Access Control',
                  dynpath: `/cloud-app-manager/${CloudAppManagerHelper.getSelectedAppId(globalState)}/access-control`,
                },
                publishing: {
                  path: '/cloud-app-manager/:id/publishing',
                  text: 'Publishing',
                  dynpath: `/cloud-app-manager/${CloudAppManagerHelper.getSelectedAppId(globalState)}/publishing`,
                },
                dataProcessing: {
                  path: '/cloud-app-manager/:id/data-processing',
                  text: 'Data Processing',
                  dynpath: `/cloud-app-manager/${CloudAppManagerHelper.getSelectedAppId(globalState)}/data-processing`,
                },
                archive: {
                  path: '/cloud-app-manager/:id/archive',
                  text: 'Archive',
                  dynpath: `/cloud-app-manager/${CloudAppManagerHelper.getSelectedAppId(globalState)}/archive`,
                },
              },
            },
          },
        },
        apiServices: {
          path: '/api-services',
          text: 'API Services',
          disabled: (globalStateArg) => !User.isAssignedActiveKey(globalStateArg),
        },
        debug: {
          path: '/debug',
          text: 'Debug',
          skip: User.isCloudAdmin() ? undefined : 1,
        },
        archive: {
          path: '/archive',
          text: 'Archive',
          skip: true,
        },
      },
    },
    getPathObject: (root, path) => {
      let pathObject = null;
      const walk = (route) => {
        if (route.path === path) {
          pathObject = route;
          return;
        }
        if (route.routes) {
          Object.values(route.routes).forEach((chRoute) => walk(chRoute));
        }
      };
      walk(root);
      return pathObject;
    },
  };

  const addRelativePathResolver = (parent) => {
    if (!parent.routes) {
      return;
    }
    Object.values(parent.routes).forEach((route) => {
      // eslint-disable-next-line no-param-reassign
      route.getRelativePath = (pathRelativeTo) => {
        if (route.path.startsWith(pathRelativeTo)) {
          return route.path.slice(pathRelativeTo.length);
        }
        return route.path;
      };
    });
  };
  const walk = (route) => {
    addRelativePathResolver(route);
    if (route.routes) {
      Object.values(route.routes).forEach((chRoute) => walk(chRoute));
    }
  };

  if (Object.keys(globalState).length !== 0) {
    walk(tree.home);
  }

  return tree;
};


export default {
  getTree,
};
