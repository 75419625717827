import Keycloak from 'keycloak-js';
import Env from './Env';

const resolveConfig = () => {
  let config = {
    realm: 'cloud',
    url: '',
    clientId: 'account',
  };

  if (Env.KC_ENABLED) {
    config = {
      realm: window.env.REACT_APP_KC_REALM || 'cloud',
      url: window.env.REACT_APP_KC_URL || 'https://auth.agena.ai/',
      clientId: window.env.REACT_APP_KC_RESOURCE || 'account',
    };
  }

  return config;
};

const keycloak = Env.KC_ENABLED ? new Keycloak(resolveConfig()) : {
  authenticated: false,
  token: '',
};

export default keycloak;
