import { mergeStyleSets } from '@fluentui/react';
import React from 'react';

const localStyles = mergeStyleSets({
  tooltip: {
    position: 'fixed',
    zIndex: '9999',
    border: '1px solid #e4e4e4',
    padding: '5px',
    background: 'white',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
});

export default ({
  x = 0, y = 0, style = {}, className, children,
}) => {
  const ref = React.useRef();
  const [position, setPosition] = React.useState({ x: x + 5, y });

  React.useState(() => {
    setTimeout(() => {
      if (!ref.current) {
        return;
      }

      const newPosition = { x, y };
      let updatePosition = false;

      const bcRect = ref.current.getBoundingClientRect();
      const clippedXAmount = (bcRect.x + bcRect.width) - window.innerWidth;
      const clippedYAmount = (bcRect.y + bcRect.height) - window.innerHeight;

      if (clippedXAmount > 0) {
        newPosition.x = Math.max(0, x - bcRect.width - 5);
        updatePosition = true;
      }

      if (clippedYAmount > 0) {
        newPosition.y = Math.max(0, y - bcRect.height);
        updatePosition = true;
      }

      if (updatePosition) {
        setPosition(newPosition);
      }
    }, 1);
  }, []);

  return (
    <div
      ref={ref}
      className={[localStyles.tooltip, className].join(' ')}
      style={{
        left: position.x,
        top: position.y,
        ...style,
      }}
    >
      {children}
    </div>
  );
};
