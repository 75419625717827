import React from 'react';
import { Stack } from '@fluentui/react';
import Input from './Input';
import CollapsiblePanel from '../../shared/CollapsiblePanel';
import tokens from '../../shared/StackTokens';
import Store from '../../shared/state/Store';
import FormReducer from '../../shared/state/FormReducer';
import Utils from '../../shared/Utils';

export default ({ config }) => {
  const {
    groupName, description, items, collapsible, collapsed,
  } = config;
  const [globalState, dispatch] = Store.useStore();

  const stateGroupCollapsed = FormReducer.isFormGroupCollapsed(globalState, groupName);
  const groupCollapsed = (stateGroupCollapsed !== undefined) ? stateGroupCollapsed : collapsed;

  return (
    <CollapsiblePanel
      collapsible={collapsible}
      collapsed={groupCollapsed}
      title={groupName}
      description={description}
      keyOverride={groupName}
      callback={(isCollapsed) => {
        dispatch(FormReducer.setFormGroupCollapsed(groupName, isCollapsed));
      }}
    >
      <Stack
        horizontal
        tokens={tokens.spacing}
        wrap="true"
        style={{ width: '100%' }}
      >
        {
        items.map((question) => (
          <Input
            key={Utils.createNetworkNodeKey(question.network, question.node)}
            config={question}
          />
        ))
      }
      </Stack>
    </CollapsiblePanel>

  );
};
