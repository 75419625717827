/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import { TextField } from '@fluentui/react';
import React from 'react';

export default (props) => {
  const {
    actualValue,
    validateValue,
    applyValue,
    onFocus,
    onBlur,
  } = props;

  const [editableText, setEditableText] = React.useState(actualValue);
  const [editMode, setEditMode] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  const childProps = { ...props };
  childProps.value = actualValue;
  delete childProps.actualValue;
  delete childProps.deleteValue;
  delete childProps.applyValue;
  delete childProps.onFocus;
  delete childProps.onBlur;

  const editableTextFieldRef = React.useRef();

  React.useEffect(() => {
    if (editableTextFieldRef.current) {
      editableTextFieldRef.current.focus();
    }
  }, [editMode]);

  if (editMode) {
    return (
      <TextField
        {...childProps}
        ref={editableTextFieldRef}
        placeholder={props.placeholder}
        value={editableText}
        onChange={(event, value) => {
          setErrorMessage('');
          setEditableText(value);
        }}
        onKeyDown={(event) => {
          if (event.keyCode === 13) {
            event.target.blur();
          } else if (event.keyCode === 27) {
            setEditMode(false);
            setErrorMessage('');
            setEditableText(actualValue);
          }
        }}
        onBlur={async () => {
          if (typeof onBlur === 'function') {
            onBlur();
          }
          let newValue;
          try {
            newValue = validateValue(editableText);
            await applyValue(newValue);
            setEditMode(false);
            setEditableText(newValue);
          } catch (error) {
            if (error instanceof Error) {
              setErrorMessage(error.message);
            } else if (typeof error === 'string') {
              setErrorMessage(error);
            } else {
              setErrorMessage('Invalid value');
            }
            if (editableTextFieldRef.current) {
              editableTextFieldRef.current.focus();
            }
          }
        }}
        errorMessage={errorMessage}
        styles={props.styles}
      />
    );
  }

  return (
    <TextField
      {...childProps}
      readOnly
      onClick={() => {
        setEditableText(actualValue);
        setEditMode(true);
      }}
      onFocus={async () => {
        if (typeof onFocus === 'function') {
          onFocus();
        } else {
          setEditableText(actualValue);
          setEditMode(true);
        }
      }}
      styles={props.styles}
    />
  );
};
