import React from 'react';
import {
  Checkbox,
  mergeStyleSets,
  Stack,
  Text,
} from '@fluentui/react';
import { Label } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import Store from '../../shared/state/Store';
import StackTokens from '../../shared/StackTokens';
import Callback from '../../shared/callback/Callback';
import UiReducer from '../../shared/state/UiReducer';
import PlaceholderError from '../../shared/PlaceholderError';
import AppsApi from '../../shared/util/AppsApi';
import AppReducer from '../../shared/state/AppReducer';
import HelpButton from '../../shared/help/HelpButton';
import Theme from '../../shared/Theme';
import TextFieldBuffered from '../../shared/TextFieldBuffered';

const componentStyles = mergeStyleSets({
  root: {
    marginTop: '10px',
    paddingRight: '20px',
  },
});

export default () => {
  const [globalState, dispatch] = Store.useStore();
  const uuid = UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.CloudAppManagerAppSelected);
  const cloudApps = UiReducer.getGenericUiAttribute(globalState, UiReducer.ListOptions.cloudManagerAppsList) || [];
  const app = cloudApps.filter((a) => a.uuid === uuid)[0];

  const update = async (configPatch) => {
    const jResponse = await AppsApi.saveApp(configPatch, uuid);

    if (jResponse.code >= 300) {
      dispatch(UiReducer.uiSetErrors(jResponse.messages));
      return;
    }

    if (uuid === AppReducer.getAppData(globalState)?.uuid) {
      // Editing currently loaded app
      dispatch(AppReducer.setAppData(jResponse));
    }

    dispatch(UiReducer.setGenericUiListItem(UiReducer.ListOptions.cloudManagerAppsList, jResponse, (a) => a.uuid === uuid));
  };

  const updateObjectFied = async (objectName, fieldName, fieldValue) => {
    const updatedObject = { ...(app[objectName] || {}) };
    updatedObject[fieldName] = fieldValue;
    const updatedJson = {};
    updatedJson[objectName] = updatedObject;
    update(updatedJson);
  };

  const updateCallback = async (fieldName, fieldValue) => {
    await updateObjectFied('callback', fieldName, fieldValue);
  };

  if (!app) {
    return <PlaceholderError message="No such app is available" />;
  }

  return (
    <>
      <Stack vertical className={componentStyles.root} tokens={StackTokens.spacing}>

        <Label>You can customise how data is processed by your app here.</Label>

        <Label className={[Theme.styles.bold, Theme.styles.inputItem].join(' ')}>Callback configuration</Label>
        <Callback
          config={app.callback || {}}
          updateConfig={updateCallback}
        />

        <Label className={[Theme.styles.bold, Theme.styles.inputItem].join(' ')}>Archiving</Label>
        <Checkbox
          label={(
            <>
              <Stack horizontal tokens={StackTokens.spacing5}>
                <Text>Enable archiving</Text>
                <HelpButton styles={{ root: { marginLeft: '5px' } }} title="Enable archiving">
                  <div style={isMobile ? {} : {
                    maxWidth: '45vw',
                  }}
                  >
                    <Text block>When enabled, detailed information about the calculation records will be stored and made available in the app Archive for review.</Text>
                  </div>
                </HelpButton>
              </Stack>
            </>
        )}
          checked={app.archivingEnabled}
          onChange={() => {
            update({ archivingEnabled: !app.archivingEnabled });
          }}
          styles={Theme.styles.inputItem}
        />

        <Label className={[Theme.styles.bold, Theme.styles.inputItem].join(' ')}>Outputs</Label>
        <Checkbox
          label={(
            <>
              <Stack horizontal tokens={StackTokens.spacing5}>
                <Text>Hide app outputs from end-users</Text>
                <HelpButton styles={{ root: { marginLeft: '5px' } }} title="Hide app outputs">
                  <div style={isMobile ? {} : {
                    maxWidth: '45vw',
                  }}
                  >
                    <Text block>When enabled, any outputs or predictions are hidden from end-users and a message is displayed instead.</Text>
                    <Text block>Only available if either Callback or Archiving are enabled.</Text>
                  </div>
                </HelpButton>
              </Stack>
            </>
        )}
          checked={app.hideOutputs?.enabled}
          onChange={() => {
            updateObjectFied('hideOutputs', 'enabled', !app.hideOutputs?.enabled);
          }}
          styles={Theme.styles.inputItem}
          disabled={!app.callback?.enabled && !app.archivingEnabled}
        />
        { app.hideOutputs?.enabled && (
          <TextFieldBuffered
            multiline
            actualValue={app.hideOutputs?.message}
            placeholder={AppReducer.Defaults.hideOutputsMessage}
            validateValue={(value) => `${value}`.trim()}
            applyValue={async (value) => {
              if (!value) {
                // eslint-disable-next-line no-param-reassign
                value = AppReducer.Defaults.hideOutputsMessage;
              }
              updateObjectFied('hideOutputs', 'message', `${value}`);
            }}
            styles={{
              root: {
                width: '400px',
              },
            }}
          />
        )}
        <Checkbox
          label={(
            <>
              <Stack horizontal tokens={StackTokens.spacing5}>
                <Text>Disable storing outputs</Text>
                <HelpButton styles={{ root: { marginLeft: '5px' } }} title="Disable storing outputs">
                  <div style={isMobile ? {} : {
                    maxWidth: '45vw',
                  }}
                  >
                    <Text block>When enabled, any outputs or predictions are not stored on the server and are immediately discarded after being posted to the callback URL.</Text>
                    <Text block>This option is only available if:</Text>
                    <ul>
                      <li>Callback is enabled</li>
                      <li>Archiving is disabled</li>
                      <li>Hiding outputs is enabled</li>
                    </ul>
                  </div>
                </HelpButton>
              </Stack>
            </>
        )}
          checked={app.resultStoringDisabled}
          onChange={() => {
            update({ resultStoringDisabled: !app.resultStoringDisabled });
          }}
          styles={Theme.styles.inputItem}
          disabled={!app.callback?.enabled || app.archivingEnabled || !app.hideOutputs?.enabled}
        />
      </Stack>
    </>
  );
};
