import Env from './Env';

const ApplicationModes = {
  Portal: 'portal',
  WebAppDesigner: 'web-app-designer',
  WebAppSelfHosted: 'web-app-self-hosted',
  WebAppCloudHosted: 'web-app-cloud-hosted',
  WebAppCloudHostedPublic: 'web-app-cloud-hosted-public',
  Archive: 'archive',
};

const getApplicationMode = () => {
  if (Object.values(ApplicationModes).includes(Env.APPLICATION_MODE)) {
    return Env.APPLICATION_MODE;
  }
  return ApplicationModes.Portal;
};

const isModePublicWebApp = () => (ApplicationModes.WebAppCloudHostedPublic === getApplicationMode());
const isModeWebApp = () => ([ApplicationModes.WebAppSelfHosted, ApplicationModes.WebAppCloudHosted, ApplicationModes.WebAppCloudHostedPublic].includes(getApplicationMode()));
const isModeWebAppDesigner = () => (ApplicationModes.WebAppDesigner === getApplicationMode());

export default {
  ApplicationModes,
  getApplicationMode,
  isModePublicWebApp,
  isModeWebApp,
  isModeWebAppDesigner,
};
