import React from 'react';
import {
  DefaultButton,
  mergeStyleSets, PrimaryButton, Stack, Text,
} from '@fluentui/react';
import { useNavigate } from 'react-router-dom';
import StackTokens from '../../../shared/StackTokens';
import MultiLineUserText from '../../../shared/MultiLineUserText';
import Store from '../../../shared/state/Store';
import AppReducer from '../../../shared/state/AppReducer';
import UiReducer from '../../../shared/state/UiReducer';
import AppsApi from '../../../shared/util/AppsApi';
import InputConfirmationDialog from '../../../shared/InputConfirmationDialog';
import EditableText from '../../../shared/EditableText';
import User from '../../../shared/User';
import Routing from '../../../shared/Routing';

const styles = mergeStyleSets({
  appSection: {
    selectors: {
      h5: {
        marginBottom: '0',
        paddingBottom: '0',
      },
    },
    padding: '5px',
    border: '1px solid rgb(237, 235, 233)',
    margin: '10px 0',
  },

  description: {
    marginTop: '0',
    paddingTop: '0',
    width: '40vmin',
  },

  logo: {
    maxHeight: '20vmin',
    maxWidth: '20vmin',
  },

  capitalize: {
    textTransform: 'capitalize',
  },
});

export default ({ appJson }) => {
  const {
    configuration, uuid, name, owner,
  } = appJson;
  const [globalState, dispatch] = Store.useStore();
  const navigate = useNavigate();
  const routingTree = Routing.getTree(globalState).home.routes.webAppDesigner;

  const { generalConfig } = configuration;
  const [showItem, setShowItem] = React.useState(true);
  const [renderItem, setRenderItem] = React.useState(true);
  const deleteDialogId = `delete-app-${(uuid.replaceAll('-', ''))}`;

  if (!showItem) {
    return <></>;
  }

  const deleteButton = (
    <span>
      <DefaultButton
        text="Delete"
        onClick={() => {
          dispatch(UiReducer.setGenericUiAttribute(deleteDialogId, true));
        }}
        iconProps={{
          iconName: 'Delete',
        }}
      />
    </span>
  );

  const editableHeader = (
    <EditableText
      actualValue={name}
      placeholder="Enter app name"
      validateValue={(value) => {
        const validatedValue = value.trim();
        if (validatedValue === '') {
          throw new Error('Please provide a name');
        }
        return validatedValue;
      }}
      applyValue={async (value) => {
        const jResponse = await AppsApi.saveApp({ name: value }, uuid);
        if (jResponse.code >= 300) {
          const errors = ['Failed to update app name, please try again later'];
          dispatch(UiReducer.uiSetErrors(jResponse.messages ? errors.concat(jResponse.messages) : errors));
          return;
        }
        if (uuid === AppReducer.getAppData(globalState)?.uuid) {
          // Editing currently loaded app
          dispatch(AppReducer.setAppData(jResponse));
        }
        dispatch(UiReducer.setGenericUiListItem(UiReducer.ListOptions.fileOpenAppsList, jResponse, (app) => app.uuid === uuid));
      }}
      styles={{
        text: {
          fontSize: '20px',
        },
      }}
    />
  );

  const access = User.getAccessMode(appJson);

  return (
    <div
      style={{
        opacity: renderItem ? '1' : '0',
        transition: renderItem ? '' : 'all 500ms linear',
      }}
    >
      <Stack
        vertical
        tokens={StackTokens.spacing}
        className={styles.appSection}
      >
        {access === User.UserMode.Owner && editableHeader}
        {access === User.UserMode.Contributor && <h5>{name}</h5>}
        {access === User.UserMode.User && <h5>{generalConfig.appName}</h5>}

        <Stack horizontal tokens={StackTokens.spacing}>

          <Stack vertical tokens={StackTokens.spacing}>

            <table>
              <tbody>
                <tr>
                  <td>Public Name</td>
                  <td>{`: ${generalConfig.appName}`}</td>
                </tr>
                <tr>
                  <td>App ID</td>
                  <td>{`: ${uuid}`}</td>
                </tr>
                <tr>
                  <td>Access</td>
                  <td className={styles.capitalize}>{`: ${access}`}</td>
                </tr>

                {access === User.UserMode.Contributor && (
                <tr>
                  <td>Owner</td>
                  <td>{`: ${owner}`}</td>
                </tr>
                )}

              </tbody>
            </table>

            <span>Description:</span>
            <MultiLineUserText className={styles.description}>{generalConfig.appDescription}</MultiLineUserText>

          </Stack>

          {generalConfig.appLogo && <span><img src={generalConfig.appLogo} alt={generalConfig.appName} className={styles.logo} /></span>}

        </Stack>

        <Stack horizontal tokens={StackTokens.horizontalSpacing}>
          <span>
            <PrimaryButton
              text="Load App"
              onClick={() => {
                dispatch(AppReducer.loadApp(configuration));
                dispatch(AppReducer.setAppData(appJson));
                navigate(routingTree.routes.design.routes.applicationProperties.path);
              }}
              iconProps={{
                iconName: 'CloudDownload',
              }}
            />
          </span>

          {access === User.UserMode.Owner && deleteButton}
        </Stack>

        <InputConfirmationDialog
          uiElementId={deleteDialogId}
          title="Delete Cloud App"
          text={(
            <>
              <Text block>
                {`Delete app "${name}"?`}
              </Text>
              <Text>App data will be stored for a short time so you can request it being restored.</Text>
            </>
          )}
          primaryButtonText="Delete"
          hideOnAction
          noInputMode
          primaryCallback={async () => {
            const response = await AppsApi.deleteApp(uuid);
            if (response.code >= 300) {
              dispatch(UiReducer.uiSetErrors(response.messages));
            } else {
              if (uuid === AppReducer.getAppData(globalState)?.uuid) {
                // Deleted currently loaded app
                dispatch(AppReducer.resetAppData());
              }
              setRenderItem(false);
              setTimeout(() => {
                setShowItem(false);
              }, 500);
            }
          }}
        />
      </Stack>
    </div>
  );
};
