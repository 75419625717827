import React from 'react';
import Store from '../../../../shared/state/Store';
import OutputGroups from './OutputGroups';
import OutputItems from './OutputItems';
import MenuPage from '../../MenuPage';
import Routing from '../../../../shared/Routing';

export default () => {
  const [globalState] = Store.useStore();
  const routingTree = Routing.getTree(globalState).home.routes.webAppDesigner.routes.design.routes.configureOutputs;

  return (
    <MenuPage
      root={routingTree}
      defaultPath={routingTree.routes.outputItems.path}
      elements={[
        <OutputItems />,
        <OutputGroups />,
      ]}
    />
  );
};
