import { DefaultButton, mergeStyleSets } from '@fluentui/react';
import React from 'react';
import ChartDefaults from '../../shared/ChartDefaults';
import { CardInfo as CardChartInfo } from './CardChart';
import { CardInfo as CardSummaryStatisticsInfo } from './CardSummaryStatistics';
import { CardInfo as CardChartDataInfo } from './CardChartData';
import { CardInfo as CardRawDataInfo } from './CardRawData';
import Theme from '../../shared/Theme';

const styles = mergeStyleSets({
  menu: {
    selectors: {
      i: {
        // fontFamily: 'FabricMDL2Icons !important',
      },
    },
  },

  menuItemSelected: {
    selectors: {
      button: {
        backgroundColor: Theme.Colors.LightGray,
      },
    },
  },
});

export default ({
  config, activeCard, setActiveCard, requestAction,
}) => {
  const menuItems = [
    {
      key: CardChartInfo.key,
      text: CardChartInfo.text,
      iconProps: { iconName: 'BarChart4' },
      onClick: () => {
        setActiveCard(CardChartInfo.key);
      },
      className: (activeCard === CardChartInfo.key) ? styles.menuItemSelected : '',
      restrictedNodeTypes: [],
    },
    {
      key: CardSummaryStatisticsInfo.key,
      text: CardSummaryStatisticsInfo.text,
      iconProps: { iconName: 'TableComputed' },
      onClick: () => {
        setActiveCard(CardSummaryStatisticsInfo.key);
      },
      className: (activeCard === CardSummaryStatisticsInfo.key) ? styles.menuItemSelected : '',
      restrictedNodeTypes: [ChartDefaults.ChartTypes.BarChart],
    },
    {
      key: CardChartDataInfo.key,
      text: CardChartDataInfo.text,
      iconProps: { iconName: 'Table' },
      onClick: () => {
        setActiveCard(CardChartDataInfo.key);
      },
      className: (activeCard === CardChartDataInfo.key) ? styles.menuItemSelected : '',
      restrictedNodeTypes: [],
    },
    {
      key: CardRawDataInfo.key,
      text: CardRawDataInfo.text,
      iconProps: { iconName: 'DiagnosticDataBarTooltip' },
      onClick: () => {
        setActiveCard(CardRawDataInfo.key);
      },
      className: (activeCard === CardRawDataInfo.key) ? styles.menuItemSelected : '',
      restrictedNodeTypes: [],
    },
  ].filter((item) => !item.restrictedNodeTypes.includes(config.chartType));

  const [button, setButton] = React.useState();

  return (
    <DefaultButton
      text=""
      title="Show as"
      menuIconProps={{ iconName: 'View' }}
      menuProps={{
        className: styles.menu,
        items: menuItems,
      }}
      onMenuClick={(event) => {
        setButton(event.target.closest('button'));
        requestAction(null);
      }}
      onAfterMenuDismiss={() => {
        button.blur();
      }}
    />
  );
};
