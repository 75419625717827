import { mergeStyleSets } from '@fluentui/react';
import React from 'react';
import CollapsiblePanel from '../../shared/CollapsiblePanel';
import Theme from '../../shared/Theme';

const localStyles = mergeStyleSets({
  secondaryTh: {
    selectors: {
      p: {
        padding: '5px 0 0 0',
        margin: '0',
      },
    },

  },
});

export default ({ featureUsage, styles }) => {
  const getFeatureTitle = (featureName) => {
    switch (featureName) {
      case 'calculator': return 'Preview calculation';
      case 'userCalculator': return 'End-user calculation';
      case 'apiCalculator': return 'API calculation';
      default: return featureName;
    }
  };

  const formatValue = (value) => Number(value).toLocaleString();

  return (
    <CollapsiblePanel
      collapsible
      collapsed={false}
      title={getFeatureTitle(featureUsage.feature)}
      keyOverride={featureUsage.feature}
      styles={{
        header: { fontSize: 'initial' },
        icon: { fontSize: 'smaller', marginTop: '3px' },
        ...styles,
      }}
    >
      <table className={[Theme.styles.table, Theme.styles.tableNoPadding, Theme.styles.tableText, Theme.styles.tableRowHighlight].join(' ')}>
        <thead>
          <tr>
            <th>Category</th>
            <th>Usage</th>
            <th>Cap</th>
          </tr>
        </thead>
        <tbody>
          <tr><th colSpan={3} className={localStyles.secondaryTh}><p>Calculation Count</p></th></tr>
          <tr>
            <td>Total</td>
            <td>{formatValue(featureUsage.meter.totalCount)}</td>
            <td>{formatValue(featureUsage.limit.totalCount)}</td>
          </tr>
          <tr>
            <td>Hourly</td>
            <td>{formatValue(featureUsage.meter.hourlyCount)}</td>
            <td>{formatValue(featureUsage.limit.hourlyCount)}</td>
          </tr>
          <tr>
            <td>Daily</td>
            <td>{formatValue(featureUsage.meter.dailyCount)}</td>
            <td>{formatValue(featureUsage.limit.dailyCount)}</td>
          </tr>
          <tr>
            <td>Weekly</td>
            <td>{formatValue(featureUsage.meter.weeklyCount)}</td>
            <td>{formatValue(featureUsage.limit.weeklyCount)}</td>
          </tr>
          <tr>
            <td>Monthly</td>
            <td>{formatValue(featureUsage.meter.monthlyCount)}</td>
            <td>{formatValue(featureUsage.limit.monthlyCount)}</td>
          </tr>

          <tr><th colSpan={3} className={localStyles.secondaryTh}><p>CPU Time (ms)</p></th></tr>
          <tr>
            <td>Total</td>
            <td>{formatValue(featureUsage.meter.totalCpuTime)}</td>
            <td>{formatValue(featureUsage.limit.totalCpuTime)}</td>
          </tr>
          <tr>
            <td>Hourly</td>
            <td>{formatValue(featureUsage.meter.hourlyCpuTime)}</td>
            <td>{formatValue(featureUsage.limit.hourlyCpuTime)}</td>
          </tr>
          <tr>
            <td>Daily</td>
            <td>{formatValue(featureUsage.meter.dailyCpuTime)}</td>
            <td>{formatValue(featureUsage.limit.dailyCpuTime)}</td>
          </tr>
          <tr>
            <td>Weekly</td>
            <td>{formatValue(featureUsage.meter.weeklyCpuTime)}</td>
            <td>{formatValue(featureUsage.limit.weeklyCpuTime)}</td>
          </tr>
          <tr>
            <td>Monthly</td>
            <td>{formatValue(featureUsage.meter.monthlyCpuTime)}</td>
            <td>{formatValue(featureUsage.limit.monthlyCpuTime)}</td>
          </tr>

          <tr><th colSpan={3} className={localStyles.secondaryTh}><p>Memory (MiB)</p></th></tr>
          <tr>
            <td>Total</td>
            <td>{formatValue(featureUsage.meter.totalMemory)}</td>
            <td>{formatValue(featureUsage.limit.totalMemory)}</td>
          </tr>
          <tr>
            <td>Hourly</td>
            <td>{formatValue(featureUsage.meter.hourlyMemory)}</td>
            <td>{formatValue(featureUsage.limit.hourlyMemory)}</td>
          </tr>
          <tr>
            <td>Daily</td>
            <td>{formatValue(featureUsage.meter.dailyMemory)}</td>
            <td>{formatValue(featureUsage.limit.dailyMemory)}</td>
          </tr>
          <tr>
            <td>Weekly</td>
            <td>{formatValue(featureUsage.meter.weeklyMemory)}</td>
            <td>{formatValue(featureUsage.limit.weeklyMemory)}</td>
          </tr>
          <tr>
            <td>Monthly</td>
            <td>{formatValue(featureUsage.meter.monthlyMemory)}</td>
            <td>{formatValue(featureUsage.limit.monthlyMemory)}</td>
          </tr>
        </tbody>
      </table>
    </CollapsiblePanel>
  );
};
