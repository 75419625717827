import React from 'react';
import * as d3 from 'd3';
import cytoscape from 'cytoscape';
import contextMenus from 'cytoscape-context-menus';
import 'cytoscape-context-menus/cytoscape-context-menus.css';
// import dblclick from 'cytoscape-dblclick';
import { mergeStyleSets } from '@fluentui/react';
import { deepEqual } from 'fast-equals';
import ChartDefaults from '../../../shared/ChartDefaults';
import Store from '../../../shared/state/Store';
import CytoReducer from './CytoReducer';
import ModellerReducer from '../../ModellerReducer';
import ModellerDefaults from '../../ModellerDefaults';
import GraphUtils from '../../GraphUtils';
import ModelUtils from '../../ModelUtils';
import UiReducer from '../../../shared/state/UiReducer';

cytoscape.use(contextMenus);

const componentStyles = mergeStyleSets({
  container: {
    width: '100%',
    height: ModellerDefaults.ComponentDefaults.ComponentHeight,
    border: '1px solid #e4e4e4',
  },
});

const color = () => {
  const scale = d3.scaleOrdinal(d3.schemeCategory10);
  return (d) => scale(d.group);
};

export const getColor = (group) => (ChartDefaults.Colors[group] ? ChartDefaults.Colors[group] : color);

export default ({
  data, graphKey,
}) => {
  const [globalState, dispatch] = Store.useStore();
  // const [readyTimeoutId, setReadyTimeoutId] = React.useState();
  const [, setZoomPanWriteTimeoutId] = React.useState();
  const [preChartData, setPreChartData] = React.useState();
  const graphRef = React.useRef(null);
  const [cy, setCy] = React.useState();
  const [rightClickedNode, setRightClickedNode] = React.useState();

  const setGraph = (fGraph) => {
    dispatch(ModellerReducer.mapGraph(graphKey, fGraph));
  };
  const getGraph = () => ModellerReducer.getMappedGraph(globalState, graphKey);

  const doPositionObservationNodesFor = (node) => {
    const parent = node.parent();
    // console.log(parent.data().id, parent.children());
    const obsNodes = parent.children('.observation') || [];

    if (obsNodes.length === 0) {
      return;
    }

    const nodeX = node.position('x');
    const nodeY = node.position('y');
    // const nodeWidth = node.width();
    const nodeHeight = node.height();
    // const newX = nodeX - nodeWidth / 2;

    let cumulativeHeightOffset = nodeY + nodeHeight / 2 + ModellerDefaults.GraphDefaults.ChartModeExtraHeight;
    // if (node.classes().includes(`mode_${ModellerDefaults.NodeModes.PLAIN}`)) {
    //   cumulativeHeightOffset += 5;
    // }

    obsNodes.forEach((obsNode) => {
      cumulativeHeightOffset += 5;
      obsNode.position('x', nodeX);
      obsNode.position('y', cumulativeHeightOffset);
      obsNode.removeClass('hidden');
      // console.log('move', obsNode.data().name, 'x', nodeX, 'y', cumulativeHeightOffset);
      cumulativeHeightOffset += obsNode.height();
    });
  };

  const doPositionAllObservationNodes = () => {
    const graph = getGraph();
    if (!graph) {
      return;
    }
    // console.log('doPositionAllObservationNodes', getGraph().nodes('.observation'));
    graph.nodes('.primary').forEach((node) => doPositionObservationNodesFor(node));
  };

  const doNodeDoubleClick = (event) => {
    const updateBatch = [];

    const selectedNodes = (event.cy || cy).$('node:selected') || [];
    let skipAutoSelect = false;
    if (selectedNodes.length === 0) {
      selectedNodes.push(event.target);
      skipAutoSelect = true;
    }
    selectedNodes.selectify(); // Restore selectability after single normal click event
    const currentClasses = selectedNodes[0]?.classes() || [];
    const modeChart = currentClasses.includes(`mode_${ModellerDefaults.NodeModes.CHART}`);
    selectedNodes.forEach((node) => {
      if (modeChart) {
        node.removeClass(`mode_${ModellerDefaults.NodeModes.CHART}`);
        node.addClass(`mode_${ModellerDefaults.NodeModes.PLAIN}`);
      } else {
        node.removeClass(`mode_${ModellerDefaults.NodeModes.PLAIN}`);
        node.addClass(`mode_${ModellerDefaults.NodeModes.CHART}`);
      }
      updateBatch.push({
        networkId: node.data().networkId,
        nodeId: node.data().nodeId,
        updateBody: { classes: node.classes() },
      });
      doPositionObservationNodesFor(node);
    });

    dispatch(CytoReducer.updateNodeGraphics({ updateBatch }));

    // selectedNodes.forEach((node) => {
    //   updateBatch.push({
    //     networkId: node.data().networkId,
    //     nodeId: node.data().nodeId,
    //     updateBody: { mode: node.data().mode === Defaults.NodeModes.CHART ? Defaults.NodeModes.PLAIN : Defaults.NodeModes.CHART },
    //   });
    // });
    // updateNodeGraphics(updateBatch);

    // console.log('classes:', selectedNodes);
    if (!skipAutoSelect) {
      setTimeout(() => {
        selectedNodes.select();
      }, 50);
    }
  };

  React.useEffect(() => {
    // Regenerate node context menu
    // Now only depends on the graph object, but later can include changes to datasets/observations
    if (!cy) {
      return;
    }


    const generateDataEntrySubmenu = () => {
      if (!rightClickedNode) {
        return undefined;
      }

      const gData = rightClickedNode.data();
      const { networkId, nodeId } = gData;
      const model = ModellerReducer.getModel(globalState);
      const network = ModelUtils.getNetwork({ model, networkId });
      const node = ModelUtils.getNode({ network, nodeId });

      return {
        id: 'enter-data',
        content: 'Enter data',
        // tooltipText: 'Enter data',
        selector: 'node.primary',
        submenu: ModellerReducer.getModel(globalState).dataSets.map((dataSet) => {
          const datasetId = dataSet.id;
          const submenu = [];

          const observation = ModellerReducer.getMappedObservation(globalState, datasetId, networkId, nodeId);
          const softEvidenceEntered = observation?.entries?.length > 1;
          const observationValue = softEvidenceEntered ? 'Soft Evidence' : observation?.entries?.[0].value;
          const hasVariables = Object.keys(node.configuration.variables || {}).length > 0;

          if (gData.continuous) {
            // For continuous nodes can only input a number
            if (observation) {
              submenu.push({
                id: `enter-data-${GraphUtils.escapeSelector(datasetId)}-current`,
                content: `Current: ${observation.entries[0].value}`,
                onClickFunction: () => {},
                hasTrailingDivider: true,
                disabled: true,
              });
            }

            submenu.push({
              id: `enter-data-${GraphUtils.escapeSelector(datasetId)}-enter`,
              content: 'Enter data',
              // tooltipText: 'Enter data',
              onClickFunction: () => {
                // Show data entry popup
                // console.log(event, `enter data for ${event.target.data().networkId} . ${event.target.data().nodeId} in ${datasetId}`);
                dispatch(UiReducer.setGenericUiAttribute(ModellerDefaults.ComponentKeys.ModellerDataEntryPopupKey, {
                  type: 'numeric', datasetId, networkId, nodeId, networkName: network.name, nodeName: node.name, nodeData: node.configuration,
                }));
                setRightClickedNode();
              },
              hasTrailingDivider: true,
            });

            if (hasVariables) {
              submenu.push({
                id: `enter-data-${GraphUtils.escapeSelector(datasetId)}-enter-variables`,
                content: 'Node variables',
                onClickFunction: () => {
                  dispatch(UiReducer.setGenericUiAttribute(ModellerDefaults.ComponentKeys.ModellerDataEntryPopupKey, {
                    type: 'variables', datasetId, networkId, nodeId, networkName: network.name, nodeName: node.name, nodeData: node.configuration,
                  }));
                  setRightClickedNode();
                },
                hasTrailingDivider: true,
              });
            }
          }

          if (gData.discrete) {
            // For discrete nodes can select a state

            if (node.configuration && node.configuration.states) {
              node.configuration.states.forEach((state, stateIndex) => {
                submenu.push({
                  id: `enter-data-${GraphUtils.escapeSelector(datasetId)}-enter-${state}`,
                  content: ((!softEvidenceEntered && state === observationValue) ? '✓ ' : '') + state,
                  // tooltipText: state,
                  onClickFunction: () => {
                    // console.log(event, `enter data for ${networkId} . ${nodeId} in ${datasetId} => ${state}`);
                    dispatch(ModellerReducer.setObservation(datasetId, networkId, nodeId, state));
                    dispatch(CytoReducer.setRepaintRequested());
                    setRightClickedNode();
                  },
                  hasTrailingDivider: (node.configuration.states.length - 1) === stateIndex,
                });
              });

              submenu.push({
                id: `enter-data-${GraphUtils.escapeSelector(datasetId)}-enter-soft-evidence`,
                content: `${softEvidenceEntered ? '✓ ' : ''}Soft evidence`,
                onClickFunction: () => {
                  dispatch(UiReducer.setGenericUiAttribute(ModellerDefaults.ComponentKeys.ModellerDataEntryPopupKey, {
                    type: 'soft', datasetId, networkId, nodeId, networkName: network.name, nodeName: node.name, nodeData: node.configuration,
                  }));
                  setRightClickedNode();
                },
                hasTrailingDivider: true,
              });
            }
          }

          if (observation) {
            submenu.push({
              id: `clear-data-${GraphUtils.escapeSelector(datasetId)}-clear`,
              content: 'Clear data',
              // tooltipText: 'Clear data',
              onClickFunction() {
                // console.log(`clear data for ${event.target.data().networkId} . ${event.target.data().nodeId} in ${datasetId}`);
                dispatch(ModellerReducer.setObservation(datasetId, networkId, nodeId, ''));
                dispatch(CytoReducer.setRepaintRequested());
                setRightClickedNode();
              },
            });
          }

          return {
            id: `enter-data-${GraphUtils.escapeSelector(dataSet.id)}`,
            content: dataSet.id,
            // tooltipText: `Enter data to ${dataSet.id}`,
            submenu,
          };
        }),

      };
    };

    const generateResultsSubmenu = () => {
      if (!rightClickedNode) {
        return [undefined];
      }
      const gData = rightClickedNode.data();
      const { networkId, nodeId } = gData;

      // If no results are mapped for this node, do not present menu options to see them
      const mappedResultsInDatasets = Object.keys(globalState.modeller?.mappedResults || {});
      if (mappedResultsInDatasets.length === 0 || !mappedResultsInDatasets.map((dsId) => (ModellerReducer.getMappedResult(globalState, dsId, networkId, nodeId) ? '1' : '')).join('')) {
        return [undefined];
      }

      const model = ModellerReducer.getModel(globalState);
      const network = ModelUtils.getNetwork({ model, networkId });
      const node = ModelUtils.getNode({ network, nodeId });
      const menuItems = [];
      if (gData.continuous) {
        menuItems.push({
          id: 'display-results-summary-statistics',
          content: 'Summary statistics',
          selector: 'node.primary',
          onClickFunction: () => {
            dispatch(UiReducer.setGenericUiAttribute(ModellerDefaults.ComponentKeys.ModellerResultsDialogKey, {
              mode: 'summaryStatistics', networkId, networkName: network.name, node,
            }));
            setRightClickedNode();
          },
        });
      }

      menuItems.push({
        id: 'display-results-marginals',
        content: 'Marginal probabilities',
        selector: 'node.primary',
        onClickFunction: () => {
          dispatch(UiReducer.setGenericUiAttribute(ModellerDefaults.ComponentKeys.ModellerResultsDialogKey, {
            mode: 'marginals', networkId, networkName: network.name, node,
          }));
          setRightClickedNode();
        },
        hasTrailingDivider: true,
      });

      return menuItems;
    };

    try {
      cy.contextMenus({
        menuItems: [
          {
            id: 'close-chart',
            content: 'Close chart',
            // tooltipText: 'Close chart',
            // image: {src: "assets/remove.svg", width: 12, height: 12, x: 6, y: 4},
            selector: `node.primary.mode_${ModellerDefaults.NodeModes.CHART}`,
            onClickFunction: (event) => {
              // console.log('context event', event);
              doNodeDoubleClick(event);
            },
            hasTrailingDivider: true,
          },
          {
            id: 'open-chart',
            content: 'Open chart',
            // tooltipText: 'Open chart',
            // image: {src: "assets/remove.svg", width: 12, height: 12, x: 6, y: 4},
            selector: `node.primary.mode_${ModellerDefaults.NodeModes.PLAIN}`,
            onClickFunction: (event) => {
            // console.log('context event', event);
              doNodeDoubleClick(event);
            },
            hasTrailingDivider: true,
          },
          generateDataEntrySubmenu(),
          ...generateResultsSubmenu(),
          // {
          //   id: 'add-node',
          //   content: 'add node',
          //   tooltipText: 'add node',
          //   image: {src: "assets/add.svg", width: 12, height: 12, x: 6, y: 4},
          //   coreAsWell: true,
          //   onClickFunction: function (event) {
          //     var data = {
          //       group: 'nodes'
          //     };

          //     var pos = event.position || event.cyPosition;

          //     cy.add({
          //       data: data,
          //       position: {
          //         x: pos.x,
          //         y: pos.y
          //       }
          //     });
          //   }
          // },
          // {
          //   id: 'select-all-nodes',
          //   content: 'select all nodes',
          //   selector: 'node',
          //   coreAsWell: true,
          //   show: true,
          //   onClickFunction: function (event) {
          //     selectAllOfTheSameType('node');

          //     contextMenu.hideMenuItem('select-all-nodes');
          //     contextMenu.showMenuItem('unselect-all-nodes');
          //   }
          // },
          // {
          //   id: 'unselect-all-nodes',
          //   content: 'unselect all nodes',
          //   selector: 'node',
          //   coreAsWell: true,
          //   show: false,
          //   onClickFunction: function (event) {
          //     unselectAllOfTheSameType('node');

          //     contextMenu.showMenuItem('select-all-nodes');
          //     contextMenu.hideMenuItem('unselect-all-nodes');
          //   }
          // },
          // {
          //   id: 'select-all-edges',
          //   content: 'select all edges',
          //   selector: 'edge',
          //   coreAsWell: true,
          //   show: true,
          //   onClickFunction: function (event) {
          //     selectAllOfTheSameType('edge');

          //     contextMenu.hideMenuItem('select-all-edges');
          //     contextMenu.showMenuItem('unselect-all-edges');
          //   }
          // },
          // {
          //   id: 'unselect-all-edges',
          //   content: 'unselect all edges',
          //   selector: 'edge',
          //   coreAsWell: true,
          //   show: false,
          //   onClickFunction: function (event) {
          //     unselectAllOfTheSameType('edge');

          //     contextMenu.showMenuItem('select-all-edges');
          //     contextMenu.hideMenuItem('unselect-all-edges');
          //   }
          // }

        ].filter((el) => el),

        // menuItemClasses: ['custom-menu-item'],
        // contextMenuClasses: ['custom-context-menu'],
        submenuIndicator: { src: '/assets/submenu-indicator-default.svg', width: 12, height: 12 },
      });
    } catch (error) {
      console.log('Failed to create context menu:', error);
    }
  }, [cy, rightClickedNode]);

  const saveZoomLevel = () => {
    const fGraph = getGraph();
    if (!fGraph) {
      console.log('Can`t save zoom level as graph is undefined', graphKey);
      return;
    }
    const updateBody = {
    // eslint-disable-next-line no-underscore-dangle
      zoom: fGraph._private.zoom,
      zoomPosition: fGraph.pan(),
    };
    console.log('Saving zoom:', updateBody, graphKey);

    if (graphKey === ModellerDefaults.FlatModelGraphKey) {
      // Update model level graphics
      dispatch(CytoReducer.updateModelGraphics(updateBody, true));
    } else {
      // Update network graphics
      const updateBatch = [
        {
          networkId: fGraph.nodes().first().data().networkId,
          updateBody,
        },
      ];
      dispatch(CytoReducer.updateNetworkGraphics({ updateBatch }));
    }
  };

  const enqueueZoomPanSave = () => {
    const timerId = setTimeout(() => {
      saveZoomLevel();
    }, 50);

    setZoomPanWriteTimeoutId((prevId) => {
      clearTimeout(prevId);
      return timerId;
    });
  };

  React.useEffect(() => {
    if (graphKey !== ModellerReducer.getSelectedGraphKey(globalState)) {
      return;
    }
    if (!preChartData) {
      return;
    }
    // console.log('prechart', data);
    if (graphRef.current) {
      const chartData = {
        container: graphRef.current,
        boxSelectionEnabled: true,
        // panningEnabled: false,
        userZoomingEnabled: false,
        layout: {
          name: preChartData.layout || 'breadthfirst',
          fit: !preChartData.zoom, // If we have custom zoom, disable auto-fit
        },
        zoom: preChartData.zoom,
        pan: preChartData.zoomPosition,
        style: [
          // {
          //   default: true,
          //   selector: 'node',
          //   style: {
          //     label: 'data(name)',

          //   },
          // },
          // {
          //   default: true,
          //   selector: 'label',
          //   style: {

          //   },
          // },
          {
            default: true,
            selector: 'node.primary',
            style: {
              width: `${ModellerDefaults.DefaultGraphics.graph.width}px`,
              height: `${ModellerDefaults.DefaultGraphics.graph.height}px`,
              'background-color': 'white',
              'text-valign': 'center',
              'text-halign': 'center',
              content: 'data(name)',
              'text-wrap': 'wrap',
              'text-max-width': (node) => node.width() - 4,
              'border-width': '1px',
              'border-color': 'black',
              padding: '5px',
            },
          },
          {
            default: true,
            selector: 'edge',
            style: {
              'target-arrow-shape': 'triangle',
              'target-arrow-color': 'black',
              'source-arrow-color': 'black',
              'line-color': '#333',
              width: 1.5,
              'curve-style': 'bezier',
              'arrow-scale': 1.5,
            },
          },
          {
            default: true,
            selector: 'edge.dashed',
            style: {
              'line-style': 'dashed',
            },
          },
          {
            default: true,
            selector: 'node.primary:selected',
            style: {
              // 'underlay-color': 'gray',
              // 'underlay-opacity': 1,
              // 'underlay-padding': '5px',

              'outline-offset': '10px',
              'outline-width': '1px',
              'outline-opacity': 1,
              'outline-color': 'black',

            },
          },
          // {
          //   default: true,
          //   selector: 'node[^.mode_chart]',
          //   style: {
          //     'background-image': 'none',
          //   },
          // },
          {
            default: true,
            selector: `node.primary.mode_${ModellerDefaults.NodeModes.CHART}`,
            style: {
              shape: 'rectangle',
              // 'background-image': `url('${example.chartUrlSvg}')`,
              // 'background-fit': 'cover',
              // 'background-fit': 'contain',
              // 'background-repeat': 'no-repeat',
              'background-width-relative-to': 'inner',
              'background-height-relative-to': 'inner',
              // 'background-width': (elNode) => `${elNode.width() * (getGraph()?._private?.zoom || 1)}px`,
              // 'background-height': (elNode) => `${elNode.height() * (getGraph()?._private?.zoom || 1)}px`,
              // 'background-width': (elNode) => `${elNode.width()}px`,
              // 'background-height': (elNode) => `${elNode.height()}px`,
              // 'background-width': (elNode) => '200px',
              // 'background-height': (elNode) => '100px',
              // 'background-width': '150%',
              // 'background-height': '100%',
              'background-position-x': '0px',
              'background-position-y': '0px',
              'background-offset-y': '25px',
              // 'background-image-opacity': 0.5,
              // 'text-max-width': '10000px',
              // 'text-margin-y': '-5px',
              // 'text-background-color': 'red',
              // 'text-background-opacity': 1,
              // 'text-outline-color': 'black',
              // 'text-border-width': '1px',
              height: `${ModellerDefaults.DefaultGraphics.graph.height + ModellerDefaults.GraphDefaults.ChartModeExtraHeight}px`,
              'text-valign': 'center',
              'text-margin-y': (node) => -node.height() / 2 + 10,
              'text-wrap': 'ellipsis',
            },
          },
          {
            default: true,
            selector: 'node.wrapper',
            style: {
              'border-width': '0px',
              'background-opacity': 0,
            },
          },
          {
            default: true,
            selector: 'node.hidden',
            style: {
              display: 'none',
            },
          },
          {
            default: true,
            selector: 'node.observation',
            style: {
              shape: 'rectangle',
              'background-color': 'data(color)',
              'text-valign': 'center',
              'text-halign': 'right',
              content: 'data(name)',
              'text-wrap': 'none',
              'text-max-width': '9999px',
              'border-width': '1px',
              width: '20px',
              height: '20px',
              'text-margin-x': '5px',
              'text-background-padding': '5px',
              'text-background-color': 'white',
              'text-background-opacity': 0.8,
              // 'z-compound-depth': 'top',
              fontSize: '10px',
            },
          },
        ],
      };
      chartData.elements = preChartData.elements;
      if (preChartData.style) {
        chartData.style = chartData.style.concat(preChartData.style);
      }

      const cyto = cytoscape(chartData);
      setGraph(cyto);
      setCy(cyto);
      console.log('Graph object', graphKey, cyto, chartData);

      cyto.ready(() => {
        console.log('Graph ready', graphKey);
        // const timerId = setTimeout(() => {
        //   try {
        //     const model = ModellerReducer.getModel(globalState);
        //     let zoomLevel;
        //     let zoomPosition;
        //     if (graphKey === ModellerDefaults.FlatModelGraphKey) {
        //     // Check zoom level in model graphics
        //       zoomLevel = model.graphics?.cyto?.zoom;
        //       zoomPosition = model.graphics?.cyto?.zoomPosition;
        //     } else {
        //     // Check zoom level in network graphics
        //       const { networkId } = cyto.nodes().first().data();
        //       const graphics = model.networks?.filter((network) => network.id === networkId)?.[0]?.graphics?.cyto;
        //       zoomLevel = graphics?.zoom;
        //       zoomPosition = graphics?.zoomPosition;
        //     }
        //     if (zoomLevel) {
        //       // console.log('zooming', zoomLevel, zoomPosition, graphKey);
        //       cyto.zoom({
        //         level: zoomLevel,
        //       });
        //       cyto.pan(zoomPosition);
        //       // cyto.center();
        //     } else {
        //       cyto.fit(cyto.nodes(), 30);
        //     }
        //   } catch (error) {
        //     console.log(error);
        //   }
        // }, 200);

        // setReadyTimeoutId((prevId) => {
        //   clearTimeout(prevId);
        //   return timerId;
        // });

        if (cyto.options().layout.name !== 'preset') {
          console.log('Node positions initialised with', cyto.options().layout.name);
          // After nodes are positioned, remember their positions in node graphics

          const nodeMap = {};
          const model = ModellerReducer.getModel(globalState);
          (model.networks || []).forEach((fNet) => {
            nodeMap[fNet.id] = {};
            (fNet.nodes || []).forEach((fNode) => {
              nodeMap[fNet.id][fNode.id] = fNode;
            });
          });

          const nodeGraphicsUpdateBatchPosition = [];
          const nodeGraphicsUpdateBatchVisibility = [];
          cyto.nodes().forEach((node) => {
            const { networkId, nodeId } = node.data();

            nodeGraphicsUpdateBatchPosition.push({
              networkId,
              nodeId,
              updateBody: {
                [graphKey]: node.position(),
              },
            });

            nodeGraphicsUpdateBatchVisibility.push({
              networkId,
              nodeId,
              updateBody: {
                visible: nodeMap?.[networkId]?.[nodeId]?.graphics?.visible !== false,
              },
            });
          });

          dispatch(CytoReducer.updateNodeGraphics({ updateBatch: nodeGraphicsUpdateBatchVisibility, updatePath: 'graphics' }));
          dispatch(CytoReducer.updateNodeGraphics({ updateBatch: nodeGraphicsUpdateBatchPosition, updatePath: 'graphics.graph.position' }));

          // Set specific graph layout to preset (model level or network level)
          const updateBody = {
            layout: 'preset',
          };

          if (graphKey === ModellerDefaults.FlatModelGraphKey) {
            // Update model level graphics
            console.log('Update model graphics', updateBody);
            dispatch(CytoReducer.updateModelGraphics(updateBody, true));
          } else {
            // Update network graphics
            const updateBatch = [
              {
                networkId: cyto.nodes().first().data().networkId,
                updateBody,
              },
            ];
            console.log('Update network graphics', updateBatch);
            dispatch(CytoReducer.updateNetworkGraphics({ updateBatch }));
          }
        }

        setTimeout(() => {
          doPositionAllObservationNodes();
        }, 25);
      });

      cyto.on('cxttapstart', 'node.primary', (event) => {
        setRightClickedNode(event.target);
      });

      cyto.on('grab', 'node.primary', (event) => {
        // Unselect all nodes except this one
        const selectedNodes = event.cy.$('node:selected');
        selectedNodes.forEach((node) => {
          if (node !== event.target) {
            // node.unselect();
          }
        });
      });

      cyto.on('drag', 'node.primary', (event) => {
        doPositionObservationNodesFor(event.target);
      });

      cyto.on('dragfreeon', 'node.primary', (event) => {
        const updateBatch = [];

        const { networkId, nodeId } = event.target.data();
        const update = {
          networkId,
          nodeId,
          updateBody: {},
        };
        update.updateBody[graphKey] = event.target.position();
        updateBatch.push(update);
        dispatch(CytoReducer.updateNodeGraphics({ updateBatch, updatePath: 'graphics.graph.position' }));
      });

      cyto.on('click', 'node.primary', (event) => {
        // console.log('normal click', event);
        const node = event.target;
        const selectedNodes = cyto.$('node:selected');
        // console.log('selected nodes', selectedNodes);
        // if (selectedNodes.length === 1 && selectedNodes[0] !== event.target){
        //   // Click on another node, which is not selected
        //   // Allow default behaviour - quick select
        // }
        if (selectedNodes.length > 0 && selectedNodes.includes(event.target)) {
          // Some nodes selected, including this one
          // Prevent default selection behaviour and wait for double click
          node.unselectify();
        }
      });

      cyto.on('pan', () => {
        enqueueZoomPanSave();
      });

      cyto.on('voneclick', 'node.primary', (event) => {
        // console.log('single click', event);
        const node = event.target;
        node.selectify(); // Restore selectability after single normal click event
        node.select();
      });

      cyto.on('vdblclick', 'node.primary', (event) => {
        // console.log('double click', event.target, event.target.data(), event.target.classes());
        doNodeDoubleClick(event);
      });
    }
  }, [preChartData, ModellerReducer.getSelectedGraphKey(globalState)]);

  React.useEffect(() => {
    if (!deepEqual(data, preChartData)) {
      setPreChartData(data);
    }
  }, [data]);

  const wheelListener = (event) => {
    // console.log('wheel', graphKey, getGraph());
    if (event.ctrlKey) {
      const fGraph = getGraph();
      if (!fGraph) {
        return;
      }
      // eslint-disable-next-line no-underscore-dangle
      const currZoom = fGraph._private.zoom;
      const zoomMod = (event.deltaY > 0) ? -1 : 1;
      const newZoomLevel = Math.max(0.1, currZoom + zoomMod * 0.05);
      fGraph.zoom({
        level: newZoomLevel,
        renderedPosition: { x: event.offsetX, y: event.offsetY },
      });

      enqueueZoomPanSave();

      event.preventDefault();
      event.stopPropagation();
    }
  };
  const keyboardListener = (event) => {
    if (event.ctrlKey && (event.keyCode === 48 || event.key === '0')) {
      const fGraph = getGraph();
      // console.log('reset zoom', fGraph);
      if (fGraph) {
        fGraph.reset();
        // setTimeout(() => {
        //   console.log('zoom level:', fGraph._private.zoom);
        // }, 100);
        enqueueZoomPanSave();
      }
    }

    if (event.altKey && (event.keyCode === 49 || event.key === '1')) {
      const fGraph = getGraph();
      if (fGraph) {
        fGraph.fit(fGraph.nodes(), 10);
        enqueueZoomPanSave();
      }
    }

    if (event.ctrlKey && (event.keyCode === 97 || event.keyCode === 65 || event.key === 'a' || event.key === 'A')) {
      const fGraph = getGraph();
      if (fGraph) {
        fGraph.nodes().select();
      }
      event.preventDefault();
      event.stopPropagation();
    }

    if (event.ctrlKey && (event.keyCode === 189 || event.keyCode === 109 || event.key === '-' || event.code === 'Minus' || event.code === 'NumpadSubtract')) {
      const fGraph = getGraph();
      if (!fGraph) {
        return;
      }
      // eslint-disable-next-line no-underscore-dangle
      const currZoom = fGraph._private.zoom;
      const zoomMod = -1;
      const newZoomLevel = Math.max(0.1, currZoom + zoomMod * 0.05);
      fGraph.zoom({
        level: newZoomLevel,
      });

      enqueueZoomPanSave();

      event.preventDefault();
      event.stopPropagation();
    }

    if (event.ctrlKey && (event.keyCode === 187 || event.keyCode === 107 || event.key === '+' || event.code === 'Equal' || event.code === 'NumpadAdd')) {
      const fGraph = getGraph();
      if (!fGraph) {
        return;
      }
      // eslint-disable-next-line no-underscore-dangle
      const currZoom = fGraph._private.zoom;
      const zoomMod = 1;
      const newZoomLevel = Math.max(0.1, currZoom + zoomMod * 0.05);
      fGraph.zoom({
        level: newZoomLevel,
      });

      enqueueZoomPanSave();

      event.preventDefault();
      event.stopPropagation();
    }
  };

  // Bind special events
  React.useEffect(() => {
    // console.log('Bind reset zoom');
    document.addEventListener('keydown', keyboardListener);
    return () => {
      // Component will dismount
      // console.log('Unbinding');
      // clearTimeout(readyTimeoutId);
      document.removeEventListener('keydown', keyboardListener);
      saveZoomLevel();
    };
  }, []);

  React.useEffect(() => {
    if (graphRef.current) {
      graphRef.current.addEventListener('wheel', wheelListener);
    }
  }, [graphRef.current]);

  return (
    <div
      id={`graph_container_${graphKey}`}
      className={componentStyles.container}
      ref={graphRef}
    />
  );
};
