import { Icon, mergeStyleSets } from '@fluentui/react';
import React from 'react';
import Store from '../../shared/state/Store';
import AssignButton from './AssignButton';
// import UiReducer from '../../shared/state/UiReducer';
// import AppsApi from '../../shared/util/AppsApi';

const styles = mergeStyleSets({
  key: {
    display: 'inline-block',
    margin: '2px 0 2px 0',
  },

  assignedKey: {
    fontWeight: 600,
  },

  assignedIcon: {
    cursor: 'pointer',
  },

  hide: {
    display: 'none',
  },


});

export default ({ keyData }) => {
  const [globalState, dispatch] = Store.useStore();
  const {
    key, expiry, policy, condition, product, assignable, allowsCreators, disabled, disabledReason, size, assignments, licensedTo, granted, owner, assigned,
  } = keyData;

  const [expanded, setExpanded] = React.useState(false);
  return (
    <tr>
      {/* <td>v</td> */}
      <td><span className={[styles.key, (assigned || granted) ? styles.assignedKey : undefined].join(' ')}>{key}</span></td>
      <td>
        <Icon
          iconName="EditCreate"
          className={(!disabled && allowsCreators && (assigned || granted)) ? styles.assignedIcon : styles.hide}
          title="This key grants you Creator access"
        />
      </td>
      <td>{new Date(expiry).toLocaleDateString(navigator.languages[0], { dateStyle: 'medium' })}</td>
      <td>{`${granted ? '?' : assignments.length} / ${assignable ? size : 0}`}</td>
      <td>{assignable && !disabled && <AssignButton licenseKey={keyData} />}</td>
      <td>{`${product}`.toLowerCase()}</td>
      <td>{condition}</td>
      <td>{policy}</td>
      <td>{disabledReason}</td>
      <td>{owner}</td>
    </tr>
  );
};
