import React from 'react';
import ModelProperties from './ModelProperties';
import SummaryStatsMarginals from './SummaryStatsMarginals';
import ObservationEntryNumeric from './ObservationEntryNumeric';
import ObservationEntrySoftEvidence from './ObservationEntrySoftEvidence';
import ObservationEntryNodeVariables from './ObservationEntryNodeVariables';

export default () => (
  <>
    <ObservationEntrySoftEvidence />
    <ObservationEntryNumeric />
    <ObservationEntryNodeVariables />
    <ModelProperties />
    <SummaryStatsMarginals />
  </>
);
